import React, { useState, useEffect, useContext } from 'react'
import { Captions } from '../../../../../Services/Hooks/captions';

import style from './UploadFile.module.css'

const UploadFile = ({ type, error, file, setFile, hideUploadButton, clearField }) => {
    const [base64, setBase64] = useState(file);
    const [uploadFaild, setUploadFaild] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const {getCaption} = useContext(Captions);
    
    useEffect(() => {
        setBase64(file);
        if(!file) {
            setUploadPercentage(0);
        }
    }, [file, type])

    useEffect(() => {
        if(uploadFaild) {
            setUploadPercentage(100);
        }
    }, [uploadFaild])

    useEffect(() => {
        if(error) {
            setUploadFaild(true);
            setUploadPercentage(100);
        }
    }, [error])
    
    const onChange = e => { 
        const file = e.target.files[0];
        const fileSize = e.target.files[0].size;
        
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        
        if (validImageTypes.includes(fileType)) {
            setUploadFaild(false);
            
            if(fileSize <= 5000000) {
                setUploadPercentage(40);
                getBase64(file)
                    .then(result => {
                        setUploadPercentage(100);
                        setBase64(result);
                        setFile(type, result);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            } else {
                setErrorMessage('first.image.invalid.size')
                setBase64('');
                setFile(type, '');
                setUploadFaild(true);
            }
        } else {
            setErrorMessage(null);
            setBase64('');
            setFile(type, '');
            setUploadFaild(true);
        }

        return
    }
    
    const getBase64 = (file) => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                baseURL = reader.result.replace("data:", "").replace(/^.+,/, "");
                resolve(baseURL);
            };
        });
    }

    return (
        <div className={style.row}>
            <div className={style.title}>
                {uploadPercentage === 100 ? 
                    <div className={style.uploaded}>
                        { uploadFaild ? 
                            <div className={style.failedUpload}>
                                {error || errorMessage ? getCaption('web.client.verification.image.invalid.size') : getCaption('web.client.verification.file.upload.faild')}
                            </div> :
                            <>
                                <div className={style.succesUploaded}>{getCaption('web.client.verification.file.uploaded')}</div>
                                <div className={style.clearFile} onClick={() => clearField(type)} />
                            </>
                        }
                    </div> : 
                    <span>{getCaption(`web.client.verification.file.side.${type}`)}</span>
                }
            </div>
            <div className={`${style.progress} ${uploadFaild ? style.warning : ''}`}>
                <div style={{ width: `${uploadPercentage}%` }} />
            </div>
            <div className={`${style.imageView} ${style[type]}`}>
                {base64 && <img src={'data:image/png;base64,' + base64} alt={type} />}
            </div>
            {!hideUploadButton && 
                <label htmlFor={"upload" + type}>
                    <div className={style.buttonStyle}>
                        <span className={style.uploadIcon} />
                        <p>{getCaption('web.client.verification.choose.file')}</p>
                    </div>
                    <input 
                        value=""
                        className={style.upload} 
                        id={"upload" + type} 
                        onChange={onChange}
                        type="file" 
                    />
                </label>
            }
            <div className={style.description}>
                <p>{getCaption('web.client.verification.files.format')}</p>
                <p>{getCaption('web.client.verification.files.size')}</p>
            </div>
        </div>
    )
}

export default UploadFile