import React, { useState, useContext } from 'react';
import { PostValidateOtp } from '../../../Services/service';
import CustomInput from '../../CustomInput/CustomInput';
import ContactsPhoneNumber from '../../../Components/ContactsPhoneNumber/ContactsPhoneNumber';
import { Captions } from '../../../Services/Hooks/captions';

import style from './AttributeValidate.module.css'

function AttributeValidate({fieldInfo, contactMobileCode, nextStep, attributeName, mobileCode }) {
    const [mobileCodeSelectedValue, setMobileCodeSelectedValue] = useState(contactMobileCode.fieldValue);
    const [form, setForm] = useState({
        [attributeName]: fieldInfo.fieldValue
    });

    const [error, setError] = useState(false)
    const { getCaption } = useContext(Captions);
    
    const changeHandler = input => e => {
        setForm({ [input]: e.target.value });
    }

    const validateOtp = (e) => {
        e.preventDefault();
        const newForm = {
            attributeName: attributeName,
            verificationFields: {
                [attributeName]: {
                    fieldName: attributeName,
                    fieldValue: form[attributeName]
                }
            }
        }

        if(attributeName !== 'contacts.email') {
            newForm.verificationFields[contactMobileCode.fieldName] = {
                fieldName: contactMobileCode.fieldName,
                fieldValue: mobileCodeSelectedValue
            }
        }
        PostValidateOtp(newForm)
            .then(res => {      
                if(res.data) {
                    nextStep();
                }
            })
    }
    
    const updateContactsPhoneNumber = (option, value) => {
        if(option === 'contacts.mobile.code') {
            return setMobileCodeSelectedValue(value);
        }
        setForm({...form, [option]: value});
    }
    
    return (
        <>
            <h3 className={style.modalTitle}>{getCaption(`client.verification.${attributeName}`)}</h3>
            <form className={style.form} onSubmit={(e) => validateOtp(e)}>
                <div className={style.inputGroup + ' ' + style[attributeName.replaceAll(".", "_")]}>
                    {attributeName !== 'contacts.email' ? 
                        <div className={style.selectContainer}>
                            <ContactsPhoneNumber 
                                formData={form}
                                errorCode={error}
                                setData={(option, value) => updateContactsPhoneNumber(option, value)}
                                label={getCaption('web.fields.placeholder.phone')}
                            />
                        </div> : 

                        <div className={style.email}>
                            <CustomInput 
                            className={'labelAnimate'}
                            type="text" 
                            name={attributeName.replaceAll(".", "_")} 
                            value={form[attributeName]}
                            onChangeHandler={changeHandler(attributeName)}
                            label={getCaption('web.fields.placeholder.email')}
                            placeholder={getCaption('web.fields.placeholder.email')}
                        />
                        </div>
                    }
                </div>
                <button className={style.submitHandler}>{getCaption(`client.verification.send.confirmation.${attributeName}`)}</button>
            </form>
        </>
    )
}

export default AttributeValidate;
