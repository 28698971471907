import React from 'react'
import style from './Sponsors.module.css'

const Sponsors = (props) => {
    return (
        <div className={style.sponsors}>
            {props.data && props.data.sort((a, b) => a.orderNumber - b.orderNumber).map((payment, index) =>
                <img key={index} className={style.payment} src={payment.source} alt={payment.productName} />
            )}
        </div>
    )
}

export default Sponsors;