import React, { useState, useEffect, useContext } from 'react';
import { Captions } from '../../Services/Hooks/captions';
import CloseButton from '../CloseButton/CloseButton';
import style from './PaymentSuccessMessage.module.css';

export const PaymentSuccessMessage = ({ paymentSuccessCallBack }) => {
    const [showMessage, setShowMessage] = useState(false);
    const { getCaption } = useContext(Captions);
    useEffect(() => {
        window.addEventListener("message", function (event) {
            if(event.data.message === "PaymentSuccess") {
                setShowMessage(true);
                const timerId = setTimeout(function() {
                    paymentSuccessCallBack();
                    setShowMessage(false);
                }, 4000)
                return () => clearTimeout(timerId);
            }
        });
    }, [])

    return <>
        {showMessage &&
            <div className={style.content}>
                <CloseButton clickHandler={() => {
                    paymentSuccessCallBack();
                    setShowMessage(false);
                }} />
                <div className={style.centerSuccess}>
                    <div className={style.icon} />
                    <h5>{getCaption('web.client.payment.success')}</h5>
                </div>
            </div>
        }
    </>
}
