import React, { useState } from 'react'
import style from './CustomAlert.module.css'
import CloseButton from '../CloseButton/CloseButton'
const CustomAlert = ({message, type}) => {
    const [showAlert, setShowAlert] = useState(true)
    return (
      showAlert &&  <div className={`${style.popupMessage} ${style[type]}`}>
            <h3 className={style.msg}>{message}</h3>
            <div>
                <CloseButton type="small" clickHandler={() => setShowAlert(false)} />
            </div>
        </div>
    )
}

export default CustomAlert