import React from 'react';
import style from './CloseButton.module.css'

function CloseButton({ clickHandler, type }) {
    return <button 
                type="button" 
                className={`${style.close} ${style[type] ? style[type] : ''}`} 
                onClick={() => clickHandler()} 
            />
}

export default CloseButton;
