import React, {useContext, useState} from 'react';
import style from  './DailySliderItem.module.css';
// import { Captions } from '../../../../../../Services/Hooks/captions';
// import { Link } from "react-router-dom";

function DailySliderItem() {
    // const pageName = 'WelcomePage';
    // const { getCaption } = useContext(Captions);
    // const [hideTimer, setHideTimer] = useState(false);

    // const onTimeout = () => {
    //     if(!props.promotionDetails.isExpired) {
    //         props.onTimeout();
    //     }  
    // }
    
    // const toggleTimer = () => {
    //     setHideTimer(!hideTimer);
    // }
    return (
        <div className={style.item}>
            <div className={style.itemHeader}>
                <span>
                    today
                </span>
               
            </div>
            <div className={style.itemContent}>
              Day 10
            </div>
        </div>
    )
}
export default DailySliderItem;