import React, {useState, useEffect, useContext} from 'react'
import { useLocation } from 'react-router-dom'
import style from './JetXPage.module.css'
import PageBanners from '../PageBanners/PageBanners'
import JetX from './JetX/JetX'
import { GetGames } from '../../../Services/service'
import { Captions } from '../../../Services/Hooks/captions'

const JetXPage = (props) => {
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const { pathname } = useLocation();
    const { getCaption } = useContext(Captions);
    useEffect(() => {
        // const interval = setInterval(() => {
            GetGames(props.pageName)
                .then(res => {
                    if(res.data !== "" ) { 
                        setResponse({ 
                            loading: false, 
                            data: res.data 
                        });
                        
                    } else {
                        setResponse({ 
                            loading: true, 
                            data: undefined 
                        });
                    }
                })
        // }, 1000);
        // return () => clearInterval(interval);
    }, [props.pageName]);
    return (
        <>
            <PageBanners pathName={pathname.replace('/', '') + 'Main'} />
            <h2 className={style.categoryAll}>
                {getCaption('web.games.page.category.title.all')}
            </h2>
            { !response.loading && <div className={style.background}>
                <div className={style.wrapper}>
                   {response.data.map(Jetx =>
                        <JetX data={Jetx} key={Jetx.pageSectionId} pageName={props.pageName} />
                    )}
                </div>
            </div> }
        </> 
    )
}

export default JetXPage;