import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import style from './SportsPage.module.css'

function SportsPage() {
    const [url, setUrl] = useState('http://testsports.ad.smartsoft.ge/SportsFrame/Sports.aspx');
    const { pathname } = useLocation();
    
    const decreament = pathname.replace('/', '').toLocaleLowerCase() === 'live' ? 0 : 582;
    useEffect(() => {
        if(pathname.replace('/', '').toLocaleLowerCase() === 'live') {
            setUrl('http://testsports.ad.smartsoft.ge/SportsFrame/LiveBetting.aspx');
        } else {
            setUrl('http://testsports.ad.smartsoft.ge/SportsFrame/Sports.aspx');
        }
    }, [pathname])
    
    useEffect(() => {
        const liveHeight = 3439;
        window.addEventListener("message", function (event) {
            if(event.data.messageType === 'sportsIframSizeUpdate') {
                const doc = document.documentElement
                if(pathname.replace('/', '').toLocaleLowerCase() === 'live') {
                    if(event.data.height < liveHeight) {
                        doc.style.setProperty('--iframe-height', `${event.data.height ? (liveHeight) + 'px' : '100vh'}`)
                    } else {
                        doc.style.setProperty('--iframe-height', `${event.data.height ? (event.data.height - decreament) + 'px' : '100vh'}`)
                    }
                } else {
                    doc.style.setProperty('--iframe-height', `${event.data.height ? (event.data.height - decreament) + 'px' : '100vh'}`)
                }
            } 
        });
    })
    return (
        <div className={style.iframeParent}>
            <iframe className={style.iframe} title='title' allow="fullscreen" src={url} />
        </div>
    )
}

export default SportsPage
