import React from 'react'
import FooterMenu from './FooterMenu/FooterMenu'
import Sponsors from './Sponsors/Sponsors'
import Certificates from './Certificates/Certificates'
import { GetMainMenu } from '../../../../Services/service'
const Footer = () => {
    let { loading, data } = GetMainMenu();
    // console.log(data)
    return (
        <>
            {!loading && <div>
                <FooterMenu data={data.footerMenu} socialMediaBanners={data.socialNetworkBanner} />
                <Sponsors data={data.paymentBanners}/>
                <Certificates data={data.footerBanners} footerMainCaption={data.mainFooterMenu}  supportInfo={data.supportFooterMenu}/>
            </div>}
        </>
    )
}

export default Footer;