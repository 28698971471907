import React, { useState, useContext } from 'react';
import style from './GameFilter.module.css';
import useMedia from '../../Services/Hooks/useMedia'
import { Captions } from '../../Services/Hooks/captions';
import { useClickOutside } from '../../Services/Hooks/outsideClick';


const GameFilter = ({ active, categoryStatus }) => {
    const { getCaption } = useContext(Captions);
    const smallScreen = useMedia("(max-width: 1280px)");
    let [dropdownShown, setDropdownShown] = useState(false);


    const menuItems = [
        { id: 1, captionKey: getCaption("web.game.filter.other.categoty.popular"), dropDown: false },
        { id: 2, captionKey: getCaption("web.game.filter.other.categoty.new"), dropDown: false },
        { id: 3, captionKey: getCaption("web.game.filter.other.categoty.favorites"), dropDown: false },
        { id: 4, captionKey: getCaption("web.game.filter.other.categoty.recently.played"), dropDown: false },
        { id: 5, captionKey: getCaption("web.game.filter.other.categoty.jackpot"), dropDown: false },
        { id: 6, captionKey: getCaption("web.game.filter.other.categoty.recommended"), dropDown: false },
        { id: 7, captionKey: getCaption("web.game.filter.other.categoty.exclusive"), dropDown: true },
        { id: 8, captionKey: getCaption("web.game.filter.other.categoty.bonus"), dropDown: true },

    ]

    const handleDropdown = () => {
        setDropdownShown(!dropdownShown);
    }
    let domNode = useClickOutside(() => {
        let modal = document.body.classList.contains('ReactModal__Body--open')
        if (!modal) setDropdownShown(false)
    })

    return (
        <>
            <div className={style.gameFilterContainer}>
                <div className={style.gameFilterMenu}>
                    {
                        menuItems.map(items =>
                            <div key={items.id} index={items.id} className={`${style.gameFilterMenuItem} 
                                    ${style[items.captionKey.replace(' ', '')]} ${active[items.id] ? style[items.captionKey.replace(' ', '') + 'Active'] : ""}  `}
                                onClick={categoryStatus(items.id, items.captionKey)}>
                                <span>
                                    {items.captionKey}
                                </span>
                            </div>
                        )
                    }

                </div>
                <div className={style.gameFilterDropDown} ref={domNode}>
                    <li className={`${style.dropDownTitle} + ${dropdownShown ? style.up : style.down} `} onClick={handleDropdown} >
                        other
                    </li>

                    {dropdownShown &&
                        <div className={style.dropDownContent}>
                            {dropdownShown &&

                                menuItems.map(menuItems =>
                                    <li key={menuItems.id} index={menuItems.id} className={`${style.listItem} ${active[menuItems.id] ? style[menuItems.captionKey.replace(' ', '') + 'Active'] : ""}`} onClick={categoryStatus(menuItems.id, menuItems.captionKey)} >
                                        {(smallScreen && menuItems.captionKey.length > 10) ? menuItems.captionKey.substring(0, 10) + '...' : menuItems.captionKey}
                                    </li>
                                )

                            }
                        </div>
                    }
                </div>

            </div>

        </>

    );
}
export default GameFilter;