import React, {useState, useEffect, useContext} from 'react';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import CustomInput from '../../../Components/CustomInput/CustomInput';
import CloseButton from '../../../Components/CloseButton/CloseButton';
import MultiBannersSlider from '../../../Components/Slider/MultiBannersSlider';
import { PaymentFailMessage } from '../../../Components/PaymentMessages/PaymentFailMessage';
import { PaymentSuccessMessage } from '../../../Components/PaymentMessages/PaymentSuccessMessage';
import DepositCardItem from "./DepositCardItem/DepositCardItem";
import PaymentModal from './PaymentModal/PaymentModal';
import { Captions } from '../../../Services/Hooks/captions';
import { Auth } from '../../../Services/Hooks/auth';
import {GetClientPayments, GetClientPaymentUrl, SendPaymentNotification} from "../../../Services/service";
import {ConsoleLog, SetItemToLocalStorage, GetItemFromLocalStorage} from '../../../Services/common';

import style from './Deposit.module.css';

function Deposit({ modalType, closeDepositModal, typeInvoice }) {
    const [currentPaymentList, setCurrentPaymentList] = useState({});
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [amount, setAmount] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [invoiceType, setInvoiceType] = useState(typeInvoice || 'Deposit');
    const [paymentIframeUrl, setPaymentIframeUrl] = useState(null);
    const [activePaymentIndex, setActivePaymentIndex] = useState(0);
    const [showException, setShowException] = useState(null);
    const [amountOldIndex, setAmountOldIndex] = useState(null);
    const { getCaption } = useContext(Captions);
    const { clientInfo, updateClientInfo } = useContext(Auth);

    useEffect(() => {
        GetClientPayments()
            .then(res => {
                setData(res.data);
                setLoading(false);
                if(res.data.availablePayments) {
                    setCurrentPaymentList(res.data.availablePayments[0])
                    // setAmount(res.data.availablePayments[0].transferFastInsertValues[0])
                }
            })
            .catch(error => {
                ConsoleLog(error);
            })
    }, [])

    useEffect(() => {
        window.addEventListener("message", function (event) {
            if(event.data.message === "PaymentSuccess") {
                // closeDepositModal();
                setPaymentIframeUrl(null);
                updateClientInfo(true);
                if(!GetItemFromLocalStorage('invoiceNumber')) return;
                SendPaymentNotification(GetItemFromLocalStorage('invoiceNumber'))
                    .then(res => {
                        ConsoleLog(res)
                    })
                    .catch(error => {
                        ConsoleLog(error)
                    })
                localStorage.removeItem('invoiceNumber');
            }
        });
    }, [])
    const getPaymentParameters = () => {
        const { productName, channelName } = data.availablePayments[activePaymentIndex];
        setShowLoader(true);
        GetClientPaymentUrl(channelName, productName, invoiceType, amount)
            .then(res => {
                if(res.data.url) {
                    setShowLoader(false);
                    setPaymentIframeUrl(res.data.url);
                    if (res.data.invoiceNumber) SetItemToLocalStorage('invoiceNumber', res.data.invoiceNumber);
                }
            })
            .catch(error => {
                setShowLoader(false);
                if(error.response) {
                    if(error.response.status === 400 && error.response.data.errors) {
                        setShowException(getCaption(error.response.data.errors.amount[0]));
                    } else if (error.response.status === 500) {
                        setShowException(getCaption(error.response.data));
                    }
                }
                ConsoleLog(error);
            })
    }

    const handleChange = () => e => {
        setAmountOldIndex(null);
        setAmount(e.target.value > 0 ? e.target.value : '');
    }

    const updateAmountHandler = (itemsAmount, index) => {
        setAmountOldIndex(index);
        if(amountOldIndex === index) {
            return setAmount(amount + itemsAmount);
        }
        setAmount(itemsAmount);
    }
    return (
        <>
            <CustomModal
                isOpen={modalType && !loading}
                closeModal={closeDepositModal}
            >
                {Object.keys(currentPaymentList).length > 0 && clientInfo && <div className={style.deposit}>
                    <PaymentSuccessMessage paymentSuccessCallBack={() => closeDepositModal()} />
                    {showException && <PaymentFailMessage show={true} message={showException} update={() => setShowException(null)} />}

                    <CloseButton clickHandler={closeDepositModal} />

                    <div className={style.tabs}>
                        <h3>{getCaption('web.deposit.button.' + invoiceType.toLocaleLowerCase())}</h3>
                    </div>

                    <div className={style.balance}>
                        {clientInfo.balance + ' ' + clientInfo.currencyCode}
                    </div>

                    <form>
                        <div className={style.formGroup}>
                            <div className={style.inputGroup}>
                                <CustomInput
                                    className="autoWidth"
                                    name="amount"
                                    type="number"
                                    value={amount}
                                    label="Amount of money"
                                    placeholder="Amount of money"
                                    onChangeHandler={handleChange()}
                                />
                            </div>
                            {
                                currentPaymentList.transferFastInsertValues.map((item, index) => {
                                    return <div key={index} onClick={() => updateAmountHandler(item, index)} className={style.amount}>{item + ' ' + currentPaymentList.currencyCode}</div>
                                })
                            }
                        </div>

                        <p>{currentPaymentList.transferFee} {clientInfo.currencyCode} {getCaption('web.deposit.amount.fee')}</p>

                        <div className={style.depositDescription}>
                            {
                                currentPaymentList.clientLimits.map((item, index) => {
                                    return <div key={index}>{getCaption(`client.deposit.limits.${item.calculateType}`)} <span>{item.amount + ' '  + currentPaymentList.currencyCode}</span></div>
                                })
                            }
                        </div>

                        <div className={style.slider}>
                            <MultiBannersSlider
                                pagination={false}
                                spaceBetweenSlides={5}
                                showSlideCounter={3.8}
                                autoplay={false}
                                loop={false}
                                className={"deposit"}
                                itemsList={!loading && data.availablePayments.map((item, index) =>
                                    <DepositCardItem
                                        iconUrl={item.iconUrl}
                                        active={index === activePaymentIndex}
                                        productName={item.productName}
                                        channelName={item.channelName}
                                        clickHandler={() => {
                                            setActivePaymentIndex(index);
                                            setCurrentPaymentList(data.availablePayments[index]);
                                        }}
                                    />
                                )}
                            />
                        </div>

                        <button
                            type="button"
                            disabled={showLoader}
                            className={style.submit}
                            onClick={getPaymentParameters}
                        >
                            {!showLoader ?
                                getCaption('web.deposit.button.' + invoiceType.toLocaleLowerCase()) :
                                <div className={style.loading}>
                                    <div className={style.carousel} />
                                </div>
                            }
                        </button>

                    </form>

                </div>
                }
            </CustomModal>
            {paymentIframeUrl &&
            <PaymentModal
                isMobile={false}
                paymentIframeUrl={paymentIframeUrl}
                closeDepositModal={() => setPaymentIframeUrl(null)}
            />
            }
        </>
    )
}

export default Deposit
