import React, { useState, useEffect, useContext } from 'react'
import style from './Header.module.css'
import HeaderLogo from '../../../../Components/HeaderLogo/HeaderLogo'
import MobileSearch from './Search/Search'
import Registration from '../../ClientAuthentication/Registration/Registration'
import WelcomePopUp from './WelcomePopUp/WelcomePopUp'
import { GetItemFromLocalStorage } from "../../../../Services/common"
import { PostRefreshToken } from "../../../../Services/service"
// import LoginButton from "../../ClientAuthentication/Login/LoginButton/LoginButton"
import Login from "../../ClientAuthentication/Login/Login"
import jwt_decode from "jwt-decode";
import { Auth } from '../../../../Services/Hooks/auth';
import DepositButton from '../../Deposit/DepositButton/DepositButton'
import { Captions } from '../../../../Services/Hooks/captions'
import { useHistory } from 'react-router'
const Header = () => {
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [registerModalShow, setRegisterModalShow] = useState(false);
    const { auth, authenticate, clientInfo, logOut } = useContext(Auth);
    const { getCaption } = useContext(Captions);
    let [triggerDeposit, setTriggerDeposit] = useState(false);
    const handleTriggerDeposit = (position) => {
        setTriggerDeposit(position);
    }
    const redirectToPromo = () => {
        history.push("/Promotions")
    }
    let history = useHistory();
    useEffect(() => {
        if (auth) {
            setLoginModalShow(false);
            const refreshToken = () => {
                PostRefreshToken({
                    refreshToken: GetItemFromLocalStorage('refreshToken'),
                    accessToken: GetItemFromLocalStorage('accessToken')
                }).then(res => {
                        let token = res.data.accessToken;
                        if(!token || res.data.errorCode === 'client.is.kicked') {
                            logOut();
                            return;
                        }
                        let decodedToken = jwt_decode(token);
                        authenticate(token, decodedToken, res.data.refreshToken);
                    })
            }

            const checkExpiredToken = () => {
                let expiredTime = GetItemFromLocalStorage('expiredToken');
                let dateToEpoch = new Date(0);
                dateToEpoch.setUTCSeconds(expiredTime);
                let expireTime = (dateToEpoch - new Date()) / 1000;
                if (expireTime <= 60) refreshToken();
            }

            const checkTokenExpired = setInterval(() => {
                checkExpiredToken();
            }, 1000);
            return () => clearInterval(checkTokenExpired);
        }
    }, [auth, logOut, authenticate]);

    const toggleRegisterPopup = () => {
        setLoginModalShow(!loginModalShow);
        setRegisterModalShow(!registerModalShow);
    }

    const toggleLoginPopup = () => {
        setLoginModalShow(!loginModalShow);
    }

    return (
        <>
            <WelcomePopUp />
            <header className={style.header}>
                <HeaderLogo />
                <div className={style.wrapper}>
                    {auth ?
                        <>
                            {clientInfo && <div className={`${style.deposit} ${!clientInfo.isWalletHide ? style.isWalletHide : ''}`}>
                                <span>{clientInfo && clientInfo.balance + ' ' + clientInfo.currencyCode}</span>
                                <DepositButton
                                    popupType="Deposit"
                                    isDeposit={true}
                                    type={"allowParent"}
                                    triggerDeposit={triggerDeposit}
                                    handleTriggerDeposit={handleTriggerDeposit}
                                />
                            </div>}
                            <div className={style.promo} onClick={redirectToPromo} />
                        </> : 
                        <>  
                            <div className={style.promo} onClick={redirectToPromo} />
                            <Registration buttonType={'default'} />
                            <Login showLoginButtons={true} toggleRegisterPopup={toggleRegisterPopup} toggleLoginPopup={toggleLoginPopup} isPopup={false} redirectUrl={false} />
                            {/*<LoginButton toggleLoginPopup={toggleLoginPopup} />*/}
                        </>
                    }
                </div>
            </header>
        </>
    )
}

export default Header;