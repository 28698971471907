import { GetItemFromLocalStorage } from "../common";
class Authentication {
    constructor() {
        this.authenticated = true;
    }
  
    LogIn(cb) {
        this.authenticated = true;
        cb();
    }
  
    LogOut(cb) {
        this.authenticated = false;
        cb();
    }
  
    IsAuthenticated() {
        return GetItemFromLocalStorage('expiredToken');
    }
}
  
export default new Authentication();