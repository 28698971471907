import React, { useEffect, useState } from 'react';
// import { Iframe } from './Iframe/Iframe';
import style from './GameContent.module.css';
import { isMobile } from "react-device-detect";
import MobileMenu from '../../../Pages/Mobile/MobileMenu/MobileMenu';
import Iframe from 'react-iframe-click';

const GameContent = (props) => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    let clientY = null;
    const showMenu = (e) => {

        if (!clientY) {
            clientY = e.targetTouches[0].clientY;
        }

        if (clientY - 2 > e.targetTouches[0].clientY) setShowMobileMenu(true);
        if (clientY + 2 < e.targetTouches[0].clientY) setShowMobileMenu(false);

    }

    let elem = document.documentElement;
    const openFullscreen = () => {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }

    return (
        <>
            <div className={!isMobile ? style.container : style.containerMob}>
                <Iframe allow="autoplay *" title={'games'} src={props.url} onInferredClick={openFullscreen} />
                {
                    isMobile && <div className={style.gameFooterMenu}>
                        <span className={style.menuButton} onTouchMove={(e) => showMenu(e)} ></span>
                    </div>

                }
                {
                    showMobileMenu && <div className={style.menuInGame} onTouchMove={(e) => showMenu(e)}><MobileMenu /></div>
                }
            </div>
        </>

    )
}

export default GameContent;