import React, { useState, useEffect, useContext } from 'react'
import UploadFile from './UploadFile/UploadFile'
import Success from './Succsess/Success'
import { Captions } from '../../../../Services/Hooks/captions';
import { IdPersonalVerification } from '../../../../Services/service';
import { Auth } from '../../../../Services/Hooks/auth'

import style from './IdVerification.module.css'

const IdVerification = () => {
    const [file, setFile] = useState({
        front: null,
        back: null
    });

    const [ error, setError ] = useState(false);
    const [ showLoader, setShowLoader ] = useState(false);
    const [ successSummited, setSuccessSummited ] = useState(false);
    const { clientInfo, updateClientInfo } = useContext(Auth);
    const { getCaption } = useContext(Captions);

    useEffect(() => {
        if(clientInfo) {
            if(clientInfo.idPersonalInfoVerifiedStatus === "Pending") setSuccessSummited(true);
        }
    }, [clientInfo])

    const setFileHandler = (name, code) => {
        setFile({ ...file, [name]: code })
    }
    
    const submitForm = (e) => {
        e.preventDefault();
        setShowLoader(true);
        const newForm = {
            attributeName: "photo.verification",
            verificationFields: {
                "id.photo.first.page": {
                    "fieldName": "id.photo.first.page",
                    "fieldValue": file.front
                },
                "id.photo.second.page": {
                    "fieldName": "id.photo.second.page",
                    "fieldValue": file.back
                }
            }
        }

        IdPersonalVerification(newForm)
            .then(res => {
                setShowLoader(false);
                if(res.status === 200) {
                    setSuccessSummited(res.data);
                    window.scrollTo(0, 0);
                    updateClientInfo();
                }
            })
            .catch(error => {
                if(error.response) {
                    setError(error.response.data)
                    console.log(error.response.data);
                }
                setShowLoader(false);
                console.log(error)
            })
    }

    const clearFieldHandler = (name) => {
        setFile({ ...file, [name]: null })
    }

    return (
        <>
            { !successSummited ?
                <div className={style.row}>
                    <div className={style.status}>
                        <div className={style.statusIcon} />
                        <h1>{getCaption('web.client.verification.required')}</h1>
                    </div>

                    <div className={style.content}>
                        <p>{getCaption('web.client.verification.description')}</p>
                        <form onSubmit={submitForm}>
                            <div className={style.fileUploader}>
                                <UploadFile 
                                    type="front" 
                                    error={error}
                                    file={file.front}
                                    setFile={setFileHandler} 
                                    clearField={clearFieldHandler}
                                    hideUploadButton={file.front && file.back} 
                                />
                                <UploadFile 
                                    type="back" 
                                    error={error}
                                    file={file.back}
                                    setFile={setFileHandler} 
                                    clearField={clearFieldHandler}
                                    hideUploadButton={file.front && file.back} 
                                />
                            </div>
                            {file.front && file.back &&
                                <button className={style.submit} type='submit' disabled={showLoader}>
                                    { showLoader ?
                                    <div className={style.loading}>
                                        <div className={style.carousel} />
                                    </div> :
                                    <span>{getCaption('web.client.verification.button.submit.files')}</span>
                                    }
                                </button>
                            }
                        </form>
                    </div>
                </div> :
                <Success text1={getCaption('web.client.verification.success.title')} text2={getCaption('web.client.verification.success.message')} />
            }
        </>
    )
}

export default IdVerification