import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import style from './SportsPage.module.css'

function SportsPage() {
    const [url, setUrl] = useState('http://testsports.ad.smartsoft.ge/SportsFrame/Mobile/Sports.aspx');
    const { pathname } = useLocation();
    
    useEffect(() => {
        if(pathname.replace('/', '').toLocaleLowerCase() === 'live') {
            setUrl('http://testsports.ad.smartsoft.ge/SportsFrame/Mobile/LiveGames.aspx')
        } else {
            setUrl('http://testsports.ad.smartsoft.ge/SportsFrame/Mobile/Sports.aspx')
        }
    }, [pathname])

    const decreament = pathname.replace('/', '').toLocaleLowerCase() === 'live' ? 0 : 0;
    useEffect(() => {
        window.addEventListener("message", function (event) {
            if(event.data.messageType === 'sportsIframSizeUpdate') {
                const doc = document.documentElement
                doc.style.setProperty('--iframe-height', `${event.data.height ? (event.data.height - decreament) + 'px' : '100vh'}`)
            } 
        });
    })

    return (
        <div className={style.iframeParent}>
            <iframe className={style.iframe} title='title' allow="fullscreen" src={url} />
        </div>
    )
}

export default SportsPage