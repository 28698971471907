import React, { useState, useEffect, useContext, useRef } from 'react'
import style from './GameItems.module.css'
import { GetGames } from '../../../../Services/service'
import GameAuthenticatedRedirect from '../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect'
import { Captions } from '../../../../Services/Hooks/captions'
import MultiBannersSlider from '../../../../Components/Slider/MultiBannersSlider'
import { useHistory } from 'react-router'
const GameItems = (props) => {
    const { getCaption } = useContext(Captions);
    const [clickedButton, setClickedButton] = useState(false);
    let history = useHistory();
    let buttonRef = useRef();
    return (
        <>
            {clickedButton ? history.push('/' + buttonRef.current) :
                <div className={style.section}>
                    <div className={style.categoryHeader}>
                        <h2 className={`${style.category} ${style[getCaption(props.pageSectionTitle)]}`}>{getCaption(props.pageSectionTitle)}</h2>
                        <button className={style.button}
                            onClick={() => { window.scrollTo(0, 0); setClickedButton(!clickedButton); buttonRef.current = props.pageSectionType }} >
                        </button>
                    </div>
                    <div className={style.categoryContent}>
                        <MultiBannersSlider
                            type="banner"
                            preventClicks={true}
                            loop={false}
                            pagination={((props.index % 2 === 0 && props.items.length > 3) || (props.index % 2 === 1 && props.items.length > 5))}
                            spaceBetweenSlides={30}
                            showSlideItem={props.index % 2 === 0 ? 3 : 5}
                            showSlideCounter={props.index % 2 === 0 ? 1 : 1}
                            className={"category"}
                            itemsList={props.items.map(game => <div className={style.game}>
                                <GameAuthenticatedRedirect src={game.bannerName} alt={game.productName} rerender={props.rerender} setResponse={props.setResponse} 
                                    channelName={game.channelName} productName={game.productName} gameName={game.captionKey} pageName={props.pageName} isFavorite={game.othersCategory.includes("myfavorites")} />
                            </div>)}
                        />
                    </div>
                </div>}
        </>
    )
}

export default GameItems;