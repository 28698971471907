import React, {useContext} from 'react'
import VerticalMenu from '../../VerticalMenu/VerticalMenu'
import { Captions } from '../../../../../../../Services/Hooks/captions'
import style from './SlotsRules.module.css'
import HorizontalSubMenu from '../HorizontalSubMenu/HorizontalSubMenu'


const SlotsRules = () => {
    const { getCaption } = useContext(Captions);
    return (
        <div className={style.container}>
        <div className={style.aside}>
         <VerticalMenu />
        </div>
        <div className={style.main}>
            <h1 className={style.title}>
                Game Rules
                {/* {getCaption('platform.gamerules.title')} */}
            </h1>
            <div >
                <HorizontalSubMenu />
            </div>
            <p className={style.subTitle}>
                {/* {getCaption('platform.gamerules.slots.rules.title')} */}
                slot rules
            </p>
            <p className={style.text}>
                {/* {getCaption('platform.gamerules.slots.rules.text1')} */}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum. Aliquam erat volutpat. Nam elementum sem et tellus faucibus, ut molestie libero accumsan. Nulla accumsan nulla quis elit auctor viverra. Donec pharetra quam tortor, sed vehicula dolor posuere a. Donec interdum turpis in turpis viverra tincidunt. Integer tincidunt sollicitudin tincidunt. Donec sed ligula velit.
            </p>
            <ul className={style.list}>
                <li>
                    {/* {getCaption('platform.gamerules.slots.rules.item1')} */}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna.
                </li>
                <li>
                    {/* {getCaption('platform.gamerules.slots.rules.item2')} */}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna.
                </li>
                <li>
                    {/* {getCaption('platform.gamerules.slots.rules.item3')} */}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. 
                </li>
            </ul>
            <h2 className={style.subTitle}>
                {/* {getCaption('platform.gamerules.slots.rules.title2')} */}
                Lorem ipsum
            </h2>
            <p className={style.text}>
                {/* {getCaption('platform.gamerules.slots.rules.text2')} */}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum. Aliquam erat volutpat. Nam elementum sem et tellus faucibus, ut molestie libero accumsan. Nulla accumsan nulla quis elit auctor viverra. Donec pharetra quam tortor, sed vehicula dolor posuere a. Donec interdum turpis in turpis viverra tincidunt. Integer tincidunt sollicitudin tincidunt. Donec sed ligula velit. <br/>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum. Aliquam erat volutpat. Nam elementum sem et tellus faucibus, ut molestie libero accumsan. Nulla accumsan nulla quis elit auctor viverra. Donec pharetra quam tortor, sed vehicula dolor posuere a. Donec interdum turpis in turpis viverra tincidunt. Integer tincidunt sollicitudin tincidunt. Donec sed ligula velit.
            </p>
            <div className={style.button}>{getCaption('web.responsible.gaming.description.contact.us')}</div>
        </div>
    </div>
    )
}

export default SlotsRules;