import React from 'react';
import CloseButton from '../CloseButton/CloseButton';
import CustomModal from "../CustomModal/CustomModal";

import style from './MobileMenuFromBottom.module.css'

const MobileMenuFromBottom = (props) => {
    return (
        <CustomModal
            isOpen={props.hideListMenu}
            className={style.modal}
            onRequestClose={() => props.setHideListMenu()}
        >
            <div className={`${!props.hideListMenuAfterDelay ? style.menuIsHide : ''} ${style.menu}`}>
                <CloseButton clickHandler={() => props.closeModal()}/>
                <div className={`${style.menuContent} ${style[props.customClass] ? style[props.customClass] : ''}`}>
                    <div className={style.menuContainer}>
                        <h3 className={style.menuTitle}>{props.title}</h3>
                        <div>{props.children}</div>
                        <div className={style.menuList}>
                            {props.menuItemList}
                        </div>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default MobileMenuFromBottom