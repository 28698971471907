import React from 'react'
import Modal from 'react-modal'
import style from './Loader.module.css'

function Loader() {
    Modal.setAppElement("#root")
    return (
        <Modal
            isOpen={true}
            className={style.modal}
            overlayClassName={style.overlay}
            disableAutoFocus={true}
            disableEnforceFocus={true}
        >
            {/* <div className={style.loading}></div> */}
            
            <div className={style.loading}>
                <div className={style.carousel} />
            </div>
        </Modal>
    )
}

export default Loader
