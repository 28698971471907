import React, {useContext} from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { GetImagePath } from '../../Services/common'
import style from './Table.module.css'
import { Captions } from '../../Services/Hooks/captions'
const Table = (props) => {
    const columns = props.columns
    const data = props.data
    const tableInstance = useTable({
        columns,
        data
    }, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state,
        gotoPage,
        pageCount,
        prepareRow,
    } = tableInstance

    const { pageIndex } = state
    const { getCaption } = useContext(Captions)

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                !column.hideHeader ? null : (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span className={style.sort}>
                                            {!column.disableSortBy ?
                                                <>
                                                    <img className={style.sortUp} src={GetImagePath("transactions/SortUpArrow.png")} id={(column.isSorted && column.isSortedDesc) ? style.hide : ""} />
                                                    <img className={style.sortDown} src={GetImagePath("transactions/SortDownArrow.png")} id={(column.isSorted && !column.isSortedDesc) ? style.hide : ""} />
                                                </>
                                                : ""}
                                        </span>
                                    </th>)
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={style[`tr${pageIndex}${index}`]}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className={style.pagination}>
                {/* <span>
                   Page &nbsp; 
                   <strong>
                       {pageIndex + 1} of  {pageOptions.length} 
                   </strong> &nbsp;
                </span> */}
                <span>
                    {getCaption('web.table.go.to.page') + ':'} &nbsp;
                    <input type="number" defaultValue={pageIndex + 1}
                        onChange={e => {
                            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(pageNumber)
                        }}
                        id={style.pageNumber} />&nbsp;
                </span>
                <div className={style.buttons}>
                    <button onClick={() => {gotoPage(0); window.scrollTo(0,0)}} disabled={!canPreviousPage} id={style.first}></button>
                    <button onClick={() => {previousPage(); window.scrollTo(0,0)}} disabled={!canPreviousPage} id={style.prev}>{getCaption('web.table.previous.page')}</button>
                    <button onClick={() => {nextPage(); window.scrollTo(0,0)}} disabled={!canNextPage} id={style.next}>{getCaption('web.table.next.page')}</button>
                    <button onClick={() => {gotoPage(pageCount - 1); window.scrollTo(0,0)}} disabled={!canNextPage} id={style.last}></button>
                </div>
            </div>
        </>
    )
}

export default Table;

