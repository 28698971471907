import React, { useState, useEffect, useContext } from 'react';
import { Auth } from '../../../../Services/Hooks/auth';
import ContactsPhoneNumber from '../../../../Components/ContactsPhoneNumber/ContactsPhoneNumber';
import VerificationModal from '../../../../Components/VerificationModal/VerificationModal';
// import ContactsMobileCode from '../../../../Components/ContactsMobileCode/ContactsMobileCode';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import { Captions } from '../../../../Services/Hooks/captions';

import style from './ContactInformation.module.css';

function ContactInformation({ contactMobileCode, fieldInfo, mobileCode, handlerCallBack, submitForm, page, errors, clientDetailsForm }) {
    const [modal, setModal] = useState({ open: false, name: '' });
    const [errorMessages, setErrorMessages] = useState({});
    const [buttonSuccess, setButtonSuccess] = useState(false);
    const [isVerified, setIsVerified] = useState(fieldInfo.fieldStatus === 'Verified');
    const [form, setForm] = useState({
        [fieldInfo.fieldName]: fieldInfo.fieldValue
    })
    const { getCaption } = useContext(Captions);
    const { updateClientInfo } = useContext(Auth);
    
    const openModal = (fieldName) => {
        setModal({ open: true, name: fieldName });
    }

    const closeModal = () => {
        setModal({ open: false, name: '' });
    }

    const handleChange = (input, pageName) => e => {
        if(errors.length) setErrorMessages({});
        handlerCallBack(input, e.target.value || e.target.checked, pageName);
        setForm({...form, [input]: e.target.value});
    }

    const updateButton = () => {
        setButtonSuccess(true);
        setErrorMessages({});
        updateClientInfo(true);
        setTimeout(() => {
            setButtonSuccess(false);
        }, 2000);
    }

    const verifiedCallback = () => {
        updateClientInfo(true);
        setIsVerified(true);
    }

    useEffect(() => {
        setErrorMessages({});
        if(errors.length) {
            let obj = {}
            errors.forEach(item => {
                obj[item.FieldName] = item.ValidationError;
                setErrorMessages(obj);
            })
        }
    }, [errors]);

    const handlerCallBackHandler = (option, value, fieldPageName) => {
        handlerCallBack(option, value, fieldPageName);
        setForm({...form, [option]: value});
    }
    
    return (
        <>
            {modal.open && 
                <VerificationModal 
                    fieldInfo={fieldInfo} 
                    contactMobileCode={contactMobileCode} 
                    modal={modal} 
                    mobileCode={mobileCode} 
                    closeModal={closeModal}
                    verifiedCallback={verifiedCallback} 
                />
            }
            {fieldInfo.fieldName === 'contacts.email' &&
                <div className={`${style[fieldInfo.fieldName.replaceAll(".", "_")] + ' ' + style.contactGroup} ${isVerified ? style.isVerified : ''}`}>
                    <>
                        <div className={!fieldInfo.editable ? `${style.inputGroup + ' ' + style.disabled}` : style.inputGroup}>
                            <CustomInput
                                className="labelAnimate"
                                errorCode={errorMessages ? errorMessages[fieldInfo.fieldName] : null}
                                name={fieldInfo.fieldName.replaceAll(".", "_")}
                                type={fieldInfo.fieldType}
                                value={form[fieldInfo.fieldName]}
                                label={getCaption(fieldInfo.placeholder)}
                                placeholder={getCaption(fieldInfo.placeholder)}
                                onChangeHandler={handleChange(fieldInfo.fieldName, fieldInfo.fieldPageName)}
                            />
                            {errorMessages[fieldInfo.fieldName] && <span className={style.errorMessage}>{getCaption(errorMessages[fieldInfo.fieldName])}</span>}
                        </div>
                        
                        <button 
                            className={style.submitHandler}
                            onClick={(e) => submitForm(e, page, updateButton, null, { "contacts.email": clientDetailsForm["contacts.email"] || '' })}
                        >{buttonSuccess ? (
                            <>
                                <span/>{getCaption('button.saved')}
                            </>
                            ) : (
                                <>
                                    {getCaption('button.save')}
                                </>
                            )}
                        </button>
                        <button 
                            type="button"
                            className={isVerified ? style.verifyHandler + ' ' + style.verified : style.verifyHandler}
                            disabled={isVerified}
                            onClick={() => openModal(fieldInfo.fieldName)}
                        >{isVerified ? getCaption('button.verified') : getCaption('button.verify')}</button> 
                    </>
                </div>
            }
            { fieldInfo.fieldName === 'contacts.phone.number' &&
                <div className={`${style[fieldInfo.fieldName.replaceAll(".", "_")] + ' ' + style.contactGroup} ${isVerified ? style.isVerified : ''}`}>
                    <ContactsPhoneNumber 
                        formData={form}
                        errorCode={errorMessages.mobileCode}
                        setData={(option, value) => handlerCallBackHandler(option, value, fieldInfo.fieldPageName)}
                        label={getCaption('web.fields.placeholder.phone')}
                    />
                        
                    <button 
                        className={style.submitHandler}
                        onClick={(e) => submitForm(e, page, updateButton, null, { "contacts.phone.number": clientDetailsForm["contacts.phone.number"] || '' })}
                    >{buttonSuccess ? (
                        <>
                            <span/>{getCaption('button.saved')}
                        </>
                        ) : (
                            <>
                                {getCaption('button.save')}
                            </>
                        )}
                    </button>
                    <button 
                        type="button"
                        className={isVerified ? style.verifyHandler + ' ' + style.verified : style.verifyHandler}
                        disabled={isVerified}
                        onClick={() => openModal(fieldInfo.fieldName)}
                    >{isVerified ? getCaption('button.verified') : getCaption('button.verify')}</button> 
                </div>
            }
                    
        </>
    )
}

export default ContactInformation;
