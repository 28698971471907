import React, { useState, useContext, useEffect } from 'react'
import style from './UserMenu.module.css'
import UserMenuItem from './UserMenuItem/UserMenuItem'
import { Auth } from '../../../../../../Services/Hooks/auth'
import { Captions } from '../../../../../../Services/Hooks/captions'
import { useClickOutside } from '../../../../../../Services/Hooks/outsideClick'
import useMedia from '../../../../../../Services/Hooks/useMedia'
const UserMenu = (props) => {
    const smallScreen = useMedia("(max-width: 1450px)")
    const { getCaption } = useContext(Captions);
    let [dropdownShown, setDropdownShown] = useState(false);
    let [withdrawModal, setWithdrawModal] = useState({ invoiceType: '', shown: false });
    let [depositModal, setDepositModal] = useState({ invoiceType: '', shown: false });
    let [trigger, setTrigger] = useState(false);
    const { updateClientInfo } = useContext(Auth);
    const handleDropdown = () => {
        // setTrigger(false)
        setDropdownShown(!dropdownShown);
    }

    const handleDeposit = (invoiceType) => {
        setDepositModal({invoiceType: invoiceType, shown: !depositModal.shown})
        
    }

    const handleWithdraw = (invoiceType) => {
        setWithdrawModal({invoiceType: invoiceType, shown: !withdrawModal.shown})
    }

    const handleTrigger = () => {
        setTrigger(!trigger)
    }

    let domNode = useClickOutside(() => {
        let modal = document.body.classList.contains('ReactModal__Body--open')
        if(!modal) setDropdownShown(false)
    })
    
    return (
        <>
            <div className={!props.pinToTop  ? [smallScreen ? style.mini : style.user] : style.pinToTop} ref={domNode}>
                <li className={style.select} id={dropdownShown ? style.up : style.down} onClick={handleDropdown}>
                    <div className={`${style.initial} ${style[props.clientInfo && props.clientInfo.username.charAt(0)]}`} />
                    <div className={style.userinfo}>
                        <p className={style.username}>{props.clientInfo && props.clientInfo.username}</p>
                        <span id={style.number}>{props.clientInfo && props.clientInfo.clientNumber}</span>
                    </div>
                </li> 
                {dropdownShown && <ul className={style.background}>
                    {props.clientInfo &&
                        <UserMenuItem
                            item={getCaption("web.user.menu.show.balance")}
                            fieldValue={props.clientInfo.isWalletHide}
                            clickResult={() => {}}
                            cn="showBalance"
                        />
                    }
                    {props.clientInfo && (props.clientInfo.idPersonalInfoVerifiedStatus !== 'Verified') &&
                        <UserMenuItem
                            item={getCaption("web.user.menu.client.id.verification")}
                            // item={'ID verification'}
                            path="/Profile"
                            query="5"
                            clickResult={() => setDropdownShown(false)}
                            cn="idVerification"
                        />
                    }
                    {props.clientInfo && !props.clientInfo.phoneNumberIsVerified &&
                        <UserMenuItem
                            title={'Phone'}
                            type={'small'}
                            fieldName={'contacts.phone.number'}
                            verifiedCallback={() => {}}
                        />
                    }
                    {props.clientInfo && !props.clientInfo.emailIsVerified &&
                        <UserMenuItem
                            title={'E-mail'}
                            type={'small'}
                            fieldName={'contacts.email'}
                            verifiedCallback={() => {}}
                        />
                    }
                    <UserMenuItem
                        item={getCaption("web.user.menu.my.favorites")}
                        path="/Favorites"
                        clickResult={() => setDropdownShown(false)}
                        cn="favorites"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.my.bonuses")}
                        path="/Client/Gifts"
                        clickResult={() => setDropdownShown(false)}
                        cn="bonuses"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.deposit")}
                        modalType={depositModal}
                        clickResult={() => handleDeposit('Deposit')}
                        cn="deposit"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.withdraw")}
                        modalType={withdrawModal}
                        clickResult={() => handleWithdraw('Withdraw')}
                        cn="withdraw"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.account.settings")}
                        path="/Profile"
                        clickResult={() => setDropdownShown(false)}
                        cn="settings"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.transactions")}
                        path="/Transactions"
                        clickResult={() => setDropdownShown(false)}
                        cn="transactions"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.log.in.history")}
                        trigger={trigger}
                        clickResult={handleTrigger}
                        cn="logHistory"
                    />
                    <UserMenuItem
                        item={getCaption("web.user.menu.exit")}
                        clickResult={props.logOutUser}
                        cn="exit"
                    />
                </ul>
                }
            </div>
        </>
    )

}

export default UserMenu;