import React, {useContext }  from 'react';
import style from './Mail.module.css';
import { Captions } from '../../../../Services/Hooks/captions';

const Mail = (props) => {
    const { getCaption } = useContext(Captions);
    return(
        <div className={style.mailContent}>
            <div className={style.mailName} > {getCaption(props.itemKey)} </div>
            <div className={style.mailAddress}>{props.itemValue} </div>
        </div>
    )
}
export default Mail;