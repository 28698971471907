import React, { useEffect, useState, useContext } from 'react'
import style from './Favorites.module.css'
import Search from '../../../Components/Search/Search'
import { GetGames } from '../../../Services/service'
import Game from '../GamePage/GameCategory/Game/Game'
import SideBarFilterContainer from '../../../Components/SideBarFilter/SideBarFilter'
import Ghost from '../../../Components/Ghost/Ghost'
import { Captions } from '../../../Services/Hooks/captions'

const Favorites = (props) => {
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const [selectedFavorites, setSelectedFavorites] = useState([])
    const [category, setCategory] = useState([])
    const [clearAll, setClearAll] = useState(false)
    const {getCaption} = useContext(Captions)
    
    useEffect(() => {
        GetGames(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    setResponse({
                        loading: false,
                        data: res.data
                    });
                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    });
                }
                const categoryArray = [];
                const array = [];

                for (let i = 0; i < res.data.length; i++) {
                    for (let j = 0; j < res.data[i].pageItems.length; j++) {

                        if (array.indexOf(res.data[i].pageItems[j].itemCategory) === -1) {
                            array.push(res.data[i].pageItems[j].itemCategory);
                            categoryArray.push({ name: res.data[i].pageItems[j].itemCategory, value: true })
                        }
                    }
                }
                setCategory(categoryArray)
                setSelectedFavorites(categoryArray.map(item => item.value && item.name))
            })
    }, [props.pageName]);

    return (
        <div className={style.container}>
            <SideBarFilterContainer setActiveCategories={setSelectedFavorites} categories={category} setCategory={setCategory} 
            setClearAll={setClearAll} activeCategories={selectedFavorites} />
            <div className={style.subContainer}>
                <Search pageName={props.pageName} />
                <div className={style.content}>
                    <h3 className={style.title}>{getCaption("web.favorites.page.title")}</h3>
                    {!clearAll && category.length > 0 && <div className={style.wrapper}>
                        {!response.loading && response.data.map((favorites, index) => <React.Fragment key={index} >
                            {favorites.pageItems.filter(item => selectedFavorites.indexOf(item.itemCategory) !== -1)
                                .map(game => <Game source={game.bannerName} productName={game.productName}
                                    key={game.webPageItemId} gameName={game.captionKey} channelName={game.channelName}
                                    sectionType={favorites.pageSectionType} rerender={GetGames} pageName={props.pageName}
                                    setResponse={setResponse} setCategory={setCategory} />)}
                        </React.Fragment>)
                        }
                    </div>}
                    {
                        (selectedFavorites.every(value => value == false) || clearAll || (!response.loading && response.data.length == 0)) 
                        && <Ghost message={getCaption('web.favorites.page.nothing.selected')} />
                    }

                </div>
            </div>
        </div>
    )
}

export default Favorites;
