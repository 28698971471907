import React, { useState, useEffect, useContext } from 'react';
import style from './VerificationComponent.module.css';
import VerificationModal from '../VerificationModal/VerificationModal';
import { GetClientDetails, GetRegistrationCountryCallingCode } from '../../Services/service';
import { Captions } from '../../Services/Hooks/captions';

const VerificationComponent = ({ fieldName, type, verifiedCallback, verificationType}) => {
    const [modal, setModal] = useState({ open: false, name: '' });
    const [groupMobileCode, setGroupMobileCode] = useState({});
    const [contactMobileCode, setContactMobileCode] = useState({});
    const [clientDetails, setClientDetails] = useState([]);
    const [hideFrame, setHideFrame] = useState(false);
    const { getCaption } = useContext(Captions);

    useEffect(() => {
        if(!modal.open) return;
        GetClientDetails()
            .then(res => {
                res.data.registrationFields.forEach(field => {
                    if(field.fieldName === 'contacts.mobile.code') {
                        setContactMobileCode(field);
                    }
                    setClientDetails(res.data.registrationFields)
                })
            })
            .catch(error => {
                
            }) 
        GetRegistrationCountryCallingCode()
            .then(res => {
                setGroupMobileCode(groupByProperty(res.data.customListItems));
            })
    }, [modal])

    
    const groupByProperty = (data) => {
        if (!data) return;
        return data.reduce((groups, item) => ({
            ...groups,
            [item.itemKey]: [...(groups[item.itemKey] || []), item]
        }), {});
    }

    const closeModal = () => {
        setModal({ open: false, name: '' });
    }

    const verificationModalOpen = () => {
        setModal({ open: true, name: fieldName });
    }
    return <>
        {clientDetails && clientDetails.map(fieldInfo => (
            fieldName === fieldInfo.fieldName && fieldInfo.fieldPageName === "page2" && 
            <VerificationModal 
                key={fieldInfo.fieldId}
                fieldInfo={fieldInfo} 
                contactMobileCode={contactMobileCode} 
                modal={modal} 
                mobileCode={groupMobileCode} 
                closeModal={closeModal}
                verifiedCallback={() => {verifiedCallback(); type === 'small' ? setHideFrame(true) : setHideFrame(false)}} 
            />
        ))}
        { verificationType !== 'welcomePopUp' ?
            <div 
                className={`${style.componentFrame + " " + style[type]} ${hideFrame ? style.hideFrame : ''}`}
                onClick={verificationModalOpen}
            >
                <div className={style.verificationBtnContent}>
                    <span className={style.verificationRequiredText}>
                        {getCaption(`${fieldName}.verification.required`)}
                    </span>
                    <span className={style.arrow} />
                </div>
            </div> : <span className={style.bluetext} onClick={verificationModalOpen}>{getCaption(`${fieldName}`)}</span>
        }
       
    </>
}

VerificationComponent.defaultProps = {
    type: 'default'
};

export default VerificationComponent;