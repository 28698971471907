import React, { useState, useEffect, useContext } from 'react';
import { Captions } from '../../../../Services/Hooks/captions';
import CustomInput from '../../../../Components/CustomInput/CustomInput';

import style from './Providers.module.css';

const Providers = ({ providers, filterGamesByProviders, selectedProviders, clearProviders }) => {
    const [ result, setResult ] = useState('');
    const [filteredProviders, setFilteredProviders] = useState([]);
    const { getCaption } = useContext(Captions);

    useEffect(() => {
        let filteredArr = [];
        providers.forEach((provider, index) => {
            if(result.length > 0 && provider[0].indexOf(result) !== -1) {
                filteredArr.push(index);
            }
            setFilteredProviders(filteredArr.length > 0 ? filteredArr : []);
        });
    }, [result, providers])

    return (
        <div className={style.providers}>

            <div className={style.header}>
                <h2>{getCaption('web.providers.title')}</h2>
                <div className={`${style.clear} ${selectedProviders.length > 0 ? style.active : ''}`} onClick={() => clearProviders()} />
            </div>
            

            <div className={style.searchGroup}>
                <div className={style.inputGroup}>
                    <CustomInput 
                        className="static"
                        name="search"
                        type="text"
                        value={result}
                        label={'Search'}
                        placeholder={'Search'}
                        onChangeHandler={(e) => setResult(e.target.value)}
                        autoComplete="off"
                    />
                    { result.length > 0 && <div className={style.clearSearchValue} onClick={() => setResult('')} /> }
                </div>
                { filteredProviders.length > 0 && 
                    <ul className={style.listContainer}>
                        {filteredProviders.map((index, keyIndex) => (
                            <li 
                                key={keyIndex} 
                                className={style.listItem}
                                onClick={() => {filterGamesByProviders(providers[index][0]); setResult('')}}
                            >
                                <div 
                                    key={index}
                                    style={{
                                        backgroundImage: 'url(' + providers[index][1].providerWebPageItem.bannerName + ')'
                                    }}
                                    className={`${style.listItemImage}`} 
                                />
                                <h3>{providers[index][0]}</h3>
                            </li>
                        ))}
                    </ul>
                }
            </div>

            { providers.map((item, index) => 
                <div 
                    key={index}
                    style={{
                        backgroundImage: 'url(' + item[1].providerWebPageItem.bannerName + ')'
                    }}
                    className={`${style.item} ${selectedProviders.indexOf(item[0]) !== -1 ? style.active : ''}`} 
                    onClick={() => filterGamesByProviders(item[0])}
                />
            )}
        </div>
    )
}

export default Providers