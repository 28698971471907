import React, { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { ConsoleLog, GetItemFromLocalStorage, SetItemToLocalStorage } from "../common";
import { GetClientInfo, PostRefreshToken } from "../service";

export const Auth = React.createContext({});

export const useAuth = () => {
    const [clientInfo, setClientInfo] = useState(null);
    const [auth, setAuth] = useState(GetItemFromLocalStorage('accessToken'));
    const [redirectAfterRegistration, setRedirectAfterRegistration] = useState(false);

    let history = useHistory();

    useEffect(() => {
        if(auth && !clientInfo) {
            GetClientInfo()
                .then(res => {
                    setClientInfo(res.data);
                })
                .catch(err => {
                    setAuth(false);
                    history.push('/Home');
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    localStorage.removeItem('expiredToken');
                    localStorage.removeItem('_grecaptcha');
                })
        }
    }, [auth, history, clientInfo])
    
    const authenticate = (token, decodedToken, refreshToken, isRedirectToGame) => {
        SetItemToLocalStorage('accessToken', token);
        SetItemToLocalStorage('expiredToken', decodedToken.exp);
        SetItemToLocalStorage('refreshToken', refreshToken);
        if(!auth) setAuth(token);
        if(isRedirectToGame) {
            history.push('/Home');
            setRedirectAfterRegistration(true);
        } 
    }

    const logOut = () => {
        setAuth(false);
        history.push('/Home');
        setClientInfo(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('expiredToken');
        localStorage.removeItem('_grecaptcha');
    }


    const refreshToken = () => {
        PostRefreshToken({
            refreshToken: GetItemFromLocalStorage('refreshToken'),
            accessToken: GetItemFromLocalStorage('accessToken')
        }).then(res => {
                let token = res.data.accessToken;
                if(!token || res.data.errorCode === 'client.is.kicked') {
                    logOut();
                    return;
                }
                let decodedToken = jwt_decode(token);
                authenticate(token, decodedToken, res.data.refreshToken);
            })
    }

    const updateClientInfo = (isReload) => {
        GetClientInfo(isReload)
            .then(res => {
                setClientInfo(res.data);
            })
            .catch(err => {
                ConsoleLog(err)
            })
    }

    const resetRedirectAfterRegistration = () => {setRedirectAfterRegistration(false)}
    
    return {
        auth,
        authenticate,
        logOut,
        refreshToken,
        clientInfo,
        updateClientInfo,
        redirectAfterRegistration,
        resetRedirectAfterRegistration
    }
}