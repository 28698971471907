import React, {useContext} from 'react';
import { NavLink } from 'react-router-dom';
import { Captions } from '../../../../../../Services/Hooks/captions';
import style from './VerticalMenu.module.css';

const VerticalMenu = () => {
    const { getCaption } = useContext(Captions);
    const menuItems = [
        { id: 1, pathName: '/TermsAndConditions', title: "web.responsible.gaming.vertical.menu.terms.condition" },
        { id: 2, pathName: '/GameRules', title: "web.responsible.gaming.vertical.menu.game.rules" },
        { id: 3, pathName: '/CookiePolicy', title: "web.responsible.gaming.vertical.menu.cookie.policy" },
        { id: 4, pathName: '/PrivacyStatement', title: "web.responsible.gaming.vertical.menu.privacy.statement" },
        { id: 5, pathName: '/ResponsibleGaming', title: "web.responsible.gaming.vertical.menuresponsible.gaming" },
        { id: 6, pathName: '/AgeRestrictionPolicy', title: "18+" }
    ]




    return (
        <div className={style.menuContainer}>
            {
                menuItems.map(Items => (
                    <NavLink to={Items.pathName} key={Items.id}
                        className={style.verticalMenuItems}
                        activeClassName={style.activeVerticalMenuItems}>
                        {
                            getCaption(Items.title)
                        }
                    </NavLink>
                )
                )
            }
        </div>







    )
}
export default VerticalMenu;

