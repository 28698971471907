import React, {useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import Slider from '../../../Components/Slider/Slider'
import PageBanners from '../PageBanners/PageBanners'
import GameItems from './GameItems/GameItems'
import { GetGames } from '../../../Services/service'
import style from './MainPage.module.css'
import Search from '../../../Components/Search/Search'
const MainPage = (props) => {
    const { pathname } = useLocation();
    const [response, setResponse] = useState({ loading: true, data: undefined });
    useEffect(() => {
        GetGames(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    setResponse({
                        loading: false,
                        data: res.data
                    });
                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    });
                }
            })
    }, [props.pageName]);
    return (
        <>
            {/* <Slider pathName={pathname.replace('/', '') + 'Main'}/> */}
            <PageBanners pathName={pathname.replace('/', '') + 'Main'} />
            {/* <div className={style.search}>
                <Search />
                <button className={style.button}>Search</button>
            </div> */}
            <div className={style.wrapper}>
            {!response.loading && response.data.map((category, index) => 
            <GameItems rerender={GetGames} setResponse={setResponse} key={index} index={index} pageName={props.pageName} pageSectionType={category.pageSectionType}
            pageSectionTitle={category.pageSectionTitle} items={category.pageItems}/>)}
            </div>
        </>
    )
}

export default MainPage;