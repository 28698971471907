import React, {useState} from 'react';
import Deposit from '../Deposit';
import style from './DepositButton.module.css';

function DepositButton(props) {
    const [showDeposit, setShowDeposit] = useState(false);
    const closeDepositModal = () => {
        props.handleTriggerDeposit();
    }
    return (
        <>
            <div
                className={props.type ? style[props.type] : style.trigger}
                onClick={() => props.handleTriggerDeposit(true)}
            >
                {props.isDeposit &&
                    <button className={style.deposit} onClick={() => setShowDeposit(true)}>{props.text}</button>
                }
            </div>
            {/*{props.triggerDeposit && <Deposit typeInvoice={props.popupType} modalType={props.triggerDeposit} closeDepositModal={closeDepositModal} />}*/}
            <Deposit typeInvoice={props.popupType} modalType={showDeposit} closeDepositModal={() => {setShowDeposit(false); closeDepositModal()}} />
        </>
    )
}

export default DepositButton
