import React, { useState, useEffect } from 'react';
import style from './ConversationButton.module.css';
import {ReCaptcha} from "../../ReCaptcha/ReCaptcha";

function ConversationButton({handleConversation, isMobile, buttonType}) {
    const [ fixed, setFixed ] = useState(false);
    const [fromBottom, setFromBottom] = useState(null)
    const controlHeader = () => {
        setFromBottom(310 - ((document.body.offsetHeight - window.innerHeight) - window.scrollY))
        setFixed((document.body.offsetHeight - window.innerHeight) - window.scrollY <= 280 )
    }

    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-chat-to-bottom', `${fixed ? fromBottom + 'px' : 'auto'}`)
    }

    window.addEventListener('resize', () => {
        appHeight()
    });
    appHeight();

    useEffect(() => {
        controlHeader()
    })
    
    const resizeObserver = new ResizeObserver(function() {
        controlHeader();
    });
    resizeObserver.observe(document.body);

    useEffect(() => {
        window.addEventListener('scroll', controlHeader)
        return () => window.removeEventListener('scroll', controlHeader)
    }, [])

    return <ReCaptcha onVerifyCaptcha={(token)=>{handleConversation(token)}}>
            <button
                className={`${buttonType ? style[buttonType] : style.button} ${fixed ? style.bottomFixed : ''}`}
                type="button"
            />
    </ReCaptcha>
}

export default ConversationButton
