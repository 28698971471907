import React, { useContext } from 'react';
import { Captions } from '../../../../../Services/Hooks/captions';

import style from './RegisterButton.module.css'

export default function RegisterButton({toggleRegisterPopup, buttonType}) {
    const { getCaption } = useContext(Captions);
    return <button 
                type="button" 
                className={buttonType ? style.button + ' ' + style[buttonType] : style.button} 
                onClick={toggleRegisterPopup} >
                    {getCaption('client.registration')}
            </button>
}
