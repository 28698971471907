import React from 'react'
import style from './JetX.module.css'
import GameAuthenticatedRedirect from '../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect'
const JetX = (props) => {
    return (
        <>
            {props.data.pageItems.map(game => 
                <div className={style.jetx} key ={game.webPageItemId}>
                    <GameAuthenticatedRedirect src={game.bannerName} url={game.redirectUrl} channelName={game.channelName} index={game.webPageItemId}
                    productName={game.productName} gameName={game.captionKey} pageName={props.pageName} isFavorite={game.othersCategory.includes("myfavorites")} /> 
                </div>)}
        </>
    )
}

export default JetX;