import React, {useState, useEffect, useContext} from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Gifts from '../../Gifts/Gifts';
import GamePage from '../../GamePage/GamePage';
import MainPage from '../../MainPage/MainPage';
import JetXPage from '../../JetXPage/JetXPage';
import Transactions from '../../Transactions/Transactions'
import GameLuncher from '../../GameLauncher/GameLauncher';
import ProtectedRoute from './ProtectedRoute';
import Contact from '../../Contact/Contact';
import SportsPage from '../../SportsPage/SportsPage';
import PromotionsPage from '../../PromotionsPage/PromotionsPage';
import TermsAndConditions from '../Footer/Information/TermsAndConditions/TermsAndConditions';
import ResponsibleGaming from '../Footer/Information/ResponsibleGaming/ResponsibleGaming';
import Favorites from '../../Favorites/Favorites';
import ClientDetails from '../../ClientDetails/ClientDetails';
import SearchedGames from '../../../../Components/SearchedGames/SearchedGames';
import CookiePolicy from '../Footer/Information/CookiePolicy/CookiePolicy';
import PrivacyStatement from '../Footer/Information/PrivacyStatement/PrivacyStatement';
import GameRules from '../Footer/Information/GameRules/GameRules';
import SlotsRules from '../Footer/Information/GameRules/SlotsRules/SlotsRules';
import AgeRestrictionPolicy from '../Footer/Information/AgeRestrictionPolicy/AgeRestrictionPolicy';
import ErrorPage from '../../ErrorPage/ErrorPage';
import { Captions } from '../../../../Services/Hooks/captions';
import { SetCategoryTitleToDocument, SetItemToLocalStorage } from '../../../../Services/common';
import { Auth } from '../../../../Services/Hooks/auth';
import LeaderBoard from '../../PromotionsPage/Promo/LeaderBoard/LeaderBoard';
import PaymentSuccess from '../../../../Components/PaymentSuccess/PaymentSuccess';

const Routes = () => {
    const [gameIsStarted, setGameIsStarted] = useState(false);
    const { getCaption, captions } = useContext(Captions);
    const { pathname } = useLocation();
    const { updateClientInfo } = useContext(Auth);
    if(captions.captions) SetCategoryTitleToDocument(getCaption(`jetx.bet.category.title${pathname.toLowerCase().replaceAll('/', '.')}`));
    useEffect(() => {
        if(pathname !== '/GameLauncher') {
            SetItemToLocalStorage('pathname', pathname);
        }
        setGameIsStarted(pathname === '/GameLauncher' ? true : false);
        if(gameIsStarted && pathname !== '/GameLauncher') updateClientInfo();
    }, [pathname, gameIsStarted, updateClientInfo])
    return (
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/Home" />)} />   
            <Route path="/Home" render={() => <MainPage pageName='MainPage' />} />
            <ProtectedRoute exact path="/Profile" component={ClientDetails} />
            <Route path="/Slots" render={() => <GamePage pageName='SlotsPage' />} />
            <ProtectedRoute path="/Client/Gifts" component={Gifts} />
            <Route path="/Casino" render={() => <GamePage pageName='CasinoPage' />} />
            <Route path="/Live" render={() => <SportsPage pageName='Live' />} />
            <Route path="/Sports" render={() => <SportsPage pageName='Sports' />} />
            <Route path="/Games" render={() => <GamePage pageName='GamesPage' />} />
            <Route path="/Keno" render={() => <JetXPage pageName='KenoPage' />} />
            <Route path="/JetX" render={() => <JetXPage pageName='JetXPage' />} />
            <Route path="/Xgames" render={() => <JetXPage pageName='XgamesPage' />} />
            <Route path="/GameLauncher" render={() => <GameLuncher />} />
            <ProtectedRoute exact path="/Transactions" component={Transactions} />
            <Route path="/Contact" render={() => <Contact pageName='Contact' />} />
            <Route path="/Promotions" render={() => <PromotionsPage pageName='PromotionsPage' />} />
            <Route path="/TermsAndConditions" render={() => <TermsAndConditions pageName='TermsAndConditions' />} />
            <Route path="/ResponsibleGaming" render={() => <ResponsibleGaming pageName='ResponsibleGaming'  />} />
            <Route path="/CookiePolicy" render={() => <CookiePolicy />} />
            <Route path="/PrivacyStatement" render={() => <PrivacyStatement />} />
            <Route path="/GameRules" render={() => <GameRules /> } />
            <Route path="/SlotsRules" render={() => <SlotsRules/> } />
            <Route path="/AgeRestrictionPolicy" render={() => <AgeRestrictionPolicy /> } />
            <ProtectedRoute exact path="/Favorites" component={Favorites} pageName='FavouritesPage' />
            <ProtectedRoute exact path="/PaymentSuccess" component={PaymentSuccess} pageName='PaymentSuccess' />
            <Route path="/SearchedGames" render={() => <SearchedGames />}/>
            <Route path="/LeaderBoard" render={() => <LeaderBoard/> } />
            <Route path="*" render={() => <ErrorPage />}/>
        </Switch>
    )
}

export default Routes;