import React, {useContext} from 'react';
import PopUpMenu from '../../../../../Components/PopUpMenu/PopUpMenu';
import { Captions } from '../../../../../Services/Hooks/captions';

const Information = () => {
    const { getCaption } = useContext(Captions);
    const menuItems = [
        { id: 0, pathName: '/TermsAndConditions', title: getCaption("web.pop.up.footer.menu.terms.and.conditions")},
        { id: 1, pathName: '/Gamerules', title: getCaption("web.pop.up.footer.menu.game.rules")},
        { id: 2, pathName: '/CookiePolicy', title: getCaption("web.pop.up.footer.menu.cookie.policy")},
        { id: 3, pathName: '/PrivacyStatement', title: getCaption("web.pop.up.footer.menu.privacy.statement")},
        { id: 4, pathName: '/ResponsibleGaming', title: getCaption("web.pop.up.footer.menu.responsible.gaming")},
        { id: 5, pathName: '/AgeRestrictionPolicy', title: getCaption("web.pop.up.footer.menu.age.restriction.policy")}
    ]

    let title = menuItems.filter(item => (item.pathName === window.location.pathname))[0].title
    return (
        <PopUpMenu menuItems={menuItems} title={title} isRedirect={true} />
    )
}

export default Information;