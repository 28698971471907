import React, {useState, useContext, useEffect} from 'react';
import style from './ShowBalanceItem.module.css';
import {PostUpdateDetails} from "../../../../../../../../Services/service";
import {Auth} from "../../../../../../../../Services/Hooks/auth";

const ShowBalanceItem = ({ fieldValue }) => {
    const [checkBoxChecked, setCheckBoxChecked] = useState(fieldValue);
    const { updateClientInfo } = useContext(Auth);

    useEffect(() => {
        setCheckBoxChecked(fieldValue);
    }, [fieldValue])

    const onChangeHandler = () => {
        setCheckBoxChecked(!checkBoxChecked);
        const formData = {
            "FieldsGroup": "ClientDetailsNavMain",
            "RegistrationInputs": [
                {
                    "FieldName": "is.wallet.hide",
                    "FieldValue": !checkBoxChecked ? 'true' : 'false'
                }
            ]
        }
        PostUpdateDetails(formData)
            .then(() => {
                updateClientInfo(true);
            })
            .catch(error => {
                console.log(error)
            })
    }
    return <div className={checkBoxChecked ? style.securityOptions + ' ' + style.checked : style.securityOptions} onClick={onChangeHandler}>
        <label className={style.checkbox}>
            <input
                name="show-balance"
                type="checkbox"
                checked={checkBoxChecked}
                onChange={() => {}}
            />
            <span />
        </label>
    </div>
}

export default ShowBalanceItem;
