import React, { useContext, useEffect, useState } from 'react';
import VerificationComponent from '../../../../../Components/VerificationComponent/VerificationComponent';
import style from './WelcomePopUp.module.css';
import { GetSliderItems }  from '../../../../../Services/service';
import { Captions } from '../../../../../Services/Hooks/captions';
import { Link } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Auth } from '../../../../../Services/Hooks/auth';

const WelcomePopUp = (props) => {
    const { getCaption } = useContext(Captions);
    const { getLanguage } = useContext(Captions);
    const { redirectAfterRegistration, resetRedirectAfterRegistration } = useContext(Auth);
    const { loading, sliderItems } = GetSliderItems("MobileWelcomeMain", getLanguage());

    useEffect(() => {
        redirectAfterRegistration ? document.body.classList.add('fixed') : document.body.classList.remove('fixed')
    });
      

    return (
        <>
            {
            redirectAfterRegistration &&
                <div className={style.welcomePopUpBackground}>
                    <div className={style.welcomePopUp}>
                        <Link to="/Promotions" className={style.closeButton} onClick={() => resetRedirectAfterRegistration()} />
                        <div className={style.popUpHeader}>
                            <div className={style.titleCaptionWelcome}>
                                {getCaption('web.welcome.page.title')}
                            </div>
                            <div className={style.companyName}>
                                {getCaption('web.welcome.page.text')}
                            </div>
                        </div>
                        <div className={style.content}>
                            {
                                 (!loading &&  sliderItems  &&  sliderItems[0])  && <img src={sliderItems[0].source} alt="welcome pop up  promotion image mobile" />
                            }
                        </div>
                        <div className={style.footer}>
                            <p>
                                Verify your
                                <span className={style.bluetext}>
                                    &nbsp;
                                    <VerificationComponent
                                        fieldName="contacts.email"
                                        verificationType={'welcomePopUp'}
                                        trigger={true} clickTrigger={() => resetRedirectAfterRegistration()}
                                    />
                                </span>
                                &nbsp;
                                and
                                &nbsp;
                                <span className={style.bluetext}>
                                    <VerificationComponent
                                        fieldName="contacts.phone.number"
                                        trigger={true}
                                        verificationType={'welcomePopUp'}
                                        clickTrigger={() => resetRedirectAfterRegistration()}
                                    />
                                </span>
                                &nbsp; to participate
                            </p>

                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default WelcomePopUp;