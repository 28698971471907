import React, { useContext, useState } from 'react'
import style from './SideBarFilter.module.css'
import { Captions } from '../../Services/Hooks/captions';
import { isMobile } from 'react-device-detect';
import CustomModal from '../../Components/CustomModal/CustomModal';
import CloseButton from '../CloseButton/CloseButton';

const SideBarFilterContainer = ({ setActiveCategories, categories, setCategory, itemStatus, setStatus, setClearAll, saveChanges, activeCategories, expiredCategories}) => {
    const { getCaption } = useContext(Captions);
    const [selectedAllActive, setSelectedAllActive] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const checkActiveCategoriesCount = (updatedList) => {
        let counter = 0;
        for (let i = 0; i < updatedList.length; i++) {
            if (updatedList[i].value) counter++
        }
        setSelectedAllActive(counter === categories.length ? true : false);
    }
    const updateListCategories = (updatedList) => {
        checkActiveCategoriesCount(updatedList)
        setActiveCategories(updatedList.map(item => item.value && item.name));
        setCategory(updatedList);
        isMobile && saveChanges()
    }
    const handleChange = name => event => {
        if (name === 'status') {
            itemStatus && setStatus && setStatus({ ...itemStatus, [name]: event.target.value })
        } else if (name === 'all') {
            let updatedList = [...categories];
            updatedList.map(item => item.value = event.target.checked);
            updateListCategories(updatedList);
            setClearAll(!event.target.checked)
        } else {
            let updatedList = [...categories];
            updatedList[name].value = event.target.checked;
            updateListCategories(updatedList);
            setClearAll(false)
            isMobile && saveChanges()
        }
    }
    return (
        <>
            {isMobile && <div className={style.menu}>
                {itemStatus ? <div className={`${style.radioBtn} ${style.radioBtnMiniMenu}`}>
                    <input
                        className={`${style.checkmark} ${style.active}`}
                        type="radio"
                        id="active"
                        name="active"
                        value="active"
                        checked={itemStatus.status === 'active' && true}
                        onChange={handleChange('status')}
                    />
                    <label className={style.activeItemsBtn} htmlFor="active">
                        {getCaption('web.promotion.active')}
                    </label>
                    <input
                        className={`${style.checkmark} ${style.expired}`}
                        type="radio"
                        id="expired"
                        name="expired"
                        value="expired"
                        checked={itemStatus.status === 'expired' && true}
                        onChange={handleChange('status')}
                    />
                    <label className={style.activeItemsBtn} htmlFor="expired">
                        {getCaption('web.promotions.expired')}
                    </label>
                </div> : <h3 className={style.title}>{getCaption('mobile.categories.filter.title')}</h3>}
                <button className={style.showPopUp} onClick={() => setIsOpen(true)}>

                </button>
            </div>}
            {
                isMobile ? <CustomModal isOpen={isOpen} >
                    <SideBarFilter
                        setIsOpen={setIsOpen}
                        categories={categories}
                        setCategory={setCategory}
                        itemStatus={itemStatus}
                        setStatus={setStatus}
                        setActiveCategories={setActiveCategories}
                        handleChange={handleChange}
                        setClearAll={setClearAll}
                        updateListCategories={updateListCategories}
                        selectedAllActive={selectedAllActive}
                        onClose={() => setIsOpen(false)}
                        saveChanges={saveChanges}
                        activeCategories={activeCategories}
                        expiredCategories={expiredCategories}
                    />
                </CustomModal>
                    :
                    <SideBarFilter
                        setIsOpen={setIsOpen}
                        categories={categories}
                        setCategory={setCategory}
                        itemStatus={itemStatus}
                        setStatus={setStatus}
                        setActiveCategories={setActiveCategories}
                        handleChange={handleChange}
                        updateListCategories={updateListCategories}
                        selectedAllActive={selectedAllActive}
                        setClearAll={setClearAll}
                        onClose={() => setIsOpen(false)}
                        activeCategories={activeCategories}
                        expiredCategories={expiredCategories}
                    />
            }


        </>
    )

}


const SideBarFilter = ({ selectedAllActive, categories, itemStatus, onClose, setClearAll, saveChanges, handleChange, updateListCategories, activeCategories, expiredCategories}) => {


    const handleClick = () => {
        let updatedList = [...categories];
        updatedList.map(item => item.value = false);
        updateListCategories(updatedList);
        setClearAll(true)
        isMobile && saveChanges()
    }

    const { getCaption } = useContext(Captions);
    return (
        <aside className={isMobile ? style.mobilePopUp : style.sideBarMenu}>

            <div className={isMobile ? style.mobileCategories : style.categories}>

                <span className={style.titleContent}> {getCaption('web.promotion.categories')}  </span>
                {isMobile && <CloseButton clickHandler={onClose} />}
            </div>
            <div className={style.MobileRadioBtns}>
                {itemStatus && <div className={isMobile ? style.radioBtnMobile : style.radioBtn}>
                    <input
                        className={`${style.checkmark} ${style.active}`}
                        type="radio"
                        id="active"
                        name="active"
                        value="active"
                        checked={itemStatus && itemStatus.status === 'active' && true}
                        onChange={handleChange('status')}
                    />
                    <label className={style.activeItemsBtn} htmlFor="active">
                        {getCaption('web.promotion.active')}
                    </label>

                    <input
                        className={`${style.checkmark} ${style.expired}`}
                        type="radio"
                        id="expired"
                        name="expired"
                        value="expired"
                        checked={itemStatus && itemStatus.status === 'expired' && true}
                        onChange={handleChange('status')}
                    />
                    <label className={`${style.activeItemsBtn} ${isMobile ? style.mobileExpiredBtn : ''}`} htmlFor="expired">
                        {getCaption('web.promotions.expired')}
                    </label>

                </div>}
            </div>

            <nav>
                <div className={isMobile ? style.selectMobile : style.select}>
                    <div className={style.selectTitle}>
                        <label className={style.selectOption}>{getCaption('web.promotions.select.all')}
                            <input type="checkbox" checked={selectedAllActive} onChange={handleChange('all')} />
                            <span className={style.checkmarkItemAll} id={
                                selectedAllActive ? "" : activeCategories.every(value => value == false) ? style.empty : style.selectedSomeItems
                                }></span>
                        </label>
                    </div>

                    <ul className={isMobile ? style.listForMobile : ''}>
                        {categories && categories.map((item, index) => (
                            <li key={index} className={`${item.value ? style.selected : ''}`}>
                                <label className={style.selectOption} > {item.name}
                                    <input type="checkbox" value={item.value} onChange={handleChange(index)} checked={item.value} />
                                    <span className={style.checkmarkItem}>{item.value}</span>
                                </label>
                            </li>
                        ))
                        
                        }
                    </ul>
                    <button className={style.clearItems} onClick={handleClick}> <div className={style.clearIcon}></div>
                        {getCaption('web.promotions.clear.all')}
                    </button>
                </div>
                {
                    isMobile ?
                        <button className={style.saveChangesBtn} onClick={() => { saveChanges(); onClose() }}>
                            {getCaption('web.promotions.save.changes')}
                        </button>
                        :
                        ''
                }
            </nav>
        </aside>
    )
}

export default SideBarFilterContainer;
