import React, {useEffect, useState} from 'react'
import style from './JetXPage.module.css'
import Slider from '../../../Components/Slider/Slider'
import JetX from './JetX/JetX'
import { GetGames } from '../../../Services/service'
import Search from '../../../Components/Search/Search'
import { useLocation } from 'react-router-dom'

const JetXPage = (props) => {
    const {pathname} = useLocation();
    const [response, setResponse] = useState({ loading: true, data: undefined });
    useEffect(() => {
        // const interval = setInterval(() => {
            GetGames(props.pageName)
                .then(res => {
                    if(res.data !== "" ) { 
                        setResponse({ 
                            loading: false, 
                            data: res.data 
                        });
                        
                    } else {
                        setResponse({ 
                            loading: true, 
                            data: undefined 
                        });
                    }
                })
        // }, 1000);
        // return () => clearInterval(interval);
    }, [props.pageName]);
    return (
        <>
            <Slider pathName={'Mobile' + pathname.replace('/', '') + 'Main'}/>
            {/* <div className={style.search}>
                <Search />
            </div> */}
            <div className={style.wrapper}>
                { !response.loading && response.data.map(Jetx =>
                    <JetX data={Jetx} key={Jetx.pageSectionId} pageName={props.pageName} />
                )}

            </div>
        </> 
    )
}

export default JetXPage;