import React, {useState, useEffect, useRef, useContext} from 'react'
import style from './GameItems.module.css'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import GameAuthenticatedRedirect from '../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect';
import { Captions } from '../../../../Services/Hooks/captions'
import MultiBannersSlider from '../../../../Components/Slider/MultiBannersSlider'
import useWindowDimensions from '../../../../Services/Hooks/windowDimesions';
const GameItems = (props) => { 
    const { getCaption } = useContext(Captions);
    const [clickedButton, setClickedButton] = useState(false);
    let history = useHistory();
    let buttonRef = useRef();
    const windowDimensions = useWindowDimensions();
    return (
        <>
        {clickedButton ? history.push('/' + buttonRef.current) :
            <div className={style.section}>
                <div className={`${style.categoryHeader} ${windowDimensions.height > windowDimensions.width 
                                        ? style.portrait : style.landscape}`}>
                    <h2 className={`${style.category} ${style[getCaption(`${props.pageSectionTitle}`)]}`}>{getCaption(`${props.pageSectionTitle}`)}</h2>
                    <button className={style.button}

                        onClick={() => { setClickedButton(!clickedButton); window.scrollTo(0, 0); buttonRef.current = props.pageSectionType }} >
                    </button>
                </div>
                <div className={`${style.categoryContent} ${windowDimensions.height > windowDimensions.width 
                                        ? style.portrait : style.landscape}`}>
                    <MultiBannersSlider
                        loop={true}
                        pagination={true}
                        autoplay={false}
                        spaceBetweenSlides={10}
                        showSlideCounter={windowDimensions.height > windowDimensions.width ? 
                            props.index % 2 == 0 ? 2 : 1 :
                            props.index % 2 == 0 ? 4 : 2
                        }
                        className={"mobileMain"}
                        itemsList={props.items.map(game => <div className={style.game}>
                            <GameAuthenticatedRedirect src={game.bannerName} alt={game.productName} rerender={props.rerender} setResponse={props.setResponse} 
                                channelName={game.channelName} productName={game.productName} gameName={game.captionKey} pageName={props.pageName} isFavorite={game.othersCategory.includes("myfavorites")} />
                        </div>)}
                    />

                </div>
            </div>}
    </>

    )
}

export default GameItems;

