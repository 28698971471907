import React from 'react';
import style from './NotificationsButton.module.css';

const NotificationsButton = ({showList, status, count, type}) => {
    return ( 
        <>
            <div className={`${type ? style[type] : style.notifications} ${style[status]}`} onClick={() => showList()} data-count={count} />
        </>
    )
}

export default NotificationsButton;