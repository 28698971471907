import React, { useState, useEffect, useContext } from "react";
import style from './MobileMenu.module.css'
import { useHistory, useLocation } from "react-router";
import UserMenu from "./UserMenu/UserMenu";
import DepositButton from "../Deposit/DepositButton/DepositButton";
import { Auth } from "../../../Services/Hooks/auth";
import GamesMenu from "./GamesMenu/GamesMenu";
import ClientAuthenticationMobile from '../ClientAuthentication/ClientAuthentication'
import { Captions } from "../../../Services/Hooks/captions";
const Menu = () => {
    const { getCaption } = useContext(Captions);
    const { auth, clientInfo, logOut } = useContext(Auth);
    let [redirectToFavorites, setRedirectToFavorites] = useState(false)
    let [triggerDeposit, setTriggerDeposit] = useState(false);
    let [userMenu, setUserMenu] = useState(false);
    let [gamesMenu, setGamesMenu] = useState(false);
    let history = useHistory();
    let location = useLocation();
    const redirectTo = (path) => {
        history.push(path)
    }
    const handleTriggerDeposit = (position) => {
        setTriggerDeposit(position);
    }
    useEffect(() => {
        setUserMenu(false);
    }, [location])
    return (
        <>
            <div className={style.menu}>
                <div className={style.home} id={(window.location.pathname === "/Home" && !gamesMenu && !userMenu) ? style.homeActive : ""} onClick={() => { redirectTo("/Home"); window.scrollTo(0, 0) }}>
                    <p className={style.homeTitle}>{getCaption('mobile.menu.item.home')}</p>
                </div>
                <div className={style.favorites} id={(window.location.pathname === "/Favorites" && !gamesMenu && !userMenu) ? style.favoritesActive : ""} onClick={() => { setRedirectToFavorites(true); redirectToFavorites && redirectTo("/Favorites"); window.scrollTo(0, 0) }}>
                    <p className={style.favoritesTitle}>{getCaption('mobile.menu.item.favorites')}</p>
                </div>
                <div className={style.deposit} id={triggerDeposit ? style.depositActive : ""} onClick={() => setTriggerDeposit(!triggerDeposit)}>
                    <p className={style.depositTitle}>{getCaption('mobile.menu.item.deposit')}</p>
                    {auth && <DepositButton
                        popupType="Deposit"
                        isDeposit={true}
                        type={"bottomMenu"}
                        triggerDeposit={triggerDeposit}
                        handleTriggerDeposit={handleTriggerDeposit}
                    />}
                </div>
                <div className={style.userMenu} id={userMenu ? style.userMenuActive : ""} onClick={() => {!auth && setUserMenu(true)}}>
                    <p className={style.userMenuTitle}>{getCaption('mobile.menu.item.user.menu')}</p>
                    {auth && <UserMenu userMenu={userMenu} handleMenu={setUserMenu}
                            logOutUser={logOut} clientInfo={clientInfo && clientInfo} 
                            triggerDeposit={triggerDeposit} setTriggerDeposit={setTriggerDeposit}
                            />}
                </div>
                <div className={style.games} id={gamesMenu ? style.gamesActive : ""}>
                    <p className={style.gamesTitle}>{getCaption('mobile.menu.item.games')}</p>
                    <GamesMenu gamesMenu={gamesMenu} handleMenu={setGamesMenu} />
                </div>
            </div>
            {!auth && <ClientAuthenticationMobile loginModalShow={userMenu} clickHandler={() => {setUserMenu(true)}}
                                closeModal={() => setUserMenu(false)} />}
            {!auth && <ClientAuthenticationMobile loginModalShow={redirectToFavorites} clickHandler={() => redirectTo("/Favorites")}
                                closeModal={() => setRedirectToFavorites(false)} />}
            {!auth && <ClientAuthenticationMobile loginModalShow={triggerDeposit} clickHandler={() => setTriggerDeposit(true)}
                                closeModal={() => setTriggerDeposit(false)} />}
        </>
    )
}

export default Menu;