import React, {useState, useContext} from 'react';
import Login from './Login/Login';
import { Auth } from '../../../Services/Hooks/auth';


const ClientAuthentication = ({ loginModalShow, clickHandler, closeModal }) => {
    const [registerModalShow, setRegisterModalShow] = useState(false);
    const { auth } = useContext(Auth);
    const toggleRegisterPopup = () => {
        setRegisterModalShow(!registerModalShow);
    }

    const toggleLoginPopup = () => {
        closeModal();
    }

    const redirectToGame = () => {
        closeModal();
        clickHandler();
    }
    
    return (
        <>
            {!auth &&
                <Login
                    loginModalShow={loginModalShow}
                    toggleRegisterPopup={toggleRegisterPopup}
                    toggleLoginPopup={toggleLoginPopup}
                    isRedirectToGame={true}
                    redirectUrl={false}
                    redirectToGame={redirectToGame} />
            }
        </>
    )
}

export default ClientAuthentication;
