import React, {useEffect, useState} from 'react'
import style from './Timer.module.css'
import { ReCaptcha } from '../../ReCaptcha/ReCaptcha';
const Timer = (props) => {
    const [dateCount, setDateCount] = useState(60);
    const [isExpired, setIsExpired] = useState(false);
    useEffect(() => {
        if (dateCount > 0) {
            let timerID = setInterval(() => {
                setDateCount(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timerID);
        } else {
            setIsExpired(true);
        }
    }, [dateCount]);
    return (
        <div className={style.timer}>
            <div className={style.time}>
                00:<span>{dateCount < 10 && 0}{dateCount}</span>
            </div>
            {isExpired &&  <ReCaptcha
                                onVerifyCaptcha={props.requestNewCode, 
                                () => {setDateCount(60); props.setError(false); 
                                props.setForm({...props.form, ['otp1']: "", ['otp2']: "", ['otp3']: "", ['otp4']: ""})}}>
                                    <p id={style.newCode}>Request a new code</p>
                            </ReCaptcha>
            }
        </div>
    )
}

export default Timer;