import React, { useContext,useState, useEffect  } from 'react';
import VerificationComponent from '../../../../../Components/VerificationComponent/VerificationComponent';
import { Auth} from '../../../../../Services/Hooks/auth';
import Timer from '../../../../../Components/Timer/Timer';
import { Captions } from '../../../../../Services/Hooks/captions';
import { GetPromotions } from '../../../../../Services/service';
// import { Link } from "react-router-dom";
import style from './LeaderBoard.module.css'
import MultiBannersSlider from '../../../../../Components/Slider/MultiBannersSlider';
import LeaderBoardSliderItem from './LeaderBoardSliderItem/LeaderBoardSliderItem';
import DailySliderItem from './LeaderBoardSliderItem/DailySliderItem';
import LogInPopUp from '../../LogInPopUp/LogInPopUp';

const LeaderBoard = (props) => {
    const { auth, clientInfo } = useContext(Auth)
    const { getCaption } = useContext(Captions);
    const [index, setIndex] = useState(0)
    const [show, setShow] = useState({[index]: index === 0 ? true : false});
    const showContent = index => (e) => {  
        setIndex(e.target.getAttribute('index'))   
        console.log(e.target.getAttribute('index'), index)
        setShow({
            ...show,
            [index]: !show[index]
        }) 
    }

    const pageName = 'WelcomePage';
   

    // const { pathname } = useLocation();
    const [activePromotions, setActivePromotions] = useState([]);
    // const [expiredPromotions, setExpiredPromotions] = useState([]);

    const refreshPromotions = () => {
        GetPromotions(false)
            .then((res) => {
                setActivePromotions(res.data.filter(promo => !promo.isExpired).sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0)));
            })
            .catch(() => {

            });
    }
    useEffect(() => {
        refreshPromotions();
    }, []);

  


    return (
        <div className={style.page}>
            <div className={style.header}></div>
            <div className={style.userInfo}>
            {
                
                (auth && clientInfo !== null && (clientInfo.emailIsVerified !== true || clientInfo.phoneNumberIsVerified !== true))

                ?
                <div className={style.verificationNotification}>
                    {getCaption('web.section.promotions.verify.text1')} &nbsp;
                    <span className={`${style.blueText + ' ' + style.blueTextPromo}`}>
                        <VerificationComponent fieldName="contacts.email" verificationType={'welcomePopUp'} trigger={true} clickTrigger={() => console.log()} />
                    </span>
                    &nbsp;
                    {getCaption('web.section.promotions.verify.text2')}&nbsp;
                    <span className={`${style.blueText + ' ' + style.blueTextPromo}`}>
                        <VerificationComponent fieldName="contacts.phone.number" trigger={true} verificationType={'welcomePopUp'} clickTrigger={() => console.log()} />
                    </span>
                </div>
                :
               <LogInPopUp />
            }
            </div>
          
            <div className={style.daySlider}>
            <MultiBannersSlider
                                autoplay={false}
                                loop={false}
                                // autoplay={
                                //     {delay: 5000},
                                //     {disableOnInteraction: false}
                                //    }
                                // pagination={true}
                                navigation={true}
                                spaceBetweenSlides={35}
                                showSlideCounter={4}
                                className={"leaderBoardDailySlider"}
                                itemsList={activePromotions.map((promo, index) => <DailySliderItem />)}
                            />
            </div>
            <div className={style.leaderboardTable}>
                <div className={style.leaderboardContent}>
                <div className={style.leaderBoardParent} id={style.leaderBoardTableHeader}>
                    <div className={style.leaderBoard}>
                        <div className={style.leaderBoardListThead}>
                            <div className={style.item}>Player</div>
                            <div className={style.item}>Score</div>
                            <div className={style.item}>Freespins</div>
                        </div>
                    </div>
                    </div>
                    <div className={style.leaderBoardListBody}>
                        <div className={style.leaderBoardParent}>
                            <div className={style.leaderBoard}>
                                <div className={style.leaderBoardList}>
                                    <div className={`${style.item + ' ' + style.player}`}>
                                        <span>1</span>
                                        K*****3
                                    </div>
                                    <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                    <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>2</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>99</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>100</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>101</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>199</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>200</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>299</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className={style.leaderBoardParent}>
                        <div className={style.leaderBoard}>
                            <div className={style.leaderBoardList}>
                                <div className={`${style.item + ' ' + style.player}`}>
                                    <span>300</span>
                                    K*****3
                                </div>
                                <div className={`${style.item + ' ' + style.score}`}>8000000</div>
                                <div className={`${style.item + ' ' + style.freespins}`}>
                                        <div  className={style.freespin}>
                                        1000
                                        </div>
                                    </div>
                            </div>
                        </div> 
                    </div>

                    </div>
                </div>

            </div>
            <div className={style.pageContent}>
               
                <React.Fragment key={index}>
                         <div className={style.contentItem} index={index} onClick={showContent(index)}>
                             about
                             {/* {item.category} */}
                             <span className={`${style.arrow} ${ show[index] ? style.active : ''}`  }  />
                         </div>
                         
                         {show[index] &&
                            <div className={style.itemText}>
                                <h5>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.
                                <div className={style.itemImgs}>
                                    <div className={`${style.itemImg + ' ' + style.itemImg1}`}></div>
                                    <div className={`${style.itemImg + ' ' + style.itemImg2}`}></div>
                                    <div className={`${style.itemImg + ' ' + style.itemImg3}`}></div>
                                </div>

                                {
                                    
                                    // item.content
                                }
                                <h5>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.
                             </div> 

                         }
                          <div className={style.contentItem} index={index} onClick={showContent(index)}>
                            how to participate?
                             {/* {item.category} */}
                             <span className={`${style.arrow} ${ show[index] ? style.active : ''}`  }  />
                         </div>
                         
                         {show[index] &&
                            <div className={style.itemText}>
                                <h5>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.

                                {
                                    
                                    // item.content
                                }
                                <h5>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.
                             </div> 

                         }

                        <div className={style.contentItem} index={index} onClick={showContent(index)}>
                            Rules
                             {/* {item.category} */}
                             <span className={`${style.arrow} ${ show[index] ? style.active : ''}`}  />
                         </div>
                         
                         {show[index] &&
                            <div className={ `${style.itemText} ${style.rulesContent}`}>
                                <h5 className={style.rules}>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.
                                
                                {
                                    
                                    // item.content
                                }
                                <h5 className={style.rules}>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.

                                <h5 className={style.rules}>lorem ipsum</h5>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                                Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit 
                                laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, 
                                magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum.
                                
                             </div> 

                         }
                    <button className={style.participate}>
                    participate
                    </button>
                    <div className={style.endDate}>
                    <Timer  />
                    </div>
                    <h1>Other promotions</h1>
                     </React.Fragment>
                    
                     
            </div>
          
            <div className={style.footerSlider}>
                     <MultiBannersSlider
                                autoplay={false}
                                loop={false}
                                // autoplay={
                                //     {delay: 5000},
                                //     {disableOnInteraction: false}
                                //    }
                                // pagination={true}
                                slidesPerView= 'auto'
                                centeredSlides={true}
                                navigation={false}
                                spaceBetweenSlides={20}
                                showSlideCounter={5.9}
                                // slidesPerView={10}
                                className={"leaderBoardSlider"}
                                itemsList={activePromotions.map((promo, index) => <LeaderBoardSliderItem key={index} promotionDetails={promo} src={promo.bannerName}  pageName={pageName}/>)}
                            />
                     </div>

        </div>
    )

}
export default LeaderBoard;