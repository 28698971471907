import React, { useState } from 'react'
import style from './CustomInput.module.css'

const CustomInput = React.forwardRef(({ name, type, placeholder, value, autoFocus, onClickHandler, onChangeHandler, 
    onBlurHandler, onFocusHandler, className, autoComplete, label, errorCode, onKeyUpHandler, maxLength, disabled, searchOnEnter }, ref) => {
    const [showLabel, setShowLabel] = useState(false);
    const onFocus = (e) => {
        if(onFocusHandler)
        onFocusHandler(e);
        setShowLabel(true)
    }
    
    const onBlur = (e) => {
        if(onBlurHandler)
        onBlurHandler(e);
        
        let isMounted = true;
        if(value.length === 0) {
            setTimeout(() => {
                if (isMounted) setShowLabel(false); 
            }, 200)
            return isMounted = false 
        }
    }

    searchOnEnter && ref.current !== null && ref.current !== undefined && ref.current.addEventListener("keyup", (e) => searchOnEnter(e))
    
    return (
        <div className={`${style.group} ${style[className] ? style[className] : ''} ${errorCode ? style.error : ''} ${showLabel ? style.placeholderDelay : ''}`}>
            { type === 'checkbox' ? 
                <label htmlFor='name'>
                    <input 
                        id="name"
                        className={`${value.toString().length > 0 ? style.valid : ''}`}
                        name={name}
                        type={type}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        value={value}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChangeHandler}
                        autoComplete={autoComplete}
                    />
                </label> : 
                <>
                    <input 
                        className={`${value.toString().length > 0 ? style.valid : ''}`}
                        name={name}
                        type={type}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        placeholder={placeholder}
                        value={value}
                        onClick={onClickHandler}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChangeHandler}
                        autoComplete={autoComplete}
                        onKeyUp={onKeyUpHandler}
                        maxLength={maxLength}
                        ref={ref}
                    />
                    {label && <label className={`${style.label} ${!showLabel && value.toString().length === 0 ? style.hide : ''}`}>{label}</label>}
                    <span className={style.bar} />
                </>
            }
            
        </div>
    )
})

export default CustomInput