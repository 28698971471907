import React, { useState, useEffect, useContext } from 'react'
import style from './Transactions.module.css'
import Range from './DatePicker/DatePicker'
import Type from './Type/Type'
import Table from '../../../Components/Table/Table'
// import MOCK_DATA from './MOCK_DATA.json'
import { Captions } from '../../../Services/Hooks/captions'
import { format } from 'date-fns'
import { GetClientTransactions } from '../../../Services/service'

const Transactions = () => {
    const [response, setResponse] = useState({ loading: true, data: undefined })
    const [filteredData, setFilteredData] = useState({ loading: true, data: undefined })
    const { getCaption } = useContext(Captions);
    useEffect(() => {
        GetClientTransactions()
            .then(res => {
                if (res.data !== "" && res.data.result !== "") {
                    setResponse({
                        loading: false,
                        data: res.data.result
                    })
                    setFilteredData({
                        loading: false,
                        data: res.data.result
                    })
                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    })
                    setFilteredData({
                        loading: true,
                        data: undefined
                    })
                }
            })
        // !response.loading && response.data.result !== null && setResponse(response.data.result)
        // !response.loading && response.data.result !== null && setFilteredData(response.data.result)
    }, [])
    const columnKeys = !response.loading && response.data.length > 0 && Object.keys(response.data[0])
    const COLUMNS = [
        {
            Header: "Product Name",
            accessor: columnKeys[1],
            hideHeader: true,
            disableSortBy: true
        },
        {
            Header: "Transaction Type",
            accessor: columnKeys[2],
            hideHeader: true,
            disableSortBy: true
        },
        {
            Header: "Currency",
            accessor: columnKeys[3],
            hideHeader: true,
            disableSortBy: true
        },
        {
            Header: "Amount",
            accessor: columnKeys[4],
            hideHeader: true
        },
        {
            Header: "State",
            accessor: columnKeys[5],
            hideHeader: true,
            disableSortBy: true
        },
        {
            Header: "Complete Date",
            accessor: columnKeys[6],
            Cell: ({ value }) => {
                return (
                    <>
                        <span id={style.date}>{value == null ? ' ' + value : format(new Date(value.split("T")[0]), 'MM/dd/yyyy')}</span>
                        <span id={style.time}>{value == null ? ' ' + value : new Date(value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</span>
                    </>
                )
            },
            hideHeader: true,
        },
    ]

    let range = (from, to) => {
        setFilteredData([...filteredData.data.filter(x => x.createTime !== null && x.completeTime !== null 
        && new Date(x.createTime.split("T")[0]) >= from 
        && new Date(x.completeTime.split("T")[0]) <= to)])
    }
    // const types = [
    //     { type: getCaption("transactions.type.all") },
    //     { type: getCaption("transactions.type.deposit") },
    //     { type: getCaption("transactions.type.withdraw") }
    // ]
    // let type = (type) => {
    //     console.log(type)
    //     type === types[0].type && response.data !== null && setFilteredData([...response.data])
    //     type === types[1].type && response.data !== null && setFilteredData([...response.data.filter(t => t.transactionType === 'Deposit')])
    //     type === types[2].type && response.data !== null && setFilteredData([...response.data.filter(t => t.transactionType === 'Withdraw')])
    // }
    return (
        <div className={style.wrapper}>
            <div className={style.options}>
                <Range data={filteredData.data} range={range} />
                <Type filteredData={filteredData} setData={setFilteredData} rerender={GetClientTransactions}/>
            </div>
            <h3 className={style.title}>{getCaption('web.game.section.title.transaction.history')}</h3>
            {(!filteredData.loading && filteredData.data && filteredData.data.length > 0) ? <>
                    <Table columns={COLUMNS} data={response.data} />
                </> : <h4 className={style.message}>{getCaption('web.transaction.history.no.data')}</h4>}
        </div>

    )

}

export default Transactions

