import React, { useContext } from 'react'
import style from './LogInHistory.module.css'
import { AuthenticationLog } from '../../../../../../../../Services/service'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Table from '../../../../../../../../Components/Table/Table';
import { format } from 'date-fns'
import CloseButton from '../../../../../../../../Components/CloseButton/CloseButton';
import Modal from 'react-modal'
import { Captions } from '../../../../../../../../Services/Hooks/captions';
import Loader from '../../../../../../../../Components/Loader/Loader'
const LogInHistory = (props) => {
    let { loading, data } = AuthenticationLog()
    const { getCaption } = useContext(Captions)
    const columnKeys = !loading && data.length > 0 && Object.keys(data[0])
    const COLUMNS = [
        {
            Header: "IP",
            accessor: !loading && columnKeys[0],
            Cell: ({ value }) => {
                return (
                    <>
                        <div className={style.key}>ip:</div>
                        <div className={style.value}>{value}</div>
                    </>
                )
            },
            hideHeader: false
        },
        {
            Header: "DATE",
            accessor: !loading && columnKeys[1],
            Cell: ({ value }) => {
                return (
                    <>
                        <div className={`${style.key} ${style.date}`}>date:</div>
                        <div className={style.value}>{format(new Date(value.split("T")[0]), 'MM/dd/yyyy')}</div>
                    </>
                )
            },
            hideHeader: false
        },
        {
            Header: "TIME",
            accessor: !loading && columnKeys[1],
            id: "onlyTime",
            Cell: ({ value }) => {
                return (
                    <>
                        <div className={`${style.key} ${style.time}`}>time:</div>
                        <div className={style.value}>{new Date(value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</div>
                    </>
                )
            },
            hideHeader: false
        },
        {
            Header: "DEVICE",
            accessor: !loading && columnKeys[2],
            Cell: ({ value }) => {
                return (
                    <Tippy className={style.tooltip} content={<p className={style.text}>{value.os}, {value.userAgent}</p>} placement='right'>
                        <var className={value.isMobile ? `${style.device} ${style.mobile}` : `${style.device} ${style.pc}`}></var>
                    </Tippy>
                )
            },
            hideHeader: false
        },

    ]
    const customStyles = {
        overlay: {
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(6, 16, 26, 0.6)',
            backdropFilter: 'blur(5px)'
        }
    }
    Modal.setAppElement('#root');
    return <Modal
        className={style.modal}
        isOpen={true}
        style={customStyles}
    //overlayClassName={style.overlay}
        closeModal={props.trigger}
    >
        <div className={style.header}>
            <h4 className={style.title}>{getCaption('web.log.in.history.title')}</h4>
            {/* <span className={style.end}>{getCaption('web.game.section.log.in.history.end.all')}</span> */}
            <CloseButton clickHandler={props.trigger} />
        </div>
        <div className={style.content}>
            {loading ? <Loader /> : (!loading && data.length > 0) ? <Table columns={COLUMNS} data={data} /> 
            : <p className={style.message}>{getCaption('web.log.in.history.empty.data')}</p>}
        </div>
    </Modal>
}

export default LogInHistory;
