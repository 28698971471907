import React, { useState, useContext } from 'react'
import useMedia from '../../../../../../Services/Hooks/useMedia';
import style from './Promotions.module.css'
import { Captions } from '../../../../../../Services/Hooks/captions';
import {useHistory} from 'react-router-dom';
const Promotions = (props) => {
    const { getCaption } = useContext(Captions);
    let history = useHistory();

    const redirectToPromo = () => {
        history.push("/Promotions")
    }

    const smallScreen = useMedia("(max-width: 1450px)")
    
    return (
        <>
           {(smallScreen || props.type === 'pinned') ? <div className={style.minipromo} onClick={redirectToPromo}></div> 
           : <div className={style.promotion} onClick={redirectToPromo}>
                <p className={style.text}>{getCaption("web.header.promotions")}</p>
            </div> }
        </>
    )
}

export default Promotions;

