export const StaticInputFields = {
    'client.username': {
        fieldType: 'text',
        fieldName: 'username',
        placeholder: 'web.fields.placeholder.username',
        fieldPageName: 'Email',
    },
    'contacts.email': {
        fieldType: 'email',
        fieldName: 'email',
        placeholder: 'web.fields.placeholder.email',
        //fieldPageName: 'Email',
    },
    'client.password': {
        fieldType: 'password',
        fieldName: 'newPassword',
        placeholder: 'web.fields.placeholder.reset.password',
        //fieldPageName: 'ChangePassword',
    },
    'client.confirm.password': {
        fieldType: 'password',
        fieldName: 'confirmedPassword',
        placeholder: 'web.fields.placeholder.confirm.reset.password',
        //fieldPageName: 'ChangePassword',
    },
    'contacts.phone.number': {
        fieldType: 'number',
        fieldName: 'phone',
        placeholder: 'web.fields.placeholder.phone',
        //fieldPageName: "SMS",
    },
    'otp.code' : {
        fieldType: 'text',
        fieldName: 'symbol'
    }
}