import React from 'react';
import {useHistory} from 'react-router-dom';
import CustomModal from "../../../../Components/CustomModal/CustomModal";
import './NotificationRenderModal.css';

const NotificationRenderModal = ({ rendererHtml, closeModal }) => {
    const history = useHistory();

    const clickHandler = (e) => {
        switch (e.target.className) {
            case "notification_modal_close":
                return closeModal();
            case "notification_modal_redirect_url":
                return parseHtml(e.target);
            case "notification_modal_redirect_to_game":
                return parseHtml(e.target);
            default:
                return "";
        }
    };

    const parseHtml = (html) => {
        const path = html.getAttribute('data-href');
        const gameLauncherOptions = JSON.parse(html.getAttribute('data-options'));
        if(gameLauncherOptions) {
            history.push(`/GameLauncher?channelName=${gameLauncherOptions.channelName}&productName=${gameLauncherOptions.productName}`);
        } else if(path) {
            history.push(path);
        }
        closeModal();
    }

    return <CustomModal
        isOpen={true}
    >
        <div
            onClick={clickHandler}
            dangerouslySetInnerHTML={{__html: rendererHtml}}
        />
    </CustomModal>
}

export default NotificationRenderModal;