import React, {useEffect} from 'react';

function PaymentSuccess() {
    useEffect(() => {
        if (window && window.parent) {
            window.parent.postMessage({message: 'PaymentSuccess'}, '*');
        }
    })
    return <div />;
}

export default PaymentSuccess;
