import React from 'react';
import GameContent from './GameContent/GameContent';
import GameHeader from './GameHeader/GameHeader';
import { isMobile } from 'react-device-detect';

const GameContainer = (props) => {
    return (
        <div>
            {
                !isMobile ?  <GameHeader path={props.path}/> : ''
            }           
            <GameContent url={props.url} />
        </div>
    )
}
export default GameContainer;