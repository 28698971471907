import React from 'react'
import style from './Game.module.css'
import GameAuthenticatedRedirect from '../../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect'
const Game = (props) => {
    return (
        <div className={style.slot}>
                <GameAuthenticatedRedirect rerender={props.rerender} pageName={props.pageName} setResponse={props.setResponse} src={props.source} sectionType={props.sectionType} 
                channelName={props.channelName} gameName={props.gameName} productName={props.productName} isFavorite={props.isFavorite} url={props.redirectUrl}  setCategory={props.setCategory} />
        </div>
    )
}

export default Game