import React, { useContext, useState } from 'react'
import { Captions } from '../../../../../../Services/Hooks/captions';
import style from './MenuItem.module.css';
import Conversation from '../../../../../../Components/Conversation/Conversation';
import { NavLink } from 'react-router-dom';
const MenuItem = (props) => {
    const { getCaption } = useContext(Captions);
    let subMenuData = props.subMenu;
    let subMenuItems = subMenuData.map(item => <p key={item.menuItemId}
        className={style.subMenuItems}>
        {item.targetUrl !== "" ? <a href={item.targetUrl} className={style.anchor}>{getCaption(item.captionKey)}</a>
            : getCaption(item.captionKey)}
        {item.captionKey === "web.footer.menu.support.chat" && <Conversation isMobile={true}
            buttonType={'transparentMobile'}
        />}
    </p>)
    return (
        <NavLink className={style.block} to={props.targetUrl} activeClassName={style.active} onClick={() => window.scrollTo(0, 0)}>
            <span className={`${style.title} ${style.arrow}`}>{getCaption(props.title)}</span>
            {subMenuItems}
        </NavLink>
    )
}

export default MenuItem;