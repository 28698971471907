import React, {useState, useEffect} from 'react';
import CloseButton from '../CloseButton/CloseButton';
import AttributeValidate from './AttributeValidate/AttributeValidate';
import OtpValidate from './OtpValidate/OtpValidate';
import SuccessVerification from './SuccessVerification/SuccessVerification';
import CustomModal from '../CustomModal/CustomModal';


import style from './VerificationModal.module.css';

function VerificationModal({fieldInfo, contactMobileCode, modal, closeModal, mobileCode, verifiedCallback }) {
    const [showCode, setShowCode] = useState(false);
    const [success, setSuccess] = useState(false);
    
    useEffect(() => {
        if(success)  {
            verifiedCallback();
        }
    }, [success])
    
    return (
        <CustomModal
            isOpen={modal.open}
            closeModal={closeModal}
        >
            <div className={style.modal}>
                {success &&
                    <SuccessVerification
                        type={modal.name}
                        updateClient={true}
                        closeModal={() => {
                            closeModal()
                            verifiedCallback()
                        }}
                    />
                }
                <div className={style.modalContent}>
                    <CloseButton clickHandler={closeModal} />
                    
                    <div className={style.modalBody}>
                        {!showCode && 
                            <AttributeValidate
                                fieldInfo={fieldInfo} 
                                contactMobileCode={contactMobileCode} 
                                attributeName={modal.name} 
                                mobileCode={mobileCode} 
                                nextStep={() => setShowCode(true)} 
                            />
                        }
                        {showCode &&
                            <OtpValidate
                                fieldInfo={fieldInfo}
                                type={modal.name}
                                contactMobileCode={contactMobileCode}
                                attrName={modal.name}
                                onSuccess={() => {
                                    setSuccess(true)
                                }}
                                goBack={() => setShowCode(false)}
                            />
                        }
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default VerificationModal;
