import React, { useContext, useEffect, useState } from 'react';
import VerificationComponent from '../../../../../../Components/VerificationComponent/VerificationComponent'
import { Link } from 'react-router-dom';
import style from './WelcomePopUp.module.css';
import { Captions } from '../../../../../../Services/Hooks/captions';
import { Auth } from '../../../../../../Services/Hooks/auth';
import { GetSliderItems } from '../../../../../../Services/service'
import CustomModal from '../../../../../../Components/CustomModal/CustomModal';

const WelcomePopUp = (props) => {
    const { getLanguage } = useContext(Captions);
    const { getCaption } = useContext(Captions);
    const { redirectAfterRegistration, resetRedirectAfterRegistration } = useContext(Auth);
    const { loading, sliderItems } = GetSliderItems("WelcomeMain", getLanguage());
    useEffect(() => {
    redirectAfterRegistration ? document.body.classList.add('fixed') : document.body.classList.remove('fixed')
    });
    return (

        <>
            {
          
                <CustomModal
                isOpen={redirectAfterRegistration}
                closeModal={() =>  resetRedirectAfterRegistration(false)}
            > 
                <div className={style.welcomePopUpBackground}>
                    <div className={style.welcomePopUp}>
                        <Link to="/Promotions" className={style.closeButton} onClick={() => resetRedirectAfterRegistration()} />
                        <div className={style.popUpHeader}>
                            <div className={style.titleCaptionWelcome}>
                                {getCaption('web.welcome.page.title')}
                            </div>
                            <div className={style.companyName}>
                                {getCaption('web.welcome.page.text')}
                            </div>
                        </div>
                        <div className={style.content}>
                            {
                                (!loading &&  sliderItems  &&  sliderItems[0]) && <img src={sliderItems[0].source} alt="welcome pop up  promotion image" />
                            }
                        </div>
                        <div className={style.footer}>
                            <p>
                                {getCaption('web.welcome.page.verify')}
                                <span className={style.bluetext}>
                                    &nbsp;
                                    <VerificationComponent
                                        fieldName="contacts.email"
                                        verificationType={'welcomePopUp'}
                                        trigger={true} clickTrigger={() => console.log()}
                                    />
                                </span>
                                &nbsp;
                                {getCaption('web.welcome.page.and')}
                                &nbsp;
                                <span className={style.bluetext}>
                                    <VerificationComponent
                                        fieldName="contacts.phone.number"
                                        trigger={true} verificationType={'welcomePopUp'}
                                        clickTrigger={() => console.log()}
                                    />
                                </span>
                                &nbsp;
                                {getCaption('web.welcome.page.participate')}
                            </p>

                        </div>
                    </div>
                </div>
                </CustomModal>
            }
         
        </>
    )
}
export default WelcomePopUp;