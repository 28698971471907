import React, { useState, useEffect, useContext } from 'react';
import { Captions } from '../../../Services/Hooks/captions';
import {PostValidateCode} from '../../../Services/service';
import {PostValidateOtp} from '../../../Services/service';
import style from './OtpValidate.module.css';

let elemRefs = [];

const Input = (props) => {
    const ref = React.createRef();
    elemRefs.push(ref);
    return (
        <div className={style.inputGroup}>
            <input 
                className={props.onError ? style.error : ''}
                data-index={props.index}
                type="number"
                name={"num" + (props.index + 1)}
                ref={ref}
                maxLength='1'
                value={props.value}
                onKeyUp={props.autoTab}
                onChange={props.handleChange('num' + (props.index + 1))}
            />
        </div>
    );
};

function OtpValidate({ onSuccess, goBack, fieldInfo, contactMobileCode, attrName }) {
    const [dateCount, setDateCount] = useState(30);
    const [isExpired, setIsExpired] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [form, setForm] = useState({
        num1: '',
        num2: '',
        num3: '',
        num4: '',
    });
    const [showHint, setShowHint] = useState(false);

    const { getCaption } = useContext(Captions);

    useEffect(() => {
        if(dateCount > 0) {
            let timerID = setInterval( () => {
                setDateCount(prev => prev - 1);
            }, 1000 );
            return () => clearInterval(timerID);
        } else {
            setIsExpired(true);
        }
    }, [dateCount]);

    elemRefs = [];

    const handleChange = input => e => {
        if(error) setError(false);
        const { value, maxLength } = e.target;
        const message = value.slice(0, maxLength);
        setForm({...form, [input]: message });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const newForm = {
            attributeName: fieldInfo.fieldName,
            verificationFields: {
                "otp.code": {
                    fieldName: "otp.code",
                    fieldValue: `${form.num1 + '' + form.num2  + '' + form.num3 + '' + form.num4}`
                }
            }
        }

        PostValidateCode(newForm)
            .then(res => {
                if(res.data) {
                    setSuccess(true);
                    onSuccess();
                } else {
                    setError(true);
                }
            })
    }



    const autoTab = (e) => {
        const BACKSPACE_KEY = 8;
        const DELETE_KEY = 46;
        let tabindex = e.target.getAttribute("data-index") || 0;
        tabindex = Number(tabindex);
        let elem = null;
        if (e.keyCode === BACKSPACE_KEY) {
            elem = tabindex > 0 && elemRefs[tabindex];
        } else if (e.keyCode !== DELETE_KEY) {
            elem = (tabindex < elemRefs.length - 1) && e.target.value.length > 0 && elemRefs[tabindex + 1];
        }
        if (elem) {
            elem.current.focus();
        }
    };
    const requestNewCode = () => {
        const newForm = {
            attributeName: attrName,
            verificationFields: {
                [attrName]: {
                    fieldName: attrName,
                    fieldValue: fieldInfo.fieldValue
                }
            }
        }
        setError(false);
        setForm({
            num1: '',
            num2: '',
            num3: '',
            num4: '',
        })

        if(attrName !== 'contacts.email') {
            newForm.verificationFields[contactMobileCode.fieldName] = {
                fieldName: contactMobileCode.fieldName,
                fieldValue: contactMobileCode.fieldValue
            }
        }
        PostValidateOtp(newForm)
            .then(res => {      
                if(res.data) {
                    setDateCount(30);
                    setIsExpired(false);
                }
            })
    }
    
    return (
            <div className={style.content}>
                <div className={style.title}>
                    <h3 className={style.modalTitle}>{getCaption(`client.verification.${fieldInfo.fieldName}`)}</h3>
                    {attrName === 'contacts.email' &&
                        <div onClick={() => setShowHint(!showHint)} className={style.infoIcon}>
                            <div className={`${showHint ? style.activeHint : ''} ${style.hintInfo}`}>{getCaption(`client.verification.${fieldInfo.fieldName}.hint.description`)}</div>
                        </div>
                    }
                </div>
                <p>{getCaption(`client.verification.${fieldInfo.fieldName}.code.sent`)}
                    <span className={style.fieldValue}>{fieldInfo.fieldValue}</span>
                </p>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className={style.formGroup}>
                        {Array.from({ length: 4 }, (element, index) => (
                            <Input
                                key={index}
                                onError={error}
                                index={index}
                                value={form["num" + (index + 1)] || ''}
                                handleChange={handleChange}
                                autoTab={autoTab}
                            />
                        ))}
                    </div>

                    <div className={style.timer}>
                        <div className={style.time}>
                            00:<span>{dateCount < 10 && 0}{dateCount}</span>
                        </div>
                        {isExpired && <p onClick={requestNewCode}>{getCaption('client.verification.request.new.code')}</p>}
                    </div>
                    <button
                        type="button"
                        className={style.goBack}
                        onClick={() => goBack()}
                    ><span className={style.goBackIcon}/>{getCaption('client.verification.button.go.back')}</button>
                    <button disabled={success} className={style.continue}>{getCaption('client.verification.button.continue')}</button>
                </form>
            </div>
    )
}

export default OtpValidate;
