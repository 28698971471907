import {isMobile} from "react-device-detect";
import jwt_decode from "jwt-decode";
import {v4 as uuidv4} from 'uuid';

const locale = {};
const hostname = process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.hostname}/back/webapi`;

export const GetImagePath = (param = '') => {
    return process.env.PUBLIC_URL + `/assets/images${isMobile ? '/mobile/' : '/'}` + param;
}

export const GetBaseUrl = () => {
    return IsLocalhost ? 'http://localhost:50776/api' : hostname + '/api';
}

export const IsLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const ConsoleLog = (msg) => {
    if (!IsLocalhost) return;
    console.log(msg);
}

export const GetCurrentTime = (time) => {
    let countDownDate = new Date(time).getTime();
    let now = new Date().getTime();

    let distance = countDownDate - now;
    let expired = distance < 0;

    let days = expired ? 0 : Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = expired ? 0 : Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = expired ? 0 : Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = expired ? 0 : Math.floor((distance % (1000 * 60)) / 1000);

    return {days, hours, minutes, seconds, expired}
}

export const SetItemToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const GetItemFromLocalStorage = (key) => {
    if(!localStorage.getItem(key) || localStorage.getItem(key) === 'undefined') {
        return false;
    }
    return JSON.parse(localStorage.getItem(key));
}

export const GetCaptchaKey = () => {
    return '6LcuU7YdAAAAABO5d3gSdAGNi7gkj2BdS5s266XX';
}

export const GetClientId = () => {
    const key = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier';
    const decodedToken = jwt_decode(GetItemFromLocalStorage('accessToken'));
    const clientId = decodedToken[key];
    return clientId;
}

export const GetCaption = (captionKey) => {
    if (captionKey !== undefined && captionKey !== null && captionKey.constructor === Array) {
        let captionArray = [];
        for (let i = 0; i < captionKey.length; i++)
            captionArray[i] = GetCaption(captionKey[i]);
        return captionArray;
    } else {
        let caption = locale[captionKey];
        return caption !== undefined ? caption : captionKey;
    }
}

export const GetBrowserUniqueId = () => {
    if (!localStorage.getItem("browserUniqueId")) {
        localStorage.setItem("browserUniqueId", uuidv4());
    }
    return localStorage.getItem("browserUniqueId");
}

export const SetCategoryTitleToDocument = (title) => {
    document.title = title;
}

export const GetregistrationPasswordRegex = () => {
    return [
        {itemId: 1, isValid: false, itemValue: '(^.{8,})', attribute1: 'web.password.must.contain.at.least.eight.character'},
        {itemId: 2, isValid: false, itemValue: '(?=.*?[A-Z])', attribute1: 'web.password.must.contain.one.uppercase.character'},
        {itemId: 3, isValid: false, itemValue: '(?=.*?[a-z])', attribute1: 'web.password.must.contain.one.lowercase.character'},
        {itemId: 4, isValid: false, itemValue: '(?=.*?[0-9])', attribute1: 'web.password.must.contain.at.least.one.digit'}
    ]
}