import React, {useContext} from 'react'
import { Captions } from '../../../../../Services/Hooks/captions'
import style from './Certificates.module.css'
import useMedia from '../../../../../Services/Hooks/useMedia'


const Certificates = (props) => {
    const { getCaption } = useContext(Captions);
    const smallScreen = useMedia("(max-width: 1280px)")

    return (
    <div className={style.container}>
        <div className={style.certificatesWrapper}>

          {props.data && props.data.sort((a, b) => a.orderNumber - b.orderNumber)
          .map((certificate, index) => <div className={style.certificate} key={index}> 
              <img className={style.img} src={certificate.source} alt={certificate.productName}/>
          </div>)}            
        </div>
        <div className={smallScreen? style.responsive : style.containerFooter }>
            <div className={smallScreen ? style.certificateLeftSmall : style.certificateLeft}>
                {
                    props.footerMainCaption.map((license, index) => <span className={smallScreen ? style.licenseSmall : style.license}  key={index}>{getCaption(license.captionKey)}</span>)
                }
                <span className={style.copyright}>{getCaption('web.footer.text')}</span>
            </div>
            <div className={style.supportContent}> 
                    {props.supportInfo.map((supportInfo, index) =>
                     (supportInfo.captionKey).includes('mail') ?
                     <a  href="mailto:someone@yoursite.com" className={style.mail} key={index}>
                          <p key={index} className={style.supportMailName}>{getCaption(supportInfo.captionKey)}</p>
                     </a>
                     :
                    <p key={index} className={style.supportMailName}>{getCaption(supportInfo.captionKey)}</p>
                    )}
            </div>   
          
        </div>
        
    </div>
    )
}

export default Certificates;