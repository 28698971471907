import React, { useContext, useEffect, useState } from 'react';
import { Captions } from '../../../../../Services/Hooks/captions';
import style from './NavMenu.module.css';
import { NavLink } from 'react-router-dom'
import Registration from '../../../ClientAuthentication/Registration/Registration';
import Login from '../../../ClientAuthentication/Login/Login'
import { GetMainMenu } from '../../../../../Services/service';
import HeaderLogo from '../../../../../Components/HeaderLogo/HeaderLogo';
import { Auth } from '../../../../../Services/Hooks/auth';
import DepositButton from '../../../Deposit/DepositButton/DepositButton';
import UserMenu from '../Header/UserMenu/UserMenu';
import Notifications from '../../../Notifications/Notifications';
import ClientAuthentication from '../../../ClientAuthentication/ClientAuthentication';
import { useHistory } from 'react-router-dom';
import Promotions from '../Header/Promotions/Promotions';
import Languages from '../Header/Languages/Languages';
import useMedia from '../../../../../Services/Hooks/useMedia';
import { SetItemToLocalStorage } from '../../../../../Services/common';
const NavMenu = (props) => {
  const { auth } = useContext(Auth);
  let { loading, data } = GetMainMenu();
  const { getCaption } = useContext(Captions);
  const [loginModalShow, setLoginModalShow] = useState(false);
  const minResolution = useMedia("(max-width: 1280px)")
  let history = useHistory()
  function clickHandler() {
      history.push(`/GameLauncher?channelName=SmartSoft-JetX&productName=JetX`)
  }
  useEffect(() => {
    if(data) {
      SetItemToLocalStorage('registerBanner', data.registerBanner[0])
    }
  }, [data])
  return (
    <div className={`${style.navMenu} ${props.pinToTop ? style.pin : ""}`} >
      <ul className={style.list}>
      {props.pinToTop && <div className={style.logoWrapper}>
              <HeaderLogo type={'pinned'}/>
              <Promotions type={'pinned'} />
        </div>}
        {!loading && data.mainMenu.sort((a, b) => a.orderNumber - b.orderNumber).map(item =>
          <li key={item.menuItemId} className={`${style.navItem} ${style[item.captionKey.split('.').pop()]} ${(item.targetUrl === window.location.pathname) ? style.active : ''}`} 
          onClick={() => {window.scrollTo(0, 0); item.targetUrl === '/JetX' && !auth && setLoginModalShow(true)}}>
            <NavLink to={item.targetUrl !== '/JetX'  ? item.targetUrl : auth ? '/GameLauncher?channelName=SmartSoft-JetX&productName=JetX' : window.location.pathname} 
              id={style[item.captionKey.split('.').pop()]} data-end="X">
                {getCaption(item.captionKey)}
            </NavLink>
          </li>
        )}
        {loginModalShow && <ClientAuthentication loginModalShow={loginModalShow} clickHandler={clickHandler} closeModal={() => setLoginModalShow(false)} />}
        {props.pinToTop && [!auth ? <div key={0} className={style.authButtons}>
           <Login isPopupMode={true} redirectUrl={false} isRedirectToGame={false} buttonType={'navMenuType'} />
           <Registration showPopup={false} buttonType={'navMenuType'} />
        </div> : <div key={1} className={style.loggedIn}>
          <DepositButton clientInfo={props.clientInfo} hideButton={true}/>
          {!minResolution && <Notifications type="bottomMenu" />}
          <UserMenu pinToTop={props.pinToTop} clientInfo={props.clientInfo && props.clientInfo} logOutUser={props.logOutUser}/>
          {!minResolution && <Languages type="bottomMenu" />}
          </div>]}
      </ul>
    </div>
  )
}

export default NavMenu;