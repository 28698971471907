import React, { useContext } from 'react'
import style from "./BlockPopup.module.css"
import CustomModal from '../CustomModal/CustomModal'
import Timer from '../Timer/Timer'
import { Captions } from '../../Services/Hooks/captions'
const BlockPopup = (props) => {
    const { getCaption } = useContext(Captions);
    return (
        <CustomModal isOpen={props.isOpen} onRequestClose={props.closeModal}>
            <div className={style.modalContent}>
                <h3 className={style.blocked}>{getCaption('web.responsible.gaming.temrorary.blocked')}</h3>
                <div className={style.wrapper}>
                    <Timer expireDate={props.unblockDate} />
                    <p className={style.msg}>{getCaption('web.responsible.gaming.left.till.unblock')}</p>
                </div>
                <button onClick={props.closeModal} className={style.ok}>ok</button>
            </div>
        </CustomModal>
    )
}

export default BlockPopup