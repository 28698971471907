import React, { useEffect, useRef, useState, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import style from './GamePage.module.css'
import PageBanners from '../PageBanners/PageBanners'
import { GetGames, GetProvidersAndProducts } from '../../../Services/service'
import Search from '../../../Components/Search/Search'
import Game from './GameCategory/Game/Game'
import { Captions } from '../../../Services/Hooks/captions'
import { ConsoleLog, SetItemToLocalStorage, GetItemFromLocalStorage } from '../../../Services/common'
import GameFilter from '../../../Components/GameFilter/GameFilter'
import { Auth } from '../../../Services/Hooks/auth'
import ClientAuthentication from '../ClientAuthentication/ClientAuthentication'
import Providers from './Providers/Providers'
import Ghost from '../../../Components/Ghost/Ghost'
import ShowMore from '../../../Components/ShowMore/ShowMore'
import useMedia from '../../../Services/Hooks/useMedia'
const GamePage = (props) => {
    //screen sizes
    const smallScreen = useMedia("(max-width: 1280px)")
    const mediumScreen = useMedia("(max-width: 1600px)")
    //
    const portion = smallScreen ? 32 : mediumScreen ? 40 : 46
    const { getCaption } = useContext(Captions);
    const [response, setResponse] = useState({ loading: true, data: undefined });
    let [showMoreCounter, setShowMoreCounter] = useState(0)
    const [renderPortion, setRenderPortion] = useState(portion)
    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [filteredGamesByProviders, setFilteredGamesByProviders] = useState([]);
    const { pathname } = useLocation();
    // start gameFilter
    let [index, setIndex] = useState(1)
    let [active, setActive] = useState({ [index]: index === 1 ? true : false });
    let [selected, setSelected] = useState('popular');
    const { auth, clientInfo } = useContext(Auth)
    const [loginModalShow, setLoginModalShow] = useState(false);


    const categoryStatus = (index, captionKey) => (e) => {
        SetItemToLocalStorage('captionKey', { 'captionKey': captionKey, 'index': index })
        if (!auth && (captionKey === "my favorites" || captionKey === "recently played")) {
            setLoginModalShow(true)
            return;
        };
        setIndex(e.target.getAttribute('index'))
        setActive({
            [index]: true
        })
        setSelected(captionKey);
        setLoginModalShow(false);
    }

    useEffect(() => {
        setRenderPortion((showMoreCounter + 1) * portion)
    }, [portion, showMoreCounter])
    
    const incrementShowMore = counter => () => {
        let c = counter + 1
        setShowMoreCounter(c)
    }

    useEffect(() => {
        if (clientInfo && GetItemFromLocalStorage('captionKey')) {
            const { captionKey, index } = GetItemFromLocalStorage('captionKey');
            setActive({
                [index]: true
            });
            setSelected(captionKey);
        }
    }, [clientInfo])

    // end  gameFilter
    useEffect(() => {
        GetGames(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    setResponse({
                        loading: false,
                        data: res.data
                    });

                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    });
                }
            })
    }, [props.pageName, auth]);

    useEffect(() => {
        setProviders([]);
        setSelectedProviders([]);
        setFilteredGamesByProviders([]);
        const categoryName = pathname.replace('/', '');
        GetProvidersAndProducts(categoryName)
            .then(res => {
                setProviders(Object.entries(res.data));
            })
            .catch(error => {
                ConsoleLog(error)
            })
    }, [pathname])

    const filterGamesByProviders = (categoryName) => {
        if (selectedProviders.length === 0) {
            for (let i = 0; i < providers.length; i++) {
                if (providers[i][0] === categoryName) {
                    setSelectedProviders([...selectedProviders, providers[i][0]]);
                    setFilteredGamesByProviders([...filteredGamesByProviders, { [providers[i][0]]: providers[i][1] }]);
                }
            }
        } else {
            const index = selectedProviders.indexOf(categoryName);
            if (index === -1) {
                for (let i = 0; i < providers.length; i++) {
                    if (providers[i][0] === categoryName) {
                        // setSelectedProviders([...selectedProviders, providers[i][0]]);
                        // setFilteredGamesByProviders([...filteredGamesByProviders, { [providers[i][0]]: providers[i][1] }]);
                        setSelectedProviders([providers[i][0]]);
                        setFilteredGamesByProviders([{ [providers[i][0]]: providers[i][1] }]);
                    }
                }
            } else {
                const newArray = [...selectedProviders];
                const newArray1 = [...filteredGamesByProviders];
                newArray.splice(index, 1);
                newArray1.splice(index, 1);
                setSelectedProviders(newArray);
                setFilteredGamesByProviders(newArray1);
            }
        }
    }

    const joinItems = (items = []) => [].concat(...items);
    let allExistingCategories = [];
    let sortedGames = [];
    if (!response.loading) allExistingCategories = Array.from(new Set(joinItems(response.data.map(category => joinItems(category.pageItems.map(item => item.othersCategory))))))
    if (!response.loading) {
        sortedGames = joinItems(response.data
            .map(category => category.pageItems
            .filter((el) => el.othersCategory.indexOf(selected.replace(' ', '')) >= 0)))
            .filter(item => item != null)
            .sort((a, b) => selected === "recently played" &&  (new Date(b.recentlyPlayedTime) - new Date(a.recentlyPlayedTime)))
    }
  
    return (
        <>
            <ClientAuthentication loginModalShow={loginModalShow} closeModal={() => setLoginModalShow(false)} />
            {/* <Slider pathName={pathname.replace('/', '') + 'Main'} /> */}
            <PageBanners pathName={pathname.replace('/', '') + 'Main'} />
            <div className={style.wrapper} id={props.pageName !== 'SlotsPage' ? style.center : ''}>
                <div className={style.games}>
                    <div className={style.search}>
                        <Search pageName={props.pageName} />
                    </div>
                    {
                        props.pageName === 'SlotsPage' ?
                            <div className={style.gameFilter}>
                                <GameFilter active={active} categoryStatus={categoryStatus} selectCategory={setSelected} />
                            </div>
                            : ''
                    }

                    <h3 className={`${style.othersCategoryTitle} ${style[selected.replace(' ', '')]}`} id={props.pageName !== 'SlotsPage' ? style.categoryAll : ''}>
                        {props.pageName === 'SlotsPage' ? selected : getCaption('web.games.page.category.title.all')}
                    </h3>
                    <div className={style.sections}>
                        <>
                            {
                                (!response.loading && selectedProviders.length === 0) && props.pageName === 'SlotsPage' ?
                                    (
                                        (
                                            (selected === "my favorites" && !allExistingCategories.includes("myfavorites")) || (selected === "recently played" && !allExistingCategories.includes("recentlyplayed"))
                                        )
                                            ?
                                            <Ghost message={getCaption(`web.games.category.${selected.replace(' ', '')}.no.data`)} /> :
                                            sortedGames.slice(0, renderPortion).map(game => <Game rerender={GetGames}
                                                pageName={props.pageName} setResponse={setResponse} source={game.bannerName}
                                                key={game.webPageItemId} isFavorite={game.othersCategory.includes("myfavorites")}
                                                gameName={game.captionKey} channelName={game.channelName} productName={game.productName}
                                            />
                                            
                                            ))

                                    :

                                    (!response.loading && selectedProviders.length === 0) ? joinItems(response.data.map(category =>
                                        <>
                                            {category.pageItems.map(game => <Game rerender={GetGames} pageName={props.pageName} setResponse={setResponse} sectionType={category.sectionType} source={game.bannerName}
                                                key={game.webPageItemId} isFavorite={game.othersCategory.includes("myfavorites")}
                                                gameName={game.captionKey} channelName={game.channelName} productName={game.productName} />)}
                                        </>
                                    )) :
                                        <div className={style.category}>
                                            <div className={style.categoryContent}>
                                                {
                                                    filteredGamesByProviders.length > 0 && filteredGamesByProviders.map((item, index) => (
                                                        item[selectedProviders[index]].productsWebPageItems.map(game => (
                                                            <Game rerender={GetGames} pageName={props.pageName} setResponse={setResponse} source={game.bannerName}
                                                                key={game.webPageItemId} isFavorite={game.othersCategory.includes("myfavorites")}
                                                                gameName={game.captionKey} channelName={game.channelName} productName={game.productName} />
                                                        ))
                                                    ))
                                                }
                                            </div>
                                        </div>
                            }
                            {sortedGames.length > renderPortion && <ShowMore increment={incrementShowMore(showMoreCounter)} />}
                        </>
                        {/* } */}
                    </div>
                </div>
                {providers.length > 0 && props.pageName === 'SlotsPage' &&
                    <Providers
                        providers={providers}
                        clearProviders={() => { setFilteredGamesByProviders([]); setSelectedProviders([]) }}
                        selectedProviders={selectedProviders}
                        filterGamesByProviders={filterGamesByProviders}
                    />
                }
            </div>
        </>
    )
}

export default GamePage;
