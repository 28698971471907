import React, { useContext, useRef, useEffect } from 'react'
import style from './Search.module.css'
import { useState } from 'react'
import SearchResult from './SearchResult/SearchResult'
import { SearchPageItems } from '../../Services/service'
import { useClickOutside } from '../../Services/Hooks/outsideClick'
import { Captions } from '../../Services/Hooks/captions'
import { useHistory } from 'react-router-dom';
import { SetItemToLocalStorage } from '../../Services/common'
import CustomInput from '../CustomInput/CustomInput'
import { isMobile } from 'react-device-detect'

const Search = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState({ loading: true, data: undefined });
    const [clicked, setClicked] = useState(false)
    const { getCaption } = useContext(Captions);
    const ref = useRef()

    const handleChange = event => {
        event.persist();
        setSearchTerm(event.target.value);
        if (event.target.value.length === 0) return;
        props.pageName !== undefined && SearchPageItems(event.target.value, props.pageName)
            .then(res => {
                if (res.data !== "") {
                    setSearchResults({
                        loading: false,
                        data: res.data
                    });
                } else {
                    setSearchResults({
                        loading: true,
                        data: undefined
                    });
                }
            })
    };

    const handleClick = () => {
        if(searchTerm.length > 0) return;
        SearchPageItems(JSON.parse(null), props.pageName)
            .then(res => {
                if (res.data !== "" && searchTerm.length == 0) {
                    setSearchResults({
                        loading: false,
                        data: res.data
                    });
                } else {
                    setSearchResults({
                        loading: true,
                        data: undefined
                    });
                }
            })
    }

    useEffect(() => {
        handleClick()
    }, [props.pageName])

    const clearSearchField = () => {
        setSearchTerm('');
        setClicked(false)
    }

    let domNode = useClickOutside(() => {
        clearSearchField();
        props.noSearch && props.noSearch()
    })
    //Search on Enter
    let history = useHistory();
    const searchOnEnter = (event) => {
        //if (event.key === 'Backspace' && searchTerm.length > 0) return
        if (!searchResults.loading && event.key === 'Enter') {
            event.preventDefault();
            SetItemToLocalStorage('searchedGames', searchResults.data)
            history.push('/SearchedGames')
            window.scrollTo(0, 0);
            clearSearchField();
        }
    }

    return (
        <>
            <div className={style.formGroup} ref={domNode}>
                <CustomInput
                    className={'static'}
                    name={'search'}
                    type={"text"}
                    placeholder={getCaption('web.game.category.field.placeholder.search')}
                    value={searchTerm}
                    onChangeHandler={handleChange}
                    onClickHandler={() => {handleClick(); searchTerm.length == 0 && setClicked(true)}}
                    data={searchResults.data}
                    autoComplete={"off"}
                    ref={ref}
                    searchOnEnter={searchOnEnter}
                    autoFocus={props.autofocus ? props.autofocus : false}
                />
                {(searchTerm.length > 0 || (clicked && (isMobile || (!searchResults.loading && searchResults.data.length > 0))) || props.noSearch) 
                ? <span id={style.close} onClick={() => {
                    clearSearchField();
                    props.noSearch && props.noSearch()
                }} /> : ""}
                {!searchResults.loading && (searchTerm.length > 0 || clicked) && <ul className={style.searchResultContainer}>
                    {searchTerm.length == 0 && searchResults.data.length > 0 && <h3 className={style.title}>{getCaption("web.game.filter.other.categoty.popular")}</h3>}
                    <div>
                        {
                            (searchResults.data.length == 0 ? searchTerm.length == 0 ? '' : <div className={style.noResult}><p>{getCaption('web.search.category.no.results')}</p></div>
                                : searchResults.data.map((item, index) => <SearchResult data={searchResults.data} key={index} index={index} name={item.publicProductName}
                                    imagePath={item.bannerName} clearSearchField={clearSearchField} provider={item.providerName} />)
                            )
                        }
                    </div>
                </ul>}
            </div>
        </>
    )
}


export default Search;