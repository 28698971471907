import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router";
import ContactInformation from './ContactInformation/ContactInformation';
import PersonalInformation from './PersonaIInformation/PersonalInformation';
import UpdatePassword from './UpdatePassword/UpdatePassword';
import SecurityOptions from './SecurityOptions/SecurityOptions';
import IdVerification from './IdVerification/IdVerification';
import MobileMenuFromBottom from "../../../Components/MobileMenuFromBottom/MobileMenuFromBottom";
import {
    GetClientDetails,
    GetRegistrationDateOfBirthPicker,
    GetRegistrationCountryCallingCode,
    PostUpdateDetails
} from '../../../Services/service';
import {Auth} from "../../../Services/Hooks/auth";
import { Captions } from '../../../Services/Hooks/captions';
import { ConsoleLog, GetregistrationPasswordRegex } from '../../../Services/common';

import style from './ClientDetails.module.css';
import CustomAlert from "../../../Components/CustomAlert/CustomAlert";

function ClientDetails() {
    let { query } = useLocation();
    const [hasQuery, setHasQuery] = useState(false);
    const [activeTabIndex, setActiveTabIndex] = useState(1);
    const [clientDetails, setClientDetails] = useState([]);
    const [detailsPerPage, setDetailsPerPage] = useState({});
    const [clientDetailsForm, setClientDetailsForm] = useState({});
    const [groupDate, setGroupDate] = useState({});
    const [groupMobileCode, setGroupMobileCode] = useState({});
    const [date, setDate] = useState({ Day: null, Month: null, Year: null });
    const [contactMobileCode, setContactMobileCode] = useState({});
    const [updateErrors, setUpdateErrors] = useState({});
    const [showMenu, setShowMenu] = useState(true);
    const [hideListMenu, setHideListMenu] = useState(false);
    const [hideListMenuAfterDelay, setHideListMenuAfterDelay] = useState(true);
    const [success, setSuccess] = useState(null);
    const { getCaption } = useContext(Captions);
    const { clientInfo, updateClientInfo } = useContext(Auth);
    let location = useLocation();

    useEffect(() => {
        if(success) {
            setTimeout(() => setSuccess(false), 3000)
        }
    }, [success])

    useEffect(() => {
        if(parseFloat(query) === 5) {
            setHasQuery(true);
            setActiveTabIndex(parseFloat(query))
        }
    }, [query])

    useEffect(() => {
        updateClientDetails();
        GetRegistrationDateOfBirthPicker()
            .then(res => {
                setGroupDate(groupByProperty(res.data.customListItems));
            })
            .catch(error => {
                ConsoleLog(error);
            })  

        GetRegistrationCountryCallingCode()
            .then(res => {
                setGroupMobileCode(groupByProperty(res.data.customListItems));
            })
            .catch(error => {
                ConsoleLog(error);
            })
    }, [date]);

    const updateClientDetails = () => {
        GetClientDetails()
            .then(res => {
                setClientDetails(res.data.registrationFields);
                let newForm = { page1: {}, page2: {}, page3: {}, page4: {} };
                res.data.registrationFields.forEach(field => {
                    if(newForm[field.fieldPageName])
                        newForm[field.fieldPageName][field.fieldName] = field.fieldValue;
                    if(field.fieldName === 'client.date.of.birth') {
                        date.Day = field.fieldValue.split('/')[0];
                        date.Month = field.fieldValue.split('/')[1];
                        date.Year = field.fieldValue.split('/')[2];
                        setDate(date)
                    }
                    if(field.fieldName === 'contacts.mobile.code') {
                        setContactMobileCode(field);
                    }
                })
                setClientDetailsForm(newForm);
            })
            .catch(error => {
                ConsoleLog(error);
            })
    }

    useEffect(() => {
        if(clientDetails.length > 0) {
            let newForm = { page1: {}, page2: {}, page3: {}, page4: {} };
            clientDetails.forEach(field => {
                if(newForm[field.fieldPageName])
                    newForm[field.fieldPageName][field.fieldName] = field.fieldValue;
                if(field.fieldName === 'client.date.of.birth') {
                    date.Day = field.fieldValue.split('/')[0];
                    date.Month = field.fieldValue.split('/')[1];
                    date.Year = field.fieldValue.split('/')[2];
                    setDate(date)
                }
                if(field.fieldName === 'contacts.mobile.code') {
                    setContactMobileCode(field);
                }
            })
            setDetailsPerPage(newForm);
        }
    }, [clientDetails])

    const groupByProperty = (data) => {
        if (!data) return;
        return data.reduce((groups, item) => ({
            ...groups,
            [item.itemKey]: [...(groups[item.itemKey] || []), item]
        }), {});
    }

    const handlerCallBack = (fieldName, fieldValue, pageName) => {
        if(clientDetailsForm[pageName]) {
            clientDetailsForm[pageName][fieldName] = fieldValue;
            setClientDetailsForm(clientDetailsForm);
        }
    }

    const submitForm = (e, pageName, callBack, inputName, object) => {
        e.preventDefault();
        setSuccess(null);
        const newObject = object ? Object.assign(detailsPerPage[pageName.toLowerCase()], object) : clientDetailsForm[pageName.toLowerCase()];
        const formData = {
            FieldsGroup: `ClientDetails${pageName}`,
            RegistrationInputs: prepareForm(newObject)
        }
        PostUpdateDetails(formData)
            .then(res => {
                if(res.data) {
                    callBack();
                    updateClientInfo();
                    updateClientDetails();
                    window.scrollTo(0, 0);
                    if(pageName !== 'Page4')
                        setSuccess(`web.client.settings.update.success.message.` + pageName.toLowerCase());
                }
                if(pageName ==='Page4') {
                    let newForm = [];
                    for(let i = 0; i < clientDetails.length; i++) {
                        if(clientDetails[i].fieldName === inputName) {
                            clientDetails[i].fieldValue = clientDetails[i].fieldValue === 'true' ? 'false' : 'true';
                        }
                        newForm.push(clientDetails[i]);
                    }
                    setClientDetails(newForm);
                }
            })
            .catch((error) => {
                if(error.response && error.response.status) {
                    setUpdateErrors(error.response.data);
                    setClientDetails(clientDetails);
                }
            })
    }

    const prepareForm = (data) => {
        let postFormData = [];
        Object.keys(data).forEach(function (key) {
            postFormData.push({ FieldName: key, FieldValue: data[key] });
        });
        return postFormData;
    }

    const goToSettingPage = (index) => {
        setActiveTabIndex(index);
        setShowMenu(false);
        document.body.classList.remove('fixed');
    }

    useEffect(() => {
        setShowMenu(true);
        if(query) {
            setHideListMenu(true);
            setActiveTabIndex(parseFloat(query));
            setHasQuery(true);
        }

        if(!hideListMenu) setHideListMenu(!hideListMenu);
        if(!hideListMenuAfterDelay) setHideListMenuAfterDelay(!hideListMenu);
    }, [location])

    const array = [
        '',
        'client.details.menu.personal.information',
        'client.details.menu.contact.info',
        'client.details.menu.change.password',
        'client.details.menu.security',
        'client.details.menu.id.verification'
    ]

    const closeModal = () => {
        setHideListMenuAfterDelay(false);
        setTimeout(() => setHideListMenu(false), 195)
    }

    return (
        <main className={style.userDetails}>
            { !hasQuery && showMenu &&
                <>
                    {hideListMenu &&
                        <MobileMenuFromBottom
                            title={getCaption('web.user.menu.account.settings')}
                            customClass={'titleWithIcon'}
                            hideListMenu={hideListMenu}
                            closeModal={() => closeModal()}
                            hideListMenuAfterDelay={hideListMenuAfterDelay}
                            setHideListMenu={() => setHideListMenu(false)}
                            menuItemList={array.map((item, index) =>
                                item !== '' && <p
                                                    key={index}
                                                    className={`${clientInfo && clientInfo.idPersonalInfoVerifiedStatus === 'Verified' ? style.idVerificationIconSuccess : '' } ${index === activeTabIndex ? style.activeMenuItem : ''} ${style.menuListItem}`}
                                                    onClick={() => goToSettingPage(index)}>{getCaption(array[index])}</p>
                            )}
                        />
                    }
                </>
            }
            <div className={style.alert}>
                {success && <CustomAlert type="success" message={getCaption(success)}/>}
            </div>

            <form>
                <h1 className={style.pageTitle}>{getCaption(array[activeTabIndex])}</h1>
                <span onClick={() => {
                    setUpdateErrors({});
                    setShowMenu(true);
                    setHasQuery(false);
                    setHideListMenu(true);
                    setHideListMenuAfterDelay(true)
                }} className={style.goBack}>{getCaption(array[activeTabIndex])}</span>

                <div className={style.personalInformationGroup}>
                    {activeTabIndex === 1 && clientDetails.length > 0 && clientDetails.map(fieldInfo => (
                        fieldInfo.fieldPageName === "page1" &&
                            <PersonalInformation
                                key={fieldInfo.fieldId}
                                page={'Page1'}
                                fieldInfo={fieldInfo}
                                groupDate={groupDate}
                                handlerCallBack={handlerCallBack}
                                submitForm={submitForm}
                                errors={updateErrors}
                                clientDetailsForm={clientDetailsForm['page1']}
                            />
                    ))}
                </div>

                <div className={style.contactInformationGroup}>
                    {activeTabIndex === 2 && clientDetails.length > 0 && clientDetails.map(fieldInfo => (
                        fieldInfo.fieldPageName === "page2" &&
                            <ContactInformation
                                key={fieldInfo.fieldId}
                                page={'Page2'}
                                clientInfo={clientInfo}
                                fieldInfo={fieldInfo}
                                mobileCode={groupMobileCode}
                                handlerCallBack={handlerCallBack}
                                submitForm={submitForm}
                                contactMobileCode={contactMobileCode}
                                errors={updateErrors}
                            />
                    ))}
                </div>

                {activeTabIndex === 3 &&
                    <div className={style.content}>
                        <UpdatePassword
                            page={'Page3'}
                            regexList={GetregistrationPasswordRegex()}
                            handlerCallBack={handlerCallBack}
                            submitForm={submitForm}
                            errors={updateErrors}
                        />
                    </div>
                }

                <div className={style.securityInformationGroup}>
                    {activeTabIndex === 4 && clientDetails.length > 0 && clientDetails.map(fieldInfo => (
                        fieldInfo.fieldPageName === "page4" &&
                        <SecurityOptions
                            key={fieldInfo.fieldId}
                            page={'Page4'}
                            fieldInfo={fieldInfo}
                            handlerCallBack={handlerCallBack}
                            submitForm={submitForm}
                        />
                    ))}
                </div>
            </form>
            <div className={style.idVerificationGroup}>
                { activeTabIndex === 5 &&
                    <IdVerification />
                }
            </div>
        </main>
    )
}

export default ClientDetails;
