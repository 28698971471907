import React, { useContext } from 'react'
import style from './FooterMenu.module.css'
import MenuItem from './MenuItem/MenuItem'
import useMedia from '../../../../../Services/Hooks/useMedia'
import { Captions } from '../../../../../Services/Hooks/captions';
import { NavLink } from 'react-router-dom'
const FooterMenu = (props) => {
    const { getCaption } = useContext(Captions);
    const mediumScreen = useMedia("(max-width: 1600px)")
    const smallScreen = useMedia("(max-width: 1280px)")
    return (
        <div className={style.container}>
            <div className={smallScreen ? style.responsive : mediumScreen ? style.medium : style.menuWrapper}>
                {props.data.sort((a, b) => a.orderNumber - b.orderNumber).map((item, index) =>
                    <NavLink className={style.block} key={index} activeClassName={style.active} to={item.targetUrl} target={item.targetUrl.includes('www') ? "_blank" : "_self"} onClick={() => window.scrollTo(0, 0)}>
                        <span className={style.title} key={item.menuItemId} >
                            {getCaption(item.captionKey)}
                        </span>
                    </NavLink>
                )}
            </div>
            <div className={style.socialMediaContainer}>
                {
                    props.socialMediaBanners.map((banners, index) =>
                        <a href={banners.redirectUrl} key={index} target={banners.redirectUrl.includes('www') ? "_blank" : "_self"} className={style.anchor}>

                            <img src={banners.source} alt={banners.bannerCategory} className={style.socialMediaIcon} key={banners.bannerId} />
                        </a>
                 )}
            </div>
        </div>
    )
}

export default FooterMenu;