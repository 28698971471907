import React, {useContext} from 'react';
import style from './ExitButton.module.css';
import { useHistory } from 'react-router-dom';
import { Captions } from '../../../../Services/Hooks/captions';
import { GetItemFromLocalStorage } from '../../../../Services/common';

const ExitButton = (props) => {
    const { getCaption } = useContext(Captions);
    let history = useHistory()
    const exitGame = () => {
        history.replace(GetItemFromLocalStorage('pathname') || '/Home');
        localStorage.removeItem('pathname');
    }
    return (
        <div onClick={exitGame} className={style.exitDiv}>
            <div className={style.exit}>
                
            </div>
           <span className={style.exitBtnCaption}>{getCaption('platform.exit.game')}</span>
        </div>
    )
}
export default ExitButton;