import React from 'react';
import style from './DepositCardItem.module.css';

function DepositCardItem({active, iconUrl, clickHandler}) {
    return <div
                style={{
                    backgroundImage: 'url(' + iconUrl + ')'
                }}
                className={`${style.item} ${active ? style.active : ''}`}
                onClick={() => clickHandler()}
            />
}

export default DepositCardItem;
