import React from 'react';
import Modal from 'react-modal';
import style from './CustomModal.module.css';

Modal.setAppElement("#root");

function CustomModal(props) {
    return (
        <Modal
            isOpen={props.isOpen}
            className={style.modal}
            overlayClassName={`${style.overlay} ${props.cn === 'noFilter' ? style.noFilter : ""} ${props.cn === 'transparent' ? style.transparent : ""}`}
            disableAutoFocus={true}
            disableEnforceFocus={true}
            onRequestClose={() => props.closeModal()}
        >
            {props.children}
        </Modal>
    )
}

export default CustomModal;
