import React, {useContext} from 'react';
import style from './GameHeader.module.css';
import ExitButton from './ExitButton/ExitButton';
// import DepositButton from '../../../Pages/Mobile/Deposit/DepositButton/DepositButton';
import DepositButton from '../../../Pages/Desktop/Deposit/DepositButton/DepositButton';

// import { isMobile } from "react-device-detect";

const GameHeader = (props) => {
   
    return (
        <div className={style.header} >
             <div className={style.deposit}> <DepositButton hideBalance={true}/></div>
            <div className={style.exit}>
                <ExitButton path={props.path} />
            </div>
           
        </div>
    )
}
export default GameHeader;