import React, { useContext } from 'react';
import Timer from '../../../../Components/Timer/Timer';
import { Captions } from '../../../../Services/Hooks/captions'

import style from './GiftItem.module.css';

export default function GiftItem({ giftStatus, giftType, giftInfo, onTimeout }) {
    const { getCaption } = useContext(Captions);

    const isExpiredCallBack = () => {
        if(!giftStatus) {
            onTimeout();
        }
    }

    return (
        <div className={style.card}>
            <div className={style.row}>
                <div className={style.container}>

                    <div className={style.header}>
                        <div>
                            <div className={style.left}>
                                <div className={style.timerContainer}>
                                    <h3>{giftInfo.productName ? giftInfo.productName : '-'}</h3>
                                    { !giftStatus &&  <Timer expireDate={giftInfo.expireDate} isExpired={isExpiredCallBack} isOldVersion={true} />}
                                </div>
                                <h4>{`${giftInfo.usedQuantity} ${giftType} ${getCaption('web.client.gift.card.freespins.used')}, ${giftInfo.quantity - giftInfo.usedQuantity} ${getCaption('web.client.gift.card.freespins.left')}`}</h4>
                            </div>
                            <div className={style.freespins}>
                                <p><span>{getCaption('web.client.gift.card.freespins.amount')} </span>0.00 GEL</p>
                            </div>
                        </div>

                        {/*<div className={style.timerContainerOld}>*/}
                        {/*    { !giftStatus ? <p>{getCaption('web.client.gift.card.time.left')}</p> : <p className={style.expired}>{getCaption('web.client.gift.card.time.expired')}</p> }*/}
                        {/*</div>*/}
                    </div>

                    <div className={style.link}>
                        <a href='s'>Promotion name/link</a>
                    </div>

                    <div className={style.cardFooter}>

                        <div>
                            <h5>{getCaption('web.client.gift.card.provider')}</h5>
                            <p>Smartsoft</p>
                        </div>
                        
                        <div className={style.cardFooterCenter}>
                            <div>
                                <img src='https://i.ibb.co/1nsHRqn/416x168-Cappadocia.png' alt="" />
                            </div>
                            <div>
                                <h5>{getCaption('web.client.gift.card.game')}</h5>
                                <p>{giftInfo.productName}</p>
                            </div>
                        </div>
                    </div>

                    <button className={style.play}>{getCaption('web.client.gift.card.button.play')}</button>

                </div>
            </div>
        </div>
    )
}
