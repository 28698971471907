import React, { useContext, useState } from 'react';
import LoginButton from '../../ClientAuthentication/Login/LoginButton/LoginButton';
import style from './LogInPopUp.module.css'
import ClientAuthentication from '../../ClientAuthentication/ClientAuthentication';
import { useHistory } from 'react-router-dom';
import { Auth } from '../../../../Services/Hooks/auth';
import { Captions } from '../../../../Services/Hooks/captions';
import Registration from '../../ClientAuthentication/Registration/Registration';


const LogInPopUp = (props) => {

    const { auth } = useContext(Auth);
    const { getCaption } = useContext(Captions);
    const [loginModalShow, setLoginModalShow] = useState(false);
    
   
    return(
        <div className={style.popUp}>
        
             <div> to participate in this promotion  &nbsp;  &nbsp; 
                 {loginModalShow && <ClientAuthentication loginModalShow={loginModalShow} clickHandler={() => setLoginModalShow(true)} closeModal={() => setLoginModalShow(false)} />} 
                 <LoginButton isPopupMode={true}  loginModalShow={loginModalShow} openModal={() => setLoginModalShow(true)} closeModal={() => setLoginModalShow(false)} /> or 
             &nbsp; &nbsp;
            <Registration buttonType={'default'}  />
            </div>    
        </div>
   
    
    )
}
export default LogInPopUp;