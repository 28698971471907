import React from 'react'
import style from './UserMenuItem.module.css'
import LogInHistory from './LogInHistory/LogInHistory'
import {NavLink} from 'react-router-dom'
import VerificationComponent from '../../../../../../../Components/VerificationComponent/VerificationComponent'
import Deposit from '../../../../../Deposit/Deposit'
import ShowBalanceItem from "./ShowBalanceItem/ShowBalanceItem";
const UserMenuItem = (props) => {
    return (
        <>
            {props.title ? (
                <VerificationComponent  
                    title={props.title} 
                    type={props.type} 
                    fieldName={props.fieldName}
                    clickTrigger={props.clickResult}
                    verifiedCallback={() => props.verifiedCallback()} 
                />
                ) : (
                <>
                    <li className={`${style.item} ${style[props.cn]} ${props.path === window.location.pathname ? style.activeLink : ""}`} onClick={props.clickResult}>
                        <NavLink to={{pathname: props.path, query: props.query ? props.query : ''}}>{props.item}</NavLink>
                        { props.cn === "showBalance" &&
                            <ShowBalanceItem fieldValue={props.fieldValue} />
                        }
                    </li>
                    {props.trigger && <LogInHistory trigger={props.clickResult} />}
                    {props.modalType && props.modalType.shown && 
                        <Deposit 
                            modalType={props.modalType.shown} 
                            closeDepositModal={props.clickResult} 
                            typeInvoice={props.modalType.invoiceType} 
                        />
                    }
                </>
            )}
        </>
    )
}

export default UserMenuItem;