import React from 'react';
import { useState, useEffect } from 'react';
import { GetCurrentTime } from '../../Services/common';
import style from './Timer.module.css';

export default function Timer({ expireDate, isExpired, isPromotion, isOldVersion, hasNoStarted }) {
    const [time, setTime] = useState(GetCurrentTime(expireDate));
    const callIsExpiredOnce = false;
    useEffect(() => {
        if(!time.expired) {
            const interval = setInterval(() => {
                setTime(GetCurrentTime(expireDate));
                // setTime(GetCurrentTime('2021-11-29T14:31:00'));
            }, 1000);
            return () => clearInterval(interval);
        } else {
           if(isExpired && !callIsExpiredOnce) {
                isExpired();
            }
        }
    }, [time, expireDate, isExpired, callIsExpiredOnce]);
   
    return (
        <div className={time.expired ? style.expired + ' ' + style.timer : style.timer}>
            { isPromotion || isOldVersion ?
            <div className={`${style.dateContainer} ${hasNoStarted ? style.yellow : style.green}`}>
                <div>
                    <div className={style.timeForPromo}>{time.days < 10 ? 0 : ''}{time.days}</div>       
                </div>
                :
                <div>
                    <div className={style.timeForPromo}>{time.hours < 10 ? 0 : ''}{time.hours}</div>   
                </div>
                :
                <div>
                    <div className={style.timeForPromo}>{time.minutes < 10 ? 0 : ''}{time.minutes}</div>
                </div>
                :
                <div>
                    <div className={style.timeForPromo}>{time.seconds < 10 ? 0 : ''}{time.seconds}</div>   
                </div>
            </div>
            :
            <div>
                <div>
                <div className={ style.time}>{time.days}</div>
                <p>days</p>
                
            </div>
            <div>
                <div className={style.time}>{time.hours}</div>
                <p>hr</p>  
            </div>
            <div>
                <div className={style.time}>{time.minutes}</div>
                <p>min</p>
            </div>
            <div>
                <div className={style.time}>{time.seconds}</div>
               <p>sec</p>
            </div>
            </div>


            }
           
            
        </div>
    )
}
