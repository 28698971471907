import React, {useState, useEffect, useContext} from 'react';
import {GetNotifications, MarkAsViewNotification} from '../../../Services/service';
import { Captions } from '../../../Services/Hooks/captions';
import { NotificationsContext } from  '../../../Services/Hooks/useNotification';
import {SetItemToLocalStorage, GetItemFromLocalStorage, ConsoleLog} from '../../../Services/common';
import NotificationsButton from "../../Desktop/Notifications/NotificationsButton/NotificatonsButton";
import style from './Notifications.module.css';
import MobileMenuFromBottom from "../../../Components/MobileMenuFromBottom/MobileMenuFromBottom";
import NotificationRenderModal from "./NotificationRenderModal/NotificationRenderModal";

function Notifications({type}) {
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const [hideNotification, setHideNotification] = useState(false);
    const [showList, setShowList] = useState(false);
    const [newItemAdd, setNewItemAdd] = useState(true);
    const [showListMenu, setShowListMenu] = useState(false);
    const [showListMenuAfterDelay, setShowListMenuAfterDelay] = useState(false);
    const { getCaption } = useContext(Captions);
    const { listIsShown, notificationList, toggleListIsShown, updateNotificationList } = useContext(NotificationsContext);

    useEffect(() => {
        if(showList) setNewItemAdd(false);
        const interval = setInterval(() => {
            GetNotifications(localStorage.getItem('lang'), localStorage.getItem('notificationId') ? localStorage.getItem('notificationId') : '')
                .then(res => {
                    if(res.data !== "" && (GetItemFromLocalStorage('notificationId') !== res.data.notificationId || !GetItemFromLocalStorage('notificationId'))) {
                        SetItemToLocalStorage('notificationId', res.data.notificationId);
                        setResponse({
                            loading: false,
                            data: res.data
                        });
                        toggleListIsShown(false);
                        updateNotificationList();
                        setHideNotification(false);
                    } else if(hideNotification) {
                        setResponse({
                            loading: true,
                            data: undefined
                        });
                    }
                })
        }, 5000);
        return () => clearInterval(interval);
    }, [hideNotification, showList]);

    useEffect(() => {
        if(!listIsShown) updateNotificationList();
    }, [listIsShown]);

    const closeModal = () => {
        setShowListMenuAfterDelay(false);
        setTimeout(() => setShowListMenu(false), 195)
    }

    const showNotification = (id) => {
        setHideNotification(false);
        setResponse({
            loading: false,
            data: notificationList.find(item => id === item.notificationId)
        });
        MarkAsViewNotification(id)
            .then(res => {
                console.log(res)
            })
            .catch(error => {
                ConsoleLog(error)
            })
    }

    return (
        <div className={style.notifications}>
            { !hideNotification && !response.loading &&
                <NotificationRenderModal
                    rendererHtml={response.data.notificationContent}
                    hideNotification={hideNotification}
                    closeModal={() => {
                        setHideNotification(true);
                        setResponse({
                            loading: true,
                            data: undefined
                        });
                    }}
                />
            }
            <MobileMenuFromBottom
                title={notificationList.length > 0 ? getCaption('web.notifications.title.notifications') : ''}
                hideListMenu={showListMenu}
                closeModal={() => closeModal()}
                hideListMenuAfterDelay={showListMenuAfterDelay}
                setHideListMenu={() => setShowListMenu(false)}
                menuItemList={notificationList.map((list, index) =>
                    <p key={index} onClick={() => showNotification(list.notificationId)}>{list.notificationTitle || '-'}</p>
                )}
            >
                {notificationList.length === 0 &&
                    <div className={style.emptyBox}>
                        You don’t have any notifications yet
                    </div>
                }
            </MobileMenuFromBottom>
            <NotificationsButton
                count={notificationList.length}
                showList={() => {
                    setShowListMenuAfterDelay(true);
                    setShowListMenu(true);
                    toggleListIsShown(true)
                }}
                status={notificationList.length > 0 && !listIsShown ? 'new' : 'default'} type={type}
            />
        </div>
    )
}

export default Notifications;
