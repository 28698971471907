import React, { useEffect, useState, useContext } from 'react';
import style from './Contact.module.css';
import ContactUs from './ContactUs/ContactUs';
import Conversation from '../../../Components/Conversation/Conversation';
import Mail from './Mail/Mail';
import { GetContactMails, GetContactGames } from '../../../Services/service';
import { Captions } from '../../../Services/Hooks/captions';



const Contact = (props) => {
    const { getCaption } = useContext(Captions);
    let [mails, setMails] = useState({
        loading: true,
        data: undefined
    });


    let [contact, setContact] = useState({
        loading: true,
        data: undefined
    })

    useEffect(() => {
        GetContactMails('Contact')
            .then(mails => {
                // console.log(mails);
                setMails({
                    loading: false,
                    data: mails.data
                })
            })
    }, []);


    return (
        <div className={style.contactLayout}>
            <div className={style.contactContent}>
                <div className={style.pageName}>
                {getCaption('web.contact.page.title')}  
                </div>
                <span className={style.textDiv}> {getCaption('web.contact.page.text')} </span>
           
                {!contact.loading &&
             
                    <>
                        <div className={style.contentTitle}>{contact.data[0].pageSectionTitle}</div>
                        {/* <span className={style.textDiv}>
                            {
                                // contact.data.captionKey
                            }

                        </span> */}
                    </>
                }

                <div className={style.mailSection}>
            
                    {!mails.loading && mails.data !== (undefined || null) && mails.data.map(mail =>
                        <Mail key={mail.itemId} itemKey={mail.itemKey} itemValue={mail.itemValue} />
                    )}

                </div>
                <div className={style.contactFooter}>
                    <div className={style.chatIcon}></div>
                    <div className={style.techSupportBtn}>
                        <p>
                        {getCaption('web.contact.page.live.tech.support')}  
                        </p>
                       
                        <Conversation isMobile={false} buttonType={'transparent'} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;