import React, { useState, useEffect, useContext, useRef } from 'react';
import jwt_decode from "jwt-decode";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { StaticInputFields } from '../../../../Services/Helpers/register';
import { PostValidateRegistrationInfo, PostRegisterUser, GetRegisterBanner } from '../../../../Services/service';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import CloseButton from '../../../../Components/CloseButton/CloseButton';
import RegisterButton from './RegisterButton/RegisterButton';
import RenderInputFields from './RenderInputFields/RenderInputFields';
import { Auth } from '../../../../Services/Hooks/auth';
import { Captions } from '../../../../Services/Hooks/captions';
import { RegistrationApi } from '../../../../Services/Hooks/registrationApi';
import { GetBrowserUniqueId, GetregistrationPasswordRegex, GetItemFromLocalStorage, ConsoleLog } from '../../../../Services/common';

import style from './Registration.module.css';


const Registration = ({buttonType, registerPopup}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const [formData, setFormData] = useState({});
    const [lists, setLists] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);
    const [bannerSource, setBannerSource] = useState(null);
    const [step, setStep] = useState(1);
    const [lang, setLang] = useState(null);

    const childRef = useRef();

    const { getCaption } = useContext(Captions);
    const { authenticate, clientInfo } = useContext(Auth);

    const browserUniqueId = GetBrowserUniqueId();
    const responses = useContext(RegistrationApi);
    const values = responses.val;
    
    if (!values) {
        responses.loadValues();
    }

    useEffect(() => {
        if(GetItemFromLocalStorage('registerBanner')) {
            setBannerSource(GetItemFromLocalStorage('registerBanner').source);
        } else {
            GetRegisterBanner()
                .then(res => {
                    if(res.data.registerBanner && res.data.registerBanner[0])
                        setBannerSource(res.data.registerBanner[0].source);
                })
                .catch(error => {
                    ConsoleLog(error)
                })
        }
    }, [])

    useEffect(() => {
        if(registerPopup)
            registerPopup(showRegistrationModal);
    }, [showRegistrationModal])

    useEffect(() => {
        if (!values) return;
        const registrationInfo = values.regisrationInfo;
        if(registrationInfo) {
            registrationInfo.registrationFields.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0))
                setResponse({ loading: false, data: registrationInfo.registrationFields });
        }
        
        const registrationCurrency = values.registrationCurrency;
        if(registrationCurrency) {
            setLists(prevState => ({
                ...prevState,
                'client.currency': registrationCurrency
            }));
        }
        
        const registrationPasswordRegex = GetregistrationPasswordRegex();
        if(registrationPasswordRegex) {
            setLists(prevState => ({
                ...prevState,
                'PasswordRegex': registrationPasswordRegex
            }));
        }

        if(!showRegistrationModal && response.data) {
            setStep(1);
            let _response = response.data;
            for(let i = 0; i < _response.length; i++) {
                if(_response[i].error) {
                    _response[i].error = '';
                }
            }
            setResponse({...response, _response}); // Clear error messages
            if(lists['PasswordRegex']) {
                let _passwordRegex = lists['PasswordRegex'];
                for (let i = 0; i < _passwordRegex.length; i++) {
                    _passwordRegex[i].isValid = false;
                }
            }
        }
        if(localStorage.getItem('lang')) setLang(localStorage.getItem('lang'));
    }, [showRegistrationModal, values]);
    
    const submitForm = (data) => {
        if (!executeRecaptcha) return;
        executeRecaptcha('contact')
            .then(res => {
                let errorsCount = 0;
                const _formData = {
                    FieldsGroup: `RegistrationPage${step}FieldsValidation`,
                    RegistrationInputs: prepareForm(data, res, step)
                }
                PostValidateRegistrationInfo(_formData)
                    .then((res) => {
                        let _errors = res.data;
                        let _data = response.data;
                        for (let i = 0; i < _errors.length; i++) {
                            for (let k = 0; k < _data.length; k++) {
                                if (_errors[i].fieldName && _data[k].fieldName ===_errors[i].fieldName) {
                                    if(!_data[k]['error']) {
                                        _data[k]['error'] = _errors[i].validationError;
                                    }
                                    if (!_errors[i].isValid) {
                                        errorsCount++;
                                    }
                                }
                            }
                        }
                        if (errorsCount === 0) {
                            _formData.FieldsGroup = 'Registration';
                            setStep(step < 2 ? step + 1 : 2);
                            if(step === 2) {
                                registerUser(_formData);
                            }
                        } else {
                            setResponse({ loading: false, data: _data });
                            errorsCount = 0;
                        }
                    })
                    .catch(function (res) {
                        ConsoleLog(res);
                    });
            })
    }
    
    const registerUser = (data) => {
        setShowLoader(true);
        PostRegisterUser(data)
            .then((res) => {
                if (!res.data.errorCode) {
                    resetFields();
                    let token = res.data.accessToken;
                    if (!token) return;
                    let decodedToken = jwt_decode(token);
                    setShowLoader(false);
                    authenticate(token, decodedToken, res.data.refreshToken, true);
                }
            })
            .catch((res) => {
                ConsoleLog(res);
            });
    }

    const resetFields = () => {
        let _data = response.data;
        for (let i = 0; i < _data.length; i++) {
            _data[i]['error'] = '';
        }
        // setClearFields(true);
        setResponse({ loading: false, data: _data });
    }

    // With ReCaptcha submit
    const onVerifyCaptcha = (e) => {
        e.preventDefault();
        setFormData(childRef.current.getAlert())
        submitForm(childRef.current.getAlert());
    };

    const prepareForm = (data, token, step) => {
        let postFormData = [];
        Object.keys(data).forEach(function (key) {
            if(StaticInputFields[key] && (StaticInputFields[key].step !== step) && step === 1) return;

            if (key === 'verification.captcha' && token) postFormData.push({ FieldName: 'verification.captcha', FieldValue: token });
            else if (key === 'verification.captcha' && token) postFormData.push({ FieldName: 'verification.captcha', FieldValue: token });
            else if (key === 'browser.unique.id') postFormData.push({ FieldName: 'browser.unique.id', FieldValue: browserUniqueId });
            else if (key === 'client.language.code') postFormData.push({ FieldName: 'client.language.code', FieldValue: lang });
            else postFormData.push({ FieldName: key, FieldValue: data[key] });
        });
        return postFormData;
    }
    
    return (
        <>
            <CustomModal
                isOpen={showRegistrationModal && !response.loading}
                closeModal={() => setShowRegistrationModal(false)}
            >
                <div className={style.registerModal}>
                {!response.loading &&
                    <form>
                        <CloseButton clickHandler={() => setShowRegistrationModal(false)} />
                        <div className={style.container}>
                            <div className={style.banner} style={{ backgroundImage: `${bannerSource ? 'url(' + bannerSource + ')' : ''}` }} />
                            <div className={style.form}>
                                <div className={style.title}>
                                    { step === 2 && <div className={style.goPreviouseStep} onClick={() => {setStep(step - 1)}} /> }
                                    <h2 className={style.registration}>{getCaption('client.registration')}</h2>
                                </div>
                                
                                <RenderInputFields 
                                    step={step}
                                    lists={lists}
                                    ref={childRef}
                                    response={response.data} 
                                    updateResponse={(data) => setResponse(data)} 
                                    updateFormData={(data) => setFormData(data)}
                                />

                                <button 
                                    className={style.submitButton} 
                                    onClick={onVerifyCaptcha} 
                                    data-badge="inline"
                                    disabled={showLoader && !clientInfo}
                                >
                                    {!showLoader ? 
                                        step === 1 ? getCaption('next') : getCaption('client.registration') : 
                                        <div className={style.loading}>
                                            <div className={style.carousel} />
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                }
                </div>
            </CustomModal>
            <RegisterButton toggleRegisterPopup={() => {setShowRegistrationModal(true)}} buttonType={buttonType} />
        </>
    )
}

export default Registration;