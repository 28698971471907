import React, { useContext, useState } from 'react'
import style from './UserMenuItem.module.css'
import { NavLink } from 'react-router-dom'
import Notifications from "../../../Notifications/Notifications";
import DepositButton from '../../../Deposit/DepositButton/DepositButton'
import VerificationComponent from '../../../../../Components/VerificationComponent/VerificationComponent'
import ShowBalanceItem from "./ShowBalanceItem/ShowBalanceItem";
import { Captions } from '../../../../../Services/Hooks/captions'
const UserMenuItem = (props) => {
    const { getCaption, setLanguage, currentLanguage, languages } = useContext(Captions);

    let [lang, setLang] = useState(currentLanguage);
    let [dropdownShown, setDropdownShown] = useState(false);

    const onChange = (e) => {
        setLanguage(e.target.className);
        setLang(e.target.className);
        //setDropdownShown(false)
    }

    const handleDropdown = () => {
        setDropdownShown(!dropdownShown);
    }

    const languageOptions = languages
        .map((language, index) => <li className={style.select} key={index}><span className={language} onClick={onChange}>{language}</span></li>);

    return (
        <>
            {props.title ? 
                <VerificationComponent
                    title={props.title}
                    type={props.type}
                    fieldName={props.fieldName}
                    clickTrigger={props.clickResult}
                    verifiedCallback={() => props.verifiedCallback()} 
                /> : 
                <>
                    <li className={`${style.item} ${style[props.cn]}`}
                        onClick={props.isDropdown ? handleDropdown
                                : !props.hasButton ? props.clickResult : undefined}
                        id={props.isDropdown && (dropdownShown === true ? style.show : style.hide)}
                    >
                        {!props.isNotification && <NavLink to={{pathname: props.path, query: props.query ? props.query : ''}}
                                  activeClassName={style.activeLink}>{props.item}</NavLink>}
                        {props.hasButton && props.clientInfo &&
                            <div className={`${style.depositButton} ${!props.clientInfo.isWalletHide ? style.isWalletHide : ''}`}>
                                <span className={style.balance}>{props.clientInfo && props.clientInfo.balance + ' ' + props.clientInfo.currencyCode}</span>
                                <DepositButton
                                    popupType={props.item}
                                    isDeposit={true}
                                    type={"allowParent"}
                                    triggerDeposit={props.triggerDeposit}
                                    handleTriggerDeposit={props.clickResult}
                                />
                            </div>
                        }
                        { props.isNotification &&
                            <Notifications />
                        }
                        { props.cn === "showBalance" &&
                            <ShowBalanceItem fieldValue={props.fieldValue} />
                        }
                    </li>
                    {(props.isDropdown && dropdownShown === true) ? 
                    <div className={style.languageDropdown}>
                        {languageOptions}
                    </div> : ""}
                </>
            }     
        </>
    )
}

export default UserMenuItem;