import React, { useState, useContext, useEffect } from 'react';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import { Captions } from '../../../../Services/Hooks/captions';
import style from './UpdatePassword.module.css'

function UpdatePassword({ regexList, handlerCallBack, submitForm, page, errors }) {
    const [passwordType, setPasswordType] = useState(true);
    const [buttonSuccess, setButtonSuccess] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const [showFieldSuccess, setShowFieldSuccess] = useState(false);
    const [regexLists, setRegexLists] = useState(regexList || []);
    const [fieldType, setFieldType] = useState({ password: false, oldPassword: false })
    const [passwordForm, setPasswordForm] = useState({
        'client.old.password': '',
        'client.password': '',
        'client.confirm.password': ''
    });
    const { getCaption } = useContext(Captions);
    const handleChange = (input, pageName='page3') => e => {
        if (input === 'client.password') validatePasswordField(input, e)
        if (input === 'client.confirm.password') validateConfirmPasswordField(input, e);
        handlerCallBack(input, e.target.value || e.target.checked, pageName);
        setPasswordForm({ ...passwordForm, [input]: e.target.value });
        setErrorMessages({...errorMessages, [input]: null});
    }

    const validatePasswordField = (input, e) => {
        setPasswordForm({ ...passwordForm, [input]: e.target.value });
        regexLists.map(list => {
            const _regExp = new RegExp("\\b" + list.itemValue + "\\b");
            return _regExp.test(e.target.value) ? list.isValid = true : list.isValid = false;
        })
    }

    const validateConfirmPasswordField = (input, e) => {
        setPasswordForm({ ...passwordForm, [input]: e.target.value });
        setShowFieldSuccess(false);
        if((passwordForm['client.password'] === e.target.value) && (e.target.value.length > 0)) setShowFieldSuccess(true);
    }

    const updateButton = () => {
        setPasswordForm({
            'client.old.password': '',
            'client.password': '',
            'client.confirm.password': ''
        });
        setErrorMessages({
            'client.old.password': null,
            'client.password': null,
            'client.confirm.password': null
        });
        setShowFieldSuccess(false);
        setButtonSuccess(true);
        const _regexLists = [...regexLists];
        _regexLists.map(list => list.isValid = false);
        setRegexLists(_regexLists);
        setTimeout(() => {
            setButtonSuccess(false)
        }, 2000);
    }

    useEffect(() => {
        if(errors.length) {
            let obj = {}
            errors.forEach(item => {
                obj[item.FieldName] = item.ValidationError;
                setErrorMessages(obj);
            })
        }
        const _regexLists = [...regexLists];
        _regexLists.map(list => list.isValid = false);
        setRegexLists(_regexLists);
    }, [errors]);
    console.log(errorMessages);
    return (
        <>
            <div className={style.updatePassword}>

                <div className={style.inputGroup}>
                    <CustomInput
                        className="static"
                        errorCode={errorMessages && errorMessages['client.confirm.password'] }
                        name="old_password"
                        type={!fieldType.oldPassword ? 'password' : 'text'}
                        value={passwordForm['client.old.password']}
                        onChangeHandler={handleChange('client.old.password')}
                        label={getCaption('web.fields.placeholder.old.password')}
                        placeholder={getCaption('web.fields.placeholder.old.password')}
                        autoComplete="off"
                    />
                    <div className={`${style.toggleShowPassword} ${fieldType.oldPassword ? style.active : ''}`} onClick={() => setFieldType({ ...fieldType, oldPassword: !fieldType.oldPassword })} />
                    {errorMessages && errorMessages['client.old.password'] && <span className={style.errorMessage}>{getCaption(errorMessages['client.old.password'])}</span>}
                </div>

                <div className={style.inputGroup}>
                    <CustomInput
                        className="static"
                        errorCode={errorMessages && errorMessages['client.password']}
                        name="new_password"
                        type={!fieldType.password ? 'password' : 'text'}
                        value={passwordForm['client.password']}
                        onChangeHandler={handleChange('client.password')}
                        label={getCaption('web.fields.placeholder.new.password')}
                        placeholder={getCaption('web.fields.placeholder.new.password')}
                        autoComplete="off"
                    />
                    <div className={`${style.toggleShowPassword} ${fieldType.password ? style.active : ''}`} onClick={() => setFieldType({ ...fieldType, password: !fieldType.password })} />
                    {errorMessages && errorMessages['client.password'] && <span className={style.errorMessage}>{getCaption(errorMessages['client.password'])}</span>}
                </div>

                <div className={style.inputGroup}>
                    <CustomInput
                        className="static"
                        errorCode={errorMessages && errorMessages['client.confirm.password']}
                        name="confirm_new_password"
                        type={!fieldType.password ? 'password' : 'text'}
                        value={passwordForm['client.confirm.password']}
                        onChangeHandler={handleChange('client.confirm.password')}
                        label={getCaption('web.fields.placeholder.confirm.password')}
                        placeholder={getCaption('web.fields.placeholder.confirm.password')}
                        autoComplete="off"
                    />
                    {errorMessages && errorMessages['client.confirm.password'] && <span className={style.errorMessage}>{getCaption(errorMessages['client.confirm.password'])}</span>}
                    {showFieldSuccess &&
                        <div className={style.fieldSucces} />
                    }
                </div>

                <button 
                    className={style.submitHandler}
                    onClick={(e) => submitForm(e, page, updateButton)}
                >{buttonSuccess ? (
                    <>
                        <span />{getCaption('button.saved')}
                    </>
                    ) : (
                        <>
                            {getCaption('button.save')}
                        </>
                    )}
                </button>

            </div>

            {
            <div className={style.inputOption}>
                <h3>{getCaption('client.password.requirements')}</h3>
                <ul>
                    {regexLists &&
                        regexLists.map(item => <li key={item.itemId} className={item.isValid ? style.isValid : ''}>{getCaption(item.attribute1)}</li>)
                    }
                </ul>
            </div>
            }
        </>
    )
}

export default UpdatePassword;
