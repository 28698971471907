import React, { useState, useEffect, useContext } from 'react';
import jwt_decode from "jwt-decode";
import CloseButton from '../../../../Components/CloseButton/CloseButton';
import { PostLoginUser } from "../../../../Services/service";
import { Captions } from '../../../../Services/Hooks/captions';
import ResetPassword from '../../../../Components/ResetPassword/ResetPassword';
import { Auth } from '../../../../Services/Hooks/auth';
import Registration from '../Registration/Registration';
import { GetBrowserUniqueId } from '../../../../Services/common';
import CustomInput from "../../../../Components/CustomInput/CustomInput";
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import LoginButton from "./LoginButton/LoginButton";
import BlockPopup from '../../../../Components/BlockPopup/BlockPopup';
import style from "./Login.module.css"
import CustomAlert from "../../../../Components/CustomAlert/CustomAlert";

export default function Login({ showLoginButtons, loginModalShow, isRedirectToGame, redirectToGame, toggleLoginPopup }) {
    const [browserUniqueId, setBrowserUniqueId] = useState(GetBrowserUniqueId);
    const [loginForm, setLoginForm] = useState({ 'client.username': '', 'client.password': '', 'browser.unique.id': browserUniqueId });
    const [passwordType, setPasswordType] = useState(true);
    const [errors, setErrors] = useState({});
    const { getCaption } = useContext(Captions);
    const [showLoader, setShowLoader] = useState(false);
    const [blockAccount, setBlockAccount] = useState({ popup: false, unblockDate: "" });
    const [resetPopup, setResetPopup] = useState(false);
    const [transparent, setTransparent] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const { auth, authenticate, clientInfo } = useContext(Auth);

    useEffect(() => {
        if (showLoginPopup) return;
        setErrors({});
        setLoginForm({ 'client.username': '', 'client.password': '', 'browser.unique.id': browserUniqueId });

    }, [showLoginPopup, browserUniqueId])

    useEffect(() => {
        if (clientInfo && auth)
            setShowLoader(false);
    }, [auth, clientInfo])

    const handleResetPopup = () => {
        setResetPopup(!resetPopup)
    }
    const handleChange = input => e => {
        setLoginForm({ ...loginForm, [input]: e.target.value });
        setErrors({});
    }

    const formatDate = (unblockDate) => {
        return new Date(new Date(unblockDate).getTime() - new Date(unblockDate).getTimezoneOffset() * 60 * 1000)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setShowLoader(true);
        PostLoginUser(prepareForm(loginForm))
            .then(res => {
                if (!res.data.errorCode) {
                    let token = res.data.accessToken;
                    if (res.data.accountUnblockDate) {
                        setShowLoader(false);
                        setBlockAccount({ ...blockAccount, popup: true, unblockDate: formatDate(res.data.accountUnblockDate) })
                    }
                    if (!token) return;
                    let decodedToken = jwt_decode(token);
                    authenticate(token, decodedToken, res.data.refreshToken);
                    if(isRedirectToGame) redirectToGame();
                } else {
                    setShowLoader(false);
                    setErrors({ errorCode: res.data.errorCode })
                }
            })
            .catch(error => {
                setShowLoader(false);
                if (error.response) {
                    setErrors({ ...errors, internalError: getCaption('web.login.form.username.or.password.is.incorrect') })
                }
            })
    }

    const prepareForm = (data, token) => {
        let postLoginForm = [];
        Object.keys(data).forEach(function (key) {
            postLoginForm.push({ inputName: key, inputValue: data[key] });
        });
        return postLoginForm;
    }

    const registerPopup = (type) => {
        setTransparent(type);
    }
    return (
        <>
            <CustomModal
                isOpen={showLoginPopup || loginModalShow}
                closeModal={() => {
                    if(loginModalShow) toggleLoginPopup();
                    setShowLoginPopup(false);
                }}
            >
                <div className={`${transparent ? style.transparent : ''} ${style.loginPopup}`}>
                    {errors.internalError &&
                        <CustomAlert message={errors.internalError} type="error" />
                    }
                    {resetPopup ? <ResetPassword popup={resetPopup} clickResult={handleResetPopup} /> : <form onSubmit={onSubmit}>
                        <CloseButton clickHandler={() => {
                            toggleLoginPopup();
                            setShowLoginPopup(false)
                        }} />
                        <div className={style.container}>
                            <div className={style.title}>{getCaption('client.sign.in')}</div>
                            <div className={style.inputGroup}>
                                <CustomInput
                                    className={"labelAnimate"}
                                    errorCode={errors.errorCode || errors.internalError}
                                    type='text'
                                    name="username"
                                    value={loginForm['client.username']}
                                    autoFocus={true}
                                    onChangeHandler={handleChange('client.username')}
                                    label={getCaption('web.fields.placeholder.username')}
                                    placeholder={getCaption('web.fields.placeholder.username')}
                                    autoComplete="off"
                                />
                                <div className={`${style.error} ${errors.errorCode ? style.show : ''}`}>{getCaption(errors.errorCode)}</div>
                            </div>

                            <div className={style.inputGroup + ' ' + style.password}>
                                <CustomInput
                                    className={"labelAnimate"}
                                    errorCode={errors.errorCode || errors.internalError}
                                    type={passwordType ? 'password' : 'text'}
                                    name="password"
                                    autoFocus={false}
                                    value={loginForm['client.password']}
                                    onChangeHandler={handleChange('client.password')}
                                    label={getCaption('web.fields.placeholder.password')}
                                    placeholder={getCaption('web.fields.placeholder.password')}
                                    autoComplete="off"
                                />
                                <div className={style.passwordIcons}>
                                    <span
                                        onClick={() => setPasswordType(!passwordType)}
                                        className={!passwordType ? style.toggleShowPassword : style.toggleShowPassword + ' ' + style.active}
                                    >
                                        <span className={style.toolTip}>
                                            {passwordType ? getCaption('tooltip.show.password') : getCaption('tooltip.hide.password')}
                                        </span>
                                    </span>
                                    <span
                                        className={style.resetPassword}
                                        onClick={() => setResetPopup(true)}
                                    >
                                        {!resetPopup && <span className={style.toolTip}>
                                            {getCaption('tooltip.reset.password')}
                                        </span>}
                                    </span>
                                </div>
                                <div className={`${style.error} ${errors.errorCode ? style.show : ''}`}>{getCaption(errors.value)}</div>
                            </div>

                            {/*<button className={style.submitButton}>{getCaption('client.sign.in')}</button>*/}
                            <button disabled={showLoader} className={`${style.submitButton} ${showLoader ? style.showLoader : ''}`}>
                                {getCaption('client.sign.in')}
                                {showLoader &&
                                <div className={style.loading}>
                                    <div className={style.carousel} />
                                </div>
                                }
                            </button>
                            <Registration buttonType={'modalType'} registerPopup={registerPopup} isFromLogin={true} />
                        </div>
                    </form>}
                    {blockAccount &&
                        <BlockPopup
                            isOpen={blockAccount.popup}
                            closeModal={() => setBlockAccount({ ...blockAccount, popup: false })}
                            unblockDate={blockAccount.unblockDate}
                        />
                    }
                </div>
            </CustomModal>
            {showLoginButtons && <LoginButton toggleLoginPopup={() => setShowLoginPopup(true)}/>}
        </>
    )
}
