import React, { useState, useContext } from 'react';
import Information from '../Information';
import style from './TermsAndConditions.module.css';
import {Captions} from '../../../../../../Services/Hooks/captions'
import { Link } from 'react-router-dom';


const TermsAndConditions = () => {
    const [show, setShow] = useState({"1": true, "2": false, "3": false, "4":false, "5":false, "6":false, "7":false, "8":false, "9":false, "10":false});
    const openDropDown = (e) => {
        setShow({
            ...show,
            [e.target.getAttribute("index")]: !show[e.target.getAttribute("index")]
        })
    }
    const { getCaption } = useContext(Captions);
   
    return(
      
        <div className={style.background}>
             <Information />
             <div className={style.content}>
                <div className={style.contentItems}>
                    <div className={style.buttonDiv}>
                    <div className={style.contentItem} index="1" onClick={openDropDown}>
                            <span> Betting terms</span>
                            <span className={style.arrow} ></span>

                        </div>
                        {
                            show["1"] && <div className={style.itemText} >
                            Bet - is a risk-driven agreement for potential winnings entered into between the customer and the bookmaker under the established Rules, 
                            where the fulfillment of such agreement is conditioned by an event whose outcome is yet to be determined. Bets are accepted on the conditions offered by the bookmaker 
                            <p>Outcome -is the result of the event (events) on which the bet was placed.</p> 
                            Bet Cancellation  - is an outcome on which the bet is not settled and winnings are not paid. As per the Rules, in the event of “bet cancellation”, 
                            an arrangement between the bookmaker and the customer shall be deemed unconcluded and the stake shall be refunded.

                         </div>
                        }
                        </div>
                        <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="2" onClick={openDropDown}>
                            <span> Age Restrionction</span>
                            <span className={style.arrow} ></span>

                        </div>
                        {
                            show["2"] && <div className={style.itemText} >
                            Bets may only be placed by individuals who are 18 years of age or the age of majority in their state (if the age of majority is over 18)
                            and agree to the Betting Rules offered by the bookmaker. The customer shall be held liable for violation of this regulation.
                            <p> Registration new customer</p>
                            No registered customer is allowed to be re-registered as a new customer (under a new name, with a new email address, etc.).
                            </div>
                        }
                        </div>
                        <div className={style.buttonDiv}>
                        <div className={style.contentItem} index="3" onClick={openDropDown}>
                            <span> Gambling addiction prevention</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["3"] && <div className={style.itemText} >
                               No advertising campaign or promotion misleads users, nor do they distort the nature of the services for potentially susceptible bettors. 
                               Customers are informed about the chances of winning as well as the potential risks. Services are provided based on the amount paid and 
                               excessive spending is not encouraged.
                                <p>Please answer the questions below. If you say 'yes' to all questions, you are likely to have a gambling problem: </p>
                                <p>Do you have uncontrolled spending?</p>
                                <p>Are you borrowing money or stealing in order to gamble?</p>
                                <p>Have you noticed that you spend less time with your family?</p>
                                <p>Do you react badly to other people’s opinion about your online gambling?</p>
                                <p>Have you lost interest in hobbies or usual leisure pursuits?</p>
                                <p>Do you feel depressed or even suicidal because of your gambling failures?</p>
                                <p>Have you ever lied to cover up the amount of money or time you have spent gambling?</p>
                                <p>It is never too late to admit that you have an addiction and to fight against it. We value our customers and in no event will we facilitate the progression of this illness. Please read the following information which can help you lower the risk of developing gambling addiction: </p>
                                <p>Do not regard gambling as your main source of income;</p>
                                <p>Set limits on the time and money to be spent. Do not break these limits;</p>
                                <p>Please do not gamble if:</p>
                                <p>You are under the influence of alcohol or any other substance;</p>
                                <p>You are depressed;</p>
                                <p>Only gamble with money that you can afford to lose.</p>
                                <p>Avoid chasing your losses.</p>

                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="4" onClick={openDropDown}>
                            <span>Underage gambling</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["4"] && <div className={style.itemText} >
                              our company does not allow minors (individuals under 18 years of age) to be involved in betting. We take every possible
                              precaution to prevent violation of this rule. We guarantee that our advertisements, sponsorship and marketing campaigns do not contain information targeted to minors. 
                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="5" onClick={openDropDown}>
                            <span>Bet Refuses</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["5"] && <div className={style.itemText} >
                             The bookmaker shall be entitled to refuse bets from customers who fail to abide by these Rules. The bookmaker reserves 
                             the right to refuse to accept a bet of any type from any customer should they violate social standards of conduct and public order.
                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="6" onClick={openDropDown}>
                            <span>Technical failures</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["6"] && <div className={style.itemText} >
                            In the case of technical failures and unfinished streams, etc. bets on Esports Live will be refunded only if the event in question does not occur
                            or bets on the event are not settled by the bookmaker.
                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="7" onClick={openDropDown}>
                            <span>Placing a bet</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["7"] && <div className={style.itemText} >
                           Placing a bet acts as proof that the Customer agrees to and accepts these Betting Rules.
                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="8" onClick={openDropDown}>
                            <span>Account </span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["8"] && <div className={style.itemText} >
                            The Customer shall be responsible for keeping their password and account number received at registration confidential.
                            All bets registered with the bookmaker shall be valid. Bet cancellation shall be subject to these Rules. Should the Customer’s login details come into the possession of 
                            a third party, the bookmaker should be informed, the Customer should change their username and password and their email password to stronger ones. You must not disclose any 
                            cash withdrawal codes or codes for changing your phone number to any third party.
                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="9" onClick={openDropDown}>
                            <span>Account Responsibility</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["9"] && <div className={style.itemText} >
                            The account holder confirms that any activities on the account are performed by themselves. If the account is managed by a third party,
                            the account holder shall take exclusive responsibility for access to the account.
                            </div>
                        }
                    </div>
                    <div className={style.buttonDiv}>
                        <div className={style.contentItem} index ="10" onClick={openDropDown}>
                            <span>Bookmaker Rights</span>
                            <span className={style.arrow}></span>

                        </div>
                        {
                            show["10"] && <div className={style.itemText} >
                            The bookmaker reserves the right to update these Rules and add new provisions at any time. Such new rules or amendments shall take effect immediately after publication on the website.
                            </div>
                        }
                    </div>
                </div>
                
            </div>
        </div>
      
    )
}
export default TermsAndConditions;