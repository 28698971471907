import React, { useState, useContext, useEffect } from 'react'
import style from './GamesMenu.module.css'
import { Captions } from '../../../../Services/Hooks/captions'
import { NavLink } from 'react-router-dom'
import { GetMainMenu } from '../../../../Services/service'
import useWindowDimensions from '../../../../Services/Hooks/windowDimesions'
import { useClickOutside } from '../../../../Services/Hooks/outsideClick'
import {Auth} from '../../../../Services/Hooks/auth'
import { useHistory } from 'react-router-dom';
import ClientAuthenticationMobile from '../../ClientAuthentication/ClientAuthentication'
const GamesMenu = (props) => {
    const { auth } = useContext(Auth);
    let { loading, data } = GetMainMenu();
    const { getCaption } = useContext(Captions);
    const [loginModalShow, setLoginModalShow] = useState(false);
    let domNode = useClickOutside(
        () => {
            props.handleMenu(false);
        }
    )
    let history = useHistory()
    function clickHandler() {
        history.push(`/GameLauncher?channelName=SmartSoft-JetX&productName=JetX`)
    }
    useEffect(() => {
        props.gamesMenu ? document.body.classList.add('fixed') : document.body.classList.remove('fixed')
    }, [props.gamesMenu])
    const windowDimensions = useWindowDimensions()
    return (
        <>
            {props.gamesMenu ? <div className={style.background} ref={domNode}>
                <div className={`${style.menu} ${windowDimensions.height > windowDimensions.width
                    ? style.portrait : style.landscape}`}>
                    {!loading && data.mainMenu.sort((a, b) => a.orderNumber - b.orderNumber).map(item => <div
                        className={style.item} id={style[item.captionKey.split('.').pop()]} onClick={props.closeMenu} key={item.menuItemId}>
                        <NavLink to={item.targetUrl !== '/JetX'  ? item.targetUrl : auth ? '/GameLauncher?channelName=SmartSoft-JetX&productName=JetX' : window.location.pathname} 
                            onClick={() => { item.targetUrl === '/JetX' && !auth && setLoginModalShow(true); window.scrollTo(0, 0); item.targetUrl !== '/JetX' && props.handleMenu(false) }}
                            activeClassName={style.activemenuItem}
                            data-end="X">
                            {
                                getCaption(item.captionKey)
                            }
                        </NavLink>
                    </div>)}
                    {loginModalShow && <ClientAuthenticationMobile loginModalShow={loginModalShow} clickHandler={clickHandler}
                                closeModal={() => setLoginModalShow(false)} />}
                </div>
            </div> : <button className={style.transparent} onClick={() => {
                props.handleMenu(!props.gamesMenu);
            }}></button>}
        </>
    )
}

export default GamesMenu;