import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import ContactInformation from './ContactInformation/ContactInformation';
import PersonalInformation from './PersonaIInformation/PersonalInformation';
import UpdatePassword from './UpdatePassword/UpdatePassword';
import SecurityOptions from './SecurityOptions/SecurityOptions';
import IdVerification from './IdVerification/IdVerification';
import { 
    GetClientDetails, 
    GetRegistrationDateOfBirthPicker,
    GetRegistrationCountryCallingCode,
    PostUpdateDetails
} from '../../../Services/service';
import { ConsoleLog, GetregistrationPasswordRegex } from '../../../Services/common';
import { Auth } from '../../../Services/Hooks/auth';
import { Captions } from '../../../Services/Hooks/captions';
import style from './ClientDetails.module.css';

function ClientDetails() {
    let { query } = useLocation();
    const [regexList, setRegexList] = useState(GetregistrationPasswordRegex());
    const [activeTabIndex, setActiveTabIndex] = useState(1);
    const [clientDetails, setClientDetails] = useState([]);
    const [detailsPerPage, setDetailsPerPage] = useState({});
    const [clientDetailsForm, setClientDetailsForm] = useState({});
    const [groupDate, setGroupDate] = useState({});
    const [groupMobileCode, setGroupMobileCode] = useState({});
    const [date, setDate] = useState({ Day: null, Month: null, Year: null });
    const [contactMobileCode, setContactMobileCode] = useState({});
    const [updateErrors, setUpdateErrors] = useState({});
    const { getCaption } = useContext(Captions);
    const { clientInfo, updateClientInfo } = useContext(Auth);

    useEffect(() => {
        if(parseFloat(query) === 5) {
            setActiveTabIndex(parseFloat(query))
        }
    }, [query])

    useEffect(() => {
        updateClientDetails();
        GetRegistrationDateOfBirthPicker()
            .then(res => {
                setGroupDate(groupByProperty(res.data.customListItems));
            })
            .catch(error => {
                ConsoleLog(error);
            })  

        GetRegistrationCountryCallingCode()
            .then(res => {
                setGroupMobileCode(groupByProperty(res.data.customListItems));
            })
            .catch(error => {
                ConsoleLog(error);
            })
    }, [date]);

    const updateClientDetails = () => {
        GetClientDetails()
        .then(res => {
            setClientDetails(res.data.registrationFields);
            let newForm = { page1: {}, page2: {}, page3: {}, page4: {} };
            res.data.registrationFields.forEach(field => {
                if(newForm[field.fieldPageName])
                    newForm[field.fieldPageName][field.fieldName] = field.fieldValue;
                if(field.fieldName === 'client.date.of.birth') {
                    date.Day = field.fieldValue.split('/')[0];
                    date.Month = field.fieldValue.split('/')[1];
                    date.Year = field.fieldValue.split('/')[2];
                    setDate(date)
                }
                if(field.fieldName === 'contacts.mobile.code') {
                    setContactMobileCode(field);
                }
            })
            setClientDetailsForm(newForm);
        })
        .catch(error => {
            ConsoleLog(error);
        }) 
    }

    useEffect(() => {
        if(clientDetails.length > 0) {
            let newForm = { page1: {}, page2: {}, page3: {}, page4: {} };
            clientDetails.forEach(field => {
                if(newForm[field.fieldPageName])
                    newForm[field.fieldPageName][field.fieldName] = field.fieldValue;
                if(field.fieldName === 'client.date.of.birth') {
                    date.Day = field.fieldValue.split('/')[0];
                    date.Month = field.fieldValue.split('/')[1];
                    date.Year = field.fieldValue.split('/')[2];
                    setDate(date)
                }
                if(field.fieldName === 'contacts.mobile.code') {
                    setContactMobileCode(field);
                }
            })
            setDetailsPerPage(newForm);
        }
    }, [clientDetails])

    const groupByProperty = (data) => {
        if (!data) return;
        return data.reduce((groups, item) => ({
            ...groups,
            [item.itemKey]: [...(groups[item.itemKey] || []), item]
        }), {});
    }

    const handlerCallBack = (fieldName, fieldValue, pageName) => {
        if(clientDetailsForm[pageName]) {
            clientDetailsForm[pageName][fieldName] = fieldValue;
            setClientDetailsForm(clientDetailsForm);
        }
    }

    const submitForm = (e, pageName, callBack, inputName, object) => {
        e.preventDefault();
        const newObject = object ? Object.assign(detailsPerPage[pageName.toLowerCase()], object) : clientDetailsForm[pageName.toLowerCase()];
        const formData = {
            FieldsGroup: `ClientDetails${pageName}`,
            RegistrationInputs: prepareForm(newObject)
        }
        PostUpdateDetails(formData)
            .then(res => {
                if(res.data) {
                    callBack();
                    updateClientInfo();
                    updateClientDetails();
                }
                if(pageName ==='Page4') {
                    let newForm = [];
                    for(let i = 0; i < clientDetails.length; i++) {
                        if(clientDetails[i].fieldName === inputName) {
                            clientDetails[i].fieldValue = clientDetails[i].fieldValue === 'true' ? 'false' : 'true';
                        }
                        newForm.push(clientDetails[i]);
                    }
                    setClientDetails(newForm);
                }
            })
            .catch((error) => {
                if(error.response && error.response.status) {
                    setUpdateErrors(error.response.data);
                    setClientDetails(clientDetails);
                }
            })
    }

    const prepareForm = (data) => {
        let postFormData = [];
        Object.keys(data).forEach(function (key) {
            postFormData.push({ FieldName: key, FieldValue: data[key] });
        });
        return postFormData;
    }
    
    return (
        <main className={style.userDetails}>
            <nav>
                <ul>
                    <li className={activeTabIndex === 1 ? style.active : ''}>
                        <span onClick={() => {
                            setUpdateErrors({})
                            setActiveTabIndex(1)
                        }}>{getCaption('client.details.menu.personal.information')}</span>
                    </li>
                    <li className={activeTabIndex === 2 ? style.active : ''}>
                        <span onClick={() => {
                            setUpdateErrors({})
                            setActiveTabIndex(2)
                        }}>{getCaption('client.details.menu.contact.info')}</span>
                    </li>
                    <li className={activeTabIndex === 3 ? style.active : ''}>
                        <span onClick={() => {
                            setUpdateErrors({})
                            setActiveTabIndex(3)
                        }}>{getCaption('client.details.menu.change.password')}</span>
                    </li>
                    <li className={activeTabIndex === 4 ? style.active : ''}>
                        <span onClick={() => {
                            setUpdateErrors({})
                            setActiveTabIndex(4)
                        }}>{getCaption('client.details.menu.security')}</span>
                    </li>
                    <li className={`${activeTabIndex === 5 ? style.active : ''} ${style.idVerification} ${clientInfo && clientInfo.idPersonalInfoVerifiedStatus === 'Verified' ? style.idVerificationIconDisabled : '' }`}>
                        {/* <span onClick={() => {setActiveTabIndex(5)
                        setUpdateErrors({}}>{getCaption('client.details.menu.id.verification')}</span> */}
                        <span className={`${style.idVerificationIcon} ${clientInfo && clientInfo.idPersonalInfoVerifiedStatus === 'Verified' ? style.idVerificationIconSuccess : '' }`} />
                        <span onClick={() => {
                            setUpdateErrors({})
                            setActiveTabIndex(5)
                        }}>{getCaption('client.details.menu.id.verification')}</span>
                    </li>
                </ul>
            </nav>

            <article>
                <form>
                    {clientDetails.length > 0 && clientDetails.map(fieldInfo => (
                        fieldInfo.fieldPageName === "page1" && 
                        activeTabIndex === 1 ? (
                            <PersonalInformation 
                                key={fieldInfo.fieldId} 
                                page={'Page1'}
                                fieldInfo={fieldInfo} 
                                groupDate={groupDate} 
                                handlerCallBack={handlerCallBack} 
                                submitForm={submitForm}
                                errors={updateErrors}
                                clientDetailsForm={clientDetailsForm['page1']}
                            />
                        ) : (
                            fieldInfo.fieldPageName === "page2" && 
                            activeTabIndex === 2 ? (
                                <ContactInformation 
                                    key={fieldInfo.fieldId} 
                                    page={'Page2'}
                                    fieldInfo={fieldInfo} 
                                    mobileCode={groupMobileCode} 
                                    handlerCallBack={handlerCallBack} 
                                    submitForm={submitForm}
                                    contactMobileCode={contactMobileCode}
                                    errors={updateErrors}
                                    clientDetailsForm={clientDetailsForm['page2']}
                                />
                            ) : (
                                fieldInfo.fieldPageName === "page4" && 
                                activeTabIndex === 4 &&
                                <SecurityOptions 
                                    key={fieldInfo.fieldId} 
                                    page={'Page4'}
                                    fieldInfo={fieldInfo} 
                                    handlerCallBack={handlerCallBack} 
                                    submitForm={submitForm}
                                    errors={updateErrors}
                                />
                            )
                        )
                    ))}
                    {activeTabIndex === 3 && 
                        <UpdatePassword 
                            page={'Page3'} 
                            regexList={regexList} 
                            handlerCallBack={handlerCallBack} 
                            submitForm={submitForm}
                            errors={updateErrors}
                        />
                    }
                </form>
                    { activeTabIndex === 5 &&
                        <IdVerification />
                    }
            </article>

        </main>
    )
}

export default ClientDetails;
