import React, { useState, useContext, useEffect } from 'react'
import style from './UserMenu.module.css'
import UserMenuItem from './UserMenuItem/UserMenuItem'
import { Captions } from '../../../../Services/Hooks/captions'
import CloseButton from '../../../../Components/CloseButton/CloseButton'
import { useClickOutside } from '../../../../Services/Hooks/outsideClick'
import { Auth } from '../../../../Services/Hooks/auth'
import {NotificationsContext} from "../../../../Services/Hooks/useNotification";
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
const UserMenu = (props) => {
    const { getCaption } = useContext(Captions);
    const { clientInfo } = useContext(Auth);
    const { notificationList, listIsShown, toggleListIsShown } = useContext(NotificationsContext);
    //let [triggerDeposit, setTriggerDeposit] = useState(false);

    // let domNode = useClickOutside(() => {
    //     let modal = document.body.classList.contains('ReactModal__Body--open')
    //     if (!modal) props.handleMenu(false);
    // })

    const handleTriggerDeposit = (position) => {
        props.setTriggerDeposit(position);
    }
    const closeOnRedirect = () => {
        props.handleMenu(false);
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        props.userMenu ? document.body.classList.add('fixed') : document.body.classList.remove('fixed')
    }, [props.userMenu])
    return (
        <>
            {props.userMenu ? <div className={style.background}>
                {/* <CloseButton clickHandler={() => props.handleMenu(false)} /> */}
                <div className={style.menu}>
                    <div className={style.upperBlock}>
                        <div className={style.user}>
                            <div className={`${style.initial} ${style[props.clientInfo && props.clientInfo.username.charAt(0)]}`} />
                            <div className={style.userInfo}>
                                <p id={style.username}>{props.clientInfo && props.clientInfo.username}</p>
                                <span id={style.number}>{props.clientInfo && props.clientInfo.clientNumber}</span>
                            </div>
                        </div>
                        <UserMenuItem
                            isNotification={true}
                            notificationList={!listIsShown ? notificationList : null}
                            clickResult={() => {
                                toggleListIsShown(true)
                            }}
                            cn="notifications"
                        />
                    </div>
                    <div className={style.block + ' ' + style.verificationBlock}>
                        {props.clientInfo && (props.clientInfo.idPersonalInfoVerifiedStatus !== 'Verified') &&
                            <UserMenuItem
                                item={getCaption("web.user.menu.client.id.verification")}
                                path="/Profile"
                                query="5"
                                //clickResult={() => props.handleMenu(false)}
                                cn="idVerification"
                            />
                        }
                        {clientInfo && !clientInfo.emailIsVerified &&
                            <UserMenuItem
                                title={'E-mail'}
                                type={'small'}
                                fieldName={'contacts.email'}
                                verifiedCallback={() =>  {} }
                            />
                        }
                        {clientInfo && !clientInfo.phoneNumberIsVerified &&
                            <UserMenuItem
                                title={'Phone'}
                                type={'small'}
                                fieldName={'contacts.phone.number'}
                                verifiedCallback={() =>  {} }
                            />
                        }
                    </div>
                    <div className={style.block}>

                        {props.clientInfo &&
                            <UserMenuItem
                                item={getCaption("web.user.menu.show.balance")}
                                fieldValue={props.clientInfo.isWalletHide}
                                clickResult={() => {}}
                                cn="showBalance"
                            />
                        }

                        <UserMenuItem
                            item={getCaption("mobile.burger.menu.deposit")}
                            hasButton={true}
                            //isDeposit={true}
                            clientInfo={props.clientInfo}
                            triggerDeposit={props.triggerDeposit}
                            clickResult={handleTriggerDeposit}
                            cn="deposit"
                        />
                        <UserMenuItem
                            item={getCaption("mobile.burger.menu.withdraw")}
                            hasButton={true}
                            //isDeposit={true}
                            clientInfo={props.clientInfo}
                            triggerDeposit={props.triggerDeposit}
                            clickResult={handleTriggerDeposit}
                            cn="withdraw"
                        />
                        <UserMenuItem
                            item={getCaption("mobile.burger.menu.transactions")}
                            path="/Transactions"
                            clickResult={closeOnRedirect}
                            cn="transactions"
                        />
                    </div>
                    <div className={style.block}>
                        <UserMenuItem
                                item={getCaption("mobile.burger.menu.my.bonuses")}
                                path="/Client/Gifts/all"
                                clickResult={closeOnRedirect}
                                cn="bonuses"
                        />
                        <UserMenuItem
                                item={getCaption("mobile.burger.menu.promotions")}
                                path="/Promotions"
                                clickResult={closeOnRedirect}
                                cn="promotions"
                        />
                    </div>
                    <div className={style.block}>
                        {/* <UserMenuItem item={getCaption("mobile.burger.menu.languages")}
                            isDropdown={true}
                            cn="languages"
                        /> */}
                        <UserMenuItem
                            item={getCaption("mobile.burger.menu.account.settings")}
                            path="/Profile"
                            clickResult={closeOnRedirect}
                            cn="settings"
                        />
                        <UserMenuItem
                            item={getCaption("mobile.burger.menu.log.in.history")}
                            path="/LogInHistory"
                            clickResult={closeOnRedirect}
                            cn="logHistory"
                        />
                         <UserMenuItem item={getCaption("mobile.burger.menu.exit")}
                            clickResult={() => { props.logOutUser(); props.handleMenu(false) }}
                            cn="exit"
                        />
                    </div>
                    {/* <div className={style.block}> */}
                        
                        {/* <UserMenuItem item={getCaption("mobile.burger.menu.tech.support")}
                            clickResult={() => props.handleMenu(false)}
                            cn="techSupport"
                        /> */}
                        {/* <UserMenuItem item={getCaption("mobile.burger.menu.promotions")}
                            path="/Promotions"
                            clickResult={() => props.handleMenu(false)}
                            cn="promotions"
                        /> */}
                       
                    {/* </div> */}
                </div>
            </div> : '' }
            <button className={style.transparent} onClick={() => {
                props.handleMenu(!props.userMenu);
                // document.body.requestFullscreen();
            }}></button>
        </>
    )
}

export default UserMenu;