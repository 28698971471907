import React, { useContext, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay, Virtual } from 'swiper';
import { Link } from "react-router-dom";
import { Captions } from '../../Services/Hooks/captions';
import { GetSliderItems } from '../../Services/service'
import 'swiper/swiper-bundle.css';
import './Slider.css';
import GameAuthenticatedRedirect from '../GameAuthenticatedRedirect/GameAuthenticatedRedirect';
import { isMobile } from "react-device-detect"


SwiperCore.use([Navigation, Pagination, Autoplay]);
const Slider = (props) => {
  const { getLanguage } = useContext(Captions);
  const slides = [];

  const returnSwiperSlide = (sliderItems) => {
    for (let i = 0; i < sliderItems.length; i++) {
      slides.push(
        <SwiperSlide key={`slide-${i}`} tag="li">
          {
            sliderItems[i].channelName 
              ?
              <GameAuthenticatedRedirect src={sliderItems[i].source}  channelName={sliderItems[i].channelName} productName={sliderItems[i].productName} hideGameName={true} hidePlayBtn={true} isSlider={true}/>
              :
              
              <Link to={sliderItems[i].redirectUrl !== '' ? sliderItems[i].redirectUrl : window.location.pathname}  >
                <img
                  src={sliderItems[i].source}
                  style={{ listStyle: 'none' }}
                  alt={`Slide ${i}`}
                />
              </Link> 
  
          }
  
        </SwiperSlide>
      );
    }
  }

  if(!props.sliderItems) {
    const { loading, sliderItems } = GetSliderItems(props.pathName, getLanguage());
    if(!loading) {
        returnSwiperSlide(sliderItems);
    }
  } else {
    const sliderItems = props.sliderItems.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
    returnSwiperSlide(sliderItems);
  }

 
  return (
    <Swiper
      id="main"
      tag="section"
      wrapperTag="ul"
      // navigation = {!isMobile && (slides.length > 1) && true}
      navigation={false}
      pagination={{ clickable: true }}
      allowSlideNext={slides.length > 1 ? true : false}
      allowSlidePrev={slides.length > 1 ? true : false}
      spaceBetween={0}
      slidesPerView={1}
      className={props.className}
      initialSlide={0}
      loop={true} 
      autoplay={
        {delay: 5000},
        {disableOnInteraction: false}
       }
      speed={1000}
      preventClicks={true}
      preventClicksPropagation={false}
      slideToClickedSlide={true}
      touchEventsTarget='wrapper'
      resistance={false}
      touchRatio={3}
      // allowTouchMove={true}
      preventClicksPropagation={true}

    >

      {slides}
    </Swiper>


  );
}


export default Slider;
