import React, { useEffect, useState, useContext } from 'react';
import NotificationsButton from './NotificationsButton/NotificatonsButton';
import { useClickOutside } from '../../../Services/Hooks/outsideClick';
import {GetNotifications, MarkAsViewNotification} from '../../../Services/service';
import {SetItemToLocalStorage, GetItemFromLocalStorage, ConsoleLog} from '../../../Services/common';
import { Captions } from '../../../Services/Hooks/captions';
import { NotificationsContext } from "../../../Services/Hooks/useNotification";
import NotificationRenderModal from "./NotificationRenderModal/NotificationRenderModal";

import style from './Notifications.module.css';

export default function Notifications({type}) {
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const [hideNotification, setHideNotification] = useState(false);
    const [showList, setShowList] = useState(false);
    const [newItemAdd, setNewItemAdd] = useState(true);
    const { getCaption } = useContext(Captions);
    const { listIsShown, notificationList, toggleListIsShown, updateNotificationList } = useContext(NotificationsContext);


    useEffect(() => {
        if(showList) setNewItemAdd(false);
        const interval = setInterval(() => {
            GetNotifications(localStorage.getItem('lang'), localStorage.getItem('notificationId') ? localStorage.getItem('notificationId') : '')
                .then(res => {
                    if(res.data !== "" && (GetItemFromLocalStorage('notificationId') !== res.data.notificationId || !GetItemFromLocalStorage('notificationId'))) {
                        SetItemToLocalStorage('notificationId', res.data.notificationId);
                        setResponse({ 
                            loading: false, 
                            data: res.data
                        });
                        toggleListIsShown(false);
                        updateNotificationList();
                        setHideNotification(false);
                    } else if(hideNotification) {
                        setResponse({ 
                            loading: true, 
                            data: undefined 
                        });
                    }
                })
        }, 5000);
        return () => clearInterval(interval);
    }, [hideNotification, showList]);

    useEffect(() => {
        if(!listIsShown) updateNotificationList();
    }, [listIsShown]);

    let domNode = useClickOutside(() => {
        setShowList(false);
    })

    const showNotification = (id) => {
        setHideNotification(false);
        setResponse({
            loading: false,
            data: notificationList.find(item => id === item.notificationId)
        });
        MarkAsViewNotification(id)
            .then(res => {
                console.log(res)
            })
            .catch(error => {
                ConsoleLog(error)
            })
    }

    return (
        <div className={style.notificationHeader} ref={domNode}>
            { !hideNotification && !response.loading &&
                <NotificationRenderModal
                    rendererHtml={response.data.notificationContent}
                    hideNotification={hideNotification}
                    closeModal={() => {
                        setHideNotification(true);
                        setResponse({
                            loading: true,
                            data: undefined
                        });
                    }}
                />
            }
            { showList &&
                <div className={`${style.dropDown} ${type && style[type] ? style[type] : ''}`}>
                    <ul>
                        {notificationList.length > 0
                        ? notificationList.map((list, index) => 
                            <li key={index} className={style.list} onClick={() => showNotification(list.notificationId)}>
                                {list.img &&
                                    <div className={style.image}>
                                        {/* <img src="" alt="" /> */}
                                    </div>
                                }
                                <div className={style.description}>
                                    <span className={style.listTitle}>{list.notificationTitle ? list.notificationTitle : 'notificationTitle'}</span>
                                    <span className={style.listTitleDate}>{list.date}</span>
                                </div>
                            </li> ) 
                        : 
                            <li className={style.emptyList}>
                                <span>{getCaption('web.notifications.not.any.notifications')}</span>
                            </li>
                        }
                    </ul>
                </div>
            }
            
            <NotificationsButton
                count={notificationList.length}
                showList={() => {
                    setShowList(!showList)
                    toggleListIsShown(true)
                }}
                status={notificationList.length > 0 && !listIsShown ? 'new' : 'default'} type={type}
            />
        </div>
    )
}