import React, { useState, useEffect } from "react";
import { 
    GetRegistrationCountrycode,
    GetRegistrationCountryCallingCode
} from "../service";

export const CountryCode = React.createContext(null);

export const useCountryCode = () => {
    const [countryCode, setCountryCode] = useState('');
    const [codes, setCodes] = useState([]);
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        if(currentValue.length > 0) return;
        if(countryCode.length === 0) {
            GetRegistrationCountrycode()
                .then(res => {
                    setCountryCode(res.data);
                })
        } 
        if(codes.length === 0) {
            GetRegistrationCountryCallingCode()
                .then(res => {
                    if(res.data.customListItems)
                        setCodes(res.data.customListItems)
                })
        }
        if(countryCode.length > 0 && codes.length > 0) setCurrentValue(codes.filter(item => item.attribute2 === countryCode)[0])
    }, [currentValue, countryCode, codes])
    
    return {
        value: currentValue,
        codes: codes
    }
}