import React, { useContext } from 'react'
import Information from '../Information'
import style from './ResponsibleGaming.module.css'
import { Link } from 'react-router-dom';
import {Captions} from '../../../../../../Services/Hooks/captions'
const ResponsibleGaming = () => {
    const { getCaption } = useContext(Captions);
    return (
        <div className={style.container}>
             <Information />
            <div className={style.main}>
                <p className={style.text}>
                Most people play for the entertainment, which means gaming to a responsible extent and within their financial means. By observing the following rules, you will be able to relax and enjoy your gaming:
                </p>
                <ul className={style.list}>
                    <li>Gaming is just for fun, not a way of earning money</li>
                    <li>Gaming is just for fun, not a way of earning money</li>
                    <li>Gaming is just for fun, not a way of earning money</li>
                </ul>
                <h2 className={style.title}>General</h2>
                <p className={style.text}>
                To ensure that the player is responsible for the game, Crystalbet offers an account blocking service. The user can block their own account for a period of 24 hours, 48 hours or 3 years (if you want to block your account for a period of 3 years, please contact us via the hotline), to prevent financial complications in the future. To remove the block before the deadline, please contact us via the hotline.To remove the block before the deadline, please contact us via the hotline.
                </p>
                {/* <p className={style.text}>
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna. Sed porta, justo vel hendrerit eleifend, magna tellus ultrices sapien, eget varius nisl magna sit amet ipsum. Aliquam erat volutpat. Nam elementum sem et tellus faucibus, ut molestie libero accumsan. Nulla accumsan nulla quis elit auctor viverra. Donec pharetra quam tortor, sed vehicula dolor posuere a. Donec interdum turpis in turpis viverra tincidunt. Integer tincidunt sollicitudin tincidunt. Donec sed ligula velit.
                </p> */}
                {/* <h2 className={style.title}>Personal limits</h2> */}
                {/* <div className={style.limits}>
                    <div className={style.limit}>Deposit limits</div>
                    <div className={style.limit}>Temprorary account block</div>
                    <div className={style.limit}>Permanent account block</div>
                    <div className={style.limit}>Gaming timer</div>
                </div> */}
                <h2 className={style.title}>External help</h2>
                <p className={style.text}>To ensure that the player is responsible for the game, Crystalb</p>
                <ul className={style.links}>
                    <li><a href="#">National Council on Problem Gambling</a></li>
                    <li><a href="#">Harvard Medical School</a></li>
                    <li><a href="#">National Council on Problem Gambling</a></li>
                    <li><a href="#">Harvard Medical School</a></li>
                    <li><a href="#">Harvard Medical School</a></li>
                </ul>
                {/* <h2 className={style.title}>Have a queston?</h2>
                <p className={style.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac suscipit ipsum. Duis vel nibh a metus blandit laoreet sit amet et magna 
                <a href="mailto:support@casino.com">support@casino.com</a>
                </p> */}
            </div>
        </div>
    )
}

export default ResponsibleGaming;