import React, { useState, useContext, useEffect } from 'react'
import style from './Type.module.css'
import { useClickOutside } from '../../../../Services/Hooks/outsideClick'
import { Captions } from '../../../../Services/Hooks/captions'
import { useMountedLayoutEffect } from 'react-table'
const Type = (props) => {
    const { getCaption } = useContext(Captions);

    const types = [
        { type: getCaption("transactions.type.all") },
        { type: getCaption("transactions.type.deposit") },
        { type: getCaption("transactions.type.withdraw") }
    ]

    let selectType = (type) => {
        props.rerender().then(res => {
            if (res.data !== "" && res.data.result !== "" && type === types[0].type) {
                props.setData({
                    loading: false,
                    data: res.data.result
                })
            } 
            if (res.data !== "" && res.data.result !== "" && type === types[1].type) {
                props.setData({
                    loading: false,
                    data: res.data.result.filter(t => t.transactionType === 'Deposit')
                })
            } 
            if (res.data !== "" && res.data.result !== "" && type === types[2].type) {
                props.setData({
                    loading: false,
                    data: res.data.result.filter(t => t.transactionType === 'Withdraw')
                })
            } 
        })
    }

    let [type, setType] = useState();
    
    useEffect(() => {
        setType(getCaption("transactions.type.all"))
    }, [getCaption])
    let [dropdownShown, setDropdownShown] = useState(false);
    
    
    const onChange = (e) => {
        setType(e.target.className);
    }

    const handleDropdown = () => {
        setDropdownShown(!dropdownShown);
    }

    const typeOptions = types.filter(t => t.type !== type)
        .map((t, index) => <li className={style.select} key={index}><span className={t.type} onClick={onChange}>{t.type}</span></li>);

    let domNode = useClickOutside(() => {
        setDropdownShown(false)
    })

    return (     
        <> 
           <div className={style.typeContainer}>
            <label className={style.label}>{getCaption("web.transactions.label.type")}</label>
                <div className={style.background} ref={domNode}>
                    <li className={style.select + " " + style.selectedValue}
                        onClick={handleDropdown}
                    >{type}</li>
                    {
                        dropdownShown && <div onClick={(e) => {
                            selectType(e.target.className); setDropdownShown(false)
                        }}
                            className={style.typeDropdown}>{typeOptions}</div>
                    }
                </div>
            </div>
        </>
    )
}

export default Type