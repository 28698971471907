import React, {useState, useContext} from 'react'
import Deposit from '../Deposit'
import style from './DepositButton.module.css'
import { Captions } from '../../../../Services/Hooks/captions'
import useMedia from '../../../../Services/Hooks/useMedia'

const DepositButton = ({clientInfo, hideButton, hideBalance}) => {
    const [showDepositModal, setShowDepositModal] = useState(false);
    const { getCaption } = useContext(Captions);
    const closeDepositModal = () => {
        setShowDepositModal(false);
    }
    
    // const smallScreen = useMedia("(max-width: 1450px)")

    return (
        <>
        {showDepositModal && <Deposit modalType={showDepositModal} closeDepositModal={closeDepositModal} /> }
        {/* {smallScreen 
        ? <div className={style.miniDeposit} onClick={() => setShowDepositModal(true)} /> */}
        <div className={`${style.deposit} ${hideBalance ? style.hideBalance: ''} ${hideButton ? style.hideButton : ''} ${clientInfo && !clientInfo.isWalletHide ? style.isWalletHide : style.isWalletShown}`}>
                <h4>{clientInfo && clientInfo.balance} <span>{clientInfo && clientInfo.currencyCode}</span></h4>
                {!hideButton && <button 
                    className={style.button}
                    onClick={() => setShowDepositModal(true)}
                >
                    {getCaption("web.header.deposit.button")}
                </button>}
            </div>
        </>
    )
}

export default DepositButton;