import React, { useState, useEffect, useRef, useContext } from 'react'
import Game from './GameCategory/Game/Game'
import style from './GamePage.module.css'
import Slider from '../../../Components/Slider/Slider'
import { GetGames, GetProvidersAndProducts } from '../../../Services/service'
import MobileSearch from '../Layout/Header/Search/Search'
import { useLocation } from 'react-router-dom'
import { Captions } from '../../../Services/Hooks/captions'
import useWindowDimensions from '../../../Services/Hooks/windowDimesions'
import Providers from './Providers/Providers'
import { ConsoleLog, SetItemToLocalStorage, GetItemFromLocalStorage } from '../../../Services/common'
import MobileDropDownMenu from '../../../Components/MobileDropDownMenu/MobileDropDownMenu'
import { Auth } from '../../../Services/Hooks/auth'
import ClientAuthentication from '../ClientAuthentication/ClientAuthentication'
import Search from '../../../Components/Search/Search'
import Ghost from '../../../Components/Ghost/Ghost'
const GamePage = (props) => {
    const { getCaption } = useContext(Captions);
    const { pathname } = useLocation();
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const [providers, setProviders] = useState([]);
    const [showProviders, setShowProviders] = useState(false);
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [productsWebPageItems, setProductsWebPageItems] = useState([]);
    const [filteredGamesByProviders, setFilteredGamesByProviders] = useState([]);

    // start gameFilter 
    let [index, setIndex] = useState(1)
    let [active, setActive] = useState({ [index]: index === 1 ? true : false });
    let [selected, setSelected] = useState('popular');
    const { auth, clientInfo } = useContext(Auth)
    const [loginModalShow, setLoginModalShow] = useState(false);
    let [dropDownShown, setDropDownShown] = useState(false);

    const categoryStatus = (index, captionKey) => (e) => {
        SetItemToLocalStorage('captionKey', { 'captionKey': getCaption(captionKey), 'index': index })
        if (!auth && (getCaption(captionKey) === "my favorites" || getCaption(captionKey) === "recently played")) {
            setLoginModalShow(true)
            return;
        };
        setIndex(e.target.getAttribute('index'))
        setActive({
            [index]: !active[index]
        })
        setSelected(getCaption(captionKey));
        setLoginModalShow(false);
    }

    useEffect(() => {
        if (clientInfo && GetItemFromLocalStorage('captionKey')) {
            const { captionKey, index } = GetItemFromLocalStorage('captionKey');
            setActive({
                [index]: true
            });
            setSelected(getCaption(captionKey));
        }
    }, [clientInfo])

    const handleDropdown = () => {
        setDropDownShown(!dropDownShown);

    }
    //end gameFilter

    useEffect(() => {
        GetGames(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    setResponse({
                        loading: false,
                        data: res.data
                    });

                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    });
                }
            })
    }, [props.pageName, auth]);

    useEffect(() => {
        setProviders([]);
        setSelectedProviders([]);
        setFilteredGamesByProviders([]);
        const categoryName = pathname.replace('/', '');
        GetProvidersAndProducts(categoryName)
            .then(res => {
                console.log(res.data)
                setProviders(Object.entries(res.data));
            })
            .catch(error => {
                ConsoleLog(error)
            })
    }, [pathname]);

    useEffect(() => {
        let arrayOfItems = [];
        for (let i = 0; i < filteredGamesByProviders.length; i++) {
            let productsWebPageItems = filteredGamesByProviders[i][selectedProviders[i]].productsWebPageItems;
            for (let j = 0; j < productsWebPageItems.length; j++) {
                arrayOfItems.push(productsWebPageItems[j])
            }
        }
        setProductsWebPageItems(arrayOfItems);
    }, [filteredGamesByProviders, selectedProviders])

    const filterGamesByProviders = (categoryName) => {
        if (selectedProviders.length === 0) {
            for (let i = 0; i < providers.length; i++) {
                if (providers[i][0] === categoryName) {
                    setSelectedProviders([...selectedProviders, providers[i][0]]);
                    setFilteredGamesByProviders([...filteredGamesByProviders, { [providers[i][0]]: providers[i][1] }]);
                }
            }
        } else {
            const index = selectedProviders.indexOf(categoryName);
            if (index === -1) {
                for (let i = 0; i < providers.length; i++) {
                    if (providers[i][0] === categoryName) {
                        setSelectedProviders([providers[i][0]]);
                        setFilteredGamesByProviders([{ [providers[i][0]]: providers[i][1] }]);
                    }
                }
            } else {
                const newArray = [...selectedProviders];
                const newArray1 = filteredGamesByProviders;
                newArray.splice(index, 1);
                newArray1.splice(index, 1);
                setSelectedProviders(newArray);
                setFilteredGamesByProviders(newArray1);
            }
        }
    }
    const windowDimensions = useWindowDimensions();
    const joinItems = (items = []) => [].concat(...items);
    let allExistingCategories = []
    let sortedGames = [];
    if (!response.loading) allExistingCategories = Array.from(new Set(joinItems(response.data.map(category => joinItems(category.pageItems.map(item => item.othersCategory))))))
    if (!response.loading) {
        sortedGames = joinItems(response.data
            .map(category => category.pageItems
                .filter((el) => el.othersCategory.indexOf(selected.replace(' ', '')) >= 0)))
            .filter(item => item != null)
            .sort((a, b) =>  selected === "recently played" &&  (new Date(b.recentlyPlayedTime) - new Date(a.recentlyPlayedTime)))
    }
    return (
        <>
            <ClientAuthentication loginModalShow={loginModalShow} closeModal={() => setLoginModalShow(false)} />
            <Slider pathName={'Mobile' + pathname.replace('/', '') + 'Main'} />
            <div className={style.wrapper}>
                <div className={props.pageName === 'MobileSlotsPage' ? style.filterOptions : style.search}>
                    {
                        props.pageName === 'MobileSlotsPage' ?
                            <>
                                <div className={style.dropDownMenu}>
                                    <MobileDropDownMenu
                                        active={active}
                                        selected={selected}
                                        dropDownShown={dropDownShown}
                                        handleDropdown={handleDropdown}
                                        selectCategory={setSelected}
                                        categoryStatus={categoryStatus}
                                        setDropDownShown={setDropDownShown}
                                        setLoginModalShow={setLoginModalShow}
                                    />
                                </div>
                                <Providers
                                    providers={providers}
                                    showProviders={showProviders}
                                    selectedProviders={selectedProviders}
                                    closeProviders={() => setShowProviders(false)}
                                    filterGamesByProviders={filterGamesByProviders}
                                    clearProviders={() => { setFilteredGamesByProviders([]); setSelectedProviders([]) }}
                                />
                            </>
                            : ''
                    }
                    {props.pageName === 'MobileSlotsPage' ? <MobileSearch pageName={props.pageName} /> : <Search pageName={props.pageName} />}
                </div>
                <h3 className={`${style.othersCategoryTitle} ${style[selected.replace(' ', '')]}`} id={props.pageName !== 'MobileSlotsPage' ? style.categoryAll : ''}>
                    {props.pageName === 'MobileSlotsPage' ? selected : getCaption('web.games.page.category.title.all')}
                </h3>
                <div className={style.sections}>
                    <div className={`${style.container} ${(windowDimensions.height > windowDimensions.width) ? style.portrait : style.landscape}  ${(!allExistingCategories.includes("myfavorites") && selected === "my favorites") ? style.ghost : ''} ${(!allExistingCategories.includes("recentlyplayed") && selected === "recently played") ? style.ghost : ""}`}>
                        {
                            (!response.loading && selectedProviders.length === 0) && props.pageName === 'MobileSlotsPage' ?
                                (((selected === "my favorites" && !allExistingCategories.includes("myfavorites")) || (selected === "recently played" && !allExistingCategories.includes("recentlyplayed"))) ?
                                    <Ghost message={getCaption(`web.games.category.${selected.replace(' ', '')}.no.data`)} />
                                    :
                                    sortedGames.map(game => <Game rerender={GetGames}
                                        pageName={props.pageName} setResponse={setResponse} source={game.bannerName}
                                        key={game.webPageItemId} isFavorite={game.othersCategory.includes("myfavorites")}
                                        gameName={game.captionKey} channelName={game.channelName} productName={game.productName}
                                    />
                                    ))

                                :

                                (!response.loading && selectedProviders.length === 0) ? joinItems(response.data.map(category =>
                                    <>
                                        {category.pageItems.map(game => <Game rerender={GetGames} pageName={props.pageName} setResponse={setResponse} sectionType={category.sectionType} source={game.bannerName}
                                            key={game.webPageItemId} isFavorite={game.othersCategory.includes("myfavorites")}
                                            gameName={game.captionKey} channelName={game.channelName} productName={game.productName} />)}
                                    </>

                                )) :
                                    <>
                                        {
                                            productsWebPageItems.map(game => (
                                                <Game
                                                    key={game.webPageItemId}
                                                    itemType="noSlider"
                                                    rerender={GetGames}
                                                    source={game.bannerName}
                                                    pageName={props.pageName}
                                                    setResponse={setResponse}
                                                    gameName={game.captionKey}
                                                    channelName={game.channelName}
                                                    productName={game.productName}
                                                    isFavorite={game.othersCategory.includes("myfavorites")}
                                                />
                                            ))
                                        }
                                    </>
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default GamePage;