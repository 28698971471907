import React, { useEffect, useState, useContext } from 'react';
import {
    Switch,
    Route,
    useParams,
    useRouteMatch
} from 'react-router-dom';
import GiftItem from './GiftItem/GiftItem';
import { GetGifts } from '../../../Services/service';
import { Captions } from '../../../Services/Hooks/captions';

import style from './Gifts.module.css';
import Ghost from "../../../Components/Ghost/Ghost";

export default function Gifts() {
    let { path } = useRouteMatch();
    return (
        <div className={style.section}>
            <GetClientGiftsByCategory />
            <Switch>
                <Route exact path={`${path}`}>
                </Route>
            </Switch>
        </div>
    )
}

function GetClientGiftsByCategory() {
    const { categoryName } = useParams();
    const [giftsInfo, setGiftsInfo] = useState([]);
    const { getCaption } = useContext(Captions);

    const refreshGifts = () => {
        GetGifts('')
            .then(res => {
                setGiftsInfo(res.data);
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        GetGifts('')
            .then(res => {
                setGiftsInfo(res.data);
            })
    }, [categoryName]);
    

    return (
        <>
            <h3 className={style.activeGifts}>{getCaption('client.gifts.title')}</h3>
            {giftsInfo.length > 0 ?
            <>
                <div className={style.items}>
                    {
                        giftsInfo.map((giftInfo, index) => (
                            !giftInfo.isExpired &&
                            <GiftItem 
                                key={index}
                                giftType={giftInfo.giftType}
                                giftInfo={giftInfo.giftInfo}
                                giftStatus={giftInfo.isExpired}
                                onTimeout={() => refreshGifts()}
                            />
                        ))
                    }
                    { 
                        giftsInfo.map((giftInfo, index) => (
                            giftInfo.isExpired &&
                            <GiftItem 
                                key={index}
                                giftType={giftInfo.giftType}
                                giftInfo={giftInfo.giftInfo}
                                giftStatus={giftInfo.isExpired}
                                onTimeout={() => refreshGifts()}
                            />
                        ))
                    }
                </div>
            </> : 
            <div className={style.emptyBonuses}>
                <Ghost message={getCaption('web.bonuses.do.not.have.any')} />
            </div>
            }
        </>
    );
  }
