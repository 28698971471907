import React from 'react'
import { Link } from "react-router-dom";
import GameAuthenticatedRedirect from '../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect'

const BannerItem = (props) => {
    return (
        <div>
            {
                props.sliderItems.channelName 
                    ?
                        <GameAuthenticatedRedirect 
                            src={props.sliderItems.source}  
                            channelName={props.sliderItems.channelName} 
                            productName={props.sliderItems.productName} 
                            hideGameName={true} 
                            hidePlayBtn={true} 
                            isSlider={true}
                        />
                    :
                    
                    <Link to={props.sliderItems.redirectUrl !== '' ? props.sliderItems.redirectUrl : window.location.pathname}  >
                        <img
                            src={props.sliderItems.source}
                            style={{ listStyle: 'none' }}
                            alt={`Slide`}
                        />
                    </Link> 

            }
        </div>
    )
}

export default BannerItem