import React, { useEffect } from 'react';
import CloseButton from '../CloseButton/CloseButton';
import style from './PaymentSuccessMessage.module.css';

export const PaymentFailMessage = ({show, message, update}) => {
    useEffect(() => {
        if(show) {
            let isMounted = true;
            setTimeout(function() {
                if(isMounted) update();
            }, 4000)
        }
    }, [show, update])

    return <>
        {show &&
            <div className={style.contentFail}>
                <CloseButton clickHandler={() => update()} />
                <div className={style.center}>
                    <div className={style.icon} />
                    <h5>{message}</h5>
                </div>
            </div>
        }
    </>
}
