import React, { useState, useEffect } from "react";
import {GetPopupNotifications} from "../service";

export const NotificationsContext = React.createContext({});

export const useNotifications = () => {
    const [ listIsShown, setListIsShown ] = useState(false);
    const [notificationList, setNotificationList] = useState([]);

    // useEffect(() => {
    //     if(notificationList.length === 0) {
    //         GetPopupNotifications(localStorage.getItem('lang'))
    //             .then(res => {
    //                 setNotificationList(res.data);
    //             })
    //             .catch(err => {
    //                 console.log(err)
    //             })
    //     }
    // }, [notificationList])

    const toggleListIsShown = (type) => {
        setListIsShown(type);
    }

    const updateNotificationList = () => {
        GetPopupNotifications(localStorage.getItem('lang'))
            .then(res => {
                setNotificationList(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }

    return {
        listIsShown,
        notificationList,
        toggleListIsShown,
        updateNotificationList
    }
}