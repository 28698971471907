import React, {useState, useEffect, useContext} from 'react';
import { Captions } from '../../../../../../../Services/Hooks/captions';
import { GetGamesAllCategory } from '../../../../../../../Services/service';
import style from './HorizontalSubMenu.module.css';

const HorizontalSubMenu = ({selectMenuItem}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { loading, data } = GetGamesAllCategory();
    const { getCaption } = useContext(Captions);

    useEffect(() => {
        if(!loading) {
            selectMenuItem(data[activeIndex].captionKey.split('.')[data[activeIndex].captionKey.split('.').length - 1].toLowerCase())
        }
    }, [loading])

    const handleMenuItem = (index, categoryName) => {
        setActiveIndex(index);
        selectMenuItem(categoryName);
    }
    
    return (
        <nav className={style.menuContainer}>
            {
                !loading && data.map(
                    (item, index) => (
                        <li
                            key={index}
                            onClick={() => handleMenuItem(index, item.captionKey.split('.')[item.captionKey.split('.').length - 1])}
                            className={`${style.horizontalSubMenuItems} ${activeIndex === index ? style.activeHorizontalSubMenuItems : ''} ${style[item.captionKey.split('.')[item.captionKey.split('.').length - 1].toLowerCase()]}`}
                        >
                            {
                                getCaption(item.captionKey)
                            }
                        </li>
                    )
                )
            }
        </nav>
    )
}
export default HorizontalSubMenu;

