import React from 'react'
import GameAuthenticatedRedirect from '../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect'
import style from './JetX.module.css'
const JetX = (props) => {
    return (
        <>
            {props.data.pageItems.map(game => 
                <div className={style.jetx} key ={game.webPageItemId}>
                    <GameAuthenticatedRedirect  src={game.bannerName} alt={game.productName} url={game.redirectUrl} channelName={game.channelName} productName={game.productName} 
                    gameName={game.captionKey} pageName={props.pageName} isFavorite={game.othersCategory.includes("myfavorites")} />
                </div>)}
        </>
    )
}

export default JetX;