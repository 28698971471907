import React, { useState, useEffect, useContext, useRef } from 'react'
import ContactsPhoneNumber from '../ContactsPhoneNumber/ContactsPhoneNumber'
import { ReCaptcha } from '../ReCaptcha/ReCaptcha'
import style from './ResetPassword.module.css'
import {
    GetPasswordResetInfo,
    PostValidateRequest,
    PostVerifyOtp,
    PostChangePassword
} from '../../Services/service'
import CloseButton from '../CloseButton/CloseButton'
import { StaticInputFields } from '../../Services/Helpers/PasswordReset'
import { Captions } from '../../Services/Hooks/captions'
import Timer from './Timer/Timer'
import { isMobile } from 'react-device-detect'
import CustomInput from '../CustomInput/CustomInput'
import CustomAlert from '../CustomAlert/CustomAlert'

const ResetPassword = (props) => {
    const { getCaption } = useContext(Captions)
    const [response, setResponse] = useState({ loading: true, data: undefined })
    const [count, setCount] = useState(1)
    const [currentPage, setCurrentPage] = useState({ resetOption: "Email", name: "Email" })
    const [token, setToken] = useState("")
    const [form, setForm] = useState({})
    const [error, setError] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        GetPasswordResetInfo()
            .then(res => {
                setResponse({ loading: false, data: res.data });
                for (let i = 0; i < res.data.passwordResetFields.length; i++) {
                    if (res.data.passwordResetFields[i].fieldName == 'otp.code') {
                        res.data.passwordResetFields[i]['otp'] = res.data.passwordResetFields[i].fieldName.split('.')[0] + res.data.passwordResetFields[i].orderNumber;
                    }
                }
            })
        if (props.username) setForm({ ...form, ['client.username']: props.username })
    }, [])

    const updateForm = name => (e) => {
        setForm({
            ...form,
            [name]: e.target.value
        })
        setError(false)
    }

    const nextPage = () => {
        setError(false)
        setCount(count + 1)
    }

    const previousPage = () => {
        setCount(count - 1)
    }
    
    const renderInputFields = () => {
        if (!response.loading) return response.data.passwordResetFields
            .filter(data => data.fieldPageName == currentPage.name)
            .sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0)).map((item, index) => {
                if (!StaticInputFields[item.fieldName]) return false;
                if (item.fieldName === 'contacts.phone.number') {
                    return <ContactsPhoneNumber key={index}
                        formData={form}
                        errorCode={error}
                        setData={(option, value) => setForm({ ...form, [option]: value })}
                        label={StaticInputFields[item.fieldName] && getCaption(StaticInputFields[item.fieldName].placeholder)}
                    />
                } else {
                    return (
                        <React.Fragment key={index}>
                            <CustomInput type={StaticInputFields[item.fieldName] && StaticInputFields[item.fieldName].fieldType}
                                name={StaticInputFields[item.fieldName] && StaticInputFields[item.fieldName].fieldName}
                                value={(item.fieldName == 'otp.code' ? form[item.otp] : form[item.fieldName]) || ""}
                                onChangeHandler={item.fieldName == 'otp.code' ? updateForm(item.otp) : updateForm(item.fieldName)}
                                className={"labelAnimate"}
                                errorCode={error}
                                label={StaticInputFields[item.fieldName] && getCaption(StaticInputFields[item.fieldName].placeholder)}
                                placeholder={StaticInputFields[item.fieldName] && getCaption(StaticInputFields[item.fieldName].placeholder)}
                                autoComplete="off"
                                onKeyUpHandler={count == 2 ? moveCursor : null}
                                maxLength={count == 2 ? "1" : undefined}
                                index={index}
                                ref={index == 0 ? ref : null}
                                autoFocus={index == 0 ? true : false}
                            />
                        </React.Fragment>
                    )
                }
            })
    }
    //Move Cursor 
    const moveCursor = (e) => {
        let from = e.target.value.length;
        let maxLength = e.target.getAttribute("maxLength")
        if (from == maxLength) {
            e.target.parentElement.nextElementSibling && e.target.parentElement.nextElementSibling.firstChild.focus()
        }
    };
    // With ReCaptcha submit
    const onVerifyCaptcha = (captcha) => {
        let fieldsData = fields();
        fieldsData["verification.captcha"] = Object.assign({
            "fieldName": "verification.captcha",
            "fieldValue": captcha
        })
        fieldsData["one.time.token"] = Object.assign({
            "fieldName": "one.time.token",
            "fieldValue": token
        })
        return fieldsData
    };

    const fields = () => {
        let fieldsData = {}
        for (let i = 0; i < Object.keys(form).length; i++) {
            fieldsData[Object.keys(form)[i]] = Object.assign({
                "fieldName": Object.keys(form)[i],
                "fieldValue": Object.values(form)[i]
            })
            if (currentPage.resetOption == "SMS") {
                fieldsData["contacts.phone.number"] = Object.assign({
                    "fieldName": "contacts.phone.number",
                    "fieldValue": form["contacts.phone.number"]
                })
            }
            if (currentPage.name == "OtpCodeVerification" || currentPage.name == "ChangePassword") {
                fieldsData["otp.code"] = Object.assign({
                    "fieldName": "otp.code",
                    "fieldValue": form['otp1'] + form['otp2'] + form['otp3'] + form['otp4']
                })
                fieldsData = (({ otp1, otp2, otp3, otp4, ...others }) => others)(fieldsData)
            }
        }
        return fieldsData
    }

    return (props.popup) ? (
        <form className={(props.isRedirect || (!isMobile && window.scrollY > 80)) ? style.popup : style.reset}>
            <div className={style.formGroup}>
                {count == 1 ? (
                    <div id={(props.isRedirect || !props.isAuth) ? style.form : style.fixed}>
                        <h3 className={style.title}>{getCaption('reset.password.first.page.title')}</h3>
                        <div className={style.buttons}>
                            <button id={currentPage.resetOption === "Email" ? style.active : style.passive}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentPage({ ...currentPage, resetOption: "Email", name: "Email" });
                                    ref.current && ref.current.focus();
                                    setError(false)
                                }}
                            >
                                {getCaption('reset.password.email')}
                            </button>
                            <button id={currentPage.resetOption === "SMS" ? style.active : style.passive}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentPage({ ...currentPage, resetOption: "SMS", name: "SMS" });
                                    ref.current && ref.current.focus();
                                    setError(false)
                                }}
                            >
                                {getCaption('reset.password.sms')}
                            </button>
                        </div>
                        <div className={currentPage.resetOption === "Email" ? style.email : style.phone}>
                            <div className={currentPage.resetOption === "Email" ? style.emailWrapper : style.phoneWrapper}>
                                {renderInputFields()}
                            </div>
                        </div>
                        <ReCaptcha
                            onVerifyCaptcha={(captcha) => {
                                PostValidateRequest({
                                    "resetMethodName": currentPage.resetOption, "passwordResetInputs": onVerifyCaptcha(captcha)
                                }).then((res) => {
                                    setToken(res.data);
                                    nextPage();
                                    setCurrentPage({ ...currentPage, name: 'OtpCodeVerification' })
                                }).catch((error) => setError(error.isAxiosError))
                            }}>
                            <button className={style.send}>{getCaption('reset.password.send')}</button>
                        </ReCaptcha>
                    </div>
                ) : ""}
                {count == 2 ? (
                    <div id={props.isRedirect ? style.form : style.fixed}>
                        <div className={style.header}>
                            <h3 className={style.title}>{getCaption('reset.password.second.page.title')}</h3>
                            {form["contacts.email"] && <div className={style.infoIcon}>
                                <div className={style.hintInfo}>{getCaption(`client.verification.hint.description`)}</div>
                            </div>}
                        </div>
                        <span id={style.message}>{getCaption('reset.password.sent.code.message') + ' ' + (form["contacts.email"] || form["contacts.phone.number"])}</span>
                        <div className={style.codeWrapper}>
                            {renderInputFields()}
                        </div>
                        <Timer setError={setError} setForm={setForm} form={form} requestNewCode={(captcha) =>
                            PostValidateRequest({
                                "resetMethodName": currentPage.resetOption, "passwordResetInputs": onVerifyCaptcha(captcha)
                            }).then((res) => {
                                setToken(res.data);
                            }).catch((error) => setError(error.isAxiosError))
                        } />
                        <div className={style.navigate}>
                            <button id={style.back} onClick={() => { previousPage(); setCurrentPage({ ...currentPage, name: currentPage.resetOption }) }}>{getCaption('reset.password.go.back')}</button>
                            <ReCaptcha
                                onVerifyCaptcha={(captcha) => {
                                    PostVerifyOtp({
                                        "resetMethodName": currentPage.resetOption, "passwordResetInputs": onVerifyCaptcha(captcha)
                                    }).then(() => {
                                        nextPage();
                                        setCurrentPage({ ...currentPage, name: 'ChangePassword' })
                                    }).catch((error) => setError(error.isAxiosError))
                                }}>
                                <button id={style.continue}>{getCaption('reset.password.continue')}</button>
                            </ReCaptcha>
                        </div>
                    </div>
                ) : ""}
                {(count == 3 || count == 4) ? (
                    <div id={props.isRedirect ? style.form : style.fixed}>
                        <h3 className={style.title}>{getCaption('reset.password.third.page.title')}</h3>
                        <div className={style.wrapper}>
                            {renderInputFields()}
                            <ReCaptcha
                                onVerifyCaptcha={(captcha) => {
                                    PostChangePassword({
                                        "resetMethodName": currentPage.resetOption, "passwordResetInputs": onVerifyCaptcha(captcha)
                                    }).then(() => {
                                        nextPage();
                                    }).catch((error) => setError(error.isAxiosError))
                                }}>
                                <button className={style.send}>{getCaption('reset.password.save')}</button>
                            </ReCaptcha>
                        </div>
                    </div>
                ) : ""}
                {count === 4 && !error && <CustomAlert type="success" message={getCaption('reset.password.successfuly.changed')} />}
                {error == true ? <span className={style.errorMessage}>{getCaption('reset.password.error')}</span> : ""}
                <CloseButton clickHandler={props.clickResult} />
            </div>
        </form>
    ) : ""
}

export default ResetPassword;