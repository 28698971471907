import React, { useState, useContext, useEffect } from 'react';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import CustomSelect from '../../../../Components/CustomSelect/Select';
import DateOfBirth from '../../../../Components/DateOfBirth/DateOfBirth';
import { Captions } from '../../../../Services/Hooks/captions';

import style from './PersonalInformation.module.css';

function PersonalInformation({ fieldInfo, groupDate, handlerCallBack, submitForm, page, errors, clientDetailsForm }) {
    const [clearFields, setClearFields] = useState(false);
    const [buttonSuccess, setButtonSuccess] = useState(false);
    const [ error, setError ] = useState(null);
    const [form, setForm] = useState({
        [fieldInfo.fieldName]: fieldInfo.fieldValue
    });
    const { getCaption } = useContext(Captions);

    useEffect(() => {
        if(errors.length > 0) {
            for(let i = 0; i < errors.length; i++) {
                if(errors[i].FieldName === fieldInfo.fieldName) {
                    setError({...error, [fieldInfo.fieldName]: errors[i].ValidationError})
                }
            }
        }
    }, [errors])

    const selectedOption = (fieldName, option, selectClassName) => {
        if(error) setError(null);
        setForm({...form, [fieldName]: option});
        handlerCallBack(fieldName, option, selectClassName);
    }

    const handleChange = (input, pageName) => e => {
        if(!/^[a-zA-Z]*$/.test(e.target.value)) return;
        handlerCallBack(input, e.target.value || e.target.checked, pageName);
        setForm({...form, [input]: e.target.value});
    }

    const updateButton = () => {
        setButtonSuccess(true);
        setTimeout(() => {
            setButtonSuccess(false);
        }, 2000);
    }

    return (
        <>
            {fieldInfo.fieldName === 'client.date.of.birth' ? (
                <div className={style[fieldInfo.fieldName.replaceAll(".", "_")]}>
                    {/*<p>{getCaption(fieldInfo.placeholder)}</p>*/}
                    <div className={style.dateSelect}>
                        <DateOfBirth
                            // className="dateOfBirth"
                            errorCode={error && error[fieldInfo.fieldName]}
                            // setErrors={setError}
                            formData={form}
                            setData={(data) => {selectedOption('client.date.of.birth', data, fieldInfo.fieldPageName)}}
                        />
                        <button
                            className={style.submitHandler}
                            onClick={(e) => submitForm(e, page, updateButton, null)}
                        >{buttonSuccess ? (
                            <>
                                <span/>{getCaption('button.saved')}
                            </>
                            ) : (
                                <>
                                    {getCaption('button.save')}
                                </>
                            )}
                        </button>
                    </div>
                </div>
            ) : (
            <>
                <div className={style[fieldInfo.fieldName.replaceAll(".", "_")]}>
                    <div className={!fieldInfo.editable ? `${style.inputGroup + ' ' + style.disabled}` : style.inputGroup}>
                        <CustomInput
                            errorCode={error && error[fieldInfo.fieldName]}
                            className={"autoWidth"}
                            name={fieldInfo.fieldName.replaceAll(".", "_")}
                            type={fieldInfo.fieldType}
                            value={form[fieldInfo.fieldName]}
                            disabled={!fieldInfo.editable}
                            label={getCaption(fieldInfo.placeholder)}
                            placeholder={getCaption(fieldInfo.placeholder)}
                            onChangeHandler={handleChange(fieldInfo.fieldName, fieldInfo.fieldPageName)}
                            autoComplete="off"
                        />
                        {/*<input*/}
                        {/*    name={fieldInfo.fieldName.replaceAll(".", "_")}*/}
                        {/*    type={fieldInfo.fieldType}*/}
                        {/*    value={form[fieldInfo.fieldName]}*/}
                        {/*    disabled={!fieldInfo.editable}*/}
                        {/*    onChange={handleChange(fieldInfo.fieldName, fieldInfo.fieldPageName)}*/}
                        {/*/>*/}
                    </div>
                </div>
            </>
            )}
        </>
    )
}

export default PersonalInformation
