export const StaticInputFields = {
    'client.username': {
        label: 'web.fields.placeholder.username',
        className: 'UserName',
        fieldType: 'text',
        fieldName: 'username',
        placeholder: 'web.fields.placeholder.username',
        isSelect: false,
        allowHint: true,
        step: 2,
        fieldSucces: true
    },
    'client.password': {
        label: 'web.fields.placeholder.password',
        className: 'Password',
        fieldType: 'password',
        fieldName: 'password',
        placeholder: 'web.fields.placeholder.password',
        allowHint: true,
        isSelect: false,
        step: 2,
        required: true,
        fieldSucces: true
    },
    'client.confirm.password': {
        label: 'web.fields.placeholder.confirm.password',
        className: 'ConfirmPassword',
        fieldType: 'password',
        fieldName: 'password1',
        placeholder: 'web.fields.placeholder.confirm.password',
        isSelect: false,
        step: 2,
        fieldSucces: true
    },
    'client.date.of.birth': {
        label: 'web.fields.placeholder.dateofbirth',
        className: 'DateOfBirth',
        fieldType: 'date',
        fieldName: 'date',
        placeholder: 'web.fields.placeholder.dateofbirth',
        errorPopup: true,
        step: 1,
        isSelect: false
    },
    'contacts.phone.number': {
        label: 'web.fields.placeholder.phone',
        className: 'PhoneNumber',
        fieldType: 'text',
        fieldName: 'phone-number',
        placeholder: 'web.fields.placeholder.phone',
        step: 1,
        isSelect: false
    },
    'contacts.email': {
        label: 'web.fields.placeholder.email',
        className: 'Email',
        fieldType: 'text',
        fieldName: 'mail',
        placeholder: 'web.fields.placeholder.email',
        step: 1,
        isSelect: false
    },
    'client.post.code': {
        label: '',
        className: 'PostCode',
        fieldType: 'text',
        fieldName: 'post-code',
        placeholder: 'web.fields.placeholder.post.code',
        step: 2,
        isSelect: false
    },
    'client.first.name': {
        label: 'web.fields.placeholder.first.name',
        className: 'FirstName',
        fieldType: 'text',
        fieldName: 'first-name',
        placeholder: 'web.fields.placeholder.first.name',
        step: 1,
        isSelect: false
    },
    'client.personal.number': {
        label: '',
        className: 'PersonalNumber',
        fieldType: 'number',
        fieldName: 'personal-number',
        placeholder: 'web.fields.placeholder.personal.number',
        step: 2,
        isSelect: false
    }, 
    'client.title': {
        label: '',
        className: 'Title',
        fieldType: 'text',
        fieldName: 'title',
        placeholder: 'web.fields.placeholder.title',
        step: 2,
        isSelect: false
    },
    'contacts.mobile.code': {
        label: 'web.fields.placeholder.mobile.code',
        className: 'MobileCode',
        fieldType: 'number',
        fieldName: 'mobile-code',
        placeholder: 'web.fields.placeholder.mobile.code',
        step: 1,
        isSelect: true
    },
    'client.last.name': {
        label: 'web.fields.placeholder.last.name',
        className: 'LastName',
        fieldType: 'text',
        fieldName: 'last-name',
        placeholder: 'web.fields.placeholder.last.name',
        step: 1,
        isSelect: false
    },
    'terms.and.conditions': {
        label: "",
        className: 'Checkbox',
        fieldType: 'checkbox',
        fieldName: 'last-name',
        placeholder: 'web.fields.placeholder.terms.and.conditions',
        step: 2,
        isSelect: false
    },
    'client.currency': {
        label: 'web.fields.placeholder.currency',
        className: 'Currency',
        fieldType: 'text',
        fieldName: 'last-name',
        placeholder: 'web.fields.placeholder.currency',
        allowHint: true,
        step: 2,
        isSelect: true
    }
}

export const validateField = (fieldName, value, isNotBlur) => {
    if(fieldName === 'contacts.email') {
        if(value.length === 0) {
            // return { isValid: false, errorMessage: 'web.mandatory.field.is.empty' }
        } else if(!/^[a-zA-Z0-9.'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
            return { isValid: false, errorMessage: 'web.email.address.is.invalid' }
        }
    } else if(fieldName === 'client.username') {
        if(value.length === 0 && isNotBlur) {
            return { isValid: false, errorMessage: 'web.mandatory.field.is.empty' }
        } else if(value.length < 5 || value.length > 16) {
            return { isValid: false, errorMessage: 'web.fields.characters.length.error' }
        } else if(!/^[a-zA-Z0-9_\.]+$/.test(value)) {
            return { isValid: false, errorMessage: 'web.fields.format.error' }
        }
    }
    return { isValid: true, errorMessage: '' }
}