import React, { useContext, useEffect, useState } from 'react'
import Languages from '../Languages/Languages'
import style from './FooterMenu.module.css'
import MenuItem from './MenuItem/MenuItem'
import { Captions } from '../../../../../Services/Hooks/captions'
import Conversation from '../../../../../Components/Conversation/Conversation'
const FooterMenu = (props) => {
    const { getCaption } = useContext(Captions);
    const [closeConversation, setCloseConversation] = useState(false);
    const [chat, setChat] = useState(false);
    return (
        <div className={style.menu}>
            <div className={style.menuWrapper}>
                <div className={style.socialMedia}>
                    <div className={style.supportDiv}>
                        {
                            props.supportInfo.map((supportInfo, index) =>
                                <span key={index} className={style.supportMailName}>{getCaption(supportInfo.captionKey)}</span>
                            )}
                    </div>
                    <div className={style.socialMediaRight}>
                        {
                            props.socialNetworkBanner.map((banners, index) =>
                                <a href={banners.redirectUrl} key={index} target={banners.redirectUrl.includes('www') ? "_blank" : "_self"} className={style.anchor}>
                                    <img src={banners.source} alt={banners.bannerCategory} className={style.socialMediaIcon} key={banners.bannerId} />
                                </a>
                            )}
                        <div className={style.chatIcon}>
                            <Conversation
                                isMobile={true}
                                buttonType={'transparentMobile'}
                                closeConversation={closeConversation}
                                createConversationHandler={(type) => {
                                    setChat(type);
                                    setCloseConversation(false)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <Languages />
                {props.data.sort((a, b) => a.orderNumber - b.orderNumber).map(item => <MenuItem title={item.captionKey} key={item.menuItemId} targetUrl={item.targetUrl}
                    subMenu={item.subMenuItems != null ? item.subMenuItems : []} />)}

            </div>
        </div>
    )
}

export default FooterMenu;