import React, {useContext} from 'react'
import style from './LogInHistory.module.css'
import { AuthenticationLog } from '../../../Services/service'
import { format } from 'date-fns'
import Tippy from '@tippyjs/react';
import Table from '../../../Components/Table/Table';
import { Captions } from '../../../Services/Hooks/captions';
const LogInHistory = (props) => {

    let { loading, data } = AuthenticationLog()
    const { getCaption } = useContext(Captions)

    const columnKeys = !loading && Object.keys(data[0])
    const COLUMNS = [
        {
            Header: "IP",
            accessor: !loading && columnKeys[0],
            Cell: ({ value }) => {
                return (
                    <>
                        <div className={style.key}>ip:</div>
                        <div className={style.value}>{value}</div>
                    </>
                )
            },
            hideHeader: false
        },
        {
            Header: "DATE",
            accessor: !loading && columnKeys[1],
            Cell: ({ value }) => {
                return (
                    <>
                        <div className={style.key}>date:</div>
                        <div className={style.value}>{format(new Date(value.split("T")[0]), 'MM/dd/yyyy')}</div>
                    </>
                )
            },
            hideHeader: false
        },
        {
            Header: "TIME",
            accessor: !loading && columnKeys[1],
            id: "onlyTime",
            Cell: ({ value }) => {
                return (
                    <>
                        <div className={style.key}>time:</div>
                        <div className={style.value}>{new Date(value).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}</div>
                    </>
                )
            },
            hideHeader: false
        },
        {
            Header: "DEVICE",
            accessor: !loading && columnKeys[2],
            Cell: ({ value }) => {
                return (
                    <Tippy className={style.tooltip} content={<p className={style.text}>{value.os}, {value.userAgent}</p>} placement='right'>
                        <var className={value.isMobile ? `${style.device} ${style.mobile}` : `${style.device} ${style.pc}`}></var>
                    </Tippy>
                )
            },
            hideHeader: false
        },

    ]
    

    return (
        <div className={style.wrapper}>
            <h4 className={style.title}>{getCaption('mobile.log.in.history.title')}</h4>
            {!loading && <Table columns={COLUMNS} data={data} />}
        </div>
    )
}

export default LogInHistory;
