import React, {useState, useEffect, useContext} from 'react';
import { Captions } from '../../../../../../../Services/Hooks/captions';
import { GetGamesAllCategory } from '../../../../../../../Services/service';
import MobileDropDownMenu from "../../../../../../../Components/MobileDropDownMenu/MobileDropDownMenu";

import style from './Menu.module.css'

function Menu({ selectMenuItem }) {
    const { loading, data } = GetGamesAllCategory();
    let [index, setIndex] = useState(1)
    let [active, setActive] = useState({ [index]: index === 1 });
    let [selected, setSelected] = useState('');
    let [dropDownShown, setDropDownShown] = useState(false);

    const categoryStatus = (index, captionKey) => (e) => {
        setIndex(e.target.getAttribute('index'))
        setActive({
            [index]: !active[index]
        })
        setSelected(captionKey);
        handleMenuItem(index, captionKey.split('.')[captionKey.split('.').length - 1])
    }

    useEffect(() => {
        if(!loading) {
            setSelected(data[0].captionKey);
            selectMenuItem(data[0].captionKey.split('.')[data[0].captionKey.split('.').length - 1].toLowerCase())
        }
    }, [loading])
    
    const handleMenuItem = (index, categoryName) => {
        selectMenuItem(categoryName);
    }

    const handleDropdown = () => {
        setDropDownShown(!dropDownShown);
    }
    
    return (
        <nav className={style.menuContainer}>
            {
                !loading &&
                    <MobileDropDownMenu
                        active={active}
                        selected={selected}
                        dropDownShown={dropDownShown}
                        handleDropdown={handleDropdown}
                        selectCategory={setSelected}
                        categoryStatus={categoryStatus}
                        setDropDownShown={setDropDownShown}
                        menuItems={data}
                    />
            }
        </nav>
    )
}

export default Menu
