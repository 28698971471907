import React, { useContext, useState, useEffect } from 'react'
import VerticalMenu from '../VerticalMenu/VerticalMenu'
import { Captions } from '../../../../../../Services/Hooks/captions'
import style from './ResponsibleGaming.module.css'
import { GetAccountBlockAllowedHours, BlockClientAccount, PostRefreshToken } from '../../../../../../Services/service'
import { Auth } from '../../../../../../Services/Hooks/auth'
import { GetItemFromLocalStorage } from '../../../../../../Services/common'
import { Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import ReactHtmlParser from 'react-html-parser';
const ResponsibleGaming = () => {
    const { getCaption } = useContext(Captions);
    const { logOut, auth } = useContext(Auth);
    const [block, setBlock] = useState({ period: ""})
    const handleChange = name => event => {
        setBlock({
            ...block,
            [name]: event.target.value
        })
    }
    const { loading, data } = GetAccountBlockAllowedHours()
    return (
        <div className={style.container}>
            <div className={style.aside}>
                <VerticalMenu />
            </div>
            <div className={style.main}>
                <div className={style.section}>
                    <h2 className={style.title}>{getCaption('web.responsible.gaming.title.responsible.gaming')}</h2>
                    <h3 className={style.subtitle}>
                        {getCaption('web.responsible.gaming.description.responsible')}
                    </h3>
                    {/* <ul className={style.list}>
                        <li>{getCaption('web.responsible.gaming.description.responsible.item1')}</li>
                        <li>{getCaption('web.responsible.gaming.description.responsible.item2')}</li>
                        <li>{getCaption('web.responsible.gaming.description.responsible.item3')}</li>
                    </ul> */}
                    <p>
                    {ReactHtmlParser(getCaption('web.responsible.gaming.description.responsible.item'))}
                    </p>
                  
                </div>
                <div className={style.generalList}>
                    <h2 className={style.title}>{getCaption('web.responsible.gaming.title.general')}</h2>
                    {/* <p className={style.text}> */}
                        {ReactHtmlParser(getCaption('web.responsible.gaming.description.general.item1'))}
                    {/* </p> */}
                </div>
                {auth && <div className={style.section}>
                    <h2 className={style.title}>{getCaption('web.responsible.gaming.temrorary.block.title')}</h2>
                    <div className={style.radioButtons}>
                        {!loading && Object.values(data)[0].split(',').map((time, index) => <React.Fragment key={index}>
                            <input type="radio" name={time} value={time} checked={block.period == time && true} onChange={handleChange('period')} />
                            <label className={style.label} htmlFor={time}>{time}{' '}hrs</label>
                        </React.Fragment>)}
                        <button className={style.block} onClick={() => {BlockClientAccount(block.period); logOut()}}>
                            {getCaption('web.responsible.gaming.temrorary.block.button')}
                        </button>
                    </div>
                </div>}
                <div className={style.section}>
                    <h2 className={style.title}>{getCaption('web.responsible.gaming.title.external.help')}</h2>
                    <p className={style.text}>{getCaption('web.responsible.gaming.description.external')}</p>
                    <ul className={style.links}>
                        <li><a href="#">{getCaption('web.responsible.gaming.description.external.link1')}</a></li>
                        <li><a href="#">{getCaption('web.responsible.gaming.description.external.link2')}</a></li>
                        <li><a href="#">{getCaption('web.responsible.gaming.description.external.link3')}</a></li>
                        <li><a href="#">{getCaption('web.responsible.gaming.description.external.link4')}</a></li>
                        <li><a href="#">{getCaption('web.responsible.gaming.description.external.link5')}</a></li>
                    </ul>
                </div>
                <div className={style.section}>
                    <h2 className={style.title}>{getCaption('web.responsible.gaming.title.have.a.question')}</h2>
                    <p className={style.text}>{getCaption('web.responsible.gaming.description.have.a.question')}
                        <a href="mailto:support@casino.com">support@casino.com</a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default ResponsibleGaming;