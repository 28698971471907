import React, { useContext, useState } from "react";
import style from './Languages.module.css'
import { Captions } from "../../../../../Services/Hooks/captions";
import MobileMenuFromBottom from "../../../../../Components/MobileMenuFromBottom/MobileMenuFromBottom";

const Languages = (props) => {
    const { setLanguage, currentLanguage, languages } = useContext(Captions);

    let [lang, setLang] = useState(currentLanguage);
    const { getCaption } = useContext(Captions);
    let [dropdownShown, setDropdownShown] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [hideListMenu, setHideListMenu] = useState(false);
    const [hideListMenuAfterDelay, setHideListMenuAfterDelay] = useState(true);
    const onChange = (value) => {
        setLanguage(value);
        setDropdownShown(false);
        setLang(value);

    }

    const handleDropdown = () => {
        setDropdownShown(!dropdownShown);
        setHideListMenu(true)
    }

    const closeModal = () => {
        setHideListMenuAfterDelay(false);
        setTimeout(() => setHideListMenu(false), 195)
    }
    const languageOptions = languages
        .filter(language => language.language !== lang)
        .map((language, index) => <li className={style.select} key={index} onClick={(e) => onChange(language.language)}>
            <img src={language.flagUrl} alt={language.langeage} className={style.flag} />
            <span className={language.language}>{language.languageFullName}</span>
        </li>);

    return (
        <div className={`${dropdownShown ? style.languageContainerFull : style.languageContainer}`} onClick={handleDropdown} >
            {
                languages.length > 0 &&
                <div className={`${style.select + style[lang]}`} id={dropdownShown ? style.upStyle : (!dropdownShown && languages.length < 3 ) ? style.popupArrow : style.down}>
                    {lang &&
                        <>
                            <img src={languages.filter(language => language.language === currentLanguage)[0].flagUrl} alt={languages.filter(language => language.language === lang)[0].language} className={style.flag} />
                            <span>{languages.filter(language => language.language === currentLanguage)[0].languageFullName}</span>
                        </>
                    }
                </div>
            }
            {
                hideListMenu && languages.length > 3 ?
                    <MobileMenuFromBottom
                        title={getCaption('web.pop.up.title.language')}
                        hideListMenu={hideListMenu}
                        closeModal={() => closeModal()}
                        hideListMenuAfterDelay={hideListMenuAfterDelay}
                        setHideListMenu={() => setHideListMenu(false)}
                        menuItemList={languages.map((item, index) =>
                            <p>
                                {
                                    <li className={style.popUpStyle} key={index} onClick={(e) => onChange(item.language)}>
                                        <img src={item.flagUrl} alt={item.langeage} className={style.flag} />
                                        <span className={style.title}>{item.languageFullName}</span>
                                    </li>
                                }
                            </p>
                        )}
                    />

                    :
                    (dropdownShown && <div className={style.wrapper}>{languageOptions}</div>)
            }
        </div>
    )
}

export default Languages;