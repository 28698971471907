import React, {useState} from 'react';
import Modal from "react-modal";
import Login from './Login/Login';

import style from './ClientAuthentication.module.css';

const ClientAuthentication = ({ loginModalShow, clickHandler, closeModal }) => {
    const [registerModalShow, setRegisterModalShow] = useState(false);

    const toggleRegisterPopup = (type) => {
        setRegisterModalShow(type);
    }
    
    const toggleLoginPopup = () => {
        closeModal();
    }

    const redirectToGame = () => {
        closeModal();
        clickHandler();
    }
    
    return (
        <>
            <Modal
                isOpen={loginModalShow}
                className={`${style.modal} ${registerModalShow ? style.registerModalShow : ''}`}
                overlayClassName={style.overlay}
                onRequestClose={() => toggleLoginPopup()}
            >
                <Login 
                    redirectUrl={false} 
                    isPopupMode={false}
                    isRedirectToGame={true} 
                    redirectToGame={redirectToGame} 
                    closeLoginPopup={toggleLoginPopup} 
                    toggleRegisterPopup={toggleRegisterPopup} 
                />
            </Modal>
        </>
    )
}

export default ClientAuthentication;
