import React from 'react'
import style from './SearchedGames.module.css'
import GameAuthenticatedRedirect from '../GameAuthenticatedRedirect/GameAuthenticatedRedirect'
import { GetItemFromLocalStorage } from '../../Services/common';
const SearchedGames = () => {
    let data = GetItemFromLocalStorage('searchedGames')
    return (
        <div className={style.wrapper}>
            {Array.isArray(data) && data.map(game =>
                <div className={style.game} key={game.webPageItemId}>
                    <GameAuthenticatedRedirect src={game.bannerName} url={game.redirectUrl} channelName={game.channelName}
                        productName={game.productName} gameName={game.captionKey} />
                </div>)}
        </div>
    )
}

export default SearchedGames;