import React, { useContext, useEffect } from 'react';
import { Captions } from '../../../Services/Hooks/captions';
import { Auth } from '../../../Services/Hooks/auth';
import CustomAlert from '../../CustomAlert/CustomAlert';
import style from './SuccessVerification.module.css';

function SuccessVerification({ type, closeModal, updateClient }) {
    const { getCaption } = useContext(Captions);
    const { updateClientInfo } = useContext(Auth);
    const successMessage = {
        'contacts.email': 'email',
        'contacts.phone.number': 'phone'
    }
    useEffect(() => {
        setTimeout(() => {
            closeModal();
            updateClientInfo(true)
        }, 3000)
    }, [])
    return (
        <div className={style.content}>
            <CustomAlert message={getCaption(`${successMessage[type]}.verification.success.msg`)} type="success" />
        </div>
    )
}

export default SuccessVerification;
