import React from 'react'
import CloseButton from '../../../../Components/CloseButton/CloseButton';
import CustomModal from '../../../../Components/CustomModal/CustomModal'
import style from './PaymentModal.module.css';

function PaymentModal({paymentIframeUrl, closeDepositModal, isMobile}) {
    return (
        <CustomModal
            isOpen={true}
            closeModal={closeDepositModal}
        >
            <div className={`${style.iframeParent} ${isMobile ? style.mobileView : ''}`}>
                <CloseButton clickHandler={closeDepositModal} />
                <iframe src={paymentIframeUrl} title="payment" />
            </div>
        </CustomModal>
    )
}

export default PaymentModal
