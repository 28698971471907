import React, {useState, useContext} from 'react'
import style from './Type.module.css'
import { useClickOutside } from '../../../../Services/Hooks/outsideClick'
import { Captions } from '../../../../Services/Hooks/captions'
const Type = (props) => {
    const { getCaption } = useContext(Captions);
    let [type, setType] = useState(props.types[0].type);
    let [dropdownShown, setDropdownShown] = useState(false);

    const onChange = (e) => {
        setType(e.target.className);
        setDropdownShown(false)
    }

    const handleDropdown = () => {
        setDropdownShown(!dropdownShown);
    }

    const typeOptions = props.types
        .filter(t => t.type !== type)
        .map((t, index) => <li className={style.select} key={index}><span className={t.type} onClick={onChange}>{t.type}</span></li>);

    let domNode = useClickOutside(() => {
        setDropdownShown(false)
    })

    return (
        <div className={style.typeContainer}>
        <label className={style.label}>{getCaption("web.transactions.label.type")}</label>
            <div className={style.background} ref={domNode}>
            <li className={`${style.select} ${style.selectedValue}`} 
            onClick={handleDropdown}
            >{type}</li>
            {
                dropdownShown && <div data={props.data} onClick={(e) => {
                    props.type(e.target.className); setDropdownShown(false)}} 
                className={style.typeDropdown}>{typeOptions}</div>
            }
            </div>
        </div>
    )
}

export default Type