import React from 'react'

import style from './Success.module.css'

const Success = (props) => {
    return (
        <div className={style.row}>
            <div className={style.icon} />
            <h3>{props.text1}</h3>
            <p>{props.text2}</p>
        </div>
    )
}

export default Success