import React, {useContext, useState} from 'react'
import { Auth } from '../../../Services/Hooks/auth'
import style from './SearchResult.module.css'
import {Link, useHistory} from 'react-router-dom'
import { Captions } from '../../../Services/Hooks/captions'
import ClientAuthentication from '../../../Pages/Desktop/ClientAuthentication/ClientAuthentication'
const SearchResult = (props) => {
    const { auth } = useContext(Auth);
    const [loginModalShow, setLoginModalShow] = useState(false);
    const { getCaption } = useContext(Captions);
    let history = useHistory()
    function clickHandler() {
        history.push(`/GameLauncher?channelName=${props.data[props.index].channelName}&productName=${props.data[props.index].productName}`)
    }
    return (
        <>
            <Link to={auth ? `/GameLauncher?channelName=${props.data[props.index].channelName}&productName=${props.data[props.index].productName}` : window.location.pathname} className={style.searchResultItem}>
                <div className={style.searchResultContainer} onClick={!auth ? () => setLoginModalShow(true) : props.clearSearchField}>
                    <div className={style.searchResultImage}>
                        <img src={props.imagePath} alt={props.name} />
                    </div>
                    <div className={style.searchResulContent}>
                        <p>{getCaption(props.name)}</p> <span className={style.provider}>{props.provider}</span>
                    </div>  
                </div>
            </Link>
            {loginModalShow && <ClientAuthentication loginModalShow={loginModalShow} clickHandler={clickHandler} closeModal={() => setLoginModalShow(false)} />}
        </>
    )
}

export default SearchResult