import React, {useState, useContext, useEffect} from "react";
import style from './MobileDropDownMenu.module.css'
import { Captions } from '../../Services/Hooks/captions';
import { useClickOutside } from '../../Services/Hooks/outsideClick';



const MobileDropDownMenu = ({ menuItems, selected, dropDownShown, handleDropdown, active, categoryStatus, setDropDownShown, setLoginModalShow }) => {
    const { getCaption } = useContext(Captions);
    const [items, setItems] = useState([
        { menuItemId: 1, captionKey: "web.game.filter.other.categoty.popular" },
        { menuItemId: 2, captionKey: "web.game.filter.other.categoty.new" },
        { menuItemId: 3, captionKey: "web.game.filter.other.categoty.favorites" },
        { menuItemId: 4, captionKey: "web.game.filter.other.categoty.recently.played" },
        { menuItemId: 5, captionKey: "web.game.filter.other.categoty.jackpot" },
        { menuItemId: 6, captionKey: "web.game.filter.other.categoty.recommended" },
        { menuItemId: 7, captionKey: "web.game.filter.other.categoty.exclusive" },
        { menuItemId: 8, captionKey: "web.game.filter.other.categoty.bonus" }

    ]);
    useEffect(() => {
        if(menuItems) setItems(menuItems);
    }, [menuItems])
    let domNode = useClickOutside(() => {
        setDropDownShown(false);
    })
    const turnUp = () => {
        setDropDownShown(false);
    }
    
    const categoryItems = items.filter((el) => getCaption(el.captionKey) !== selected).map((category, index) =>
        <div className={style.menuItem} key={index} onClick={categoryStatus(category.menuItemId, category.captionKey)} >
            <span className={`${style.itemTitle} ${style[getCaption(category.captionKey).replace(' ', '')]} ${style[category.captionKey.split('.')[category.captionKey.split('.').length - 1].toLowerCase()]}`}>
                {getCaption(category.captionKey)}
            </span>
        </div>
    )
    return (
        <>
            <div className={style.menuHeader} onClick={handleDropdown}>
                <h3 className={`${style.menuTitle} ${style[selected.replace(' ', '')]} ${style[selected.split('.')[selected.split('.').length - 1].toLowerCase()]}`} >{getCaption(selected)}</h3>
                <span className={ dropDownShown ? style.up : style.down} />
            </div>
            {
                dropDownShown && <ul className={style.menuContainer} onClick={turnUp} >{categoryItems}</ul>
            }
        </>
    )
}
export default MobileDropDownMenu;