import React, { useState, useEffect, useContext, Fragment } from 'react';
import { Captions } from '../../Services/Hooks/captions';
import { useClickOutside } from '../../Services/Hooks/outsideClick';

import style from './Select.module.css'

export default function CustomSelect({ data, fieldName, selectName, onSelect, clearFields, selectClassName, error, componentClassName, selectedField, allowInputField }) {
    let [localeData, setLocaleData] = useState(data);
    let [option, setOption] = useState(selectName);
    let [countryCodeFlag, setCountryCodeFlag] = useState(selectedField ? selectedField.attribute1 : null);
    let [options, setOptions] = useState(null);
    let [isShown, setIsShown] = useState(false);
    let [isChanged, setIsChanged] = useState(false);
    const [inputIsFocused, setInputIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { getCaption } = useContext(Captions);
    
    useEffect(() => {
        if(allowInputField) {
            setLocaleData([{type: 'input'}, ...localeData])
        }
    }, [allowInputField])

    useEffect(() => {
        if(allowInputField) {
            setInputValue('');
            filterDataByInputValue('');
        }
    }, [allowInputField, isShown])
    
    useEffect(() => {
        if(option !== selectName) setIsChanged(true);
        const selectOption = (e) => {
            setOption(e.target.innerText);
            setCountryCodeFlag(e.target.getAttribute('data-src'));
            setIsShown(false);
            setInputIsFocused(false);
            onSelect(fieldName, selectName, e.target.className.length > 0 ? e.target.className.toString() : fieldName, selectClassName);
        }
        
        if (clearFields) setOption(selectName);
        const Options = localeData
            .map((item, index) =>
                <Fragment key={index}>
                    { item.type === 'input' ? 
                        <input 
                            key={index}
                            className={style.input}
                            type="text"    
                            value={inputValue}
                            placeholder={'Search'}
                            onChange={(e) => onChangeHandler(e.target.value)} 
                        /> : 
                        <li className={style.list} key={index}>
                            
                                <span className={item.itemValue} onClick={selectOption}>
                                    {selectClassName === 'MobileCode' || selectClassName === 'Code' ?
                                        <div className={item.itemValue} data-src={item.attribute1}>
                                            <img className={style.countryCodeFlag} src={item.attribute1} alt={item.itemValue} />+{item.itemValue}
                                        </div> :
                                        item.itemValue
                                    }
                                </span>
                        </li>
                    }
                </Fragment>);
        setOptions(Options);
    }, [option, localeData, onSelect, inputValue, fieldName, selectName, selectClassName, clearFields, componentClassName, selectedField])

    const handleDropdown = () => {
        setIsShown(!isShown);
    }

    let domNode = useClickOutside(() => {
        setIsShown(false);
    }, true)

    const onChangeHandler = (value) => {
        if(/^\d+$/.test(value) || value.length === 0) {
            setInputValue(value);
            filterDataByInputValue(value);
        }
    }

    const filterDataByInputValue = (value) => {
        const filteredoptions = data.filter(item => value === item.itemValue.slice(0, value.length));
        filteredoptions.unshift({ type: 'input' });
        setLocaleData(filteredoptions.length === 0 ? localeData : filteredoptions);
    }
    
    return (
        <div  
            className={`${style[selectClassName] ? style.select + ' ' + style[selectClassName] + ' ' + (style[componentClassName + 'Parent'] || '') : style.select + ' ' + (style[componentClassName + 'Parent'] || '')} ${inputIsFocused ? style.inputFieldMode : ''}`} ref={domNode}>
            <li 
                className={`${!isChanged && !selectedField ? style.changed : ''} ${error ? style.errorBorder + ' ' + style.list + ' ' + style.selectList + ' ' + (style[componentClassName] || '') : style.list + ' ' + style.selectList + ' ' + (style[componentClassName] || '')}`} 
                onClick={handleDropdown}
            >
                {selectClassName === 'MobileCode' || selectClassName === 'Code'
                    ? <div>
                        {countryCodeFlag && <img className={style.countryCodeFlag} src={countryCodeFlag} alt={option} />}
                        {getCaption(option)}

                    </div>
                    : getCaption(option)
                }
            </li>
            {isShown && <ul className={style.listContainer}><div>{options}</div></ul>}
        </div>
    )
}

CustomSelect.defaultProps = {
    data: []
};