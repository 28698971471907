import React, {useState, useEffect, useContext} from "react"
import Header from "./Header/Header"
import NavMenu from "./NavMenu/NavMenu"
import {GetItemFromLocalStorage} from "../../../../Services/common"
import { Auth } from '../../../../Services/Hooks/auth'
const HeaderContainer = () => {
    const [showHeader, setShowHeader] = useState(true)

    const controlHeader = () => {
        // document.body.offsetHeight
        // window.innerHeight
        if(window.scrollY > 84) {
            setShowHeader(false)
        }else{
            setShowHeader(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', controlHeader)
        return () => window.removeEventListener('scroll', controlHeader)
    }, [])
    
    const { auth, authenticate, clientInfo, logOut, refreshToken } = useContext(Auth);
    useEffect(() => {
        if(auth) {
            const checkExpiredToken = () => {
                let expiredTime = GetItemFromLocalStorage('expiredToken');
                let dateToEpoch = new Date(0);
                dateToEpoch.setUTCSeconds(expiredTime);
                let expireTime = (dateToEpoch - new Date()) / 1000;
                if(expireTime < 60) refreshToken();
            }

            const checkTokenExpired = setInterval(() => {
                checkExpiredToken();
            }, 1000);

            return () => clearInterval(checkTokenExpired);
        }
    }, [auth, logOut, authenticate, clientInfo, refreshToken]);
    
    return (
        <>
            <Header hide={!showHeader} clientInfo={clientInfo} logOutUser={logOut}/>
            <NavMenu pinToTop={!showHeader}  clientInfo={clientInfo} logOutUser={logOut}/>
        </>
    )
}
export default HeaderContainer