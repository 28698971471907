import React, { useState, useEffect, useContext } from 'react'
import { GetSliderItems } from '../../../Services/service'
import { Captions } from '../../../Services/Hooks/captions'
import Slider from '../../../Components/Slider/Slider'
import BannerItem from './BannerItem/BannerItem'

import style from './PageBanners.module.css'

const PageBanners = (props) => {
    const { getLanguage } = useContext(Captions);
    const { loading, sliderItems } = GetSliderItems(props.pathName, getLanguage());

    const [gridBanners, setGridBanners] = useState([]);
    const [sliderBanners, setSliderBanners] = useState([]);
    const [leftRightBanners, setLeftRightBanners] = useState([]);

    useEffect(() => {
        if(!loading) {
            const _sliderBanners = [];
            const _leftRightBanners = [];
            const _gridBanners = [];
            sliderItems.forEach(element => {
                if(element.position === 'Grid') {
                    _gridBanners.push(element)
                } else if(element.position === 'Left' || element.position === 'Right') {
                    _leftRightBanners.push(element)
                } else {
                    _sliderBanners.push(element)
                }
            });
            setGridBanners(_gridBanners);
            setSliderBanners(_sliderBanners);
            setLeftRightBanners(_leftRightBanners);
        }
    }, [loading, sliderItems]);

    const getSourceSide = (position) => {
        return position === leftRightBanners[0].position ? leftRightBanners[0] : leftRightBanners[1]
    }
    return (
        <div className={style.row}>
            <div className={style.left}>
                {leftRightBanners.length > 0 && getSourceSide('Left') && 
                    <BannerItem sliderItems={getSourceSide('Left')} />
                }
            </div>
            <div className={style.center}>
                { sliderBanners.length > 0 && 
                    <Slider sliderItems={sliderBanners} className="main" />
                }
                { gridBanners.length > 0 && 
                    
                    <div className={style.gridView}>
                        { gridBanners.map((item, index) => (
                            <BannerItem sliderItems={item} />
                        ))
                        }
                    </div>
                }
            </div>
            <div className={style.right}>
                {leftRightBanners.length > 0 && getSourceSide('Right') && 
                    <BannerItem sliderItems={getSourceSide('Right')} />
                }
            </div>
        </div>
    )
}

export default PageBanners