import React, { useEffect, useState, useContext } from 'react'
import style from './Languages.module.css'
import { Captions } from "../../../../../../Services/Hooks/captions";
import { useClickOutside } from '../../../../../../Services/Hooks/outsideClick';
import { Auth } from '../../../../../../Services/Hooks/auth';

const Languages = (props) => {
    const { clientInfo } = useContext(Auth);
    const { setLanguage, currentLanguage, languages } = useContext(Captions);
    let [lang, setLang] = useState(currentLanguage || 'en');
    let [dropdownShown, setDropdownShown] = useState(false);
    
    const onChange = (value) => {
        setLanguage(value);
        setDropdownShown(false);
        setLang(value);
    }

    const handleDropdown = () => {
        setDropdownShown(!dropdownShown);
    }

    const languageOptions = languages
        .filter(language => language.language !== lang)
        .map((language, index) => <li className={style.select} key={index} onClick={(e) => onChange(language.language)}>
            <img src={language.flagUrl} alt={language.langeage} className={style.flag} />
            <span className={language.language}>{language.language}</span>
        </li>);
   
    const domNode = useClickOutside(() => {
        setDropdownShown(false)
    })

    useEffect(() => {
        if(clientInfo) {
            onChange(localStorage.getItem('lang') || clientInfo.languageCode);
        }
    }, [clientInfo])
    return (
        <div className={props.type === "bottomMenu" ? style.transparent : style.languageContainer} ref={domNode}>
           {currentLanguage && languages.length > 0 && <li className={`${style.select} ${style[lang]}`} id={dropdownShown ? style.up : style.down} onClick={handleDropdown}>
               {lang &&
               <>
                    <img src={languages.filter(language => language.language === currentLanguage)[0].flagUrl} alt={languages.filter(language => language.language === lang)[0].language} className={style.flag} />
                    <span>{languages.filter(language => language.language === currentLanguage)[0].language}</span>
               </>
               }
                
            </li> }
            {
                dropdownShown && <div className={style.languageDropdown}>{languageOptions}</div>
            }
        </div>
    )
}

export default Languages;