import React, {useState, useEffect, useContext} from 'react';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import CloseButton from '../../../Components/CloseButton/CloseButton';
import MultiBannersSlider from '../../../Components/Slider/MultiBannersSlider';
import DepositAmountItem from "./DepositAmountItem/DepositAmountItem";
import { Captions } from '../../../Services/Hooks/captions';
import { Auth } from '../../../Services/Hooks/auth';
import PaymentModal from './PaymentModal/PaymentModal';
import { GetClientPayments, GetClientPaymentUrl, SendPaymentNotification } from '../../../Services/service';
import { ConsoleLog, SetItemToLocalStorage, GetItemFromLocalStorage } from '../../../Services/common';
import {PaymentSuccessMessage} from "../../../Components/PaymentMessages/PaymentSuccessMessage";
import {PaymentFailMessage} from "../../../Components/PaymentMessages/PaymentFailMessage";
import style from './Deposit.module.css';

function Deposit({ modalType, closeDepositModal, typeInvoice }) {
    const [currentPaymentList, setCurrentPaymentList] = useState({});
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [amount, setAmount] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [paymentIframeUrl, setPaymentIframeUrl] = useState(null);
    const [activePaymentIndex, setActivePaymentIndex] = useState(0);
    const [showException, setShowException] = useState(null);
    const [amountOldIndex, setAmountOldIndex] = useState(null);
    const { getCaption } = useContext(Captions);
    const { clientInfo, updateClientInfo } = useContext(Auth);

    useEffect(() => {
        GetClientPayments()
            .then(res => {
                setData(res.data);
                setLoading(false);
                setCurrentPaymentList(res.data.availablePayments[0])
            })
            .catch(error => {
                ConsoleLog(error);
            })
    }, [])

    useEffect(() => {
        window.addEventListener("message", function (event) {
            if(event.data.message === "PaymentSuccess") {
                // closeDepositModal();
                setPaymentIframeUrl(null);
                updateClientInfo(true);
                if(!GetItemFromLocalStorage('invoiceNumber')) return;
                SendPaymentNotification(GetItemFromLocalStorage('invoiceNumber'))
                    .then(res => {
                        ConsoleLog(res)
                    })
                    .catch(error => {
                        ConsoleLog(error)
                    })
                localStorage.removeItem('invoiceNumber');
            }
        });
    }, [])

    const getPaymentParameters = () => {
        const { productName, channelName } = data.availablePayments[activePaymentIndex];
        setShowLoader(true);
        GetClientPaymentUrl(channelName, productName, typeInvoice, amount)
            .then(res => {
                if(res.data.url) {
                    setShowLoader(false);
                    setPaymentIframeUrl(res.data.url);
                    if (res.data.invoiceNumber) SetItemToLocalStorage('invoiceNumber', res.data.invoiceNumber);
                }
            })
            .catch(error => {
                setShowLoader(false);
                if(error.response) {
                    if(error.response.status === 400 && error.response.data.errors) {
                        setShowException(getCaption(error.response.data.errors.amount[0]));
                    } else if (error.response.status === 500) {
                        setShowException(getCaption(error.response.data));
                    }
                }
                ConsoleLog(error);
            })
    }

    const handleChange = () => e => {
        setAmountOldIndex(null);
        setAmount(e.target.value > 0 ? e.target.value : '');
    }

    const updateAmountHandler = (itemsAmount, index) => {
        setAmountOldIndex(index);
        if(amountOldIndex === index) {
            return setAmount(amount + itemsAmount);
        }
        setAmount(itemsAmount);
    }

    return (
        <>
            <CustomModal
                isOpen={modalType && !loading}
                closeModal={() => closeDepositModal(false)}
            >
            <PaymentSuccessMessage paymentSuccessCallBack={() => closeDepositModal()} />
            <PaymentFailMessage show={showException} message={showException} update={() => setShowException(null)} />
            {Object.keys(currentPaymentList).length > 0 && clientInfo &&
                <div className={style.deposit}>
                    <CloseButton clickHandler={() => closeDepositModal(false)} />
                    <div className={style.tabs}>
                        <h3>{getCaption('web.deposit.button.' + typeInvoice.toLocaleLowerCase())}</h3>
                    </div>

                    <div className={style.balance}>
                        {clientInfo.balance + ' ' + clientInfo.currencyCode}
                    </div>

                    <form>
                        <div className={style.formGroup}>
                            <div className={style.slider}>
                                {!loading && data.availablePayments[activePaymentIndex].transferFastInsertValues.map((item, index) =>
                                    <DepositAmountItem
                                        key={index}
                                        item={item}
                                        itemCurrency={clientInfo.currencyCode}
                                        clickHandler={() => updateAmountHandler(item, index)}
                                    />
                                )}
                            </div>
                            <div className={style.inputGroup}>
                                <input 
                                    name="amount"
                                    type="number"
                                    placeholder="Amount of money"
                                    onChange={handleChange()}
                                    value={amount}
                                />
                            </div>
                        </div>
                        <p>{currentPaymentList.transferFee} {clientInfo.currencyCode} {getCaption('web.deposit.amount.fee')}</p>
                        <div className={style.depositDescription}>
                            {
                                currentPaymentList.clientLimits.map((item, index) => {
                                    return <div key={index}>{getCaption(`client.deposit.limits.${item.calculateType}`)} <span>{item.amount + ' '  + currentPaymentList.currencyCode}</span></div>
                                })
                            }
                        </div>
                        <div className={style.cards}>
                            <MultiBannersSlider
                                slidesPerColumn={2}
                                slidesPerGroup={2}
                                slidesPerColumnFill={'row'}
                                pagination={false}
                                spaceBetweenSlides={10}
                                showSlideCounter={2}
                                autoplay={false}
                                loop={false}
                                className={"depositMobile"}
                                itemsList={!loading && data.availablePayments.map((item, index) =>
                                    <div
                                        key={index}
                                        style={{
                                            backgroundImage: 'url(' + item.iconUrl + ')'
                                        }}
                                        onClick={() => {
                                            setActivePaymentIndex(index);
                                            setCurrentPaymentList(data.availablePayments[index]);
                                        }}
                                        className={`${style.cardItem} ${activePaymentIndex === index ? style.active : ''}`}
                                    />
                                )}
                            />

                        </div>
                        <button
                            disabled={showLoader}
                            type="button" 
                            className={style.submit}
                            onClick={getPaymentParameters}
                        >
                            {!showLoader ? 
                                getCaption('web.deposit.button.' + typeInvoice.toLocaleLowerCase()) :
                                <div className={style.loading}>
                                    <div className={style.carousel} />
                                </div>
                            }
                        </button>
                    </form>
                </div>
            }
            </CustomModal>
            {paymentIframeUrl && <PaymentModal paymentIframeUrl={paymentIframeUrl} closeDepositModal={() => setPaymentIframeUrl(null)} isMobile={true} />}
        </>
    )
}

export default Deposit
