import React, { useState, useEffect, useContext, Fragment, forwardRef, useImperativeHandle  } from 'react'
import { Link } from 'react-router-dom';
import DateOfBirth from '../../../../../Components/DateOfBirth/DateOfBirth';
import CustomInput from '../../../../../Components/CustomInput/CustomInput';
import CustomSelect from '../../../../../Components/CustomSelect/Select';
import ContactsPhoneNumber from '../../../../../Components/ContactsPhoneNumber/ContactsPhoneNumber';
import { Captions } from '../../../../../Services/Hooks/captions';
import { StaticInputFields, validateField } from '../../../../../Services/Helpers/register';
import { IsUserNameAlreadyTaken } from '../../../../../Services/service';
import { ConsoleLog } from '../../../../../Services/common';

import style from './RenderInputFields.module.css'

const RenderInputFields = forwardRef(({ response, lists, updateResponse, step }, ref) => {
    const { getCaption } = useContext(Captions);
    const [formData, setFormData] = useState({});
    const [passwordType, setPasswordType] = useState(true);
    const [showPasswordRegex, setShowPasswordRegex] = useState(false);
    const [showFieldSuccess, setShowFieldSuccess] = useState({});
    const [showFieldSuccessOfRegex, setShowFieldSuccessOfRegex] = useState({});
    const [showHints, setShowHints] = useState({})
    
    useEffect(() => {
        if(response) {
            let newForm = {};
            for (let i = 0; i < response.length; i++) {
                newForm[response[i].fieldName] = '';
            }
            setFormData(newForm);
        }
    }, [response])

    useImperativeHandle(ref, () => ({
        getAlert() {
            return formData;
        }
    }));

    const clearErrors = () => {
        let _response = response;
        for(let i = 0; i < _response.length; i++) {
            if(_response[i].error) {
                _response[i].error = '';
            }
        }
        updateResponse({ loading: false, data: _response });
    }


    const handleBlurHandler = fieldName => e => {
        const { isValid, errorMessage } = validateField(fieldName, e.target.value);
        const fields = response;
        if(!isValid) {
            for(let i = 0; i < fields.length; i++) {
                if(fields[i].fieldName === fieldName) {
                    fields[i]['error'] = errorMessage;
                }
            }
            updateResponse({ loading: false, data: fields })
        }
        if(fieldName === "client.password") {
            setShowPasswordRegex(false);
        }
    }

    const setErrors = (fieldName, errorMessage) => {
        const fields = response;
        for(let i = 0; i < fields.length; i++) {
            if(fields[i].fieldName === fieldName) {
                fields[i]['error'] = errorMessage;
            }
        }
        updateResponse({ loading: false, data: fields })
    }

    const onClickHandler = (name) => {
        // if(name === "client.password") {
        //     setShowPasswordRegex(true);
        // }
    }

    const onChangeHandler = input => e => { 
        if (input === 'client.username') validateUsername(input, e);
        if (input === 'client.password') validatePasswordField(input, e);
        if (input === 'client.confirm.password') validateConfirmPasswordField(input, e);
        else if (input === 'client.first.name') setFormData({ ...formData, [input]: /^[a-zA-Z]*$/.test(e.target.value) ? e.target.value : formData['client.first.name'] });
        else if (input === 'client.last.name') setFormData({ ...formData, [input]: /^[a-zA-Z]*$/.test(e.target.value) ? e.target.value : formData['client.last.name'] });
        else if (input === 'contacts.phone.number') setFormData({ ...formData, [input]: !isNaN(+e.target.value) ? e.target.value : formData['contacts.phone.number'] });
        else if (input === 'terms.and.conditions') setFormData({ ...formData, [input]: e.target.checked.toString() });
        else setFormData({ ...formData, [input]: e.target.value });
        // Clear input error message
        // removeErrorsField(input);
        clearErrors();
    }
    
    const validateUsername = (fieldName, e) => {
        const { isValid } = validateField(fieldName, e.target.value, true);
        setShowFieldSuccess({ ...showFieldSuccess,  'client.username': isValid});
        if(isValid) {
            IsUserNameAlreadyTaken(e.target.value)
                .then(res => {
                    setShowFieldSuccessOfRegex({...showFieldSuccessOfRegex, [fieldName]: !res.data});
                })
                .catch(error => {
                    ConsoleLog(error);
                })
        }
    }

    const validatePasswordField = (input, e) => {
        setFormData({ ...formData, [input]: e.target.value });
        lists['PasswordRegex'].map(list => {
            const _regExp = new RegExp("\\b" + list.itemValue + "\\b");
            return _regExp.test(e.target.value) ? list.isValid = true : list.isValid = false;
        })
        let validationRegexCount = 0;

        lists['PasswordRegex'].forEach(item => {
            if(item.isValid) validationRegexCount++
        })
        
        setShowFieldSuccessOfRegex({...showFieldSuccessOfRegex, 'client.confirm.password': validationRegexCount === lists['PasswordRegex'].length});
        setShowFieldSuccess({ ...showFieldSuccess,  'client.confirm.password': !!((formData['client.confirm.password'] === e.target.value) && showFieldSuccessOfRegex)});
    }

    const validateConfirmPasswordField = (input, e) => {
        setFormData({ ...formData, [input]: e.target.value });
        setShowFieldSuccess({ ...showFieldSuccess,  'client.confirm.password': !!((formData['client.password'] === e.target.value) && showFieldSuccessOfRegex)});
    }

    const updateContactsPhoneNumber = (option, value) => {
        setFormData({...formData, [option]: value});
        clearErrors();
    }

    const returnClassName = (fieldName) => {
        if (style[fieldName]) return style[fieldName];
        else return '';
    }

    const renderSelectInput = (error, fieldName) => {
        let pickerNames = [];
        if(lists[fieldName]) {
            pickerNames = Object.keys(lists[fieldName]);
        }
        return pickerNames.map((item, index) => {
            if (lists[fieldName]) {
                let list = lists[fieldName][item];
                if (!list) return false;
                if (item === 'Year') {
                    lists[fieldName][item].sort((a, b) => parseFloat(b.itemValue) - parseFloat(a.itemValue));
                }
                return <Fragment key={index}>
                        {item === 'Currency' && <div className={style.currencyDescription}>{getCaption('web.select.date.of.birth.currency')}</div>}
                        <CustomSelect
                            allowInputField={item === 'contacts.mobile.code'}
                            selectedField={''} 
                            selectName={'web.select.date.of.birth.' + item.toLocaleLowerCase()} 
                            selectClassName={item}
                            fieldName={fieldName}
                            data={list}
                            onSelect={selectedOption}
                            error={error}
                        />
                    </Fragment>
            } else {
                return false;
            }
        })
    }
    const selectedOption = (fieldName, selectName, option) => {
        setFormData({ ...formData, [fieldName]: option });
        clearErrors();
    }

    const toggleHint = (item) => {
        console.log(item)
        setShowHints({...showHints, [item]: !showHints[item]});
    }
    return (
        <>
            { response.map(item => 
                StaticInputFields[item.fieldName] && StaticInputFields[item.fieldName].step === step &&
                <div key={item.fieldId} className={style.inputGroup + " " + returnClassName(StaticInputFields[item.fieldName].className)}>
                    { !StaticInputFields[item.fieldName].isSelect ?
                        <>
                            {item.fieldName === 'terms.and.conditions' && 
                                <span className={style.termsText}>{getCaption('terms.condition.text1')}
                                    <Link to='/PrivacyStatement' target="_blank"> {getCaption('privacy.statement')}</Link> <span>{getCaption('privacy.statement.and')}</span>
                                    <Link to='/TermsAndConditions' target="_blank"> {getCaption('terms.and.condition')}</Link> <span>{getCaption('terms.and.condition.apply')}</span>
                                </span>
                            }

                            { item.fieldName !== 'client.date.of.birth' && item.fieldName !== 'contacts.phone.number' ? 
                                <CustomInput
                                    className={`${StaticInputFields[item.fieldName].fieldType === 'checkbox' ? "checkboxStyle" : "labelAnimate"}`}
                                    errorCode={item.error}
                                    type={StaticInputFields[item.fieldName] && StaticInputFields[item.fieldName].fieldType === 'password' ? passwordType ? 'password' : 'text' : StaticInputFields[item.fieldName].fieldType }
                                    name={StaticInputFields[item.fieldName] && StaticInputFields[item.fieldName].fieldName}
                                    value={formData[item.fieldName] || ''}
                                    onClickHandler={() => onClickHandler(item.fieldName)}
                                    onBlurHandler={handleBlurHandler(item.fieldName)}
                                    onChangeHandler={onChangeHandler(item.fieldName)}
                                    label={getCaption(StaticInputFields[item.fieldName].label)}
                                    placeholder={getCaption(StaticInputFields[item.fieldName].label)}
                                    autoFocus={item.fieldName === 'client.first.name'}
                                    autoComplete="off"
                                /> 
                                
                                : item.fieldName === 'contacts.phone.number' ? 

                                <ContactsPhoneNumber 
                                    formData={formData}
                                    errorCode={item.error}
                                    registrationCountryCallingCode={lists.registrationCountryCallingCode}
                                    setData={(option, value) => updateContactsPhoneNumber(option, value)}
                                    label={getCaption(StaticInputFields[item.fieldName].label)}
                                /> : 
                                
                                <DateOfBirth 
                                    errorCode={item.error} 
                                    setErrors={setErrors}
                                    formData={formData}
                                    setData={(data) => {setFormData({ ...formData, [item.fieldName]: data }); clearErrors();}} 
                                />
                            }
                            {StaticInputFields[item.fieldName].fieldType === 'checkbox' && <span className={formData['terms.and.conditions'] === 'true' ? style.checkmark : style.checkmarkOff} />}
                            {/*<div className={`${style.error} ${StaticInputFields[item.fieldName].errorPopup && item.error ? style.errorPopupType : ''}`}>{getCaption(item.error)}</div>*/}
                            <div className={style.error}>{getCaption(item.error)}</div>

                            {StaticInputFields[item.fieldName].allowHint &&

                                <div className={style.hintContent}>
                                    { StaticInputFields[item.fieldName].required && 
                                        <span 
                                            onClick={() => setPasswordType(!passwordType)} 
                                                className={!passwordType ? style.toggleShowPassword : style.toggleShowPassword + ' ' + style.active} 
                                            >
                                                <span className={style.toolTip}>
                                                    {passwordType ? getCaption('tooltip.show.password') : getCaption('tooltip.hide.password')}
                                                </span>
                                        </span>
                                    }
                                    <div onClick={() => toggleHint(item.fieldName)} className={`${style.hintIcon} ${showFieldSuccess[item.fieldName] && showFieldSuccessOfRegex[item.fieldName] ? style.hideHintIcon : ''}`}>

                                        { StaticInputFields[item.fieldName].required 
                                        
                                            ?

                                            <div className={`${showHints[item.fieldName] ? style.activeHint : ''} ${style.inputOption + ' ' + style.hintBackground} ${showPasswordRegex ? style.showRegex : ''}`}>
                                                <h3>{getCaption('client.password.requirements')}</h3>
                                                <ul>
                                                    {lists['PasswordRegex'] &&
                                                        lists['PasswordRegex'].map(item => <li key={item.itemId} className={item.isValid ? style.isValid : ''}>{getCaption(item.attribute1)}</li>)
                                                    }
                                                </ul>
                                            </div> 
                                            
                                            : 
                                            
                                            <div className={`${showHints[item.fieldName] ? style.activeHint : ''} ${style.hintBackground}`}>{getCaption(StaticInputFields[item.fieldName].label + '.hint')}</div>
                                        }
                                    </div>
                                </div>
                            }

                            {StaticInputFields[item.fieldName].fieldSucces && showFieldSuccess[item.fieldName] && showFieldSuccessOfRegex[item.fieldName] &&
                                <div className={style.fieldSuccess} />
                            }
                        </> : 
                        <>
                            {item.fieldName !== 'contacts.mobile.code' &&
                                <>
                                
                                    {renderSelectInput(item.error, item.fieldName, item.fieldId)}
                                    {StaticInputFields[item.fieldName].allowHint &&
                                        <div className={style.hintContent}>
                                            <div onClick={() => toggleHint(item.fieldName)}  className={style.hintIcon}>
                                                <div className={`${showHints[item.fieldName] ? style.activeHint : ''} ${style.hintBackground}`}>{getCaption(StaticInputFields[item.fieldName].label + '.hint')}</div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
            )}
        </>
    )
})

export default RenderInputFields