import React, { useEffect, useState } from 'react'
import style from './DatePicker.module.css'
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css"
import DatePicker from 'react-datepicker'
const Range = (props) => {
    const [startDate, setStartDate] = useState(new Date().setMonth((new Date().getMonth()) - 1));
    const [endDate, setEndDate] = useState(new Date());
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    return (
        <div className={style.datePickers}>
            <div>
                <label className={style.label}>From</label>
                <DatePicker 
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    onChangeRaw={handleDateChangeRaw}
                />
            </div>
            <div>
                <label className={style.label}>To</label>
                <DatePicker
                    selected={endDate}
                    onChange={(date) => {props.range(startDate, endDate); setEndDate(date)}}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    onChangeRaw={handleDateChangeRaw}
                />
            </div>
        </div>
    )
}

export default Range