import React, {useContext, useState} from 'react';
import style from  './LeaderBoardSliderItem.module.css';
import { Captions } from '../../../../../../Services/Hooks/captions';
import { Link } from "react-router-dom";

function LeaderBoardSliderItem(props) {
    const pageName = 'WelcomePage';
    const { getCaption } = useContext(Captions);
    const [hideTimer, setHideTimer] = useState(false);

    const onTimeout = () => {
        if(!props.promotionDetails.isExpired) {
            props.onTimeout();
        }  
    }
    
    const toggleTimer = () => {
        setHideTimer(!hideTimer);
    }
    return (
        <div className={style.item}>
            <div className={style.itemHeader}>
            {
               getCaption(props.promotionDetails.captionKey)
            }
            </div>
            <div className={style.itemContent}>
                <Link to={props.promotionDetails.redirectUrl} className={style.redirectClass}>
                    <img src={props.promotionDetails.bannerName} alt={props.promotionDetails.bannerName} />
                </Link>
            </div>
        </div>
    )
}
export default LeaderBoardSliderItem;