import React, { useState } from "react";
import { 
    GetRegistrationInfo,
    GetRegistrationCurrency,
    GetRegistrationCountrycode,
    GetRegistrationDateOfBirthPicker,
    GetRegistrationCountryCallingCode
} from "../service";

export const RegistrationApi = React.createContext(null);

export const useRegistrationApi = () => {
    const [values, setValues] = useState(null);

    const groupByProperty = (data) => {
        if (!data) return;
        return data.reduce((groups, item) => ({
            ...groups,
            [item.itemKey]: [...(groups[item.itemKey] || []), item]
        }), {});
    }

    return {
        loadValues: () => {
            GetRegistrationInfo()
                .then(res => {
                    setValues(prevState => ({
                        ...prevState,
                        regisrationInfo: res.data
                    }));
                })
            
            GetRegistrationCountrycode()
                .then(res => {
                    if(res) {
                        setValues(prevState => ({
                            ...prevState,
                            registrationCountrycode: res.data
                        }));
                    }
                })

            GetRegistrationDateOfBirthPicker()
                .then(res => {
                    if(res) {
                        setValues(prevState => ({
                            ...prevState,
                            registrationDateOfBirthPicker: groupByProperty(res.data.customListItems)
                        }));
                    }
                })

            GetRegistrationCurrency()
                .then(res => {
                    if(res) {
                        setValues(prevState => ({
                            ...prevState,
                            registrationCurrency: groupByProperty(res.data.customListItems)
                        }));
                    }
                })

            GetRegistrationCountryCallingCode()
                .then(res => {
                    setValues(prevState => ({
                        ...prevState,
                        registrationCountryCallingCode: res.data.customListItems
                    }));
                })
        },
        val: values
    };
}