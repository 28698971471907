import React from "react";
import { useEffect, useState } from "react";
import { GetCaptions, GetMainMenu, PostUpdateDetails } from "../service";

export const Captions = React.createContext({});

export const useCaptions = () => {
    const currentLanguage = localStorage.getItem("lang");

    const [captions, setCaptions] = useState({});
    const [languages, setLanguages] = useState([]);

    const [language, setLanguage] = useState(currentLanguage);
    const [isRequestSent, setIsRequestSent] = useState(false);

    const { loading, data } = GetMainMenu();
    
    const result = {
        getCaption: (key) => {
            if (captions[language] && captions[language][key]) {
                return captions[language][key];
            }
            return key;
        },
        getLanguage: () => {
            return language;
        },
        setLanguage: (lang) => {
            if (lang) {
                if (!captions[lang]) {
                    if (!isRequestSent) {
                        if(localStorage.getItem('accessToken')) {
                            setIsRequestSent(true);
                            PostUpdateDetails({
                                "fieldsGroup": "ClientDetailsPage5",
                                "registrationInputs": [{ "fieldName": "client.language.code", "fieldValue": lang }]
                            })
                                .then(res => {
                                    if(res.data) {
                                        GetCaptions(lang)
                                            .then(data => {
                                                setCaptions({ captions, [lang]: data.data });
                                                setIsRequestSent(false);
                                            });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                        } else {
                            setIsRequestSent(true);
                            GetCaptions(lang)
                                .then(data => {
                                    setCaptions({ captions, [lang]: data.data });
                                    setIsRequestSent(false);
                                });
                        }
                    }
                }
                localStorage.setItem("lang", lang);
                setLanguage(lang);
            }
        },
        currentLanguage: language,
        languages: languages,
        captions: captions
    };


    useEffect(() => {
        if (!loading) {
            setLanguages(data.portalInfo.countryInfo);
            result.setLanguage(language || data.portalInfo.countryInfo[0].language);
        }
    }, [loading, language, result, data]);

    return result;
};
