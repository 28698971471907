import React, {useContext} from 'react';
import { Captions } from '../../../../../../Services/Hooks/captions'
import Information from '../Information';
import style from './AgeRestrictionPolicy.module.css';

const AgeRestrictionPolicy = () => {
    const { getCaption } = useContext(Captions);
    return (
        <div className={style.container}>
             <Information />
             <div className={style.main}>
                <h2 className={style.title}>{getCaption('platform.age.restriction.policy.header1')}</h2>
                <p className={style.text}> {getCaption('platform.age.restriction.policy.header2')}</p>
                <p className={style.text}>{getCaption('platform.age.restriction.policy.description.text')}</p>
            </div>
        </div>
    )
}
export default AgeRestrictionPolicy;