import React, {useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import VerticalMenu from '../VerticalMenu/VerticalMenu'
import { Captions } from '../../../../../../Services/Hooks/captions'
import style from './GameRules.module.css'
import HorizontalSubMenu from './HorizontalSubMenu/HorizontalSubMenu'
import { GetProvidersForCurrentGameAndInformation } from '../../../../../../Services/service';
import { ConsoleLog } from '../../../../../../Services/common';
const GameRules = () => {
    const [currencyCode, setCurrencyCode] = useState('');
    const [providers, setProviders] = useState([]);
    const [activeIndexes, setActiveIndexes] = useState([]);
    const { getCaption } = useContext(Captions);
    const selectMenuItem = (categoryName) => {
        GetProvidersForCurrentGameAndInformation(categoryName, localStorage.getItem('lang'))
            .then(res => {
                setCurrencyCode(res.data.currencyCode);
                setProviders(Object.entries(res.data.gameProvidersWithSettings));
            })
            .catch(error => {
                ConsoleLog(error);
            })
    }

    const showRulesContent = index => {
        if(activeIndexes.indexOf(index) === -1) setActiveIndexes([...activeIndexes, index]);
        if(activeIndexes.indexOf(index) !== -1) setActiveIndexes(activeIndexes.filter((item, _index) => _index !== activeIndexes.indexOf(index)));
    };

    return (
        <div className={style.container}>
            <div className={style.aside}>
                <VerticalMenu />
            </div>
            <div className={style.main}>
                <div>
                    <HorizontalSubMenu selectMenuItem={selectMenuItem} />
                </div>
                { providers.length > 0 && providers.map((item, index) => (
                    <div className={style.rulesItem} key={index}>
                        <div 
                            className={style.rulesItemHeader}
                            onClick={() => showRulesContent(index)}
                        >
                            <h4>{getCaption(`web.games.rules.provider.title.${item[0].toLowerCase()}`)}</h4>
                            <div className={`${style.showRules} ${activeIndexes.indexOf(index) !== -1 ? style.selected : ''}`} />
                        </div>
                        <div className={`${style.rulesItemTable} ${activeIndexes.indexOf(index) !== -1 ? style.selected : ''}`}>
                            <div className={style.tableHead}>
                                <div className={style.tableColumnId} />
                                <div className={style.tableColumnGame}>{getCaption('web.games.rules.table.column.games')}</div>
                                <div className={style.tableColumnMinBet}>{getCaption('web.games.rules.table.column.min.bet')}</div>
                                <div className={style.tableColumnMaxBet}>{getCaption('web.games.rules.table.column.max.bet')}</div>
                                <div className={style.tableColumnPayouts}>{getCaption('web.games.rules.table.column.payouts')}</div>
                            </div>
                            <div className={style.rulesItemContent}>
                            { Object.entries(item[1]).map((game, index) => (
                                <div className={style.tableRow} key={index}>
                                    <div className={style.tableColumnId}>{index + 1}</div>
                                    <div className={style.tableColumnGame}>{game[1]['aggregator.game.name']}</div>
                                    <div className={style.tableColumnMinBet}>{game[1]['min.bet']} <span className={style.currencyCode}>{currencyCode}</span></div>
                                    <div className={style.tableColumnMaxBet}>{game[1]['max.bet']} <span className={style.currencyCode}>{currencyCode}</span></div>
                                    <div className={style.tableColumnPayouts}>{game[1]['win.max']}</div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                ))}
                {/* <div className={style.contactBtn}>
                    <Link to="/Contact" className={style.button} >{getCaption('web.responsible.gaming.description.contact.us')} </Link>
                </div> */}
            </div>
        </div>
    )
}

export default GameRules;