import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import VerticalMenu from '../VerticalMenu/VerticalMenu';
import style from './TermsAndConditions.module.css';
import { Captions } from '../../../../../../Services/Hooks/captions'
import { GetTermsAndConditions } from '../../../../../../Services/service'


const TermsAndConditions = () => {
    const [index, setIndex] = useState(0)
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const [show, setShow] = useState({ [index]: index === 0 ? true : false });
    const showContent = index => (e) => {
        setIndex(e.target.getAttribute('index'))
        setShow({
            ...show,
            [index]: !show[index]
        })
    }

    const { getCaption } = useContext(Captions);
    useEffect(() => {
        GetTermsAndConditions(false)
            .then(res => {
                if (res.data !== "") {
                    setResponse({
                        loading: false,
                        data: res.data
                    });

                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    });
                }

            })

    }, []);


    return (
        <div className={style.container}>
            <div className={style.aside}>
                <VerticalMenu />
            </div>
            <div className={style.main}>
                <div className={style.contentHeader}>
                    <span>Terms and conditions</span>
                </div>
                <div className={style.contentItems}>
                    {!response.loading && response.data.map((item, index) =>
                        <React.Fragment key={index}>
                            <div className={style.contentItem} index={index} onClick={showContent(index)}>
                                {
                                    getCaption(item.category)
                                }
                                <span className={`${style.arrow} ${ show[index] ? style.active : ''}`  } />

                            </div>
                            {show[index] &&
                                <div className={style.itemText}  >
                                    {
                                        getCaption(item.content)
                                    }
                                </div>
                            }
                        </React.Fragment>
                    )}



                    {/* <div className={style.contactBtn}>
                        <Link to="/Contact" className={style.button} >{getCaption('web.responsible.gaming.description.contact.us')} </Link>
                    </div> */}
                </div>


            </div>

        </div>


    )
}
export default TermsAndConditions;