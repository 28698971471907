import React, {useContext} from 'react';
import VerticalMenu from '../VerticalMenu/VerticalMenu';
import { Captions } from '../../../../../../Services/Hooks/captions'
import style from './CookiePolicy.module.css';
import { Link } from 'react-router-dom';
const CookiePolicy = () => {
    const { getCaption } = useContext(Captions);
    return (
        <div className={style.container}>
            <div className={style.aside}>
            <VerticalMenu/>
            </div>
            <div className={style.main}>
                <h2 className={style.title}>{getCaption('platform.cookiepolicy.cookie.header1')}</h2>
                <p className={style.text}> {getCaption('platform.cookiepolicy.cookie.header2')}</p>
                <p className={style.text}>{getCaption('platform.cookiepolicy.cookie.description.text')}</p>
                <ul className={style.list}>
                    <li>{getCaption('platform.cookiepolicy.cookie.description.item1')}</li>
                    <li>{getCaption('platform.cookiepolicy.cookie.description.item2')}</li>
                </ul>
                <h2 className={style.title}>{getCaption('platform.cookiepolicy.cookie.header3')}</h2>
                <p className={style.text}>{getCaption('platform.cookiepolicy.cookie.description.text2')}</p>
                <p className={style.text}>{getCaption('platform.cookiepolicy.cookie.p')}</p>
            </div>
           
        </div>
        
    )
}
export default CookiePolicy;