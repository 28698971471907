import React, { useEffect, useState, useContext } from 'react';
import Promo from './Promo/Promo';
import { GetPromotions } from '../../../Services/service';
import PageBanners from '../PageBanners/PageBanners';
import SideBarFilter from '../../../Components/SideBarFilter/SideBarFilter';
import { useLocation } from 'react-router';
import { Auth } from '../../../Services/Hooks/auth'
import VerificationComponent from '../../../Components/VerificationComponent/VerificationComponent';
import style from './PromotionsPage.module.css';
import { Captions } from '../../../Services/Hooks/captions';
import Ghost from '../../../Components/Ghost/Ghost';


const PromotionsPage = (props) => {
    const { pathname } = useLocation();
    const { getCaption } = useContext(Captions);
    const { auth, clientInfo } = useContext(Auth)
    const [promotionsCategory, setPromotionsCategory] = useState([]);
    const [promoStatus, setPromoStatus] = useState({ status: "active" });
    const [activeCategories, setActiveCategories] = useState([]);
    const [activePromotions, setActivePromotions] = useState([]);
    const [expiredPromotions, setExpiredPromotions] = useState([]);
    const [expiredCategories, setExpiredCategories] = useState([]);
    const [clearAll, setClearAll] = useState(false)

    const refreshPromotions = () => {
        setClearAll(false)
        GetPromotions(false)
            .then((res) => {
                setActivePromotions([]);
                setExpiredPromotions([]);
                const arrayActive = [];
                const arrayExpired = [];
                const activeCategoriesArray = [];
                const expiredCategoriesArray = [];
                for (let i = 0; i < res.data.length; i++) {
                    if ((res.data[i].pageName === 'PromotionsPage' && res.data[i].isExpired == false) && arrayActive.indexOf(res.data[i].category.toLowerCase()) === -1) {
                        arrayActive.push(res.data[i].category.toLowerCase());
                        activeCategoriesArray.push({ name: res.data[i].category.toLowerCase(), value: true })
                    } else if ((res.data[i].pageName === 'PromotionsPage' && res.data[i].isExpired == true) && arrayExpired.indexOf(res.data[i].category.toLowerCase()) === -1) {
                        arrayExpired.push(res.data[i].category.toLowerCase());
                        expiredCategoriesArray.push({ name: res.data[i].category.toLowerCase(), value: true })
                    }
                }

                setPromotionsCategory(promoStatus.status === 'active' ? activeCategoriesArray : expiredCategoriesArray)
                setExpiredCategories(expiredCategoriesArray.map(item => item.value && item.name))
                setActivePromotions(res.data.filter(promo => !promo.isExpired && promo.pageName === 'PromotionsPage').sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0)));
                setExpiredPromotions(res.data.filter(promo => promo.isExpired && promo.pageName === 'PromotionsPage').sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0)));
                setActiveCategories(activeCategoriesArray.map(item => item.value && item.name));
            })
            .catch(() => {

            });
    }
    useEffect(() => {
        refreshPromotions();
    }, [promoStatus.status]);
    return (
        <>
            <PageBanners pathName={pathname.replace('/', '') + 'Main'} className={'promoPgSlider'} />
            <div className={style.page}>
                <div className={style.content}>
                    <SideBarFilter
                        setActiveCategories={promoStatus.status === 'active' ? setActiveCategories : setExpiredCategories}
                        categories={promotionsCategory}
                        setCategory={setPromotionsCategory}
                        itemStatus={promoStatus}
                        setStatus={setPromoStatus}
                        setClearAll={setClearAll}
                        activeCategories={promoStatus.status === 'active' ? activeCategories : expiredCategories}
                    />
                    <div className={`${auth ? style.itemsAuthNotipication : style.items} 
                                    ${(promoStatus.status === 'active' && activeCategories.every(value => value == false)) ||
                                    (promoStatus.status === 'expired' && expiredCategories.every(value => value == false)) ||
                                    clearAll ?
                                    style.emptyContent : ''}`}
                    >
                        {
                            (auth && clientInfo !== null && (clientInfo.emailIsVerified !== true || clientInfo.phoneNumberIsVerified !== true))

                                ?
                                <div className={style.verificationNotification}>
                                    <span className={style.caution}>{getCaption('web.section.promotions.verify.text1')}
                                    </span>
                                    &nbsp;
                                    <span className={style.blueText}>
                                        <VerificationComponent
                                            fieldName="contacts.email"
                                            verificationType={'welcomePopUp'}
                                            trigger={true}
                                            clickTrigger={() => console.log()}
                                        />
                                    </span>
                                    &nbsp;
                                    {getCaption('web.section.promotions.verify.text2')}&nbsp;
                                    <span className={style.blueText}>
                                        <VerificationComponent
                                            fieldName="contacts.phone.number"
                                            trigger={true}
                                            verificationType={'welcomePopUp'}
                                            clickTrigger={() => console.log()}
                                        />
                                    </span>
                                </div>
                                :
                                ' '
                        }

                        {
                            promoStatus.status === 'active' &&
                            activePromotions.map((promo, index) => activeCategories.indexOf(promo.category.toLowerCase()) !== -1 ?
                                <Promo pageName={props.pageName} key={index} promotionDetails={promo} onTimeout={() => refreshPromotions()} /> : '')
                        }
                        {
                            promoStatus.status === 'expired' && !expiredCategories.every(value => value == false) &&
                            expiredPromotions.map((promo, index) => expiredCategories.indexOf(promo.category.toLowerCase()) !== -1 ?
                                <Promo pageName={props.pageName} key={index} promotionDetails={promo} onTimeout={() => refreshPromotions()} /> : '')
                        }
                        {
                            (
                                (promoStatus.status === 'active' && activeCategories.every(value => value == false)) ||
                                (promoStatus.status === 'expired' && expiredCategories.every(value => value == false)) ||
                                clearAll

                            ) && <Ghost message={getCaption('web.section.promotions.no.item')} />
                        }

                    </div>
                </div>
            </div>
        </>
    )
}
export default PromotionsPage;