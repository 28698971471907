import React from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
  
export const CaptchaButton = ({onVerifyCaptcha, children}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!executeRecaptcha) return;

        const token = await executeRecaptcha('contact');
        onVerifyCaptcha(token);
    };

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { onClick:(e)=>{onSubmit(e)} });
        }
        return child;
    });

    return (
        <>
            {childrenWithProps}
        </>
    );
};
