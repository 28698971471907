import React, { useContext } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import { Captions } from "../../../Services/Hooks/captions";
import style from './ErrorPage.module.css'

function ErrorPage() {
    Modal.setAppElement("#root")
    const { getCaption } = useContext(Captions);
    return (
        <Modal
            isOpen={true}
            className={style.modal}
            overlayClassName={style.overlay}
            disableAutoFocus={true}
            disableEnforceFocus={true}
        >
            <div className={style.content}>
                <h1>{getCaption('web.page.error.title')}</h1>
                <div className={style.background} />
                <div className={style.description}>
                    <p>{getCaption('web.page.error.description')}</p>
                    <Link to="/Home" className={style.link} ><span />{getCaption('web.page.error.button')}</Link>
                </div>
            </div>
        </Modal>
    )
}

export default ErrorPage
