import React, {useContext} from 'react'
import style from './ShowMore.module.css'
import { Captions } from '../../Services/Hooks/captions'

const ShowMore = ({increment}) => {
    const {getCaption} = useContext(Captions)
    return (
        <div className={style.more} onClick={increment}>
            <p className={style.text}>{getCaption('web.show.more.component.text')}</p>
        </div>
    )
}

export default ShowMore