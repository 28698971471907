import React, { useState } from 'react'
import style from './Search.module.css'
import Search from '../../../../../Components/Search/Search'
const MobileSearch = (props) => {
    const [openSearch, setOpenSearch] = useState(false)
    const handleSearch = () => {
        setOpenSearch(!openSearch)
    }
    return (
        <div className={style.wrapper}>          
            {openSearch ? <Search autofocus={true} noSearch={() => setOpenSearch(false)} pageName={props.pageName} /> 
            : <div className={style.search} onClick={handleSearch}></div>}
        </div>
    )
}

export default MobileSearch;