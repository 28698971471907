import React, { useState, useEffect } from 'react'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import MobileHeader from './Pages/Mobile/Layout/Header/Header'
import Routes from './Pages/Desktop/Layout/Routes/Routes'
import MobileRoutes from './Pages/Mobile/Layout/Routes/Routes'
import Footer from './Pages/Desktop/Layout/Footer/Footer'
import MobileFooter from './Pages/Mobile/Layout/Footer/Footer'
import { isMobile } from "react-device-detect"
import { Captions, useCaptions } from "./Services/Hooks/captions"
import { Auth, useAuth } from "./Services/Hooks/auth"
import { NotificationsContext, useNotifications } from "./Services/Hooks/useNotification"
import MobileMenu from './Pages/Mobile/MobileMenu/MobileMenu'
import Conversation from "./Components/Conversation/Conversation";
import HeaderContainer from './Pages/Desktop/Layout/HeaderContainer/HeaderContainer';
import {RegistrationApi, useRegistrationApi} from './Services/Hooks/registrationApi';
import {CountryCode, useCountryCode} from './Services/Hooks/countryCode';
import { GetCaptchaKey } from './Services/common';
import './App.css'
import Loader from './Components/Loader/Loader';



export const App = () => {
    const auth = useAuth();
    const captions = useCaptions();
    const countryCode = useCountryCode();
    const notifications = useNotifications();
    const registrationApi = useRegistrationApi();
    const [showLoader, setShowLoader] = useState(true);
    useEffect(() => {
        if(captions.captions[captions.currentLanguage]) setShowLoader(false);
    }, [captions])
    return (
        <GoogleReCaptchaProvider reCaptchaKey={GetCaptchaKey()}>
            <Auth.Provider value={auth}>
                <Captions.Provider value={captions}>
                    <CountryCode.Provider value={countryCode}>
                        <NotificationsContext.Provider value={notifications}>
                            <RegistrationApi.Provider value={registrationApi}>
                                <div className="app-wrapper">
                                    {showLoader && <Loader />  }
                                    {isMobile ? <MobileHeader />
                                        : <HeaderContainer />
                                    }
                                    <section className="content">
                                        {isMobile ? <MobileRoutes /> : <Routes />}
                                        {!isMobile && <Conversation isMobile={isMobile} createConversationHandler={() => {}} />}
                                    </section>
                                    {isMobile ? <>
                                        <MobileFooter />
                                        <MobileMenu />
                                    </> : <Footer />}
                                </div>
                            </RegistrationApi.Provider>
                        </NotificationsContext.Provider>
                    </CountryCode.Provider>
                </Captions.Provider>
            </Auth.Provider>
        </GoogleReCaptchaProvider>
    );
}
