import React, { useState, useContext } from 'react';
import { Captions } from '../../../../Services/Hooks/captions';
import style from './SecurityOptions.module.css';

function SecurityOptions({ fieldInfo, handlerCallBack, submitForm, page }) {
    const [checkBoxChecked, setCheckBoxChecked] = useState(fieldInfo.fieldValue === 'true');  
    const { getCaption } = useContext(Captions);

    const updatecheckbox = () => {
        setCheckBoxChecked(!checkBoxChecked);
    }

    const handleChange = (input, pageName) => e => {
        handlerCallBack(input, e.target.checked.toString(), pageName);
        setCheckBoxChecked(checkBoxChecked);
        submitForm(e, page, updatecheckbox);
    }

    return (
        <>
            <div className={checkBoxChecked ? style.securityOptions + ' ' + style.checked : style.securityOptions}>
                <p>{getCaption(fieldInfo.placeholder)}</p>
                <label className={style.checkbox}>
                    <input
                        name={fieldInfo.fieldName.replaceAll(".", "_")} 
                        type="checkbox" 
                        checked={checkBoxChecked}
                        onChange={handleChange(fieldInfo.fieldName, fieldInfo.fieldPageName)}
                    />
                    <span />
                </label>
            </div>
        </>
    )
}

export default SecurityOptions;
