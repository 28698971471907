import React, { useState, useEffect } from 'react';
import style from './GameAuthenticatedRedirect.module.css';
import { useHistory } from 'react-router-dom';
import { AddToFavorites, GetGames, RemoveFromFavorites } from '../../Services/service';
import Auth from '../../Services/Helpers/authentication';
import ClientAuthentication from '../../Pages/Desktop/ClientAuthentication/ClientAuthentication';
import ClientAuthenticationMobile from '../../Pages/Mobile/ClientAuthentication/ClientAuthentication';
import { isMobile } from "react-device-detect"
import { SetItemToLocalStorage } from '../../Services/common';

const GameAuthenticatedRedirect = (props) => {
    const [inElement, setInElement] = useState((isMobile || props.pageName === (isMobile ? "MobileFavouritesPage" : "FavouritesPage") || props.isFavorite) ? true : false);
    const [selected, setSelected] = useState(false);
    const [loginModalShow, setLoginModalShow] = useState(false);

    let history = useHistory()
    function clickHandler() {
        // history.push({pathname: `/GameLauncher?channelName=${props.channelName}&productName=${props.productName}`,
        // state: {
        //    currentPath: window.location.pathname
        // }})
        history.push(`/GameLauncher?channelName=${props.channelName}&productName=${props.productName}`)
    }

    const ref = React.useRef();
    function checkUser(e) {
        if (e.target === ref.current)
            return;
        else {
            if (Auth.IsAuthenticated()) {
                clickHandler();
            } else {
                setLoginModalShow(true);
            }
        }
    }
    const onMouseEnter = () => {
        setInElement(true)
    }

    const onMouseLeave = () => {
        if(props.isFavorite || selected == true || props.pageName == (isMobile ? "MobileFavouritesPage" : "FavouritesPage")) return;
        setInElement(false)
    }
    const onAddToFavorites = async () => {
        setSelected(!selected);
        await AddToFavorites({ "channelName": props.channelName, "gameName": props.productName });
        props.rerender && await props.rerender(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    props.setResponse({
                        loading: false,
                        data: res.data
                    });
                } else {
                    props.setResponse({
                        loading: true,
                        data: undefined
                    });
                }
            });
    }
    const onRemoveFromFavorites = async () => {
        setSelected(!selected);
        await RemoveFromFavorites({ "channelName": props.channelName, "gameName": props.productName });
        props.rerender && await props.rerender(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    props.setResponse({
                        loading: false,
                        data: res.data
                    });
                    if (props.pageName === (isMobile ? "MobileFavouritesPage" : "FavouritesPage")) {
                        const categoryArray = [];
                        const array = [];
                        for (let i = 0; i < res.data.length; i++) {
                            for (let j = 0; j < res.data[i].pageItems.length; j++) {

                                if (array.indexOf(res.data[i].pageItems[j].itemCategory) === -1) {
                                    array.push(res.data[i].pageItems[j].itemCategory);
                                    categoryArray.push({ name: res.data[i].pageItems[j].itemCategory, value: true })
                                }
                            }
                        }
                        props.setCategory(categoryArray)
                    }
                } else {
                    props.setResponse({
                        loading: true,
                        data: undefined
                    });
                }
            });
    }
    return (
        <>
            {isMobile ?
                loginModalShow ? <ClientAuthenticationMobile loginModalShow={loginModalShow} clickHandler={clickHandler} closeModal={() => setLoginModalShow(false)} /> : '' :

                loginModalShow ? <ClientAuthentication loginModalShow={loginModalShow} clickHandler={clickHandler} closeModal={() => setLoginModalShow(false)} /> : ''
            }
            <div className={style.AuthenticatedRedirect} onClick={checkUser} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <img src={props.src} alt={props.productName} className={style.gameBanner} id={props.isSlider ? style.slider : style.game} onClick={props.isSlider ? checkUser : null} />
                {!props.isSlider && inElement && Auth.IsAuthenticated() && [(selected == true || props.pageName === (isMobile ? "MobileFavouritesPage" : "FavouritesPage") || props.isFavorite)
                    ? <span key={0} ref={ref} onClick={onRemoveFromFavorites} className={`${style.like} ${style.selected}`} /> : <span key={1} ref={ref} onClick={onAddToFavorites}
                        className={`${style.like} ${style.deselected}`} />]}
                {/* {props.pg === "MainPage" && <div className={style.mainPageGameName}>{getCaption(props.gameName)}</div>} */}
                {/* {!props.hidePlayBtn && <div className={style.play} >PLAY{getCaption('')}</div>}
                {!props.hideGameName && props.pg !== "MainPage" && <div className={style.gameName}>{getCaption(props.gameName)}</div>} */}
            </div>
        </>
    )
}
export default GameAuthenticatedRedirect;