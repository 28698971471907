import React, { useContext } from 'react';
import { useState } from 'react';
import Timer from '../../../../Components/Timer/Timer';
import style from './Promo.module.css';
import { Captions } from '../../../../Services/Hooks/captions';
import { Link } from "react-router-dom";
const Promo = (props) => {
    const { getCaption } = useContext(Captions);
    const [hideTimer, setHideTimer] = useState(false);
    // const onTimeout = () => {
    //     if (!props.promotionDetails.isExpired) {
    //         props.onTimeout();
    //     }
    // }
    const toggleTimer = () => {
        setHideTimer(!hideTimer);
    }
    const convertUtcToLocalDate = (dateString) => {
        return new Date(new Date(dateString).getTime() - new Date(dateString).getTimezoneOffset() * 60 * 1000)
    }
    return (
        <div className={style.card}>
            <div className={style.row}>
                <div className={style.container}>
                    <div className={props.promotionDetails.isExpired ? style.expired + ' ' + style.content : style.content}>
                        <Link to={props.promotionDetails.redirectUrl} className={style.redirectClass}>
                            <img src={props.promotionDetails.bannerName} alt={props.promotionDetails.bannerName} />
                        </Link>
                    </div>

                </div>
                <div className={hideTimer ? style.showTimer + ' ' + style.active : style.showTimer} onClick={toggleTimer}></div>
                <div className={hideTimer ? style.footer + ' ' + style.active : style.footer}>
                    <div className={style.left}>
                        <h3>
                            {
                                getCaption(props.promotionDetails.captionKey.length > 20 ? props.promotionDetails.captionKey.substring(0, 20) + '...' : props.promotionDetails.captionKey)
                            }
                        </h3>

                    </div>
                    <div className={style.right}>
                        <div className={`${!props.promotionDetails.isExpired ? style.date : style.hideCaption} ${style.green}`}> {getCaption('web.promotion.ending.in')}</div>
                        {
                            !props.promotionDetails.isExpired ? <Timer isPromotion={true} expireDate={convertUtcToLocalDate(props.promotionDetails.endDate)} isExpired={() => props.onTimeout()} />
                                :
                                <div className={`${style.date} ${style.expiredTitle}`}>
                                    {
                                        getCaption('web.promotion.expired.title')
                                    }
                                </div>

                        }

                        {/* { !props.promotionDetails.isExpired && <div className={style.play}></div> } */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Promo;