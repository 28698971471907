import {useEffect, useRef} from 'react'

export const useClickOutside = (handler, ifInToModal) => {
    let domNode = useRef();
    useEffect(() => {
        let modal = document.body.classList.contains('ReactModal__Body--open')
            let maybeHandler = (event) => {
                if(domNode && domNode.current && !domNode.current.contains(event.target) && (!modal || ifInToModal)) {
                    handler();
                }  
            }
            document.addEventListener('mousedown', maybeHandler);
            return () => {
                document.removeEventListener('mousedown', maybeHandler);
            }
    });
    return domNode;
}
