import React, { useState, useEffect, useContext } from 'react';
import GameContainer from '../../../Components/GameContainer/GameContainer';
import { useLocation } from "react-router-dom";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import style from './GameLauncher.module.css';
import { GetGameUrl } from '../../../Services/service';
import { withRouter } from 'react-router';
import { Captions } from '../../../Services/Hooks/captions';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const GameLuncher = (props) => {
    const query = useQuery();
    const [gameURL, setGameURL] = useState('');
    const { getLanguage } = useContext(Captions);
    // gameURL ? disableBodyScroll(document) : enableBodyScroll(document)
    useEffect(() => {
        let isMounted = true;      
        GetGameUrl(query.get("channelName"), query.get("productName"), true, getLanguage())
            .then((res) => {
                if (isMounted) setGameURL(res.data.url);
            })
            .catch(() => {

            });
        return () => { isMounted = false };
    }, []);
    window.location.pathname.includes('/GameLauncher')? document.body.classList.add('fixed') : document.body.classList.remove('fixed') 
    return (
        <div className={style.gameLuncherContainer}>
            <GameContainer url={gameURL} />
        </div>
    )
}

export default withRouter(GameLuncher);