import React, {useContext} from 'react';
import {Captions} from "../../../../../Services/Hooks/captions";
import style from "./LoginButton.module.css";

export default function LoginButton({showLoader, openModal, buttonType}) {
    const { getCaption } = useContext(Captions);
    return <button className={`${style.submitButton + ' ' + style[buttonType]} ${showLoader ? style.showLoader : ''}`} onClick={openModal}>
            {getCaption('client.sign.in')}
            { showLoader &&
                <div className={style.loading}>
                    <div className={style.carousel} />
                </div>
            }
        </button>
}