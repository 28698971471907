import React from 'react'
import style from './Ghost.module.css'

const Ghost = ({message}) => {
    return (
        <div className={style.wrapper}>
            <div className={style.ghost} />
            <h2 className={style.message}>{message}</h2>
        </div>
    )
}

export default Ghost