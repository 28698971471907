import React, { useState, useEffect, useContext } from 'react';
import VerificationModal from '../../../../Components/VerificationModal/VerificationModal';
import { Captions } from '../../../../Services/Hooks/captions';

import style from './ContactInformation.module.css';
import ContactsPhoneNumber from "../../../../Components/ContactsPhoneNumber/ContactsPhoneNumber";
import CustomInput from "../../../../Components/CustomInput/CustomInput";

function ContactInformation({ clientInfo, contactMobileCode, fieldInfo, mobileCode, handlerCallBack, submitForm, page, errors }) {
    const [modal, setModal] = useState({ open: false, name: '' });
    const [errorMessages, setErrorMessages] = useState({});
    const [buttonSuccess, setButtonSuccess] = useState(false);
    // const [isVerified, setIsVerified] = useState(fieldInfo.fieldStatus === 'Verified');
    const [form, setForm] = useState({
        [fieldInfo.fieldName]: fieldInfo.fieldValue
    })
    const { getCaption } = useContext(Captions);

    // useEffect(() => {
    //     if(fieldInfo.fieldStatus === 'Verified') {
    //         setIsVerified(form[fieldInfo.fieldName] !== fieldInfo.fieldValue);
    //     }
    // }, [isVerified, form, fieldInfo])

    const openModal = (fieldName) => {
        setModal({ open: true, name: fieldName });
    }

    const closeModal = () => {
        setModal({ open: false, name: '' });
    }

    const handleChange = (input, pageName) => e => {
        if(errors.length) setErrorMessages({});
        handlerCallBack(input, e.target.value || e.target.checked, pageName);
        setForm({...form, [input]: e.target.value});
    }

    const updateButton = () => {
        setButtonSuccess(true);
        setTimeout(() => setButtonSuccess(false), 2000);
    }

    // const verifiedCallback = () => {
    //     setIsVerified(true);
    // }

    useEffect(() => {
        setErrorMessages({});
        if(errors.length) {
            let obj = {}
            errors.forEach(item => {
                obj[item.FieldName] = item.ValidationError;
                setErrorMessages(obj);
            })
        }
    }, [errors]);

    const handlerCallBackHandler = (option, value, fieldPageName) => {
        handlerCallBack(option, value, fieldPageName);
        setForm({...form, [option]: value});
    }

    return (
        <>
            {modal.open && 
                <VerificationModal 
                    fieldInfo={fieldInfo} 
                    contactMobileCode={contactMobileCode} 
                    modal={modal} 
                    mobileCode={mobileCode} 
                    closeModal={closeModal}
                    verifiedCallback={() => {} }
                />
            }
            <div className={style[fieldInfo.fieldName.replaceAll(".", "_")] + ' ' + style.contactGroup}>
                {fieldInfo.fieldName === 'contacts.phone.number' ? (
                    <>
                        <button
                                type="button"
                                className={clientInfo.phoneNumberIsVerified ? style.verifyHandler + ' ' + style.verified : style.verifyHandler}
                                disabled={clientInfo.phoneNumberIsVerified}
                                onClick={() => openModal(fieldInfo.fieldName)}
                            >{clientInfo.phoneNumberIsVerified ? '' : getCaption('button.verify')}
                        </button>

                        <ContactsPhoneNumber
                            formData={form}
                            errorCode={errorMessages.mobileCode}
                            setData={(option, value) => handlerCallBackHandler(option, value, fieldInfo.fieldPageName)}
                            label={getCaption('web.fields.placeholder.phone')}
                        />
                        
                        <button 
                            className={style.submitHandler}
                            onClick={(e) => submitForm(e, page, updateButton)}
                        >{buttonSuccess ? (
                            <>
                                <span/>{getCaption('button.saved')}
                            </>
                            ) : (
                                <>
                                    {getCaption('button.save')}
                                </>
                            )}
                        </button>
                    </>
                ) : (
                    fieldInfo.fieldName === 'contacts.email' && (
                        <>
                            <div className={!fieldInfo.editable ? `${style.inputGroup + ' ' + style.disabled}` : style.inputGroup}>
                                <button
                                    type="button"
                                    className={clientInfo.emailIsVerified ? style.verifyHandler + ' ' + style.verified : style.verifyHandler}
                                    disabled={clientInfo.emailIsVerified}
                                    onClick={() => openModal(fieldInfo.fieldName)}
                                >{clientInfo.emailIsVerified ? getCaption('button.verified') : getCaption('button.verify')}
                                </button>

                                <CustomInput
                                    className="autoWidth"
                                    errorCode={errorMessages ? errorMessages[fieldInfo.fieldName] : null}
                                    name={fieldInfo.fieldName.replaceAll(".", "_")}
                                    type={fieldInfo.fieldType}
                                    value={form[fieldInfo.fieldName]}
                                    label={getCaption(fieldInfo.placeholder)}
                                    placeholder={getCaption(fieldInfo.placeholder)}
                                    onChangeHandler={handleChange(fieldInfo.fieldName, fieldInfo.fieldPageName)}
                                    autoComplete="off"

                                />
                                {errorMessages[fieldInfo.fieldName] && <span className={style.errorMessage}>{getCaption(errorMessages[fieldInfo.fieldName])}</span>}
                            </div>
                        </>
                    )
                )}
            </div>
        </>
    )
}

export default ContactInformation
