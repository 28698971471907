import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Registration from '../Registration/Registration';
import CustomAlert from '../../../../Components/CustomAlert/CustomAlert';
import CustomInput from '../../../../Components/CustomInput/CustomInput';
import LoginButton from './LoginButton/LoginButton';
import ResetPassword from '../../../../Components/ResetPassword/ResetPassword';
import { Captions } from '../../../../Services/Hooks/captions';
import { Auth } from '../../../../Services/Hooks/auth';
import { PostLoginUser } from "../../../../Services/service";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { GetBrowserUniqueId } from "../../../../Services/common";
import BlockPopup from '../../../../Components/BlockPopup/BlockPopup';
import { useClickOutside } from '../../../../Services/Hooks/outsideClick';
import style from "./Login.module.css";
import CloseButton from '../../../../Components/CloseButton/CloseButton';

export default function Login({ isRedirectToGame, redirectToGame, closeLoginPopup, isPopupMode, buttonType, toggleRegisterPopup }) {
    const [browserUniqueId, setBrowserUniqueId] = useState(GetBrowserUniqueId);
    const [loginForm, setLoginForm] = useState({ 'client.username': '', 'client.password': '', 'browser.unique.id': browserUniqueId });
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [passwordType, setPasswordType] = useState(true);
    const [errors, setErrors] = useState({});
    const [showLoader, setShowLoader] = useState(false);
    const [resetPopup, setResetPopup] = useState(false);
    const [blockAccount, setBlockAccount] = useState({ popup: false, unblockDate: "" });
    const [transparent, setTransparent] = useState(false);
    const { getCaption } = useContext(Captions);
    const { auth, authenticate, clientInfo } = useContext(Auth);
    const resetPasswordButton = React.useRef(null)
    const query = useLocation();
    useEffect(() => {
        if (clientInfo && auth)
            setShowLoader(false);
    }, [auth, clientInfo])

    useEffect(() => {
        if(!showLoader) setLoginForm({ 'client.username': '', 'client.password': '', 'browser.unique.id': browserUniqueId });
    }, [showLoader, query, browserUniqueId])

    useEffect(() => {
        setErrors({});
    }, [showLoginForm, browserUniqueId])

    const handleResetPopup = () => { setResetPopup(!resetPopup) }

    const handleChange = input => e => {
        setLoginForm({ ...loginForm, [input]: e.target.value });
        setErrors({});
    }

    const formatDate = (unblockDate) => {
        return new Date(new Date(unblockDate).getTime() - new Date(unblockDate).getTimezoneOffset() * 60 * 1000)
    }

    showLoginForm && (isPopupMode || isRedirectToGame) ? disableBodyScroll(document) : enableBodyScroll(document)

    const onSubmit = (e) => {
        e.preventDefault();
        setShowLoader(true);
        PostLoginUser(prepareForm(loginForm))
            .then(res => {
                if (!res.data.errorCode) {
                    let token = res.data.accessToken;
                    if (res.data.accountUnblockDate) {
                        setShowLoader(false);
                        setBlockAccount({ ...blockAccount, popup: true, unblockDate: formatDate(res.data.accountUnblockDate) })
                    }
                    if (!token) return
                    let decodedToken = jwt_decode(token);
                    authenticate(token, decodedToken, res.data.refreshToken);
                    if (isRedirectToGame) redirectToGame();
                } else {
                    setShowLoader(false);
                    setErrors({ errorCode: res.data.errorCode })
                }
            })
            .catch(error => {
                setShowLoader(false);
                if (error.response) {
                    setErrors({ ...errors, internalError: getCaption('web.login.form.username.or.password.is.incorrect') })
                }
            })
    }

    const prepareForm = (data) => {
        let postLoginForm = [];
        Object.keys(data).forEach(function (key) {
            postLoginForm.push({ inputName: key, inputValue: data[key] });
        });
        return postLoginForm;
    }

    let domNode = useClickOutside(() => {
        setErrors({});
    })

    const handleOutsideClick = (event) => {
        const path = event.path || (event.composedPath && event.composedPath())
        if (!path.includes(resetPasswordButton.current) && (!isRedirectToGame || !isPopupMode)) {
            setResetPopup(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleOutsideClick)
        return () => window.removeEventListener('click', handleOutsideClick)
    }, [handleOutsideClick])

    const registerPopup = (type) => {
        setTransparent(type);
        if (toggleRegisterPopup) toggleRegisterPopup(type);
    }

    return (
        <>
            {!clientInfo && (showLoginForm || !isPopupMode) &&
                <div
                    className={`${(isRedirectToGame || isPopupMode) ? style.loginPopup : style.loginFixed} ${isRedirectToGame ? style.isRedirectToGame : ''} ${transparent ? style.transparent : ''}`}>

                    <form onSubmit={onSubmit} ref={domNode}>
                        {(isRedirectToGame || isPopupMode) && <CloseButton clickHandler={() => closeLoginPopup ? closeLoginPopup() : setShowLoginForm(false)} />}

                        {(isRedirectToGame || isPopupMode) && errors.internalError &&
                            <CustomAlert message={errors.internalError} type="error" />
                        }

                        <div className={style.container}>
                            {(isRedirectToGame || isPopupMode) && <div className={style.title}>{getCaption('client.sign.in')}</div>}
                            <div className={style.inputGroup}>
                                <CustomInput
                                    className={(isRedirectToGame || isPopupMode) ? "labelAnimate" : "static"}
                                    errorCode={errors.errorCode || errors.internalError}
                                    type='text'
                                    name="username"
                                    value={loginForm['client.username']}
                                    autoFocus={true}
                                    onChangeHandler={handleChange('client.username')}
                                    label={getCaption('web.fields.placeholder.username')}
                                    placeholder={getCaption('web.fields.placeholder.username')}
                                    autoComplete="off"
                                />

                                <div className={style.error}>
                                    {getCaption(errors.errorCode) || ((!isPopupMode && !isRedirectToGame) && getCaption(errors.internalError))}
                                </div>
                            </div>

                            <div className={style.inputGroup + ' ' + style.password}>
                                <CustomInput
                                    className={(isRedirectToGame || isPopupMode) ? "labelAnimate" : "static"}
                                    errorCode={errors.errorCode || errors.internalError}
                                    type={passwordType ? 'password' : 'text'}
                                    name="password"
                                    autoFocus={false}
                                    value={loginForm['client.password']}
                                    onChangeHandler={handleChange('client.password')}
                                    label={getCaption('web.fields.placeholder.password')}
                                    placeholder={getCaption('web.fields.placeholder.password')}
                                    autoComplete="off"
                                />
                                <div className={style.passwordIcons}>
                                    <span
                                        onClick={() => setPasswordType(!passwordType)}
                                        className={!passwordType ? style.toggleShowPassword : style.toggleShowPassword + ' ' + style.active}
                                    >
                                        <span className={style.toolTip}>
                                            {passwordType ? getCaption('tooltip.show.password') : getCaption('tooltip.hide.password')}
                                        </span>
                                    </span>
                                    <div ref={resetPasswordButton}>
                                        <span
                                            className={style.resetPassword}
                                            onClick={handleResetPopup}
                                            
                                        >
                                            {!resetPopup && <span className={style.toolTip}>
                                                {getCaption('tooltip.reset.password')}
                                            </span>}
                                        </span>
                                        {resetPopup &&
                                            <ResetPassword
                                                isAuth={auth}
                                                username={loginForm['client.username']}
                                                isRedirect={isRedirectToGame}
                                                popup={resetPopup}
                                                clickResult={() => setResetPopup(false)}
                                            />
                                        }
                                    </div>
                                </div>

                                <div className={style.error}>{getCaption(errors.value)}</div>
                            </div>

                            <button className={`${style.submitButton} ${showLoader ? style.showLoader : ''}`}>
                                {getCaption('client.sign.in')}
                                {showLoader &&
                                    <div className={style.loading}>
                                        <div className={style.carousel} />
                                    </div>
                                }
                            </button>

                            {!auth && (isRedirectToGame || isPopupMode) &&
                                <Registration buttonType={'modalType'} registerPopup={registerPopup} />
                            }
                        </div>
                    </form>

                    {blockAccount &&
                        <BlockPopup
                            isOpen={blockAccount.popup}
                            closeModal={() => setBlockAccount({ ...blockAccount, popup: false })}
                            unblockDate={blockAccount.unblockDate}
                        />
                    }
                </div>
            }

            {isPopupMode &&
                <LoginButton
                    showLoader={showLoader}
                    openModal={() => setShowLoginForm(true)} buttonType={buttonType}
                />
            }
        </>
    )
}
