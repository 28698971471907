import React, {useContext, useEffect, useState} from 'react'
import { Captions } from '../../../../Services/Hooks/captions';
import MobileMenuFromBottom from "../../../../Components/MobileMenuFromBottom/MobileMenuFromBottom";

import style from './Providers.module.css'
import CustomInput from "../../../../Components/CustomInput/CustomInput";

function Providers({ providers, selectedProviders, filterGamesByProviders, clearProviders }) {
    const [result, setResult] = useState('');
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [hideListMenu, setHideListMenu] = useState(false);
    const [hideListMenuAfterDelay, setHideListMenuAfterDelay] = useState(true);

    const { getCaption } = useContext(Captions);

    useEffect(() => {
        let filteredArr = [];
        providers.forEach((provider, index) => {
            if(result.length > 0 && provider[0].indexOf(result) !== -1) {
                filteredArr.push(index);
            }
            setFilteredProviders(filteredArr.length > 0 ? filteredArr : []);
        });
    }, [result, providers])

    const showModal = () => {
        setHideListMenu(true);
        setHideListMenuAfterDelay(true);
    }

    const closeModal = () => {
        setHideListMenuAfterDelay(false);
        setTimeout(() => setHideListMenu(false), 195)
    }
    return (
        <>
            {hideListMenu &&
                <MobileMenuFromBottom
                    title={getCaption('web.providers.title')}
                    hideListMenu={hideListMenu}
                    closeModal={() => closeModal()}
                    hideListMenuAfterDelay={hideListMenuAfterDelay}
                    setHideListMenu={() => setHideListMenu(false)}
                    menuItemList={providers.length > 0 && providers.map((item, index) => (
                        <div
                            key={index}
                            className={`${style.item} ${selectedProviders.indexOf(item[0]) !== -1 ? style.active : ''}`}
                            onClick={() => {
                                closeModal();
                                filterGamesByProviders(item[0]);
                            }}
                            style={{
                                backgroundImage: 'url(' + item[1].providerWebPageItem.bannerName + ')'
                            }}
                        />
                    ))
                    }
                >
                    <div className={style.header}>
                        <div className={`${style.clear} ${selectedProviders.length > 0 ? style.active : ''}`} onClick={() => clearProviders()} >Clear all</div>
                    </div>


                    <div className={style.searchGroup}>
                        <div className={style.inputGroup}>
                            <CustomInput
                                className="autoWidth"
                                name="search"
                                type="text"
                                value={result}
                                label={'Search'}
                                placeholder={'Search'}
                                onChangeHandler={(e) => setResult(e.target.value)}
                                autoComplete="off"
                            />
                            { result.length > 0 && <div className={style.clearSearchValue} onClick={() => setResult('')} /> }
                        </div>
                        { filteredProviders.length > 0 &&
                            <ul className={style.listContainer}>
                                {filteredProviders.map((index, keyIndex) => (
                                    <li
                                        key={keyIndex}
                                        className={style.listItem}
                                        onClick={() => {
                                            filterGamesByProviders(providers[index][0]);
                                            setResult('');
                                            closeModal();
                                        }}
                                    >
                                        <div
                                            key={index}
                                            style={{
                                                backgroundImage: 'url(' + providers[index][1].providerWebPageItem.bannerName + ')'
                                            }}
                                            className={`${style.listItemImage}`}
                                        />
                                        <h3>{providers[index][0]}</h3>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                </MobileMenuFromBottom>
            }
            <div className={style.showProviders} onClick={showModal} />
            {/*<div className={style.container}>*/}
            {/*    <CloseButton clickHandler={closeProviders} />*/}
            {/*    <div className={style.scrollView}>*/}
            {/*        { providers.length > 0 && providers.map((item, index) => (*/}
            {/*                <div */}
            {/*                    key={index}*/}
            {/*                    className={`${style.item} ${selectedProviders.indexOf(item[0]) !== -1 ? style.active : ''}`} */}
            {/*                    onClick={() => filterGamesByProviders(item[0])}*/}
            {/*                    style={{*/}
            {/*                        backgroundImage: 'url(' + item[1].providerWebPageItem.bannerName + ')'*/}
            {/*                    }}*/}
            {/*                />*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default Providers
