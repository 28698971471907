import React, {useState, useEffect, useContext} from 'react';
import CustomSelect from '../CustomSelect/Select';
import CustomInput from '../CustomInput/CustomInput';
import { RegistrationApi } from '../../Services/Hooks/registrationApi';

import style from './ContactsPhoneNumber.module.css'

const ContactsPhoneNumber = ({ label, setData, errorCode, formData }) => {
    const [lists, setLists] = useState({});
    const [countryCode, setCountryCode] = useState('');
    const [filterCountryCode, setFilterCountryCode] = useState(null);

    const responses = useContext(RegistrationApi);
    const values = responses.val;

    if (!values) {
        responses.loadValues();
    }

    useEffect(() => {
        if(!values) return;
        const registrationCountryCode = values.registrationCountrycode;
        if(registrationCountryCode) {
            setCountryCode(registrationCountryCode);
        }

        const registrationCountryCallingCode = values.registrationCountryCallingCode;
        if(registrationCountryCallingCode) {
            setLists(registrationCountryCallingCode);
            let data = registrationCountryCallingCode.filter(item => item.attribute2 === countryCode);
            if(data.length > 0) setData('contacts.mobile.code', data[0].itemValue);
            setFilterCountryCode(data);
        }
    }, [values, countryCode])

    const handleChange = name => event => {
        setData([name], event.target.value);
    }
    
    return (
        <div className={style.row}>
            {filterCountryCode && filterCountryCode.length > 0 && 
                <div className={style.selectBox}>
                    {<p className={style.title}>{"Phone Number"}</p>}
                    <CustomSelect 
                        allowInputField={true}
                        selectedField={filterCountryCode[0]} 
                        fieldName="contacts.mobile.code"
                        selectName={'+' + filterCountryCode[0].itemValue} 
                        selectClassName={'Code'}
                        data={lists}
                        onSelect={(fieldName, selectName, option, selectKey) => setData(fieldName, option)}
                    />
                </div>
            }
            <div className={style.input}>
                <CustomInput 
                    className="autoWidth"
                    errorCode={errorCode}
                    type="number"
                    name="phone-number"
                    value={formData['contacts.phone.number'] || ''}
                    onChangeHandler={handleChange('contacts.phone.number')}
                    label={label}
                    placeholder={label}
                    autoComplete="off"
                />
            </div>
        </div>
    )
}

export default ContactsPhoneNumber