import React, {useState, useEffect, useContext} from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Gifts from '../../Gifts/Gifts';
import MainPage from '../../MainPage/MainPage';
import JetXPage from '../../JetXPage/JetXPage'
import GamePage from '../../GamePage/GamePage'
import Transactions from '../../Transactions/Transactions'
import GameLuncher from '../../GameLauncher/GameLauncher';
import SportsPage from '../../../Mobile/SportsPage/SportsPage';
import ProtectedRoute from './ProtectedRoute';
import Contact from '../../Contact/Contact';
import LogInHistory from '../../LogInHistory/LogInHistory';
import ErrorPage from "../../ErrorPage/ErrorPage";
// import PromotionsPage from '../../PromotionsPage/PromotionsPage';
import Notifications from '../../Notifications/Notifications';
import TermsAndConditions from '../Footer/Information/TermsAndConditions/TermsAndConditions';
import ResponsibleGaming from '../Footer/Information/ResponsibleGaming/ResponsibleGaming';
import ClientDetails from '../../ClientDetails/ClientDetails';
import SearchedGames from '../../../../Components/SearchedGames/SearchedGames';
import CookiePolicy from '../Footer/Information/CookiePolicy/CookiePolicy';
import PrivacyStatement from '../Footer/Information/PrivacyStatement/PrivacyStatement';
import AgeRestrictionPolicy from '../Footer/Information/AgeRestrictionPolicy/AgeRestrictionPolicy';
import PromotionsPage from '../../../Mobile/PromotionsPage/PromotionsPage';
import { SetItemToLocalStorage } from '../../../../Services/common';
import GameRules from '../Footer/Information/GameRules/GameRules';
import Favorites from '../../Favorites/Favorites';
import PaymentSuccess from '../../../../Components/PaymentSuccess/PaymentSuccess';
import { Auth } from '../../../../Services/Hooks/auth';
const Routes = () => {
    const [gameIsStarted, setGameIsStarted] = useState(false);
    const { pathname } = useLocation();
    const { updateClientInfo } = useContext(Auth);
    useEffect(() => {
        if(pathname !== '/GameLauncher') {
            SetItemToLocalStorage('pathname', pathname);
        }
        setGameIsStarted(pathname === '/GameLauncher');
        if(gameIsStarted && pathname !== '/GameLauncher') updateClientInfo();
    }, [pathname, gameIsStarted, updateClientInfo])
    useEffect(() => {
        const doc = document.documentElement
        doc.style.setProperty('--header-sticky', `${pathname ===  '/Sports' || pathname ===  '/Live' ? 'fixed' : 'relative'}`);
        doc.style.setProperty('--header-top', `${pathname ===  '/Sports' || pathname ===  '/Live' ? '0' : '0'}`);
        doc.style.setProperty('--footer-display', `${pathname ===  '/Sports' || pathname ===  '/Live' ? 'none' : 'block'}`);
    })
    return (
        <Switch>
            <Route exact path="/" render={() => (<Redirect to="/Home" />)} /> 
            <Route exact path="/Home" render={() => <MainPage pageName='MobileMainPage'/>} />
            <Route exact path="/Slots" render={() => <GamePage pageName = 'MobileSlotsPage'/>} />
            <ProtectedRoute path="/Client/Gifts" component={Gifts} />
            <ProtectedRoute exact path="/Profile" component={ClientDetails} />
            <ProtectedRoute exact path="/Notifications" component={Notifications} />
            <Route path="/Live" render={() => <SportsPage pageName='Live' />} />
            <Route path="/Sports" render={() => <SportsPage pageName='Sports' />} />
            <Route exact path="/Casino" render={() => <GamePage pageName = 'MobileCasinoPage'/> }/>
            <Route exact path="/Games" render={() => <GamePage pageName = 'MobileGamesPage'/> }/>
            <Route exact path="/Keno" render={() => <JetXPage pageName='MobileKenoPage' />} />
            <Route exact path="/JetX" render={() => <JetXPage pageName='MobileJetXPage' />} />
            <Route exact path="/Xgames" render={() => <JetXPage pageName = 'MobileXgamesPage'/>} />
            <Route exact path="/GameLauncher" render={() => <GameLuncher /> } />
            <ProtectedRoute exact path="/Transactions" component={Transactions} />
            <Route exact path ="/Contact"  render={() => <Contact  pageName = 'Contact' /> }/>
            <ProtectedRoute exact path ="/LogInHistory"  component={LogInHistory}/>
            <Route  path = "/Promotions" render={() => <PromotionsPage   pageName='PromotionsPageMob'/>} />
            <Route exact path="/TermsAndConditions" render={() => <TermsAndConditions pathName="TermsAndConditions"/>}/>
            <Route exact path="/ResponsibleGaming" render={() => <ResponsibleGaming pathName="ResponsibleGaming" /> }/>
            <Route exact path="/CookiePolicy" render={() => <CookiePolicy />}/>
            <Route exact path="/PrivacyStatement" render={() => <PrivacyStatement /> }/>
            <Route exact path="/AgeRestrictionPolicy" render={() => <AgeRestrictionPolicy /> }/>
            <Route exact path="/GameRules" render={() => <GameRules /> }/>
            <ProtectedRoute exact path="/Favorites" component={Favorites} pageName='MobileFavouritesPage' />
            <ProtectedRoute exact path="/PaymentSuccess" component={PaymentSuccess} pageName='PaymentSuccess' />
            <Route path="/SearchedGames" render={() => <SearchedGames />}/>
            <Route path="*" render={() => <ErrorPage /> }/>
        </Switch>
    )
}

export default Routes;