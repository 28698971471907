import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Slider from '../../../Components/Slider/Slider'
import GameItems from './GameItems/GameItems'
import { GetGames } from '../../../Services/service'
import style from './MainPage.module.css'
const MainPage = (props) => {
    const { pathname } = useLocation();
    const [response, setResponse] = useState({ loading: true, data: undefined });
    useEffect(() => {
        // const interval = setInterval(() => {
        GetGames(props.pageName)
            .then(res => {
                if (res.data !== "") {
                    setResponse({
                        loading: false,
                        data: res.data
                    });

                } else {
                    setResponse({
                        loading: true,
                        data: undefined
                    });
                }
            })
        // }, 1000);
        // return () => clearInterval(interval);
    }, [props.pageName]);
    return (
        <>
            <Slider pathName={'Mobile' + pathname.replace('/', '') + 'Main'} />
            <div className={style.wrapper}>
                {!response.loading && response.data.map((category, index) =>
                    <GameItems rerender={GetGames} setResponse={setResponse} key={index} index={index} pageName={props.pageName} pageSectionType={category.pageSectionType}
                        pageSectionTitle={category.pageSectionTitle} items={category.pageItems} />)}
            </div>
        </>
    )
}

export default MainPage;