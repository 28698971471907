import React, {useState,useEffect, useRef, useContext} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import TextareaAutosize from "react-textarea-autosize";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {formatRelative, subDays} from 'date-fns';
import { Auth } from '../../Services/Hooks/auth';
import { Captions } from '../../Services/Hooks/captions';
import {ReCaptcha} from '../ReCaptcha/ReCaptcha';
import ConversationButton from "./ConversationButton/ConversationButton";
import {CreateConversation, AddToConversationItems, GetConversation, EndConversation} from "../../Services/service";
import {GetItemFromLocalStorage, SetItemToLocalStorage} from "../../Services/common";

import style from './Conversation.module.css';

function Conversation({ isMobile, buttonType, createConversationHandler, closeConversation }) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(true);
    const [showConversation, setShowConversation] = useState(false);
    const [showEndConversation, setShowEndConversation] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [conversationId, setConversationId] = useState(GetItemFromLocalStorage('conversationId'));
    const [messagesList, setMessagesList] = useState([]);
    const [fold, setFold] = useState(false);
    const [foldMessageCount, setFoldMessageCount] = useState(0);
    const [counterNewMessages, setCounterNewMessages] = useState(false);
    const [isEnterKeyPress, setIsEnterKeyPress] = useState(false);
    const scrollElement = useRef(null);
    let messagesLength = 0;
    const { getCaption } = useContext(Captions);
    const { auth } = useContext(Auth);
    
    isMobile && conversationId ? document.body.classList.add('fixed') : document.body.classList.remove('fixed');

    useEffect(() => {

        if(closeConversation) {
            if(messagesList.length === 0) {
                endConversation();
                setShowEndConversation(false);
            } else {
                setShowEndConversation(true)
            }
        }

        const interval = setInterval(() => {
            if(conversationId) {
                if (!executeRecaptcha) return;
                executeRecaptcha('contact')
                    .then(res => {
                        if(res) {
                            GetConversation({captchaToken: res, conversationId: conversationId}, auth)
                                .then(res => {
                                    setMessagesList(res.data.conversationItems);
                                    if(messagesLength !== res.data.conversationItems.length) {
                                        scrollTo();
                                        if(!fold) setFoldMessageCount(res.data.conversationItems.length);
                                        else setCounterNewMessages(res.data.conversationItems.length - foldMessageCount);
                                    }
                                    messagesLength = res.data.conversationItems.length;
                                })
                        }
                    });
            }
        }, 1000)

        if(isEnterKeyPress) {
            if (!executeRecaptcha) return;
            executeRecaptcha('contact')
                .then(res => {
                    if(res) {
                        sendMessage(res);
                        setIsEnterKeyPress(false);
                    }
                });
        }

        if(conversationId) {
            if(createConversationHandler) createConversationHandler(true);
            setLoading(false);
        }

        return () => clearInterval(interval);
        
    }, [conversationId, executeRecaptcha, fold, foldMessageCount, isEnterKeyPress, closeConversation])

    const createConversation = (token) => {
        if(conversationId) {
            return setFold(!fold)
        }
        setShowConversation(true);
        if(createConversationHandler) createConversationHandler(true);
        CreateConversation({captchaToken: token}, auth)
            .then(res => {
                if(res.data) {
                    setShowEndConversation(false);
                    setLoading(false);
                    setConversationId(res.data);
                    SetItemToLocalStorage('conversationId', res.data);
                }
            })
    }

    const sendMessage = (token) => {
        if(token) {
            AddToConversationItems({captchaToken: token, conversationId: conversationId, conversationItems: [{text: messageText}]}, auth)
                .then(res => {
                    if(res) setMessageText('');
                })
        }
    }

    const endConversation = () => {
        if(conversationId) {
            if (!executeRecaptcha) return;
            executeRecaptcha('contact')
                .then(res => {
                    if(res) {
                        EndConversation({captchaToken: res, conversationId: conversationId}, auth)
                            .then(res => {
                                if(res.data === "Success") {
                                    localStorage.removeItem("conversationId");
                                    setShowConversation(false);
                                    setConversationId(null);
                                    setShowEndConversation(false);
                                    setMessagesList([]);
                                    if(createConversationHandler) createConversationHandler(false);
                                }
                            })
                    }
                });
        } else {
            setShowConversation(false);
            if(createConversationHandler) createConversationHandler(false);
        }
    }

    const formatDate = (createdDate) => {
        let clientTimezone = new Date(new Date(createdDate).getTime() - new Date(createdDate).getTimezoneOffset() * 60 * 1000)
        let value = formatRelative(subDays(new Date(clientTimezone), 3), new Date()).split(' ');
        return value[value.length - 2] + ' ' + value[value.length - 1]
    }

    const scrollTo = () => {
        scrollElement.current.scrollIntoView({ behavior: 'smooth' })
    }

    const onKeyDownHandler = (e) => {
        let keyCode = e.wich || e.keyCode;
        if(keyCode === 13) {
            e.preventDefault();
            setIsEnterKeyPress(true);
        }
    }

    const closeConversationHandler = (event) => {
        event.stopPropagation();
        if(messagesList.length === 0) {
            endConversation();
            setTimeout(() => setFold(false), 1000);
            return;
        }
        setShowEndConversation(!showEndConversation);
        setFold(false);
    }

    return (
        <>
            {(showConversation || conversationId) &&
                <div className={fold ? isMobile ? style.isMobile : style.conversation + ' ' + style.foldPosition : isMobile ? style.isMobile : style.conversation}>
                    <div 
                        className={`${style.header} ${counterNewMessages > 0 ? style.newMessages : ''}`}
                        onClick={() => fold ? setFold(false) : ''} 
                    >
                        {   !loading ?
                            // <h4 className={style.headerTitle}>
                            //     {fold ? 
                            //         counterNewMessages > 0 ? 
                            //             `${counterNewMessages + ' ' + getCaption('web.conversation.fold.position.new.message.received')}` 
                            //             : getCaption('web.conversation.fold.position.no.new.message') : getCaption('web.conversation.operator.online')}
                            // </h4>
                            <h4 className={style.headerTitle}>
                                {!fold &&  getCaption('web.conversation.operator.online')}
                            </h4>
                            :
                            <div className={style.loading}>
                                <div className={style.loader} />
                            </div>
                        }
                        <div className={style.conversationOptions}>
                            { !showEndConversation && <button onClick={() => setFold(true)} type="button" className={style.fold} />}
                            {!fold && <button 
                                onClick={closeConversationHandler} 
                                type="button" 
                                className={style.close} 
                            />}
                        </div>
                    </div>
                    <div className={`${style.content} ${showEndConversation ? style.blur : ''}`}>
                        {messagesList.map(message =>
                            <div key={message.conversationItemId} className={style[message.senderType]}>
                                <div className={style.avatar} />
                                <div className={style.text}>
                                    <div className={style.message}>{message.text}</div>
                                    <div className={style.date}>
                                        {getCaption(`web.conversation.message.description.${message.senderType === 'Client' ? 'you' : 'operator'}`) + ' ' + formatDate(message.createDate)}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div ref={scrollElement} />
                    </div>
                    <div className={fold ? style.foldPosition + ' ' + style.editableContentParent : style.editableContentParent}>
                        <div className={style.editableContent}>
                            <div className={style.messageTextFieldContent}>
                                <TextareaAutosize
                                    rowsmax={3}
                                    type="text"
                                    placeholder={getCaption('web.conversation.message.field.placeholder')}
                                    value={messageText}
                                    className={style.messageTextField}
                                    onKeyDown={(e) => onKeyDownHandler(e)}
                                    onChange={(event) => setMessageText(event.target.value)}
                                    onInput={event => setMessageText(event.target.value)}
                                />
                            </div>
                            <div className={style.messageOptions}>
                                <ReCaptcha onVerifyCaptcha={(token)=>{sendMessage(token)}}>
                                    <button type="button" className={style.sendMessage} />
                                </ReCaptcha>
                            </div>
                        </div>
                    </div>
                    <div className={showEndConversation ? style.endConversation : style.endConversation + ' ' + style.hidden}>
                        <div className={style.endConversationContent}>
                            <h5>{getCaption('web.conversation.end.conversation.text')}</h5>
                            <div className={style.endConversationButtons}>
                                <button 
                                    className={style.goBackButton}
                                    onClick={() => {
                                        if(createConversationHandler) createConversationHandler(true);
                                        setShowEndConversation(!showEndConversation)
                                    }}
                                >
                                    {getCaption('web.conversation.button.go.back')}
                                </button>
                                <button 
                                    onClick={endConversation} 
                                    className={style.endConversationButton}
                                >
                                    {getCaption('web.conversation.button.end.conversation')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                }
                
                <ConversationButton isMobile={isMobile} buttonType={buttonType} handleConversation={createConversation} />
            {/* } */}
        </>
    )
}

export default Conversation;
