import React, { useState, useEffect, useContext } from 'react';
import style from './PopUpMenu.module.css'
import { useLocation, useHistory } from "react-router";
import MobileMenuFromBottom from '../MobileMenuFromBottom/MobileMenuFromBottom';


const PopUpMenu = (props) => {
    const [menuTitle, setMenuTitle] = useState('');
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [hideListMenu, setHideListMenu] = useState(false);
    const [hideListMenuAfterDelay, setHideListMenuAfterDelay] = useState(true);
    let history = useHistory();
    let location = useLocation();

    const goToSettingPage = (index) => {
        setMenuTitle(props.menuItems[index].title);
        setActiveTabIndex(index);
        setHideListMenu(false);
        if(props.isRedirect) history.push(props.menuItems[index].pathName);
        document.body.classList.remove('fixed');
    }

    useEffect(() => {
        setMenuTitle(props.menuItems.filter(item => item.pathName === location.pathname)[0].title);
    }, [location, props.menuItems])

    const closeModal = () => {
        setHideListMenuAfterDelay(false);
        setTimeout(() => setHideListMenu(false), 195)
    }
    
    return (
        <div className={style.menuContainer}>
            <div
                className={`${style.menu} ${location.pathname === '/Gamerules' ? style.smallMenu : ''}`}
                id={props.pageName === "MobileFavouritesPage" ? style.smallMenu : ''}
                onClick={() => { setHideListMenu(true); setHideListMenuAfterDelay(true) }}>
            <div className={style.menuBtn}/>
             <div className={style.menuItemTitle}>{props.title}</div>   
            </div>
            { hideListMenu &&  
                <MobileMenuFromBottom  
                    title={menuTitle}
                    hideListMenu={hideListMenu}
                    closeModal={() => closeModal()}
                    hideListMenuAfterDelay={hideListMenuAfterDelay}
                    setHideListMenu={() => setHideListMenu(false)}
                    menuItemList={props.menuItems.map((item, index) =>
                        item !== '' && <p key={index} onClick={() => goToSettingPage(index)}>{item.title}</p>
                    )}
                />
            }
        </div>
    );
}
export default PopUpMenu;