import React, { Fragment } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import './Slider.css';
import WelcomePopUpSliderItem from '../../Pages/Desktop/Layout/HeaderContainer/Header/WelcomePopUp/WelcomePopUpSliderItems/WelcomePopUpSliderItems';
import DepositCardItem from '../../Pages/Desktop/Deposit/DepositCardItem/DepositCardItem';
import DepositAmountItem from '../../Pages/Mobile/Deposit/DepositAmountItem/DepositAmountItem';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const MultiBannersSlider = (props) => {
  const slides = [];

  const makeArray = (arr, n) => {
    let outerArray = [];
    let innerArray = [];  
    for(let i = 0; i < arr.length;i ++) {
      innerArray.push(arr[i]);
      if(i > 0 && (i + 1) % n === 0) {
        outerArray.push(innerArray);
        innerArray = [];
      }
    }
    if(innerArray.length > 0)
      outerArray.push(innerArray);
    return outerArray;
  }

  if(props.type !== "banner") {

    for (let i = 0; i < props.itemsList.length; i++) {
      slides.push(
        <SwiperSlide key={`slide-${i}`} tag="li">

          {/* {props.className === 'deposit' ?
            <DepositCardItem /> :
            props.className === 'depositMobile' ?
            <DepositAmountItem /> :
            <WelcomePopUpSliderItem />
          } */}
          {
            props.itemsList[i]
          }
        </SwiperSlide>
      );
    } 
  } else {
    const result = makeArray(props.itemsList, props.showSlideItem)
    for (let i = 0; i < result.length; i++) {
      slides.push(
        <SwiperSlide key={`slide-${i}`} tag="li">
          { result[i].map((item, index) => <Fragment key={index}>{item}</Fragment>)}
        </SwiperSlide>
      );
    }
  }

  return (
    <Swiper
      id="main"
      tag="section"
      wrapperTag="ul"
      navigation={true}
      pagination={!props.pagination ? { el: null } : { clickable: true }}
      spaceBetween={props.spaceBetweenSlides}
      slidesPerView={props.showSlideCounter}
      className={`${props.className} grid-${props.showSlideItem}`}
      loop={props.loop}
      autoplay={props.autoplay}
      delay={5000}
      disableoninteraction="false"
      speed={1000}
      preventClicks={true}
      preventClicksPropagation={true}
      slideToClickedSlide={true}
      touchEventsTarget='wrapper'
      slidesPerColumn={props.slidesPerColumn}
      slidesPerColumnFill={props.slidesPerColumnFill}
    >
      
      {slides}
    </Swiper>
  );
}


export default MultiBannersSlider;
