import React from 'react'
import style from './Game.module.css'
import GameAuthenticatedRedirect from '../../../../../Components/GameAuthenticatedRedirect/GameAuthenticatedRedirect';

const Game = (props) => {
    return (
        <div 
            className={`${style.slot} ${style[props.itemType] ? style[props.itemType] : ''}`} 
        >
                <GameAuthenticatedRedirect src={props.source} category={props.category} url={props.redirectUrl} setResponse={props.setResponse} 
                channelName={props.channelName} gameName={props.gameName} productName={props.productName} rerender={props.rerender} pageName={props.pageName} 
                isFavorite={props.isFavorite} sectionType={props.sectionType} setCategory={props.setCategory}/>
        </div>
    )
}

export default Game