import {useAuthorizedGet, useGet} from './Hooks/get';
import {GetBaseUrl, GetItemFromLocalStorage} from './common';
import axios from 'axios';
import {isMobile} from 'react-device-detect';
// API CALLS

export const GetMainMenu = (PageName = 'SlotsPage') => {
    const { loading, data } = useGet(`/common/PageInfo?PageName=${PageName}`);
    return { loading, data };
}

export const GetRegisterBanner = (PageName = 'SlotsPage') => {
    return axios.get(GetBaseUrl() + `/common/PageInfo?PageName=${PageName}`);
}

export function GetGifts(categoryName) {
    return AuthorizedGet(GetBaseUrl() + `/client/ActiveGifts?Category=${categoryName}`);
}
export function GetSliderItems(categoryName, languageCode) {
    const {loading, data } = useGet(`/common/${isMobile ? 'MobileBannerList' : 'BannerList'}?bannerCategory=${categoryName}&LanguageCode=${languageCode}`);
    return {loading, sliderItems: data };  
}

export function GetGameUrl(channelName, productName, isMobile, lang){
    return AuthorizedGet(GetBaseUrl() + `/game/GameLaunchUrl?channelName=${channelName}&productName=${productName}&isMobile=${isMobile}&lang=${lang}`) 
} 

export async function GetCaptions(lang) {
    const response = await GetSiteConfiguration().catch(() => { });
    return axios.get(GetBaseUrl() + `/common/Captions?languageCode=${lang}&v=${response?.data?.localeVersion ?? ""}`);
} 

export function GetGlobalParameter(lang, key) {
    return axios.get( GetBaseUrl() + `/Common/GetGlobalParameter?languageCode=${lang}&parameterKey=${key}`);
}

export function GetContactGames() {
    return axios.get(GetBaseUrl() + `/common/${isMobile ? 'MobilePageSections' : 'PageSections'}?PageName=Contact`);
}

export function GetContactMails(pageName){
    return axios.get(GetBaseUrl() + `/Common/PageSections?pageName=${pageName}`)
}

export function GetProvidersAndProducts(categoryName) {
    return axios.get(GetBaseUrl() + `/common/GetProvidersAndProducts?categoryName=${categoryName}`);
}

// <Notification>
export function GetNotifications(languageCode, lastNotificationId) {
    return axios.get(GetBaseUrl() + `/notification/popup?languageCode=${languageCode}&lastNotificationId=${lastNotificationId}`);
}
export function GetPopupNotifications(languageCode) {
    return AuthorizedGet(GetBaseUrl() + `/Notification/GetPopupNotifications?languageCode=${languageCode}`);
}
export function MarkAsViewNotification(notificationTemplateId) {
    return AuthorizedGet(GetBaseUrl() + `/Notification/MarkAsViewNotification?notificationTemplateId=${notificationTemplateId}`);
}
// />

// <Registration>
export function GetRegistrationInfo(){
    return axios.get(GetBaseUrl() + `/registration/GetInfo`);
} 

export function GetRegistrationCountrycode(){
    return axios.get(GetBaseUrl() + `/Common/GetCountrycode`);
} 

export function GetRegistrationPasswordRegex() {
    return axios.get(GetBaseUrl() + `/common/CustomLists?customlisttype=PasswordRegex`);
} 

export function GetRegistrationDateOfBirthPicker() {
    return axios.get(GetBaseUrl() + `/common/CustomLists?customlisttype=DateOfBirthPicker`);
} 

export function GetRegistrationCurrency() {
    return axios.get(GetBaseUrl() + `/common/CustomLists?customlisttype=Currency`);
}

export function IsUserNameAlreadyTaken(userName){
    return axios.get(GetBaseUrl() + `/Registration/IsUserNameAlreadyTaken?userName=${userName}`);
} 
// />

// export function GetPromotions(pageName) {
//     return axios.get(GetBaseUrl() + `/Common/GetPromotions?pageName=${pageName}`);
// }
export function GetPromotions() {
    return axios.get(GetBaseUrl() + `/Common/GetPromotions`);
}
export function GetTermsAndConditions() {
    return axios.get(GetBaseUrl() + `/Common/GetTermsAndConditions`);
}

export function GetRegistrationCountryCallingCode() {
    return axios.get(GetBaseUrl() + `/common/CustomLists?customListType=CountryCallingCode`);
} 

export function PostValidateRegistrationInfo(data) {
    return axios.post(GetBaseUrl() + '/registration/ValidateInputs', data);
}

export function PostRegisterUser(data) {
    return axios.post(GetBaseUrl() + '/registration/RegisterClient', data);
}

export function PostLoginUser(data) {
    return axios.post(GetBaseUrl() + '/Authentication/Authenticate', data);
}

export function PostRefreshToken(data) {
    return AuthorizedPost(GetBaseUrl() + '/Authentication/RefreshToken', data);
}

export function GetClientInfo(isReload) {
    return AuthorizedGet(GetBaseUrl() + `/Client/ClientInfo?reload=${(!!isReload)}`);
}

export function GetClientActiveGifts() {
    const {loading, data} = useAuthorizedGet(GetBaseUrl() + '/Client/ActiveGifts');
    return {loading, data};
}

export function GetClientDetails() {
    return AuthorizedGet(GetBaseUrl() + '/Client/ClientDetails');
}

export function PostUpdateDetails(data) {
    return AuthorizedPost(GetBaseUrl() + '/Client/UpdateDetails', data);
}

export function PostValidateOtp(data) {
    return AuthorizedPost(GetBaseUrl() + '/Verification/VerifyAttribute', data);
}


export function PostValidateCode(data) {
    return AuthorizedPost(GetBaseUrl() + '/Verification/ValidateOtp', data);
}

export function IdPersonalVerification(data) {
    return AuthorizedPost(GetBaseUrl() + '/Verification/IdPersonalVerification', data);
}

export function GetGames(pageName) {
    return AuthorizedGet(GetBaseUrl() + `/common/${isMobile ? 'MobilePageSections' : 'PageSections'}?PageName=${pageName}`)   
}

export const AddToFavorites = (data) => {
    return AuthorizedPost(GetBaseUrl() + `/Game/AddToFavourites`, data)
}

export const RemoveFromFavorites = (data) => {
    return AuthorizedPost(GetBaseUrl() + `/Game/RemoveFromFavourites`, data)
}

// export const SearchAllItems = (pattern) => {
//     return axios.get(GetBaseUrl() + `/Common/SearchAllItems?pattern=${pattern}`)
// }

export const SearchPageItems = (pattern, pageName) => {
    if(pattern == null) return AuthorizedGet(GetBaseUrl() + `/Common/SearchPageItems?pattern=&pageName=${pageName}`)
    return AuthorizedGet(GetBaseUrl() + `/Common/SearchPageItems?pattern=${pattern}&pageName=${pageName}`)
}

export const AuthenticationLog = () => {
    const {loading, data} = useAuthorizedGet(`/Client/AuthenticationLog`)
    return {loading, data}
}

export const GetSiteConfiguration = () => {
    return axios.get(GetBaseUrl() + `/Common/GetSiteConfiguration`);
}

export function GetPasswordResetInfo(){
    return axios.get(GetBaseUrl() + `/PasswordReset/GetInfo`);
} 

export function PostValidateRequest(data){
    return axios.post(GetBaseUrl() + `/PasswordReset/ValidateRequestAndSendOtp`, data)
}

export function PostVerifyOtp(data){
    return axios.post(GetBaseUrl() + `/PasswordReset/VerifyOtpCode`, data)
}

export function PostChangePassword(data){
    return axios.post(GetBaseUrl() + `/PasswordReset/ChangeClientPassword`, data)
}

// <Payment>
export function GetClientPayments() {
    // const {loading, data} = useAuthorizedGet('/Payment/Payments');
    return AuthorizedGet(GetBaseUrl() + '/Payment/Payments');
}

export function GetClientPaymentUrl(channelName, productName, invoiceType, amount) {
    return AuthorizedGet(GetBaseUrl() + `/Payment/PaymentUrl?channelName=${channelName}&productName=${productName}&invoiceType=${invoiceType}&amount=${amount}`);
}

export function SendPaymentNotification(invoceNumber) {
    return AuthorizedPost(GetBaseUrl() + `/Common/SendPaymentNotification?invoceNumber=${invoceNumber}`);
}
// />

// <Conversation>
export function CreateConversation(data, isAuth) {
    if(isAuth) return AuthorizedPost(GetBaseUrl() + `/Conversation/CreateConversation`, data);
    return axios.post(GetBaseUrl() + `/Conversation/CreateConversation`, data)
}
export function AddToConversationItems(data, isAuth) {
    if(isAuth) return AuthorizedPost(GetBaseUrl() + `/Conversation/AddToConversationItems`, data);
    return axios.post(GetBaseUrl() + `/Conversation/AddToConversationItems`, data)
}
export function GetConversation(data, isAuth) {
    if(isAuth) return AuthorizedPost(GetBaseUrl() + `/Conversation/GetConversation`, data);
    return axios.post(GetBaseUrl() + `/Conversation/GetConversation`, data);
}
export function EndConversation(data, isAuth) {
    if(isAuth) return AuthorizedPost(GetBaseUrl() + `/Conversation/EndConversation`, data);
    return axios.post(GetBaseUrl() + `/Conversation/EndConversation`, data);
}
// />

// <GameRules>
export const GetGamesAllCategory = () => {
    const { loading, data } = useGet(`/Common/GetGamesAllCategory`);
    return { loading, data };
}

export const GetProvidersForCurrentGameAndInformation = (categoryName, languageCode) => {
    return axios.get(GetBaseUrl() + `/Common/GetProvidersForCurrentGameAndInformation?categoryName=${categoryName}&languageCode=${languageCode}`)
}
// />

export function AuthorizedGet(url, data) {
    let accessToken = GetItemFromLocalStorage('accessToken');
    return axios.get(url,{
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

export function AuthorizedPost(url, data) {
    let accessToken = GetItemFromLocalStorage('accessToken');
    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    })
}

/* Account block */
export const GetAccountBlockAllowedHours = () => {
    const {loading, data} = useAuthorizedGet('/Client/GetAccountBlockAllowedHours');
    return {loading, data};
}

export const BlockClientAccount = (hours) => {
    return AuthorizedGet(GetBaseUrl() + `/Client/BlockClientAccount?hours=${hours}`)
}
/*Transactions*/
export function GetClientTransactions() {
    return AuthorizedGet(GetBaseUrl() + `/Payment/GetPaymentHistory`);
}
// export function


