import React, { useState, useEffect, useContext } from 'react'
import CustomInput from '../CustomInput/CustomInput';
import { Captions } from '../../Services/Hooks/captions';

import style from './DateOfBirth.module.css'

const DateOfBirth = ({ setData, errorCode, setErrors, formData, className }) => {
    const [titileHide, setTitileHide] = useState(false);
    const [value, setValue] = useState({day: '', month: '', year: ''});

    const { getCaption } = useContext(Captions);
    
    useEffect(() => {
        if(formData['client.date.of.birth']) {
            setValue({
                day: formData['client.date.of.birth'].split('/')[0],
                month: formData['client.date.of.birth'].split('/')[1],
                year: formData['client.date.of.birth'].split('/')[2],
            });
        }
    }, [formData])

    const returnDateFormat = (option, val) => {
        value[option] = val
        return `${value.day}/${value.month}/${value.year}`
    }
    const handleChange = option => event => {
        const { isValid } = validateFieldOnChange(option, event.target.value);
        if(isValid) 
            setData(returnDateFormat(option, event.target.value))
    }   

    const validateFieldOnChange = (option, value) => {
        if(option !== 'year') {
            if(value.length > 2) {
                return { isValid: false }
            }
        } else {
            if(value.length > 4) {
                return { isValid: false }
            }
        }
        return { isValid: true }
    }

    const validateFieldOnBlur = () => {
        const { day, month, year } = value;
        if(parseFloat(day) < 10 && day.length === 1) {
            setData(`0${day}/${month}/${year}`)
        }

        if(parseFloat(month) < 10 && month.length === 1) {
            setData(`${day}/0${month}/${year}`)
        }
        if(parseFloat(year) < 1930) {
            setErrors('client.date.of.birth', 'web.datetime.is.invalid');
        }
    }

    const handleBlur = () => {
        if(value.day.length === 0 && value.month.length === 0 && value.year.length === 0) {
            setTitileHide(false);
        }
        validateFieldOnBlur();
    }

    const onFocusHandler = () => {
        if(value.day.length === 0 && value.month.length === 0 && value.year.length === 0) {
            setTitileHide(true);
        }
    }
    
    return (
        <>
            {(value.day.length === 0 && value.month.length === 0 && value.year.length === 0) && !titileHide && 
                <p className={style.title}>{getCaption('web.fields.placeholder.dateofbirth')}</p>
            }
            <div className={`${style.row} ${style[className] ? style[className] : ''}`}>

                <div className={style.day}>
                    <CustomInput 
                        className={"autoWidth"}
                        errorCode={errorCode}
                        type='number'
                        name="username"
                        value={value.day || ''}
                        autoFocus={false}
                        onFocusHandler={onFocusHandler}
                        onBlurHandler={handleBlur}
                        onChangeHandler={handleChange('day')}
                        label={getCaption('web.select.date.of.birth.day')}
                        placeholder={getCaption('web.select.date.of.birth.day')}
                        autoComplete="off"
                    />
                </div>

                <div className={style.month}>
                    <CustomInput 
                        className={"autoWidth"}
                        errorCode={errorCode}
                        type='number'
                        name="username"
                        value={value.month || ''}
                        autoFocus={false}
                        onFocusHandler={onFocusHandler}
                        onBlurHandler={handleBlur}
                        onChangeHandler={handleChange('month')}
                        label={getCaption('web.select.date.of.birth.month')}
                        placeholder={getCaption('web.select.date.of.birth.month')}
                        autoComplete="off"
                    />
                </div>
                
                <div className={style.year}>
                    <CustomInput 
                        className={"autoWidth"}
                        errorCode={errorCode}
                        type='number'
                        name="username"
                        value={value.year || ''}
                        autoFocus={false}
                        onFocusHandler={onFocusHandler}
                        onBlurHandler={handleBlur}
                        onChangeHandler={handleChange('year')}
                        label={getCaption('web.select.date.of.birth.year')}
                        placeholder={getCaption('web.select.date.of.birth.year')}
                        autoComplete="off"
                    />
                </div>
            </div>
        </>
    )
}

export default DateOfBirth