import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Captions } from '../../../../../../Services/Hooks/captions';
import Information from '../Information';
import { GetProvidersForCurrentGameAndInformation } from '../../../../../../Services/service';
import Menu from './Menu/Menu';
import style from './GameRules.module.css';

const GameRules = () => {
    const [currencyCode, setCurrencyCode] = useState('');
    const [providers, setProviders] = useState([]);
    const [activeIndexes, setActiveIndexes] = useState([]);
    const { getCaption } = useContext(Captions);

    const selectMenuItem = (categoryName) => {
        GetProvidersForCurrentGameAndInformation(categoryName, localStorage.getItem('lang'))
            .then(res => {
                setCurrencyCode(res.data.currencyCode);
                setProviders(Object.entries(res.data.gameProvidersWithSettings));
            })
            .catch(error => {
                console.log(error);
            })
    }

    const showRulesContent = index => {
        if(activeIndexes.indexOf(index) === -1) setActiveIndexes([...activeIndexes, index]);
        if(activeIndexes.indexOf(index) !== -1) setActiveIndexes(activeIndexes.filter((item, _index) => _index !== activeIndexes.indexOf(index)));
    };

    return (
        <div className={style.background}>
            <Information />
            <div className={style.content}>
                <div className={style.contentItems}>
                    <Menu selectMenuItem={selectMenuItem} />

                    { providers.length > 0 && providers.map((item, index) => (
                        <div key={index} className={style.rulesItem}>
                            <div 
                                className={style.rulesItemHeader}
                                onClick={() => showRulesContent(index)}
                            >
                                <h4>{item[0]}</h4>
                                <div className={`${style.showRules} ${activeIndexes.indexOf(index) !== -1 ? style.selected : ''}`} />
                            </div>
                            <div className={`${style.rulesItemTable} ${activeIndexes.indexOf(index) !== -1 ? style.selected : ''}`}>
                                <div className={style.rulesItemContent}>
                                { Object.entries(item[1]).map((game, index) => (
                                    <div key={index} className={style.tableRow}>
                                        <div className={style.tableColumnId}>
                                            <div className={style.tableColumnName}>{getCaption('web.games.rules.table.column.number')}</div>
                                            <div className={style.tableColumnValue}>{index + 1}</div>
                                        </div>
                                        <div className={style.tableColumnGame}>
                                            <div className={style.tableColumnName}>{getCaption('web.games.rules.table.column.games')}</div>
                                            <div className={style.tableColumnValue}>{game[1]['aggregator.game.name']}</div>
                                        </div>
                                        <div className={style.tableColumnMinBet}>
                                            <div className={style.tableColumnName}>{getCaption('web.games.rules.table.column.min.bet')}</div>
                                            <div className={style.tableColumnValue}>{game[1]['min.bet']} {currencyCode}</div>
                                        </div>
                                        <div className={style.tableColumnMaxBet}>
                                            <div className={style.tableColumnName}>{getCaption('web.games.rules.table.column.max.bet')}</div>
                                            <div className={style.tableColumnValue}>{game[1]['max.bet']} {currencyCode}</div>
                                        </div>
                                        <div className={style.tableColumnPayouts}>
                                            <div className={style.tableColumnName}>{getCaption('web.games.rules.table.column.payouts')}</div>
                                            <div className={style.tableColumnValue}>{game[1]['win.max']}</div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    
                </div>

            </div>
        </div>

    )
}
export default GameRules;