import axios from 'axios';
import { useEffect, useState } from 'react';
import {GetBaseUrl, GetItemFromLocalStorage} from '../common';

export const useGet = (url) => {
    const [response, setResponse] = useState({ loading: true, data: undefined });
    useEffect(() => {
        axios.get(GetBaseUrl() + url)
            .then(res => {
                setResponse({ data: res.data, loading: false });
            })
            .catch(error => {
                console.log(error);
            })
    }, [url]);

    return response;
}

export const useAuthorizedGet = (url) => {
    const [response, setResponse] = useState({ loading: true, data: undefined });
    const accessToken = GetItemFromLocalStorage('accessToken');
    useEffect(() => {
        axios.get(GetBaseUrl() + url, { headers: {
                'Authorization': `Bearer ${accessToken}`
            }})
            .then(res => {
                setResponse({ data: res.data, loading: false });
            })
            .catch(error => {
                console.log(error);
            })
    }, [url, accessToken]);

    return response;
}
