import React from 'react'
import Sponsors from './Sponsors/Sponsors'
import Certificates from './Certificates/Certificates'
import FooterMenu from './FooterMenu/FooterMenu'
import { GetMainMenu } from '../../../../Services/service'

const Footer = () => {
    let { loading, data} = GetMainMenu();
    return (
        <>
        {!loading && <div>
            <FooterMenu data={data.footerMenu} socialNetworkBanner={data.socialNetworkBanner} supportInfo={data.supportFooterMenu}/>
            <Sponsors data={data.paymentBanners}/>
            <Certificates data={data.footerBanners}/>
        </div>}
    </>
    )
}

export default Footer;