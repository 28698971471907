import React, {useEffect, useState, useRef} from 'react'
import { Link } from "react-router-dom";
import style from './HeaderLogo.module.css'
import { GetSiteConfiguration } from '../../Services/service';

const HeaderLogo = (props) => {
    const unmounted = useRef(false);
    const [data, setData] = useState(null);
    useEffect(() => {
        GetSiteConfiguration()
            .then(response => {
                setData(response.data)
            })
            .catch(err => {
                
            });
            return () => { unmounted.current = true }
    }, [])
    return (
        <>
            {data && <div className={`${style.logo} ${style[props.type]}`} onClick={() => window.location.pathname === "/Home" &&  window.location.reload()}>
                <Link to="/Home">
                    <img src={data.url} alt="logo"></img>
                </Link>
            </div>}
        </>
    )
}

export default HeaderLogo;
