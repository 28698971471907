import React, { useEffect, useContext } from 'react'
import { Auth } from '../../../../../Services/Hooks/auth';
import style from './Header.module.css'
import HeaderLogo from '../../../../../Components/HeaderLogo/HeaderLogo'
import DepositButton from '../../../Deposit/DepositButton/DepositButton'
import Notifications from '../../../Notifications/Notifications'
import UserMenu from './UserMenu/UserMenu'
import Languages from './Languages/Languages'
import Registration from '../../../ClientAuthentication/Registration/Registration'
import Login from '../../../ClientAuthentication/Login/Login'
import WelcomePopUp from './WelcomePopUp/WelcomePopUp'
import useMedia from "../../../../../Services/Hooks/useMedia"
import Promotions from './Promotions/Promotions';
const Header = (props) => {
    const smallScreen = useMedia("(max-width: 1400px)");
    const { auth, clientInfo } = useContext(Auth);
    return (
        <>
            <WelcomePopUp />
            <header className={style.header} id={props.hide ? style.hide : ""}>
                <HeaderLogo />
                <Promotions />
                <div className={style.wrapper}>
                    {auth && clientInfo ?
                        <>
                            <DepositButton clientInfo={props.clientInfo} />
                            <Notifications />
                            <UserMenu clientInfo={props.clientInfo && props.clientInfo} logOutUser={props.logOutUser} />
                            <Languages />
                        </>
                        :
                        <>
                            <Login isPopupMode={smallScreen} redirectUrl={false} isRedirectToGame={false} buttonType={'default'} /> 
                            <Registration buttonType={'default'} />
                            <Languages />
                        </>
                    }
                </div>
            </header>
        </>
    )
}

export default Header;