import React, {useContext} from 'react'
import { Captions } from '../../../../../Services/Hooks/captions'
import style from './Certificates.module.css'

const Certificates = (props) => {
    const { getCaption } = useContext(Captions);
    return (
    <div className={style.container}>
        <div className={style.certificatesWrapper}>

          {props.data && props.data.sort((a, b) => a.orderNumber - b.orderNumber)
          .map((certificate, index) => <div className={style.certificate} key={index}> 
              <img className={style.img} src={certificate.source} alt={certificate.productName}/>
          </div>)}            
        </div>
        <p className={style.license}>{getCaption('web.footer.license')}</p>
        <p className={style.copyright}>{getCaption('web.footer.text')}</p>
    </div>
    )
}

export default Certificates;