import React, { useContext } from 'react';
import VerticalMenu from '../VerticalMenu/VerticalMenu';
import { Captions } from '../../../../../../Services/Hooks/captions'
import style from './PrivacyStatement.module.css';
import { Link } from 'react-router-dom';
const PrivacyStatement = () => {
    const { getCaption } = useContext(Captions);
    return (
        <div className={style.container}>
            <div className={style.aside}>
                <VerticalMenu />
            </div>
            <div className={style.main}>
                <h2 className={style.title}>{getCaption('platform.privacypolicy.privacy.header1')}</h2>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.header2')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.description.text1')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.header3')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.description.text2')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.header4')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.description.text3')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.description.text4')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.header5')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.description.text5')}</p>
                <p className={style.text}>{getCaption('platform.privacypolicy.privacy.header6')}</p>
                
            </div>
        </div>

    )
}
export default PrivacyStatement;