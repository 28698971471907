import React from 'react';
// import { Captions } from '../../../../../Services/Hooks/captions';

import style from "./LoginButton.module.css";

export default function LoginButton({toggleLoginPopup}) {
    // const { getCaption } = useContext(Captions);
    // return <button className={style.button} onClick={toggleLoginPopup} >{getCaption('client.sign.in')}</button>
    return <button className={style.button} onClick={toggleLoginPopup}/>
}
