import React from 'react';
import { Redirect, Route } from "react-router-dom";
import Auth from "../../../../Services/Helpers/authentication";

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (Auth.IsAuthenticated()) {
                    return <Component {...props} {...rest}/>;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
}

export default ProtectedRoute;